const find_error = (response, t) => {

    const errors = [
        {
            "code": 500,
            "description": "Something went wrong",
            "translatedMessage": (t("Something went wrong")),
        },
        {
            "code": 501,
            "description": "No data found",
            "translatedMessage": (t("Δεν βρέθηκαν δεδομένα για τις επιλεγμένες τις ημερομηνίες")),
        },
        {
            "code": 190,
            "description": "Invalid accessToken",
            "translatedMessage": (t("Something went wrong")),
        },
        {
            "code": 200,
            "description": "Missing permissions",
            "translatedMessage": (t("Αν δεν βλέπεις τα KPIs ορισμένων Facebook Pages επισκέψου τα settings του Business manager Account σου και δώσε πρόσβαση στο Προφίλ σου στο συγκεκριμένο Page.")),
        },
        {
            "code": 2500,
            "description": "An active access token must be used to query information about the current user.",
            "translatedMessage": (t("An active access token must be used to query information about the current user.")),
        },
        {
            "code": 100,
            "description": "No data available",
            "translatedMessage": (t("Metrics data is available for the last 2 years")),
        }
    ];

    const responseErrorCode = response?.data?.error?.code;
    const hasData = response?.data?.data;

    const error = errors.find((err) => err.code === responseErrorCode) 
        ? errors.find((err) => err.code === responseErrorCode) 
        : errors.find((err) => err.code === 500) ;


    return hasData ? false : error;
};


export const get_error_translation = (error, t) => {
    const errors = [
        {
            "code": 500,
            "description": "Something went wrong",
            "translatedMessage": (t("Something went wrong")),
        },
        {
            "code": 501,
            "description": "No data found",
            "translatedMessage": (t("No data found")),
        },
        {
            "code": 190,
            "description": "Invalid accessToken",
            "translatedMessage": (t("Invalid accessToken")),
        },
        {
            "code": 200,
            "description": "Missing permissions",
            "translatedMessage": (t("Missing permissions")),
        },
        {
            "code": 2500,
            "description": "An active access token must be used to query information about the current user.",
            "translatedMessage": (t("An active access token must be used to query information about the current user.")),
        }
    ];

    return errors.find((err) => err.code === error.code) ? errors.find((err) => err.code === error.code) : false;
}

export default find_error;
