/* 
    This file contain functions that are used only in slider callbacks.  
     If a function is called at a slider callback but is used in other parts of the application as well, it will not be located in this file.    
*/

import { deepClone } from "../../../../../helpers/DeepCloneObject"
import { deletePresetStructure } from "../../../services/api/PresetStructures"
import { deletePresetStyle } from "../../../services/api/PresetStyles"
import { deleteTemplate } from "../../../services/api/Templates"
import { get_campaigns } from "../../../services/api/MetaApi"
import { getClientCampaigns } from "../../../../google/services/api/GoogleApi"


export const trigger_preset_structure_change_check = (setCreator) => {
    setCreator((prev) => ({
        ...prev, 
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            triggerPresetStructureInitChange: !prev.presetStructureHelper.triggerPresetStructureInitChange
        }
    }))
}


export const set_preset_structure_and_report_title = (structure, setCreator) => {
    setCreator((prev) => ({
        ...prev, 
        presetStructure: structure,
        report: {
            ...prev.report, 
            title: structure.title,
            currency: structure.currency,
        }
    }))
}


export const handle_template_selection = (template, setCreator, setReportStyle, store) => {
    store.dispatch({type: "SAVE_PRESELECTED_TEMPLATE", payload: ""});
    
    setCreator((prev) => ({
        ...prev, 
        presetStructure: template?.presetStructureId,
        report: {
            ...prev.report,
            currency: template?.presetStructureId?.currency,
            title: template?.presetStructureId?.title,
            templateId: template?._id,
            template: template,
        }
    }))
    setReportStyle(template?.presetStyleId);
    trigger_preset_structure_change_check(setCreator);
}


export const trigger_dates_change_check = (setCreator) => {
    setCreator((prev) => ({
        ...prev, 
        datesHelper: {
            ...prev.datesHelper, 
            triggerDateChangeCheck: !prev.datesHelper.triggerDateChangeCheck
        }
    }))
}


export const save_date_format = (e, setCreator, store) => {
    setCreator((prev) => ({...prev, report: {...prev.report, dateFormat: e.target.value}}))
    // store.dispatch({type: "SAVE_CREATOR_DATES_FORMAT", payload: e.target.value})
}


export const save_report_dates = (dates, setCreator, store) => {
    setCreator((prev) => ({...prev, report: {...prev.report, dateFrom: dates.dateFrom, dateTo: dates.dateTo}}))
    // store.dispatch({type: "SAVE_CREATOR_DATES_RANGE", payload: {dateFrom: dates.dateFrom, dateTo: dates.dateTo}})
}


export const update_splitter_text = (creator, setCreator, sectionTitleValue, splitter) => {
    let updatedReportSections = deepClone(creator.report?.sections);
    
    updatedReportSections.find((obj) => 
        obj.type === splitter.type &&
        obj.source === splitter.source &&
        obj.position === splitter.position
    ).sectionTitle = sectionTitleValue

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const update_comment_title = (creator, setCreator, newValue, sectionType, sectionId, idTypeHelper, commentRow, sectionPosition) => {
    let updatedReportSections = deepClone(creator.report?.sections);

    updatedReportSections.find((obj) => 
        obj.position === sectionPosition && 
        obj.type === sectionType &&
        obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId).rows
        .find((row) => row.position === commentRow.position && row.type === commentRow.type).title = newValue
    

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const update_comment_text = (creator, setCreator, newValue, sectionType, sectionId, idTypeHelper, commentRow, sectionPosition) => {
    let updatedReportSections = deepClone(creator.report?.sections);
      
    updatedReportSections.find((obj) => 
        obj.position === sectionPosition && 
        obj.type === sectionType &&
        obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId).rows
        .find((row) => row.position === commentRow.position && row.type === commentRow.type).text = newValue

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}

export const media_update_grid = (creator, setCreator, newValue, sectionType, sectionId, idTypeHelper, mediaRow, sectionPosition) => {
    let updatedReportSections = deepClone(creator.report?.sections);

    updatedReportSections.find((obj) => 
        obj.position === sectionPosition && 
        obj.type === sectionType &&
        obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId).rows
        .find((row) => row.position === mediaRow.position && row.type === mediaRow.type).grid = newValue
    

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}

export const media_update_img = (creator, setCreator, newValue, sectionType, sectionId, idTypeHelper, mediaRow, sectionPosition) => {
    let updatedReportSections = deepClone(creator.report?.sections);

    updatedReportSections.find((obj) => 
        obj.position === sectionPosition && 
        obj.type === sectionType &&
        obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId).rows
        .find((row) => row.position === mediaRow.position && row.type === mediaRow.type).image = newValue
    

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}

export const delete_preset_structure = (structure, setCreator) => {
    deletePresetStructure(structure?._id).catch((err) => console.log('err'));
    setCreator((prev) => ({
        ...prev,
        fetchedPresets: {
            ...prev.fetchedPresets, 
            accessed: {
                ...prev.fetchedPresets.accessed,
                templates: prev.fetchedPresets?.accessed?.templates?.filter((obj) => obj?.presetStructureId?._id !== structure?._id),
                structures: prev.fetchedPresets?.accessed?.structures?.filter((obj) => obj?._id !== structure?._id),
            }
        }
    }))
}

export const delete_preset_style = (style, setCreator) => {
    deletePresetStyle(style?._id).catch((err) => console.log('err'));
    setCreator((prev) => ({
        ...prev,
        fetchedPresets: {
            ...prev.fetchedPresets, 
            accessed: {
                ...prev.fetchedPresets.accessed,
                templates: prev.fetchedPresets?.accessed?.templates?.filter((obj) => obj?.presetStyleId?._id !== style?._id),
                styles: prev.fetchedPresets?.accessed?.styles?.filter((obj) => obj?._id !== style?._id),
            }
        }
    }))
}

export const delete_preset_template = (template, setCreator) => {
    if(template?.presetStructureId?.isPrebuilt === false) {
        deletePresetStructure(template?.presetStructureId?._id).catch((err) => console.log('err'));
    }
    deleteTemplate(template?._id).catch((err) => console.log('err'));
    setCreator((prev) => ({
        ...prev,
        fetchedPresets: {
            ...prev.fetchedPresets, 
            accessed: {
                ...prev.fetchedPresets.accessed,
                templates: prev.fetchedPresets?.accessed?.templates?.filter((obj) => obj?._id !== template?._id)
            }
        }
    }))
}


export const toggleOption = (creator, setCreator, option, action, rowType, sectionType, sectionId, idTypeHelper) => {

    let updatedReportSections = deepClone(creator.report?.sections);
    let updatedSectionElements = deepClone(creator.sectionElements);
        switch (option){ 
        case ("compare"): {

            /* Manage report sections */
            let elements = updatedReportSections.find((section) => 
                section.type === sectionType &&
                section.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)
                ?.rows
                ?.find((row) => row.type === rowType).elements;

            let isCompared = updatedReportSections.find((section) => 
                section.type === sectionType &&
                section.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)
                ?.rows
                ?.find((row) => row.type === rowType).isCompared;

            isCompared = !isCompared;

            let showCompare = action === "show" ? true : false;
            elements = elements?.map((block) => {
                if (showCompare) return {...block, displayedCompareValue: block.comparedValue}
                else return {...block, displayedCompareValue: ""}
            })

            updatedReportSections.find((section) => 
                section.type === sectionType &&
                section.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)
                .rows
                .find((row) => row.type === rowType).elements = elements;
            
            updatedReportSections.find((section) => 
                section.type === sectionType &&
                section.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)
                .rows
                .find((row) => row.type === rowType).isCompared = isCompared;

            /* Manage section elements */
            let sectionElements = updatedSectionElements.find((section) => 
                section.type === sectionType &&
                section.row === rowType &&
                section.id === sectionId)?.kpis;
        
            sectionElements = sectionElements?.map((kpi) => {
                if (showCompare) return {...kpi, displayedCompareValue: kpi.comparedValue}
                else return {...kpi, displayedCompareValue: ""}
            })

            updatedSectionElements.find((section) => 
                section.type === sectionType &&
                section.row === rowType &&
                section.id === sectionId).kpis = sectionElements;


            setCreator((prev) => ({
                ...prev,
                compareHelper: {
                    ...prev.compareHelper,
                    showCompare: !prev.compareHelper.showCompare
                },
                report: {
                    ...prev.report,
                    sections: updatedReportSections,
                },
                sectionElements: updatedSectionElements,
            }))
            break;
        }
    }

}



export const fill_section_source = async (creator, setCreator, accessedAccounts, source, item) => {
    
    let datafilledStructure = creator.presetStructureHelper?.datafilledStructure 
    ? deepClone(creator.presetStructureHelper?.datafilledStructure)
    : deepClone(creator.presetStructure);

    const section = creator.report.sections?.filter((s) => s.type !== "overview")?.[creator.initialFlowHelper.currentSectionIndex];


    const sectionType = section.type
    const sectionPosition = section.position;

    switch(source) {
        case("meta"): {
            const metaAccount = accessedAccounts?.meta;
            if(sectionType === "paid") {
                let campaigns;
                let allFetchedCampaigns = creator.metaHelper?.campaigns;
                const selectedBusiness = creator?.metaHelper?.selectedBusiness
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = item.name;
                s.sectionData = [
                    {
                        "type": "adAccount",
                        "name": item.name,
                        "id": item.id,
                    },
                    {
                        "type": "facebookAccount",
                        "name": metaAccount.username,
                        "id": metaAccount.userId,
                        "accessToken": metaAccount.accessToken,
                    }
                ];
                s.parentBusiness = selectedBusiness;

                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    },
                }))
    
                if(! allFetchedCampaigns?.some((campaign) => campaign.account_id === item.account_id)) {
                    setCreator((prev) => ({
                        ...prev,
                        loaders: {
                            ...prev.loaders,
                            campaigns: true,
                        }
                    }))
                    var campaignsResponse = await get_campaigns(item.id, metaAccount.accessToken);

                    campaigns = [...allFetchedCampaigns, ...campaignsResponse?.data?.data?.myCampaigns];
                }
                else {
                    campaigns = allFetchedCampaigns;
                }

                setCreator((prev) => ({
                    ...prev,
                    loaders: {
                        ...prev.loaders,
                        campaigns: false,
                    },
                    metaHelper: {
                        ...prev.metaHelper,
                        campaigns: campaigns,
                    }
                }));
            }
            else if(sectionType === "facebook") {
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = item.name;
                s.sectionData = [
                    {
                        "type": "facebookPage",
                        "name": item.name,
                        "id": item.id,
                        "accessToken": item.access_token
                    }
                ];

                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    },
                }));
            }
            else if(sectionType === "instagram") {
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = item.instagram_business_account?.username;
                s.sectionData = [
                    {
                        "type": "instagramPage",
                        "username": item.instagram_business_account?.username,
                        "id": item.instagram_business_account?.id,
                        "accessToken": item.access_token
                    }
                ];

                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    },
                }));
            }
            break;

        }
        case("google"): {

            const googleAccount = accessedAccounts?.google;
            let campaigns;
            let allFetchedCampaigns = creator.googleHelper?.campaigns;
            const selectedManager = creator?.googleHelper?.selectedManager;
            let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
            s.sectionTitle = item.descriptiveName;
            s.sectionData = [
                {
                    "type": "googleAdAccount",
                    "name": item.descriptiveName,
                    "id": item.id,
                },
                {
                    "type": "googleUser",
                    "name": googleAccount.username,
                    "id": googleAccount.id,
                    "accessToken": googleAccount.accessToken,
                }
            ];
            s.parentManager = selectedManager;
            setCreator((prev) => ({
                ...prev,
                presetStructureHelper: {
                    ...prev.presetStructureHelper,
                    datafilledStructure,
                },
            }))


            if(! allFetchedCampaigns?.some((campaign) => campaign.customerId === section.id)) {
                setCreator((prev) => ({
                    ...prev,
                    loaders: {
                        ...prev.loaders,
                        googleCampaignsLoader: true,
                    }
                }))
                var campaignsResponse = await getClientCampaigns(item.id, selectedManager?.id, googleAccount.accessToken, googleAccount.userId)
                campaigns = [...allFetchedCampaigns, ...campaignsResponse?.data?.data?.clientCampaigns || []];
            }
            else {
                campaigns = allFetchedCampaigns;
            }

            setCreator((prev) => ({
                ...prev,
                loaders: {
                    ...prev.loaders,
                    googleCampaignsLoader: false,
                },
                googleHelper: {
                    ...prev.googleHelper,
                    campaigns: campaigns,
                }
            }));


            break;  
        }
    }
}

export const remove_section_source = (creator, setCreator, source) => {

    const section = creator.report.sections?.filter((s) => s.type !== "overview")?.[creator.initialFlowHelper.currentSectionIndex];
    const sectionType = section.type;
    const sectionPosition = section.position;
    let datafilledStructure = creator.presetStructureHelper?.datafilledStructure 
        ? deepClone(creator.presetStructureHelper?.datafilledStructure)
        : deepClone(creator.presetStructure);

    switch(source) {
        case("meta"): {
            if(sectionType === "paid") {
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = 'Ad Account';
                s.sectionData = [
                    {
                        "type": "adAccount",
                        "name": "adAccount",
                        "id": "",
                    },
                    {
                        "type": "facebookAccount",
                        "name": "",
                        "id": "",
                        "accessToken": "",
                    }
                ];

                s.parentBusiness = {};
                s.campaigns = [];
                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    },
                }));
            }
            else if(sectionType === "facebook") {
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = 'Facebook Page';
                s.sectionData = [
                    {
                        "type": "facebookPage",
                        "name": "facebookPage",
                        "id": "",
                        "accessToken": ""
                    }
                ];

                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    }
                }));
            
            }
            else if(sectionType == "instagram") {
                let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
                s.sectionTitle = 'Instagram Page';
                s.sectionData = [
                    {
                        "type": "instagramPage",
                        "name": "instagramPage",
                        "id": "",
                        "accessToken": ""
                    }
                ];

                setCreator((prev) => ({
                    ...prev,
                    presetStructureHelper: {
                        ...prev.presetStructureHelper,
                        datafilledStructure,
                    }
                }));
            };

            break;
        }
        case("google"): {
            let s = datafilledStructure.sections?.find((s) => s.position === sectionPosition);
            s.sectionTitle = 'Google Ad Account';
            s.sectionData = [
                {
                    "type": "googleAdAccount",
                    "name": "googleAdAccount",
                    "id": "",
                },
                {
                    "type": "googleUser",
                    "name": "",
                    "id": "",
                    "accessToken": "",
                }
            ];

            s.parentManager = null;
            s.campaigns = [];
            setCreator((prev) => ({
                ...prev,
                presetStructureHelper: {
                    ...prev.presetStructureHelper,
                    datafilledStructure,
                },
            }));
            break;
        }
    }

}



export const initial_slider_flow_helper = (creator, setCreator, open_close_slider) => {

    if ( !creator.initialFlowHelper.isCompleted ) {
        const nextSection = creator.report.sections?.filter((s) => s.type !== "overview")?.[creator.initialFlowHelper.currentSectionIndex + 1];
        console.log(nextSection);
        if ( nextSection ) {
            setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, currentSectionIndex: prev.initialFlowHelper.currentSectionIndex + 1}}));
        }
        else {
            setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, isCompleted: true}}));
            open_close_slider('');
        }


    }
}

export const prevSection = (creator, setCreator) => {

    if ( !creator.initialFlowHelper.isCompleted ) {
        setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, currentSectionIndex: prev.initialFlowHelper.currentSectionIndex - 1}}));

    }
}



// func to use when we want to skip any section using the red button of conformation

export const skip_Section = (creator, setCreator, open_close_slider) => {
    if ( !creator.initialFlowHelper.isCompleted ) {
        const nextSection = creator.report.sections?.filter((s) => s.type !== "overview")?.[creator.initialFlowHelper.currentSectionIndex];

        if ( nextSection ) {
            setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, currentSectionIndex: prev.initialFlowHelper.currentSectionIndex+1 }}));
            open_close_slider('fill-section-source', nextSection);
        }
        else {
            setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, isCompleted: true}}));
            open_close_slider('');
        }
    }
}

export const set_confirmation_status = (creator, setCreator, status) => {
    setCreator((prev) => ({
        ...prev, 
        initialFlowHelper: {
            ...prev.initialFlowHelper, 
            conformationCommunicate: status
        }
    }))
}

