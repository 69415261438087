import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import store from '../../../redux/store';
import { Actions_, AlertContext, Alert_, Builder_, CardsTools, Full_Card_Loader, Google_Search, Grey_, Input_, Main_, On_Click_Card, Slider_, Textarea_ } from 'monica-alexandria'
import { findMissing, updateTranslations, uploadTranslations } from '../../../services/api/Translations';
import { useTranslation } from 'react-i18next';

const Keymaker = styled.div`

`
const SearchWrapper = styled.div`
  margin-bottom: 4rem;
`
const client = process.env.REACT_APP_NAME;

export const Keymaker_ = (props) => {
    const { alerts, setAlerts } = useContext(AlertContext);
    const [slider,toggleSlider] = useState(false);
    const [newTranslations, setNewTranslations] = useState([]);
    const [newPair, setNewPair] = useState({key: '', value: ''})
    const [missing, setMissing] = useState(false);
    const [activeTranslations, setActiveTranslations] = useState([]);
    const [activeSlider, setActiveSlider] = useState(false);
    const [isLoading, setIsLoading] = useState(true) 
    const [searchResults, setSearchResults] = useState([]);
    const [displayedTranslations, setDisplayedTranslations] = useState([]);
    const [isUploading, setIsUploading] = useState(true)

    const {t} =  useTranslation()

    const translationChecker = (key) => {

    let notices = [];

    let indexKey = newTranslations.findIndex(item => item.key == key)
   
    if (indexKey !==-1) notices.push(['This field has been changed'])

    return notices;
  }

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
  };

  const openTranslation = (language) => {

    loadTranslationsFromCache(language);

    toggleSlider(!slider);

    setActiveSlider(language);

  }

  const purge_cache_and_reload = () => {

    store.dispatch({type:'SAVE_TRANSLATIONS', payload: false});
    window.location.reload();

  }

  const refreshNewTranslations = (incomingKey, incomingValue) => {

    let temp = JSON.parse(JSON.stringify(newTranslations))

    let index = temp.findIndex(item => item.key == incomingKey)

    if (index !== -1) temp.splice(index, 1);
    
    temp.push({key: incomingKey, value: incomingValue});

    setNewTranslations(temp)
  }

  const addNonExistingTranslations = () => {

    let temp = JSON.parse(JSON.stringify(activeTranslations));
    let tempb = JSON.parse(JSON.stringify(newTranslations));

    temp.push(newPair);
    tempb.push(newPair)

    setActiveTranslations(temp);
    setNewTranslations(tempb);

  }

  // fetches missing translations ONLY in local environment
  const fetchMissingTranslationsFromDB = () => {
    findMissing()
      .then(data =>{
         setMissing(data?.data?.missing)
         setIsLoading(false)
        })
      .catch(err => {})

}
    const applySearchResults = () => {
      if (searchResults?.input) setDisplayedTranslations(searchResults?.data);
      else setDisplayedTranslations(activeTranslations);
    };


  useEffect(() => {
    if (activeTranslations?.length > 0) setDisplayedTranslations(activeTranslations);
  }, [activeTranslations]);

  useEffect(() => {
      applySearchResults();
  }, [searchResults]);

  useEffect(()=> {
    if (!missing) fetchMissingTranslationsFromDB();
  },[])

  const editTranslations = () => {

    updateTranslations(activeSlider, client, prepareEmptyTranslationsForDB())
      .then(() => {
        purge_cache_and_reload();
      })
      .catch(err=> {})
  }

  const prepareEmptyTranslationsForDB = () => {

    let prepared = [];

    newTranslations.forEach(tr => prepared.push(tr))

    let index;
    activeTranslations.forEach(tr => {
      index = newTranslations.findIndex(item => item.key == tr.key)
      if (index == -1) prepared.push(tr);
    })

    return prepared;
  }

  const loadTranslationsFromCache = (language) => {

    const translations = store.getState()?.translations?.languages;

    let langIndex = missing.findIndex(item => item.lang == language);

    if (langIndex == -1) return;
    const trans = Object.keys(translations[language]).map(key => ({ key, value: translations[language][key]}));
    missing[langIndex].keys.forEach(key => {
      trans.push({key: key, value: ''})
    });
    setActiveTranslations(trans)
  }

  const transfer_translations =(target) =>{
    alertMessage(t('Uploading translations...'), 'Loading');

    uploadTranslations(target)
    .then(res =>{
      setIsUploading(false)
      alertMessage(t('Upload completed!'), 'Success');

    })
    .catch(err =>{
      alertMessage(
        t('Upload failed. Please try again'),'Error');
      console.log(err)
    })
  }

  if(isLoading){
    return (            
      <Builder_ 
      title = {t("Loading missing translations, this might take a while...")}>
      <Full_Card_Loader /> 
      </Builder_>
  )}

  return (
    <Keymaker>
      <Alert_ messages={alerts} />

      <Actions_>
      <Grey_  text={t('PURGE CACHE')} onClick={() => purge_cache_and_reload()}/>
      {process.env.REACT_APP_SERVER === 'STAGING' ? <Main_ text={t('UPLOAD TRANSALATIONS TO PRODUCTION')} onClick={() => transfer_translations('production')}/>:null}
      </Actions_>

    {slider &&
        <Slider_
        title = {activeSlider}
        done = {t('Done')}
        onClick = {() => editTranslations()}
        onClose = {() => toggleSlider(false)}
        >
        <SearchWrapper>
          <Google_Search
              file={activeTranslations}
              fileKeys={['key', 'value']}
              defaultValue={''}
              sanitize={false}
              placeholder={t('Search for text to translate')}
              applyReverseFileProperties={[
                  {
                      originalKey: 'key',
                      reversedKey: 'key_reversed',
                  },
              ]}
              setResults={(data) => setSearchResults(data)}
              debounceTime={750}
              minMatchCharacters={1}
              maxMatchCharacters={50}
              disabled={false}
              isCaseSensitive={false}
              maxAcceptedScore={0.6}
              />
         </SearchWrapper>

        {/* <Input_
            placeholder = {t('New translation key')}
            defaultValue = {t('New translation key')}
            onChange = {(e) => setNewPair({...newPair, key: e.target.value})}
        />
        
        <Input_
            placeholder = {t('New translation value')}
            defaultValue = {t('New translation value')}
            onChange = {(e) => setNewPair({...newPair, value: e.target.value})}
        />
        <button onClick={() => addNonExistingTranslations(newPair.key, newPair.value)}>{t('ADD NEW')}</button> */}
        {activeSlider ?
        <>
        {displayedTranslations?.map((translation, i) => 
            <Builder_ key={i}
              title = {translation.key}
            > 
            {translation?.value?.length <= 70 ?
            <Input_
              placeholder = {translation.value}
              onChange = {(e) => refreshNewTranslations(translation.key, e.target.value)}
              errors = {translationChecker(translation.key, activeSlider)}
            />
            :
            <Textarea_
              placeholder = {translation.value}
              onChange = {(e) => refreshNewTranslations(translation.key, e.target.value)}
              errors = {translationChecker(translation.key, activeSlider)}
            />
          }
            </Builder_>
        )}
            </>
        :
            null
        }
        </Slider_>
    }
    <CardsTools>
        <On_Click_Card
          imgSmall="https://genius1071.friktoriaservers.net/doitforme/monica/app/assets/flags/English.svg"
          title="English"
          onClick = {() => openTranslation('en')}
        />
        <On_Click_Card 
          imgSmall="https://genius1071.friktoriaservers.net/doitforme/monica/app/assets/flags/Greek.svg"
          title="Ελληνικά"
          onClick = {() => openTranslation('el')}
        />
        <On_Click_Card
          imgSmall="https://genius1071.friktoriaservers.net/doitforme/monica/app/assets/flags/German.svg"
          title="Deutsch"
          onClick = {() => openTranslation('de')}
        />
      </CardsTools>
      </Keymaker>
  )
}
