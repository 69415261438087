import { Builder_, CardsSixty, On_Stat, Section_Title, Sixty_Stat_Loader, Stat_, device } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

// import { BuilderContent } from '../../../../meta/pages/createReport/CREATE_REPORT.css'; // Fix this
import GoogleLogo from '../../../../../assets/googleNetwork/googleLogo.png'; // Fix this
import { fixNumbers } from '../../../../../helpers/fixNumbers';
import { fixKeys } from '../../../helpers/fixDisplayedKeys';


const InfoSection = styled.div`
    margin-bottom: 2rem;
`;

const InfoSectionCards = styled(CardsSixty)`
    @media ${device.xl} {
        grid-template-columns: 1fr;
    }
`;

const GoogleAdsSection = styled.div``;

const InfoSectionComments = styled.div``;



export default function Campaigns_Section(props) {
    const { t } = useTranslation();

    return (
        <>
            {props.report?.campaigns?.map((campaign, index) => {
                const isLoading = props.isLoading.loading && props.isLoading.loadingCampaignIds.includes(campaign.campaign.id);
                return <>
                    <InfoSection>

                    <Section_Title 
                        text={campaign.campaign?.name} 
                        img={GoogleLogo} 
                        value={!isLoading && 'tempValue'}
                        // value={props?.currency 
                        //         ? `${props?.ads_spend ? fixNumbers(parseFloat(props.ads_spend), true) : ''}${currencies[props?.currency]?.symbol}` 
                        //         : null
                        //     } 
                        // onEdit={() => props.handleSliderClick('edit_meta_ads_price')} 
                    />

                    <InfoSectionCards>
                        <Stat_ 
                            title={t('Days reporting')} 
                            variant="focus" 
                            text={isLoading ? (t("Days")) : fixNumbers(props.report.days)} 
                            icon="Calendar" 
                        />
                        <On_Stat 
                            text={t("Comments")} 
                            title={t("Custom")} 
                            variant="focus" 
                            btnIcon="Edit" 
                            btnText={t('Edit')} 
                            id={"AdSnap_Info_Section_1"}
                            onClick={() => props.handleSliderClick('edit_comment', {index})}
                        />
                        <On_Stat 
                            text={isLoading ? (t("Spend")) : 'tempText'}
                            // text={props?.currency ? `${props?.ads_spend ? fixNumbers(parseFloat(props.ads_spend), true) : ''}${currencies[props?.currency]?.symbol}` : (t("Spend"))} 
                            title={t("Adspent")} 
                            variant="focus" 
                            btnIcon="Edit" 
                            btnText={t('Edit')} 
                            id={"AdSnap_Info_Section_2"}
                            // onClick={() => props.handleSliderClick('spend_ads')} 
                        />
                    </InfoSectionCards>

                    <InfoSectionComments>
                        <p>{campaign?.comment}</p>
                    </InfoSectionComments>
                    </InfoSection>


                    { !isLoading ? <GoogleAdsSection>
                    <Section_Title
                        text={t('Static KPIs')}
                    />
                    <CardsSixty>
                        {campaign?.kpis?.map((kpi) => {
                            return <On_Stat 
                                id={"AdSnap_Google_Ads_Section_1"} 
                                variant="focus" 
                                title={fixKeys(kpi)} 
                                text={fixNumbers(kpi.value)} 
                                onClick={() => props.handleSliderClick('edit_kpi', {kpi, index})} 
                                edited={kpi?.originalKey && kpi?.originalKey !== kpi?.key ? kpi?.originalKey : ''} 
                                btnIcon="Edit" 
                                btnText={t('Edit')} 
                            />;
                        })}
                        <On_Stat 
                            id={"AdSnap_Google_Ads_Section_2"} 
                            variant="focus" 
                            title={t('new')} 
                            text={t('KPI')} 
                            btnIcon="Add" 
                            btnText={t('Add')} 
                            onClick={() => props.handleSliderClick('select_kpis', {index})} 
                        />
                    </CardsSixty>
                    </GoogleAdsSection> : <Sixty_Stat_Loader /> }
                </>
            })}
        </>
    );
}
