import { CardsSixty, On_Click_Card, Section_Title } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StructurePresetPrebuilt = styled.div``;

export default function Structure_Preset_Prebuilt(props) {
    const { t } = useTranslation();

    return (
        <StructurePresetPrebuilt>
            <Section_Title text={t('Prebuilt presets')} />
            <CardsSixty>
                {props.preBuilt?.map((s) => {
                    return <On_Click_Card
                        icon="Report"
                        subtitle={s.title}
                        onClick={() => props.onApply(s)}
                    />
                })}
            </CardsSixty>
        </StructurePresetPrebuilt>
    );
}
