import axios from 'axios';
import store from '../redux/store';
import { expireSession } from '../services/authentication/SessionService';
import i18n from '../localization/i18next';

const clients = require('../config/index').config
const client = process.env.REACT_APP_CLIENT;
const authClient = process.env.REACT_APP_AUTH_CLIENT;
const lang = i18n.language ? i18n.language : 'en';

export function jwtReqInterceptor() {

    axios.interceptors.request.use(request => {

        const token = store.getState()?.user?.token;

        request.headers.Authorization = `${token}`;

        return request;
    });
}

export function jwtResInterceptor() {

    axios.interceptors.response.use(response => {
        return response;
    }, err => {
        if (err.response?.data?.code == 403 ) {
           window.location.replace(`${clients[client]}/${lang}/access-denied`)
        }
        else if (err?.response?.data?.code == 401) {
            window.location.assign(`${clients[authClient]}/${lang}/auth?redirectUri=${window.location.href}`)
        }
        else if (err?.response?.data?.code == 422) {
        }
    })
}
