import { validate_Tool_Ownership } from "../services/api/Users";

const planName = process.env.REACT_APP_PLAN_NAME;


export const checkOwnership = (userId, navigate, lang, setHasCheckOwnership, source) => { // Source will be needed in the future
    
    const navigateURL = source ? `/${lang}/subscribe` : `/${lang}/subscribe`; // If source, it will navigate to a page build for purchasing sources;
    
    validate_Tool_Ownership(userId, planName)
    .then((res) => {
        if (res?.data?.user_own_tool) {
            setHasCheckOwnership(true)
            return;
        } else navigate(navigateURL);
    }).catch((err) => navigate(navigateURL));
};