import {CardsTools, On_Click_Card} from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fixNumbers } from '../../../../helpers/fixNumbers';


const BasicAnalytics = styled.div``;

export default function Basic_Analytics(props) {
  
	const { t } = useTranslation();


  return (
    <BasicAnalytics>
      <CardsTools>
          <On_Click_Card
              title={'Reports'}
              header={`${fixNumbers(props.analytics?.total_reports)}`}    
              headerColor = {'Main'}
              subtitle = {'Total amount of reports'}
          />
          <On_Click_Card 
              title={'Users'}
              header={`${fixNumbers(props.analytics?.total_users)}`}    
              headerColor = {'Main'}
              subtitle = {'Amount of users that made a report'}
          />
          <On_Click_Card 
              title={'Avg Report/User'}
              header={`${fixNumbers(props.analytics?.average_reports_per_user)}`}    
              headerColor = {'Main'}
              subtitle = {'Average reports per user'}
          />
          {<On_Click_Card 
              title={'New Users'}
              header={ props.analytics?.hasOwnProperty('new_users') ? `${fixNumbers(props.analytics?.new_users)}` : "0" }    
              headerColor = {'Main'}
              subtitle = { props.analytics?.hasOwnProperty('new_users') ? 'Amount of users that made their first report in that period' : 'Select dates to see new users'}
          /> }
        </CardsTools>
    </BasicAnalytics>
  )
}




