import axios from "axios"
import { config } from "../../config"


const getUserMedia = (userId) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/media/getUserMedia?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const deleteMedia = (image) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/media/deleteMedia?imageName=${image}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

// because we are sending an image to backend we must be careful and use formData, 
// userId is dummy here since we obtain userId throught auth middleware in the backend
// only the file has essence
const upLoadMedia = (userId, file) =>{

    let form = new window.FormData();
    let data = {            
        userId
    };

    if (file) form.append('file', file);
    
    form.append('data', JSON.stringify(data));

    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/media/upLoadMedia`, form, {})
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}


export {
    getUserMedia,
    deleteMedia,
    upLoadMedia
};