import { Actions_, Confirmation_, Grey_, Main_, PageAlwaysVisible, PageSixty, PageTitle, Sixty_Stat_Loader, PageFull, Main_Loader, Slider_ , Alert_, AlertContext, Partial_Loader, Alert, Main_Plain} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../../../../redux/store';
import styled from 'styled-components';
import Edit_Report_Title from './components/edit/Edit_Report_Title';
import Edit_Report_Dates from './components/edit/Edit_Report_Dates';
import { ReportStyleContext } from '../../context/ReportStyleContext';
import Edit_Structure_Preset from './components/edit/Edit_Structure_Preset';
import Edit_Template_Preset from './components/edit/Edit_Template_Preset';
import Edit_Style_Preset from './components/edit/Edit_Style_Preset';
import { update_block_element, add_block_to_report, remove_block_from_report, add_demographic_block_to_report, remove_demographic_block_from_report, remove_demographic_from_report } from './utils/UpdateBlocks';
import Add_Section from './components/add/Add_Section';
import { handle_section_change, remove_emptysource_section, remove_section_from_report, update_section_name } from './utils/UpdateSections';
import Edit_Splitter_Text_Section from './components/edit/Edit_Splitter_Text_Section';
import { add_row_to_section, remove_row_from_section, update_row_elements_color, update_row_name } from './utils/UpdateRows';
import { delete_preset_structure, delete_preset_style, delete_preset_template, fill_section_source, remove_section_source, handle_template_selection, initial_slider_flow_helper, skip_Section, save_date_format, save_report_dates, save_report, set_preset_structure_and_report_title, toggleOption, trigger_dates_change_check, trigger_preset_structure_change_check, update_comment_text, update_comment_title, set_image_comment, update_splitter_text, set_confirmation_status, prevSection, media_update_grid, media_update_img} from './utils/SliderCallbackFunctions';
import { initalize_page, trigger_restructure_section_elements_handler, trigger_structure_change_check_handler, update_dates_and_section_elements, assign_properties_to_report_blocks, handle_section_element_change, initialize_overview_automatic_values, update_error_translations, trigger_structure_init_change_handler, apply_pre_selected_template } from './utils/UseEffectFunctions';
import { format_date } from '../../../../helpers/DateFormatter';
import Edit_Element_Comments from './components/edit/Edit_Element_Comments';
import idTypeHelperJson from './data/id-type-helper.json';
import Add_Section_Meta from './components/add/Add_Section_Meta';
import { add_source, remove_source, add_campaign, remove_campaign, add_campaign_to_section, remove_campaign_from_section } from './utils/Sources';
import { useNavigate, useParams } from 'react-router-dom';
import { handle_preset_actions, start_report_finalization_process } from './utils/FinalizeReport';
import i18n from '../../../../localization/i18next';
import { getDateFlexibleDaysEarlier } from '../../../../helpers/GetDateFlexibleDaysEarlier';
import { Helmet } from 'react-helmet';
import Report_Actions from './components/report/Report_Actions';
import Add_Section_Fbin from './components/add/Add_Section_Fbin';
import Edit_Section from './components/edit/Edit_Section';
import Edit_Row from './components/edit/Edit_Row';
import { generatePDF } from './utils/ExportPDF';
import Report_Data from './components/report/Report_Data';
import { FileContext } from '../../context/FileContext';
import { checkPermissions } from '../../../../helpers/checkPermissions';
import { get_my_subscriptions } from '../../../../services/api/Users';
import { AuthContext } from '../../../../services/authentication/Guard';
import Edit_Logo from './components/edit/Edit_Logo';
import Edit_Media from './components/edit/Edit_Media';
import Wizard_ from './components/wizard/Wizard_';
import Add_Section_Google from './components/add/Add_Section_Google';

const clients = require('../../../../config/index').config

const Report = styled.div`
    max-width: 1200px;
`;

const ReportPaper = styled.div`
    box-shadow: ${(p) => p.theme.outFocus};
`;

const ReportPreviewPdf = styled.div`
    width: 900px;
    margin: 0 auto;
    min-height: 2552px;
`

const Creator_Right_Actions = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`

const CreatorActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    position: sticky;
    z-index: 2;
    top: 7rem;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: ${p => p.theme.out};
    background: ${p => p.theme.background};
`

const maximumAcceptableDateRange = parseInt(process.env.REACT_APP_MAX_ACCEPTABLE_DATE_RANGE_V2);

export default function CREATOR() {
    const [businessCursor,  setBusinessCursor] = useState("initial"); // for pagination of businesses 
    const [pagesCursor, setPagesCursor] = useState("initial"); // for pagination of organic pages

    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';
    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);

    const dateNow = getDateFlexibleDaysEarlier(new Date(), 0);
    const date30DaysEarlier = getDateFlexibleDaysEarlier(new Date(), 27);

    const { reportStyle, setReportStyle } = useContext(ReportStyleContext);
    const { file, setFile } = useContext(FileContext);


    /* *** Local Storage *** */
    const preSelectedData = store.getState()?.app?.creator?.preSelected; // Pre-selected data such as dates, templates etc.
    const accessedAccounts = store.getState()?.app?.accessedAccounts; // User info for his logged accounts such as user's meta account.
    const user = store.getState()?.user?.user;
    const localStoredDates = preSelectedData?.dates;

    /* if reportId is defined, we are going to fetch a report and update it instead of creating a new one */
    const [reportId, setReportId] = useState(useParams().id);
    const { mode } = useParams();
    const userId = user.sub;
    const auth = useContext(AuthContext)
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const permission = checkPermissions('Knight', auth.user.roles);
    /* *** Slider *** */
    const [slider, setSlider] = useState({
        status: reportId ? false : true,
        active:  reportId ? "" : 'wizard',
    });

    const open_close_slider = (active, item) => {
        setSlider({ ...slider, status: true, active: active, item });
    };

        // for alerts
    const { alerts, setAlerts } = useContext(AlertContext);
    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
    const [pdfLoader, setPdfLoader] = useState(false);
    /* *** Creator Data *** */
    const [creator, setCreator] = useState({
        t: t,
        presetStructure: false,
        preSelectedTemplate: preSelectedData?.template,
        applyPreSelectedTemplate: false,
        report: {
            userIds: [user?.sub],
            dateFrom: date30DaysEarlier,
            dateTo: dateNow,
            dateFormat: 'DD-MM-YYYY',
            title: 'Marketing Report',
            templateId: '',
            currency: 'EUR',
            sections: [],
            template: false,
        },
        sectionElements: [
            // Used as a section helper to store fetched kpis / Trigger KpiFetchUtils | Providing dummyData to showcase the expected objects
            // { name: "" source: "meta" type: "paid", id: "", accessToken: "", row: "static", kpis: [] },  // AdAccount will be used as id in type paid
            // { name: "" source: "meta" type: "facebook", id: "", accessToken: "", row: "static", kpis: [] },
            // { name: "" source: "meta" type: "instagram", id: "", accessToken: "", row: "static", kpis: [] }, // instagram_business_account.id will be used as id in type paid
        ],
        loaders: {
            initialize: true,
            sections: false,
            section: { position: -1, loading: false },
            pdf: false,
            adAccounts: false, //meta
            campaigns: false, //meta
            googleAdAccountLoader: false,
            googleCampaignsLoader: false,
        },
        errors: {
            fetchBusinesses: { extraInfo: false, error: false },
            fetchAdAccounts: [
                // { extraInfo: false, error: false }
            ],
            fetchOrganicPages: { extraInfo: false, error: false },
            fetchOverview: { extraInfo: false, error: false },
            fetchMetaPaidKpis: [
                /*{ extraInfo: false, error: false }*/
            ],
            fetchFacebookKpis: [
                /*{ extraInfo: false, error: false }*/
            ],
            fetchInstagramKpis: [
                /*{ extraInfo: false, error: false }*/
            ],
        },
        viewMode: mode === "viewMode"? true : false,
        fetchedPresets: {
            // Prebuilt and accessed presets fetched from the database
            preBuilt: {
                styles: [],
                structures: [],
            },
            accessed: {
                styles: [],
                structures: [],
                templates: [],
            },
        },
        metaHelper: {
            // Additional data for the meta source.
            pages: {
                facebookPages:  [] ,
                instagramPages: [] ,
            },
            businessAccounts: [] ,
            adAccounts: [],
            selectedBusiness: null,
            selectedAdAccount: null,
            campaigns: [],
            selectedCampaigns: [],
        },
        googleHelper: {
            managerAccounts: [],
            accessibleAdAccounts: [],
            selectedManager: null,
            adAccounts: [],
            selectedAdAccount: null,
            campaigns: [],
            selectedCampaigns: []
        },
        compareHelper: {
            showCompare: true,
        },
        datesHelper: {
            // Additional data for dates.
            fetchedForDates: {
                dateFrom: '',
                dateTo: '',
            },
            triggerDateChangeCheck: false, // Triggers the sectionElement useEffect
        },
        presetStructureHelper: {
            // Additionan data for presetStructure
            datafilledStructure: false, // Used to store section data after triggerPresetStructureInitChange to use to set report after triggerPresetStructureChangeCheck
            isInitilized: false, // Indicated that a presetStrucutre has been selected but not filled with real sources
            selectedPresetStructureId: '',
            triggerPresetStructureInitChange: false, // When triggered, we assign preset's section to report without actuall source data
            triggerPresetStructureChangeCheck: false, // Triggers the sectionElement useEffect
            isModified: false, // Must always set to true after adding/editing/removing an element if we want after this action to be able to reset the template by re-selecting it
        },
        presetStyleHelper: {
            // Additionan data for presetStyle
            isModified: false, // Must always set to true after changing the style. Must always set to false when selecting a style
        },
        shouldFetch: true, // If true, we are going to proceed to fetchingKpis logic.
        shouldAssign: false, // If true, we are going to proceed to assigning values from sectionElements to report sections.
        restructureSectionElements: false, // If true, we are going to re-structure the sectionElements based on the report's sections.
        keepReportValues: false, // If true, handle_section_element_change will run an extra function to assign sectionElement kpis values from report values and then set keepReportValues to false
        confirmationHelper: {
            showConfirmationStyle: false,
            showConfirmationStructure: false,
            isPresetActionsProcessFinished: false,
            styleAction: '',
            structureAction: '',
            onYesStyle: () => {
                setCreator((prev) => ({
                    ...prev,
                    confirmationHelper: {
                        ...prev.confirmationHelper,
                        styleAction: 'overwrite',
                        isPresetActionsProcessFinished: !creator?.confirmationHelper?.isPresetActionsProcessFinished,
                        showConfirmationStyle: false,
                    },
                }));
            },
            onNoStyle: () => {
                setCreator((prev) => ({
                    ...prev,
                    confirmationHelper: {
                        ...prev.confirmationHelper,
                        styleAction: 'create',
                        isPresetActionsProcessFinished: !creator?.confirmationHelper?.isPresetActionsProcessFinished,
                        showConfirmationStyle: false,
                    },
                }));
            },
            onYesStructure: () => {
                setCreator((prev) => ({
                    ...prev,
                    confirmationHelper: {
                        ...prev.confirmationHelper,
                        structureAction: 'overwrite',
                        isPresetActionsProcessFinished: !creator?.confirmationHelper?.isPresetActionsProcessFinished,
                        showConfirmationStructure: false,
                    },
                }));
            },
            onNoStructure: () => {
                setCreator((prev) => ({
                    ...prev,
                    confirmationHelper: {
                        ...prev.confirmationHelper,
                        structureAction: 'create',
                        isPresetActionsProcessFinished: !creator?.confirmationHelper?.isPresetActionsProcessFinished,
                        showConfirmationStructure: false,
                    },
                }));
            },
        },
        initialFlowHelper: {
            // Data only for the initial slider-to-slider flow on page load.
            currentSectionIndex: 0,
            isCompleted: reportId ? true : false,
            // for false it means the user didnt want to import the section - for true it means he import it
            // using it to show corresponding messages to Notify
            conformationCommunicate: false, 
            flow:[]
        },
        sliderFlowHelper: {
            // Additional for slider-to-slider ux implementation.
            shouldOpen: false, // Should the slider at "openSlider" open?
            openSlider: "", // Which slider to open
            previousSlider: "", // Which slider was the previous one? Could help
            metadata: {}, // Assign data that you want to pass that will help with logic.
            closeNested: false, // May be helpful
            openNested: false, // May be helpful
        },
        currentTemplate: preSelectedData?.template, // We store the current template that is applied to report, when we change the report and we save a new template we update this value
        availableMedia: [],
    });



    /* 
        Open close slider based on other slider actions
        This function will open the creator.sliderFlowHelper.openSlider if creator.sliderFlowHelper.shouldOpen is true 
    */
    useEffect(() => {
        if ( creator.sliderFlowHelper?.shouldOpen ) {
            open_close_slider(creator.sliderFlowHelper.openSlider, creator.sliderFlowHelper.metadata);
            // Set values back to default
            setCreator((prev) => ({...prev, sliderFlowHelper: {
                ...prev.sliderFlowHelper,
                shouldOpen: false,
                openSlider: "",
                previousSlider: "",
                metadata: {},
                closeNested: false,
                openNested: false,
            }}))
        }
    }, [creator.sliderFlowHelper?.shouldOpen]);

    /* 
        Map all sectionElements to empty the kpis array. 
        This will trigger the sectionElements useEffect, followed by fetching kpis again (because dates has changed) for all sectionElements. 
        Triggered on triggerDateChangeCheck followed by a true "if" statement      
    */
    useEffect(() => {
        update_dates_and_section_elements(creator, setCreator);
    }, [creator.datesHelper?.triggerDateChangeCheck]);

    /* 
        Construct the datafilledStructure awaiting the user to select sources.
        Triggered on triggerPresetStructureInitChange followed by a true "if" statement      
    */
    useEffect(() => {
        trigger_structure_init_change_handler(creator, setCreator);
    }, [creator.presetStructureHelper?.triggerPresetStructureInitChange]);

    /* 
        Construct the sectionElements array of object based on the presetStructure.
        Triggered on triggerPresetStructureChangeCheck followed by a true "if" statement      
        Also set presetStructure's sections to report's section
    */
    useEffect(() => {
        trigger_structure_change_check_handler(creator, setCreator, accessedAccounts);
    }, [creator.presetStructureHelper?.triggerPresetStructureChangeCheck]);

    /* 
        Construct the sectionElements array of object.
        Triggered on restructureSectionElements followed by a true "if" statement      
        Supposed to trigger sectionElements useEffect after partially adding a new row
    */
    useEffect(() => {
        trigger_restructure_section_elements_handler(creator, setCreator, accessedAccounts);
    }, [creator.restructureSectionElements]);

    /* 
        Run a check when a change occur to the section elements. 
        Fetch kpis for sectionElements with empty kpis array. 
    */
    useEffect(() => {
        if(creator?.initialFlowHelper?.isCompleted)
            handle_section_element_change(creator, setCreator, accessedAccounts);
    }, [creator?.sectionElements, creator?.sectionElements?.length]);

    /* 
        Currently, shouldAssign is only set true when updating the creator after handle_section_element_change 
        If true, we are going to handle (put value, replace whole, etc) the blocks of every row of every section in the report after checking if needed
    */
    useEffect(() => {
        assign_properties_to_report_blocks(creator, setCreator);
    }, [creator.shouldAssign]);

    /* 
        This function is responsible to initialize the values of the overview sectionElement kpis.
        This hook is triggered when we fetch overview and paid kpis. 
        If the overview sectionElement automatic filled kpis are zero we should assign them with values.    
    */
    useEffect(() => {
        initialize_overview_automatic_values(creator, setCreator);
    }, [creator.sectionElements?.find((se) => se.type === 'overview' && se.row === 'static')?.kpis?.length, creator.sectionElements?.find((se) => se.type === 'paid' && se.row === 'static')?.kpis?.length, creator.report?.dateFrom, creator.report?.dateTo]);

    /* This useEffect is triggered after the user clicks create/update report 
        Should be triggered only once and after the completion of "run_modification_check" function 
        We run a check if the user has modified an existing preset 
        Process is finished if the user has not modified presets or if the user make a decision in Confirmation 
    */
    useEffect(() => {
        if(creator?.confirmationHelper?.structureAction !== "create-or-overwrite" && creator?.confirmationHelper?.styleAction !== "create-or-overwrite")
            handle_preset_actions(creator, setCreator, reportStyle, setReportStyle, reportId, setReportId, file);
        
    }, [creator?.confirmationHelper]);

    /* At this moment we assign translation to some error values. We use this useEffect to update the values when changing lang */
    useEffect(() => {
        update_error_translations(creator, setCreator);
    }, [lang]);

    /* This useEffect gets triggered after init requests if we have a pre selected template. If so, we apply the pre-selected template */
    useEffect(() => {
        if (creator.applyPreSelectedTemplate) {
            apply_pre_selected_template(creator, setCreator, setReportStyle);
        }
    }, [creator.applyPreSelectedTemplate]);

    /* 
        Handle logic when first rendering the page.
        Fetch presetStructures, presetStyles and templates.
        Note that initalize loader is expected to turn false when done. 
    */
    useEffect(() => {
        if(businessCursor || pagesCursor) {
            initalize_page(creator, setCreator, userId, accessedAccounts, reportId, reportStyle, setReportStyle, businessCursor, setBusinessCursor, pagesCursor, setPagesCursor);
        }

    }, [businessCursor, pagesCursor]);

    /* Validate Tool Ownership */
    useEffect(() => {
        get_my_subscriptions(userId)
        .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                console.log('lois subs', lois_subs)
                if(!lois_subs?.tools?.some(tool => tool?.active) && !permission) window.location.assign(payments_URL);
                else setHasCheckOwnership(true)
        }).catch((err) => console.log('error', err));
    }, [userId]);
    
    /* if the user doesnt complete a source we must remove the empty sources */
    /* Also will remove selectedAdAccounts and selectedBusiness*/

    useEffect(() => {   
        if(creator?.initialFlowHelper?.isCompleted) {
            setCreator((prev) => ({
                ...prev,
                presetStructureHelper: {
                    ...prev.presetStructureHelper,
                    triggerPresetStructureChangeCheck: !prev.presetStructureHelper.triggerPresetStructureChangeCheck,
                    
                },
                metaHelper: {
                    ...prev.metaHelper,
                    selectedBusiness: null,
                    selectedAdAccount: null,
                    selectedCampaigns: []
                },
                googleHelper: {
                    ...prev.googleHelper,
                    selectedManager: null,
                    selectedAdAccount: null,
                    selectedCampaigns: []
                }
            }))
    }
    }, [creator?.initialFlowHelper?.isCompleted])
    

    // /* **** LOGS **** */
    // console.group('CREATOR.js');
    // console.log('creator: ', creator);
    useEffect(() => {
        if(pdfLoader)
            generatePDF(creator.report?.title, setPdfLoader);

    }, [pdfLoader]);
    // console.log("reportStyle: ", reportStyle);
    // console.groupEnd();


    if (creator.loaders.initialize)
        return (
            <>
                <Helmet>
                    <title>Adsnap | Report</title>
                </Helmet>
                <Main_Loader />;
            </>
        );
    return (
        <PageFull>
            <Alert_>
                messages={alerts}
            </Alert_>

            <Alert_ 
                loadingStatus={creator.loaders?.sections}
                loadingText={(t('Loading data'))}
            /> 

            <Alert_ 
                loadingStatus={pdfLoader}
                loadingText={(t('Downloading report'))}
            /> 
            
            <Helmet>
                <title>Adsnap | Report</title>
            </Helmet>
            
            {hasCheckOwnership ? (
                
                <Report>
                    {creator.confirmationHelper?.showConfirmationStyle ? 
                    <Confirmation_ 
                        message={t('You have changed the style of this template. Do you want to overwrite the previous style or create a new style')} 
                        yes={t('Overwrite')} no={t('Create new')} onYes={() => creator.confirmationHelper.onYesStyle()} 
                        onNo={() => creator.confirmationHelper.onNoStyle()} 
                    />: null}

                    {creator.confirmationHelper?.showConfirmationStructure ? 
                    <Confirmation_ 
                        message={t('You have changed the structure of this template. Do you want to overwrite the previous structure or create a new structure')} 
                        yes={t('Overwrite')} no={t('Create new')} onYes={() => creator.confirmationHelper.onYesStructure()} 
                        onNo={() => creator.confirmationHelper.onNoStructure()} 
                    />: null}   

                    {/* Sliders */}
                    {slider.status && slider.active == 'export-pdf' && 
                    <Slider_ className="ReportSlider" text={t("Export")} title={t("Preview PDF")} 
                        onClick={() => {
                            setPdfLoader(true);
                        }} 
                        iconGreyLeft="Back" grey={t('Back')} onGrey={() => open_close_slider('')} onClose={() => open_close_slider('')}
                    >
                        <ReportPreviewPdf >
                            <Report_Data 
                                id="target-div-for-pdf-creation"
                                pdf={true}
                                className="ReportDataPdf"
                                presetStyle={reportStyle} 
                                onDates={() => open_close_slider('dates')} 
                                brand={reportStyle?.brand}  
                                dateFrom={format_date(creator?.report?.dateFrom, creator.report.dateFormat)} 
                                dateTo={format_date(creator?.report?.dateTo, creator.report.dateFormat)} 
                                viewMode={true}
                                title={creator?.report?.title} 
                                onClickTitle={() => open_close_slider('title')} 
                                sections={creator.report.sections}
                                onClickCardOnElement={() => {
                                    handle_section_change(creator, setCreator, 'add', { name: 'Overview' }, "overview", false, accessedAccounts);
                                }}        
                                onDeleteSplitterSection={(section, sectionType) => handle_section_change(creator, setCreator, 'remove', section, sectionType, section.source)} 
                                onEditSplitterSection={(section) => open_close_slider('splitter-text', section)} 
                                creatorSectionElements={creator.sectionElements}
                                sliderFlowHelper={creator.sliderFlowHelper}
                                errors={creator.errors}
                                currency={creator.report?.currency}
                                compare={creator.compareHelper}
                                selectSource={(section) => open_close_slider('fill-section-source', section)}
                                removeSectionBeforeSourceSelection={(section) => remove_emptysource_section(creator, setCreator, section)}
                                // toggleOption={(option, action, rowType) => {
                                //     toggleOption(creator, setCreator, option, action, rowType, sectionType, sectionId, idTypeHelper);
                                // }}
                                onSetBlock={(element, value, property, sectionType, sectionId, rowType, idTypeHelper) => {
                                    update_block_element(creator, setCreator, element, value, property, sectionType, sectionId, rowType, idTypeHelper);
                                }}
                                onAddBlock={(block, sectionType, sectionId, rowType, idTypeHelper) => {
                                    add_block_to_report(creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper, t);
                                }}
                                onRemoveBlock={(block, sectionType, sectionId, rowType, idTypeHelper) => {
                                    remove_block_from_report(creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper);
                                }}
                                onAddRow={(rowType, sectionType, sectionId, idTypeHelper, sectionPosition) => {
                                    add_row_to_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, t, sectionPosition);
                                }}
                                onRemoveRow={(rowType, sectionType, sectionId, idTypeHelper,rowPosition, sectionPosition) => {
                                    remove_row_from_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition);
                                }}
                                onRemoveDemographic={(item, sectionType, sectionId, idTypeHelper) => {
                                    remove_demographic_from_report(creator, setCreator, item, sectionType, sectionId, idTypeHelper)
                                }}
                                onAddDemographicChart={(item, chartType, sectionType, sectionId, idTypeHelper) => {
                                    add_demographic_block_to_report(creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper)
                                }}
                                onRemoveDemographicChart={(item, chartType, sectionType, sectionId, idTypeHelper) => {
                                    remove_demographic_block_from_report(creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper)
                                }}
                                onUpdateComment={(row, sectionType, sectionId, idTypeHelper) => {
                                    open_close_slider('comment', { sectionType, sectionId, idTypeHelper, row });
                                }}
                                onRemoveComment={(rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition) => {
                                    remove_row_from_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition);
                                }}
                                onEditSection={(section, idTypeHelper) => {
                                    open_close_slider('edit-section', {section, idTypeHelper})
                                }}
                                onEditRow={(section, idTypeHelper, row, sectionType, sectionId) => {
                                    open_close_slider('edit-row', {section, idTypeHelper, row, sectionType, sectionId})
                                }}
                                onTitleEdit={(e, sectionType, sectionId, idTypeHelper, indexOfNextComment) => {
                                    update_comment_title(creator, setCreator, e.target.value, sectionType, sectionId, idTypeHelper, 
                                        creator.report.sections.find((obj) => obj.type === sectionType && obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)?.rows.find((row) => row.position === indexOfNextComment && row.type==='comment')
                                        );
                                }}
                                onTextEdit={(e, sectionType, sectionId, idTypeHelper, indexOfNextComment) => {
                                    update_comment_text(creator, setCreator, e.target.value, sectionType, sectionId, idTypeHelper, 
                                        creator.report.sections.find((obj) => obj.type === sectionType && obj.sectionData.find((sd) => sd.type === idTypeHelper)?.id === sectionId)?.rows.find((row) => row.position === indexOfNextComment && row.type==='comment'));
                                }}
                                onClickCreatorPlus={() => open_close_slider('section')}
                                onRemoveSection={(sectionType, sectionId, idTypeHelper) => {
                                    if (sectionType === 'paid') {
                                        remove_source(creator, setCreator, creator.metaHelper.selectedAdAccounts.find((a) => a.id === sectionId), idTypeHelper);
                                    } else {
                                        remove_section_from_report(creator, setCreator, sectionType, sectionId, idTypeHelper);
                                    }
                                }}
                            />
                        </ReportPreviewPdf>
                    </Slider_>
                    }
                    {slider.status && slider.active == 'structure' && (
                        <Edit_Structure_Preset
                            onClick={() => {
                                open_close_slider('');
                                trigger_preset_structure_change_check(setCreator);
                            }}
                            accessed={creator.fetchedPresets.accessed.structures}
                            preBuilt={creator.fetchedPresets.preBuilt.structures}
                            onApply={(structure) => {
                                set_preset_structure_and_report_title(structure, setCreator)
                            }}
                            onDelete={(structure) => delete_preset_structure(structure, setCreator)}
                        />
                    )}
                    {slider.status && slider.active == 'style' && ( 
                        <Edit_Style_Preset 
                            onClick={() => open_close_slider('')} 
                            accessed={creator.fetchedPresets.accessed.styles} 
                            preBuilt={creator.fetchedPresets.preBuilt.styles} 
                            onDelete={(style) => delete_preset_style(style, setCreator)} 
                            availableMedia={creator?.availableMedia}
                            removeMedia={(imgName) => {
                                const currMedia = creator.availableMedia?.filter((media) => media != imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }}
                            addMedia={(imgName) => {
                                let currMedia = creator.availableMedia;
                                currMedia.push(imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }} 
                        />
                    )}
                    {slider.status && slider.active == 'template' && (
                        <Edit_Template_Preset
                            onClick={() => {
                                open_close_slider('');
                                // trigger_preset_structure_change_check(setCreator);
                            }}
                            templates={creator.fetchedPresets.accessed.templates.concat(creator.fetchedPresets.preBuilt.templates)}
                            onApply={(template) => handle_template_selection(template, setCreator, setReportStyle, store)}
                            onDelete={(template) => delete_preset_template(template, setCreator)}
                        />
                    )}
                    {slider.status && slider.active == 'dates' && (
                        <Edit_Report_Dates
                            onClick={() => {
                                open_close_slider('');
                                trigger_dates_change_check(setCreator);
                                initial_slider_flow_helper(creator, setCreator, open_close_slider);
                            }}
                            onDates={(dates) => save_report_dates(dates, setCreator, store)}
                            onDateFormat={(e) => save_date_format(e, setCreator, store)}
                            currentFormat={creator.report.dateFormat}
                            dateFrom={creator.report.dateFrom}
                            dateTo={creator.report.dateTo}
                            acceptableRange={maximumAcceptableDateRange}
                            initLoad={!creator.initialFlowHelper.isCompleted}                            
                            skipSection={() => {
                                skip_Section(creator, setCreator, open_close_slider)
                            }}
                            changeConformationStatus={(status) => set_confirmation_status(creator, setCreator, status)}
                            confStatus={creator.initialFlowHelper.conformationCommunicate}
                            flow={creator?.presetStructure?.sections.map(item => item.type).filter(type => type !== "overview")}
                        />
                    )}
                    {slider.status && slider.active == 'logo' ? 
                        <Edit_Logo
                            onClick={() => open_close_slider('')} 
                            availableMedia={creator?.availableMedia}
                            removeMedia={(imgName) => {
                                const currMedia = creator.availableMedia?.filter((media) => media != imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }}
                            addMedia={(imgName) => {
                                let currMedia = creator.availableMedia;
                                currMedia.push(imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }}                            
                        />
                    :null 
                    }
                    {slider.status && slider.active == 'title' && (
                        <Edit_Report_Title 
                            onClick={() => {
                                open_close_slider('');
                                if(creator?.initialFlowHelper?.isCompleted === false) {
                                    setCreator((prev) => ({...prev, initialFlowHelper: {...prev.initialFlowHelper, isCompleted: true}}));
                                }
                            }} 
                            name={creator?.report?.title} 
                            onName={(e) => setCreator((prev) => ({ ...prev, report: { ...prev.report, title: e.target.value } }))} 
                            onCurreny={(e) => setCreator((prev) => ({ ...prev, report: { ...prev.report, currency: e.target.value } }))} 
                        />
                    )}
                    {slider.status && slider.active == 'section' && (
                    <Add_Section
                        onClick={() => open_close_slider('')}
                        businessAccounts={creator.metaHelper?.businessAccounts}
                        isOverviewAdded={creator.report.sections.some((section) => section.type === 'overview')}
                        addSource={(source, type) => open_close_slider(`section-source-${source}`, type)}
                        accessedAccounts={accessedAccounts}
                        save={() => start_report_finalization_process(creator, setCreator, reportStyle, "export")}
                    />
                    )}
                    {slider.status && slider.active == 'section-source-google' && (
                    <Add_Section_Google 
                        onClick={() => {
                            setCreator((prev) => ({
                                ...prev,
                                googleHelper: {
                                    ...prev.googleHelper,
                                    selectedManager: null,
                                    selectedAdAccount: null,
                                    selectedCampaigns: []
                                }
                            }))
                            open_close_slider(''); 
                        }}
                        onBack={() => {
                            setCreator((prev) => ({
                                ...prev,
                                googleHelper: {
                                    ...prev.googleHelper,
                                    selectedManager: null,
                                    selectedAdAccount: null,
                                    selectedCampaigns: []
                                }
                            }))
                            open_close_slider('section'); 
                        }}
                        managerAccounts={creator?.googleHelper?.managerAccounts}
                        accessibleAdAccounts={creator?.googleHelper?.accessibleAdAccounts}
                        selectedManager={creator?.googleHelper?.selectedManager}
                        addSource={(source, type) => {
                            add_source(creator, setCreator, source, type, accessedAccounts);
                        }}
                        removeSource={(type) => {
                            remove_source(creator, setCreator, type);
                        }}
                        adAccounts={creator?.googleHelper?.adAccounts}
                        selectedSections={creator.report.sections}
                        selectedAdAccount={creator?.googleHelper?.selectedAdAccount}
                        adAccountLoader={creator?.loaders?.googleAdAccountLoader}
                        addSection={(section, sectionType, sectionSource) => {
                            handle_section_change(creator, setCreator, 'add', section, sectionType, sectionSource, accessedAccounts);
                        }}
                        removeSection={(section, sectionType, sectionSource) => {
                            handle_section_change(creator, setCreator, 'remove', section, sectionType, sectionSource, accessedAccounts);
                        }}

                        campaigns={creator?.googleHelper?.campaigns}
                        selectedCampaigns={creator?.googleHelper?.selectedCampaigns}
                        campaignLoader={creator?.loaders?.googleCampaignsLoader}
                        addCampaign={(campaign, section) => {
                            add_campaign_to_section(creator, setCreator, campaign, section);
                        }}
                        removeCampaign={(campaign, position) => {
                            remove_campaign_from_section(creator, setCreator, campaign);
                        }}
                        resetGoogleVariables={() => {
                            console.log('reset');
                            setCreator((prev) => ({
                                ...prev,
                                googleHelper: {
                                    ...prev.googleHelper,
                                    selectedManager: null,
                                    selectedAdAccount: null,
                                    selectedCampaigns: []
                                }
                            }))
                        }}
                    />

                    )}

                    {slider.status && slider.active == 'section-source-meta' && (
                        <Add_Section_Meta
                            onClick={() => {
                                open_close_slider(''); 
                                setCreator((prev) => ({
                                    ...prev,
                                    metaHelper: {
                                        ...prev.metaHelper,
                                        selectedBusiness: null,
                                        selectedAdAccount: null,
                                        selectedCampaigns: []
                                    }
                                }))
                            }}
                            onBack={() => {
                                open_close_slider('section'); 
                                setCreator((prev) => ({
                                    ...prev,
                                    metaHelper: {
                                        ...prev.metaHelper,
                                        selectedBusiness: null,
                                        selectedAdAccount: null,
                                        selectedCampaigns: []
                                    }
                                }))
                            }}
                            businessAccounts={creator.metaHelper?.businessAccounts}
                            adAccounts={creator.metaHelper?.adAccounts}
                            selectedBusiness={creator.metaHelper.selectedBusiness}
                            selectedAdAccount={creator.metaHelper.selectedAdAccount}
                            selectedSections={creator.report.sections}
                            errors={creator.errors}
                            addSource={(source, type) => {
                                add_source(creator, setCreator, source, type, accessedAccounts);
                            }}
                            removeSource={(type) => {
                                remove_source(creator, setCreator, type);
                            }}
                            addSection={(section, sectionType, sectionSource) => {
                                handle_section_change(creator, setCreator, 'add', section, sectionType, sectionSource, accessedAccounts);
                            }}
                            removeSection={(section, sectionType, sectionSource) => {
                                handle_section_change(creator, setCreator, 'remove', section, sectionType, sectionSource, accessedAccounts);
                            }}
                            laodingAdAccounts={creator.loaders.adAccounts}
                            loadingCampaigns={creator.loaders.campaigns}
                            campaigns={creator?.metaHelper?.campaigns}
                            selectedCampaigns={creator?.metaHelper?.selectedCampaigns}
                            campaignLoader={creator?.loaders?.campaigns}
                            addCampaign={(campaign, section) => {
                                add_campaign_to_section(creator, setCreator, campaign, section);
                            }}
                            removeCampaign={(campaign, position) => {
                                remove_campaign_from_section(creator, setCreator, campaign);
                            }}
                        />
                    )}
                    {slider.status && slider.active == 'section-source-page' && (
                        <Add_Section_Fbin
                            onClick={() => open_close_slider('')}
                            onBack={() => open_close_slider('section')}
                            facebookPages={creator.metaHelper?.pages?.facebookPages}
                            instagramPages={creator.metaHelper?.pages?.instagramPages}
                            selectedSections={creator.report.sections}
                            sectionElements={creator.sectionElements}
                            pageType={slider.item}
                            errors={creator.errors}
                            addSource={(section, sectionType, sectionSource) => {
                                handle_section_change(creator, setCreator, 'add', section, sectionType, sectionSource, accessedAccounts);
                            }}
                            removeSource={(section, sectionType, sectionSource) => {
                                handle_section_change(creator, setCreator, 'remove', section, sectionType, sectionSource, accessedAccounts);
                            }}

                        />
                    )}
                    {slider.status && slider.active == 'splitter-text' && ( 
                        <Edit_Splitter_Text_Section 
                            onClick={() => open_close_slider('')} 
                            section={creator.report.sections.find((obj) => obj.type === slider.item.type && obj.source === slider.item.source && obj.position === slider.item.position)} 
                            onEdit={(e) => update_splitter_text(creator, setCreator, e.target.value, slider.item)} 
                        />
                    )}
                    {/* TODO put this element inside section_meta */}
                    {slider.status && slider.active == 'comment' && (
                        <Edit_Element_Comments
                            viewMode={creator.viewMode}
                            onClick={() => open_close_slider('')}
                            comment={creator.report.sections.find((obj) => obj.position === slider.item.sectionPosition && obj.type === slider.item.sectionType && obj.sectionData.find((sd) => sd.type === slider.item.idTypeHelper)?.id === slider?.item?.sectionId)?.rows.find((row) => row.position === slider.item.row.position && row.type===slider.item.row.type)}
                            onTitleEdit={(e) => {
                                update_comment_title(creator, setCreator, e.target.value, slider.item?.sectionType, slider.item?.sectionId, slider.item?.idTypeHelper, slider.item?.row, slider.item?.sectionPosition);
                            }}
                            onTextEdit={(e) => {
                                update_comment_text(creator, setCreator, e.target.value, slider.item?.sectionType, slider.item?.sectionId, slider.item?.idTypeHelper, slider.item?.row, slider.item?.sectionPosition);
                            }}
                            item={slider}
                        />
                    )}
                    {/* TODO: This is the Media slider <Edit_Media /> */}
                    {slider.status && slider.active == 'media' && (
                        <Edit_Media
                            viewMode={creator.viewMode}
                            row={creator.report.sections.find((obj) => obj.position === slider.item.sectionPosition && obj.type === slider.item.sectionType && obj.sectionData.find((sd) => sd.type === slider.item.idTypeHelper)?.id === slider?.item?.sectionId)?.rows.find((row) => row.position === slider.item.row.position && row.type===slider.item.row.type)}
                            onClick={() => open_close_slider('')}
                            onTitleEdit={(e) => {
                                // we use the same function with comments because the do the exact same thing
                                update_comment_title(creator, setCreator, e.target.value, slider.item?.sectionType, slider.item?.sectionId, slider.item?.idTypeHelper, slider.item?.row, slider.item?.sectionPosition);
                            }}
                            onGridEdit={(gridValue) => {
                                media_update_grid(creator, setCreator, gridValue, slider.item?.sectionType, slider.item?.sectionId, slider.item?.idTypeHelper, slider.item?.row, slider.item?.sectionPosition)
                            }}
                            availableMedia={creator?.availableMedia}
                            onSelectImage={(imgName) => {
                                media_update_img(creator, setCreator, imgName, slider.item?.sectionType, slider.item?.sectionId, slider.item?.idTypeHelper, slider.item?.row, slider.item?.sectionPosition)

                            }}
                            removeMedia={(imgName) => {
                                const currMedia = creator.availableMedia?.filter((media) => media != imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }}
                            addMedia={(imgName) => {
                                let currMedia = creator.availableMedia;
                                currMedia.push(imgName);
                                setCreator((prev) => ({
                                    ...prev,
                                    availableMedia: currMedia
                                }))
                            }}

                        />
                        
                    )}
                    {slider.status && slider.active == 'wizard' && 
                        <Wizard_ 
                            onInitial={() =>
                                initial_slider_flow_helper(creator, setCreator, open_close_slider)
                            }
                            onBack={() =>
                                prevSection(creator, setCreator)
                            }
                            addSource={(source, type) => {
                                add_source(creator, setCreator, source, type, accessedAccounts);
                            }}
                            removeSource={(type, section) => {
                                remove_source(creator, setCreator, type, section);
                            }}
                            addSection={(source, item) => 
                                fill_section_source(creator, setCreator, accessedAccounts, source, item)
                            }
                            removeSection={(source) => {
                                remove_section_source(creator, setCreator, source)
                            }}
                            errors={creator.errors}
                            businessAccounts={creator.metaHelper?.businessAccounts}
                            metaAdAccounts={creator.metaHelper?.adAccounts}
                            selectedBusiness={creator.metaHelper?.selectedBusiness}
                            selectedSections={creator.presetStructureHelper?.datafilledStructure?.sections}
                            facebookPages={creator.metaHelper?.pages?.facebookPages}
                            instagramPages={creator.metaHelper?.pages?.instagramPages}
                            addCampaign={(campaign, section) => {
                                add_campaign(creator, setCreator, campaign, section)
                            }}
                            removeCampaign={(campaign, sectionPosition) => {
                                remove_campaign(creator, setCreator, campaign, sectionPosition)
                            }}

                            initLoad={!creator.initialFlowHelper.isCompleted}
                            skipSection={() => {
                                skip_Section(creator, setCreator, open_close_slider)
                            }}
                            laodingAdAccounts={creator?.loaders?.adAccounts}
                            campaignLoader={creator?.loaders?.campaigns}
                            businessCursor={businessCursor}
                            flow={creator?.initialFlowHelper?.flow}
                            currentSectionIndex={creator?.initialFlowHelper?.currentSectionIndex}
                            metaCampaigns={creator?.metaHelper?.campaigns}
                            // title
                            reportTitle={creator?.report?.title} 
                            onTitle={(e) => setCreator((prev) => ({ ...prev, report: { ...prev.report, title: e.target.value } }))} 
                            onCurrency={(e) => setCreator((prev) => ({ ...prev, report: { ...prev.report, currency: e.target.value } }))} 
                            // dates
                            onClickDates={() => {
                                trigger_dates_change_check(setCreator);
                                // initial_slider_flow_helper(creator, setCreator, open_close_slider);
                            }}
                            onDates={(dates) => save_report_dates(dates, setCreator, store)}
                            onDateFormat={(e) => save_date_format(e, setCreator, store)}
                            currentFormat={creator.report.dateFormat}
                            dateFrom={creator.report.dateFrom}
                            dateTo={creator.report.dateTo}
                            acceptableRange={maximumAcceptableDateRange}

                            //google
                            managerAccounts={creator.googleHelper?.managerAccounts}
                            googleAccessibleAdAccounts={creator.googleHelper?.accessibleAdAccounts}
                            selectedManager={creator.googleHelper?.selectedManager}
                            googleAdAccounts={creator.googleHelper?.adAccounts}
                            googleSelectedAdAccount={creator.googleHelper?.selectedAdAccount}
                            loaderGoogleAdAccounts={creator.loaders.googleAdAccountLoader}
                            googleCampaigns={creator.googleHelper?.campaigns}
                            loaderGoogleCampaigns={creator.loaders.googleCampaignsLoader}
                        />
                    }

                    {slider.status && slider.active == 'edit-section' ? (
                        <Edit_Section
                            onClick={() => open_close_slider('')}
                            onEditName={(name) => update_section_name(creator, setCreator, slider?.item?.section, name, slider.item.idTypeHelper)}
                            section={
                                creator.report.sections.find((s) => s.source === slider.item.section.source && s.type === slider.item.section.type &&
                                    s.sectionData.find((sd) => sd.type === slider.item.idTypeHelper).id === slider.item.section.sectionData.find((sd) => sd.type === slider.item.idTypeHelper).id
                                )
                            }
                            matchingSectionElement={creator.sectionElements.find((item) => item.type === slider.item?.section?.type && item.source === slider?.item?.section?.source && slider?.item?.section?.sectionData?.find((sd) => sd.type === slider?.item?.idTypeHelper)?.id === item?.id)}
                        />
                    ) : null}
                    {slider.status && slider.active == 'edit-row' ? (
                        <Edit_Row
                            compare={slider.item.row.isCompared} 
                            toggleCompareOption={(option) => {
                                toggleOption(creator, setCreator, "compare", option, slider.item.row.type, slider.item.sectionType, slider.item.sectionId, slider.item.idTypeHelper)
                            }} 
                            sectionType={slider.item.sectionType}
                            rowType={slider.item.row.type}
                            onClick={() => open_close_slider('')}
                            onEditName={(name) => {
                                update_row_name(creator, setCreator, slider?.item?.section, slider?.item?.row, name, slider.item.idTypeHelper)
                            }}
                            row={
                                creator.report.sections.find((s) => s.source === slider.item.section.source && s.type === slider.item.section.type &&
                                    s.sectionData.find((sd) => sd.type === slider.item.idTypeHelper).id === slider.item.section.sectionData.find((sd) => sd.type === slider.item.idTypeHelper).id
                                ).rows.find((r) => r.type === slider.item.row.type)
                            }
                            matchingSectionElement={creator.sectionElements.find((item) => item.row === slider.item.row.type && item.type === slider.item?.section?.type && item.source === slider?.item?.section?.source && slider?.item?.section?.sectionData?.find((sd) => sd.type === slider?.item?.idTypeHelper)?.id === item?.id)}
                            onUpdateColor={(color) => {
                                update_row_elements_color(creator, setCreator, slider?.item?.section, slider?.item?.row, color, slider.item.idTypeHelper)}
                            }
                        />
                    ) : null }
                    {/* End of Sliders */}

                    <PageAlwaysVisible>
                        <PageTitle>
                            <h1>{t('Create report')}</h1>
                            {/* {true ?
                            <Alert_>
                                messages={alerts}
                                duration={30000}
                            </Alert_>
                            :null} */}
                        </PageTitle>
                        <CreatorActions>
                            <Grey_ text={t('Back')} iconLeft="Back" onClick={() => navigate(`/${lang}/report-template`)} />
                            <Creator_Right_Actions>
                                <Main_Plain text={t('Save and export')} iconLeft="File" onClick={() => {open_close_slider('export-pdf'); start_report_finalization_process(creator, setCreator, reportStyle, "export")}} />
                                <Main_ text={reportId ? (t('Update')) : (t('Save'))} iconRight="Check" 
                                    onClick={() => {
                                        start_report_finalization_process(creator, setCreator, reportStyle, 'save');
                                        alertMessage((t('Report created successfully...')), 'Success');
                                    }} 
                                />
                            </Creator_Right_Actions>
                        </CreatorActions>
                        <Report_Actions 
                            onLoadTemplate={() => open_close_slider('template')} 
                            onStylePreset={() => open_close_slider('style')} 
                            onStructurePreset={() => open_close_slider('structure')} 
                            onChangeView={() => setCreator((prev) => ({ ...prev, viewMode: !prev.viewMode }))}
                            viewMode={creator.viewMode} 
                            onExport={() => {open_close_slider('export-pdf'); start_report_finalization_process(creator, setCreator, reportStyle, "export")}}
                            isSaved={reportId ? true : false}
                        />
                            <ReportPaper>
                                <Report_Data 
                                    pdf={false}
                                    presetStyle={reportStyle} 
                                    onLogo={() => open_close_slider('logo')}
                                    onDates={() => open_close_slider('dates')} 
                                    brand={reportStyle?.brand} 
                                    dateFrom={format_date(creator?.report?.dateFrom, creator.report.dateFormat)} 
                                    dateTo={format_date(creator?.report?.dateTo, creator.report.dateFormat)} 
                                    viewMode={creator.viewMode}
                                    title={creator?.report?.title} 
                                    onClickTitle={() => open_close_slider('title')} 
                                    sections={creator.report.sections}
                                    onClickCardOnElement={() => {
                                        handle_section_change(creator, setCreator, 'add', { name: 'Overview' }, "overview", false, accessedAccounts);
                                    }}        
                                    onDeleteSplitterSection={(section, sectionType) => handle_section_change(creator, setCreator, 'remove', section, sectionType, section.source)} 
                                    onEditSplitterSection={(section) => open_close_slider('splitter-text', section)} 
                                    creatorSectionElements={creator.sectionElements}
                                    sliderFlowHelper={creator.sliderFlowHelper}
                                    errors={creator.errors}
                                    currency={creator.report?.currency}
                                    compare={creator.compareHelper}
                                    selectSource={(sliderName, section, info) => open_close_slider(sliderName, section, info)}
                                    removeSectionBeforeSourceSelection={(section) => remove_emptysource_section(creator, setCreator, section)}
                                    // toggleOption={(option, action, rowType) => {
                                    //     toggleOption(creator, setCreator, option, action, rowType, sectionType, sectionId, idTypeHelper);
                                    // }}
                                    onSetBlock={(element, value, property, sectionType, sectionId, rowType, idTypeHelper) => {
                                        update_block_element(creator, setCreator, element, value, property, sectionType, sectionId, rowType, idTypeHelper);
                                    }}
                                    onAddBlock={(block, sectionType, sectionId, rowType, idTypeHelper, sectionPosition) => {
                                        add_block_to_report(creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper, t, sectionPosition);
                                    }}
                                    onRemoveBlock={(block, sectionType, sectionId, rowType, idTypeHelper, sectionPosition) => {
                                        remove_block_from_report(creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper, sectionPosition);
                                    }}
                                    onAddRow={(rowType, sectionType, sectionId, idTypeHelper, sectionPos) => {
                                        add_row_to_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, t, sectionPos);
                                    }}
                                    onRemoveRow={(rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition) => {
                                        remove_row_from_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition);
                                    }}
                                    onRemoveDemographic={(item, sectionType, sectionId, idTypeHelper) => {
                                        remove_demographic_from_report(creator, setCreator, item, sectionType, sectionId, idTypeHelper)
                                    }}
                                    onAddDemographicChart={(item, chartType, sectionType, sectionId, idTypeHelper) => {
                                        add_demographic_block_to_report(creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper)
                                    }}
                                    onRemoveDemographicChart={(item, chartType, sectionType, sectionId, idTypeHelper) => {
                                        remove_demographic_block_from_report(creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper)
                                    }}
                                    onUpdateComment={(row, sectionType, sectionId, idTypeHelper, sectionPosition) => {
                                        open_close_slider('comment', { sectionType, sectionId, idTypeHelper, row, sectionPosition});
                                    }}
                                    onRemoveComment={(rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition) => {
                                        remove_row_from_section(creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition);
                                    }}
                                    onEditSection={(section, idTypeHelper) => {
                                        open_close_slider('edit-section', {section, idTypeHelper})
                                    }}
                                    onEditRow={(section, idTypeHelper, row, sectionType, sectionId) => {
                                        open_close_slider('edit-row', {section, idTypeHelper, row, sectionType, sectionId})
                                    }}
                                    onTitleEdit={(e, sectionType, sectionId, idTypeHelper, comment, sectionPosition) => {
                                        update_comment_title(creator, setCreator, e.target.value, sectionType, sectionId, idTypeHelper, comment, sectionPosition);
                                    }}
                                    onTextEdit={(e, sectionType, sectionId, idTypeHelper, comment, sectionPosition) => {
                                        update_comment_text(creator, setCreator, e.target.value, sectionType, sectionId, idTypeHelper, comment, sectionPosition);
                                    }}
                                    onClickCreatorPlus={() => open_close_slider('section')}
                                    onRemoveSection={(sectionType, sectionId, idTypeHelper, position) => {
                                        remove_section_from_report(creator, setCreator, sectionType, sectionId, idTypeHelper);
                                    }}
                                    //media
                                    onEditMedia={(row, sectionType, sectionId, idTypeHelper, sectionPosition) => {
                                        open_close_slider('media', { sectionType, sectionId, idTypeHelper, row, sectionPosition});
                                    }}             
                                    availableMedia={creator?.availableMedia}  
                                    onGridEdit={(gridValue, sectionType, sectionId, idTypeHelper, row, sectionPosition) => {
                                        media_update_grid(creator, setCreator, gridValue, sectionType, sectionId, idTypeHelper, row, sectionPosition)
                                    }}
                                    onSelectImage={(imgName, sectionType, sectionId, idTypeHelper, row, sectionPosition) => {
                                        media_update_img(creator, setCreator, imgName, sectionType, sectionId, idTypeHelper, row, sectionPosition)
                                    }}
                                    removeMedia={(imgName) => {
                                        const currMedia = creator.availableMedia?.filter((media) => media != imgName);
                                        setCreator((prev) => ({
                                            ...prev,
                                            availableMedia: currMedia
                                        }))
                                    }}
                                    addMedia={(imgName) => {
                                        let currMedia = creator.availableMedia;
                                        currMedia.push(imgName);
                                        setCreator((prev) => ({
                                            ...prev,
                                            availableMedia: currMedia
                                        }))
                                    }}


                                    dataLoader={creator.loaders?.sections}
                                />
                            </ReportPaper>
                        
                    </PageAlwaysVisible>
                </Report>
            ) : null}
        </PageFull>
    );
}
