import { Button, Grey_Link, IconSvg, LangContext, device } from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '../../../localization/i18next';

const NavbarMenu = styled.div``;

const NavbarMenuList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media ${device.md} {
        display: none;
    }
`;

const NavbarMenuItem = styled(Button)`
    position: relative;
    background: transparent;
    cursor: pointer;

    span {
        font-size: var(--smallText);
        font-family: 'fontMedium';
        color: var(--greyDark);
    }

    &:hover {

        span {
            color: ${(p) => p.theme.color};
        }
    }
`;

const NavbarInnerMenu = styled.div`
    position: fixed;
    top: 7rem;
    left: 50%;
    transform: translateX(-50%);
    padding: var(--smallDist);
    background: ${(p) => p.theme.mid};
    box-shadow: ${(p) => p.theme.out};
    border-radius: var(--smallRadius);
    display: flex;
    gap: 2rem;
`;

const NavbarInnerMenuColumn = styled.div`
    h6 {
        margin-bottom: 1rem;
    }
`;

const NavbarInnerMenuList = styled.ul``;

export default function Navbar_Menu(props) {

    const lang = i18n.language ? i18n.language : 'en';
    const {t} = useTranslation()
    const navigate = useNavigate();


    return (
        <NavbarMenu>
            <NavbarMenuList>
                <NavbarMenuItem size="small" onClick={()=> navigate(`/${lang}/features`)}>
                    <span>{t("Features")}</span>
                </NavbarMenuItem>
                <NavbarMenuItem size="small" onClick={()=> navigate(`/${lang}/pricing`)}>
                    <span>{t("Pricing")}</span>
                </NavbarMenuItem>
                {/* <NavbarMenuItem size="small" onClick={()=> navigate(`/${lang}/integrations`)}>
                    <span>{t("Integrations")}</span>
                </NavbarMenuItem> */}
            </NavbarMenuList>
        </NavbarMenu>
    );
}
