import React, { useState } from 'react';
import styled from 'styled-components';
import { H2, H3, P } from '../../../styles/components/LandingText';
import { Card, device } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const FeaturesExamples = styled.section``;

const FeaturesExamplesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

const FeaturesExamplesCard = styled(Card)`
    padding: var(--smallDist);
    h3 {
        margin-bottom: 2rem;
    }
    h4 {
        margin-bottom: 1rem;
        color: var(--focus);
    }
`;

const FeaturesExamplesCta = styled(FeaturesExamplesCard)`
    background: linear-gradient(to bottom right, var(--mainDark), var(--focusLight));
    cursor: pointer;
    transition: transform 0.5s;

    h3,
    h4,
    p {
        color: var(--white);
    }

    &:hover {
        transition: transform 0.5s;
        transform: scale(1.02);
    }
`;

export default function Features_Expamples(props) {

    const {t} = useTranslation()

    return (
        <FeaturesExamples>
            <H2>{t("Explore our Features")}</H2>
            <FeaturesExamplesGrid>
                <FeaturesExamplesCard>
                    <H3>{t("Get beautiful Meta Reports.")}</H3>
                    <h4>{t("Really fast.")}</h4>
                    <P>{t("Select between the most popular KPIs. Fetch data in real time from Ad Accounts,facebook and instagram pages. For you or your clients")}!</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Google Ads Integration.")}</H3>
                    <h4>{t("Boost Results with Google Ads.")}</h4>
                    <P>{t("Unlock the full potential of your advertising data with our Google Ads Integration feature. Effortlessly connect your Google Ads account to generate comprehensive reports that offer deep insights into your campaign performance. Analyze key metrics like CTR, CPC, conversion rates, and more with precision. Customize your reports to highlight the data that matters most to your business, and stay ahead of your competition with real-time, accurate insights!")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Prebuilt Templates")}</H3>
                    <h4>{t("Instant, Professional Reporting")}</h4>
                    <P>{t("Choose from our range of expert-designed templates for quick and easy reporting. Simply select a template, integrate your accounts, and generate comprehensive reports in seconds. Our user-friendly templates save you time and effort, allowing you to focus on insights rather than setup.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Custom Templates")}</H3>
                    <h4>{t("Your Reports, Your Way")}</h4>
                    <P>{t("Embrace the flexibility of creating your own templates with our Custom Templates feature. Tailor your reports to fit your unique requirements and save them for future use. Reuse your personalized templates as often as needed, ensuring consistency and efficiency in your reporting process. It's about making your workflow as individual as your business needs.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("White Label Solutions")}</H3>
                    <h4>{t("Tailor Your Reports with Style")}</h4>
                    <P>{t("Elevate the aesthetic of your reports with our versatile Report Style options. Select from a range of prebuilt styles to quickly give your reports a professional and polished look. Or, for a touch that's uniquely yours, create your own brand kit. Customize with your choice of colors, fonts, and more to align your reports with your brand identity. It's about making your data not just informative, but visually compelling.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Custom KPIs")}</H3>
                    <h4>{t("Define Success on Your Terms")}</h4>
                    <P>{t("With our Custom KPIs feature, take control of your performance metrics. Add your own Key Performance Indicators (KPIs) to your reports to focus on what matters most to your business. Tailor your analysis to your specific goals and gain deeper insights into your unique success parameters.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Report Comments")}</H3>
                    <h4>{t("Interactive Communication within Reports")}</h4>
                    <P>{t("Enhance your reports with our Report Comments feature. Add unlimited comments to discuss insights, explain data points, or communicate directly with clients within the report. Elevate your communication further by uploading images for clearer illustration and better engagement. This interactive tool transforms your reports into a dynamic platform for collaboration and detailed explanation.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Dynamic Comparison")}</H3>
                    <h4>{t("Insightful Period-on-Period Analysis")}</h4>
                    <P>{t("Our Dynamic Comparison tool enables you to effortlessly compare current period values with those of previous periods. Gain critical insights by observing trends, measuring growth, and identifying patterns over time. This feature allows for an in-depth understanding of your data's progression, making it easier to make informed decisions based on historical context.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCard>
                    <H3>{t("Export PDF and Share")}</H3>
                    <h4>{t("Effortless Sharing, Professional Format")}</h4>
                    <P>{t("Our Export PDF feature simplifies report sharing while maintaining a professional look. With just a click, convert your detailed reports into a sleek PDF format, ready to be shared with clients or team members. This tool ensures your reports are not only informative but also easily distributable in a universally accessible format, enhancing communication and collaboration.")}</P>
                </FeaturesExamplesCard>
                <FeaturesExamplesCta onClick={props.onBuy}>
                    <H3>{t("Ready to Elevate Your Reporting?")}</H3>
                    <h4>{t("Start Your Free Trial Today!")}</h4>
                    <P>{t("Dive into the full experience of our powerful features. Click here to begin your free trial and discover firsthand why we are the real deal in advanced reporting solutions. Don't just take our word for it - test our features and see the difference they can make in your data analysis and reporting.")}</P>
                </FeaturesExamplesCta>
            </FeaturesExamplesGrid>
        </FeaturesExamples>
    );
}
