import React, { useContext, useEffect, useState } from 'react';
import { googleLogin } from '../../services/api/GoogleApi';
import { AlertContext, Alert_, Facebook_, Grey_, Main_Plain, On_Click_Card, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageSixty, PageSubtitle, PageTitle } from 'monica-alexandria';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../../../redux/store';

import {
    ConnectGoogleActions,
    ConnectGoogleActionsButtonWrapper,
    ConnectGoogleActionsRight,
} from './CONNECT.css';
import { AuthContext } from '../../../../services/authentication/Guard';
import { checkOwnership } from '../../../../helpers/verifyToolOwnership';
import { Google_ } from './components/Google_Button';

export default function CONNECT() {


    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = store.getState().selectedLanguage ? store.getState().selectedLanguage : 'en';
    const auth = useContext(AuthContext);
    const userId = auth?.user?.sub;

    /* For tool ownership validation */
    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);


    const { alerts, setAlerts } = useContext(AlertContext);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const google_Login = () => {
        googleLogin()
            .then((response) => {
                window.location.assign(response?.data?.data?.authenticationURL)
            })
            .catch(error=>{console.error(error); alertMessage((t("Something went wrong")),'Error');})
    }


    const connctToDifferentAccount = () => {
        googleLogin({shouldSelectAccount: true})
        .then((response) => {
            window.location.assign(response?.data?.data?.authenticationURL)
        })
        .catch(error=>{console.error(error); alertMessage((t("Something went wrong")),'Error');})
    }

    /* Validate Tool Ownership */
    useEffect(() => {
        if (userId) checkOwnership(userId, navigate, lang, setHasCheckOwnership);
    }, [userId]);


    return (
         
         <>
        <Helmet>
            <title>Adsnap | Connect To Google</title>
        </Helmet>
            { hasCheckOwnership && <PageForty>
            <Alert_ messages={alerts} />
                <PageAlwaysVisible>
                    <PageTitle>
                        <h1>{t('Connect account')}</h1>
                    </PageTitle>
                    <PageSubtitle>
                        <p>
                            {t("Connect your app with Google to access the necessary data for automated reports. By connecting, you'll be able to retrieve information from your Google accounts seamlessly")}
                        </p>
                    </PageSubtitle>

                        <ConnectGoogleActions>
                        <Grey_
                            text={t("Back")}
                            iconLeft="Back"
                            id={"AdSnap_Connect_Google_1"}
                            onClick={() => navigate(`/${lang}/google/accounts`)}
                        />
                        <ConnectGoogleActionsRight>
                            <ConnectGoogleActionsButtonWrapper>
                                <Google_
                                    icon
                                    // size="medium"
                                    // id={"AdSnap_Connect_Google_2"}
                                    text={t("Connect with Google")}
                                    onClick={google_Login}
                                />
                            </ConnectGoogleActionsButtonWrapper>
                            
                            <Main_Plain
                                text={t('Connect to a different account')}
                                id={"AdSnap_Connect_Google_3"}
                                onClick={() => connctToDifferentAccount()}
                                noPadding
                                className="DifferentAccount"
                            />
                        </ConnectGoogleActionsRight>
                    </ConnectGoogleActions>


                </PageAlwaysVisible>
                <PageDesktopOnly>
                    <PageIllu>
                        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/Password.svg" />
                    </PageIllu>
                </PageDesktopOnly>
            </PageForty> }
        </>
    );
}