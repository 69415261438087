import { PageTitle, device } from 'monica-alexandria';
import styled from 'styled-components';

export const Plan = styled.div`
    position: relative;
    width: 100%;
`;

export const PlanRight = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: calc(100vh);
    width: calc(100% - 60rem);
    background: linear-gradient(to bottom, var(--focus), var(--mainDark) 160%);

    @media ${device.xl} {
        width: calc(100% - 80rem);
        .Canvas {
            display: none;
        }
    }

    @media ${device.md} {
        width: calc(100% - 60rem);
    }

    @media ${device.sm} {
        display: none;
    }
`;

export const PlanLeft = styled.div`
    width: 100%;
    max-width: 60rem;
    padding: 0 2rem 4rem 4rem;
    background: ${(p) => p.theme.background};

    @media ${device.xl} {
        width: 100%;
        max-width: 80rem;
    }

    @media ${device.md} {
        max-width: 60rem;
    }

    @media ${device.sm} {
        max-width: none;
    }
`;

export const PlanCurve = styled.img`
    position: fixed;
    left: 60rem;
    height: 100%;
    z-index: 2;
    pointer-events: none;

    @media ${device.xl} {
        left: 80rem;
    }

    @media ${device.md} {
        left: 60rem;
    }

    @media ${device.sm} {
        display: none;
    }
`;

export const PlanContainer = styled.div`
    position: relative;
    z-index: 3;
`;

export const PlanTitle = styled(PageTitle)`
    display: block;
    h5 {
        margin-top: 2rem;
        color: var(--greyDark);
    }
`;

export const PlanTitleFocus = styled.span`
    color: var(--focus);
`;

export const PlanCard = styled.div`
    background: linear-gradient(to bottom left, var(--focus) -100%, var(--main), var(--mainDark) 300%);
    width: 100%;
    padding: 2rem;
    border-radius: var(--normalRadius);
    display: flex;
    align-items: center;
    flex-direction: column;

    h6 {
        text-align: center;
        color: var(--white);
        margin-top: 4rem;
    }
`;

export const PlanIcon = styled.div`
    background: ${(p) => p.theme.background};
    border-radius: var(--normalRadius);
    padding: 2rem;
    aspect-ratio: 1/1;

    img {
        margin: 0 auto;
        display: block;
        width: 15rem;
        height: 15rem;
        object-fit: contain;
    }

    h2 {
        text-align: center;
    }
`;

export const PlanWrapper = styled.div`
    text-align: center;

    h2 {
        font-size: 5rem;
        color: var(--white);
        position: relative;

        span {
            font-size: 1.6rem;
        }

        div {
            position: absolute;
            height: 2px;
            width: 15rem;
            top: 50%;
            left: -1rem;
            background: var(--grey);
            transform: rotate(-5deg);
        }
    }
`;

export const PlanCta = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlanCoupon = styled.div`
    h4 {
        color: var(--white);
    }
`;

export const PlanSection = styled.div`
    margin-top: 4rem;

    h2 {
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1rem;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
`;
