import { Actions_, Focus_, PageAlwaysVisible, PageDesktopOnly, PageForty, PageFull, PageIllu, PageSixty, PageTitle , Main_Loader} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { AuthContext } from '../../services/authentication/Guard';
import { has_account } from '../../services/api/Accounts';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import store from '../../redux/store';
import { getNumOfReports } from '../../sources/meta/services/api/Reports';
import { checkPermissions } from '../../helpers/checkPermissions';
import { get_my_subscriptions } from '../../services/api/Users';

const clients = require('../../config/index').config

const FirstReport = styled.div``

const FirstReportWrapper = styled.div`
    max-width: 55rem;
    width: 100%;
    padding: 2rem;
    box-shadow: ${p => p.theme.outFocus};
    border-radius: var(--normalRadius);
    margin: 4rem auto 0 auto;
    background: ${p => p.theme.low};
`

const FirstReportIllu = styled.img``

export default function FIRST_REPORT() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const [firstReportContent, setFirstReportContent] = useState({
        title: '',
        titleBr:'',
        text1:'',
        text2:'',
        text3:'',        
    });
    const planName = process.env.REACT_APP_PLAN_NAME;
    const auth = useContext(AuthContext);
    const userId = auth?.user?.sub;
    const [userInfo, setUserInfo] = useState({
        userId: auth.user?.userId ? auth.user?.userId : false,
        isLogged: auth.user?.userId ? true : false,
        hasBusiness: false,
        username: auth.user?.firstname,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);
    const [renderPage, setRenderPage] = useState(false); // State to handle redirect to /my-reports
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const permission = checkPermissions('Knight', auth.user.roles);

    const handleNavigate = () => {
        navigate(`/${lang}/report-template`);
    };

    const fetchUserAccounts = () => {
        has_account(userInfo.userId)
            .then((res) => {
                if (res?.data?.data?.length > 0) setUserInfo({ ...userInfo, hasBusiness: true });
                else setUserInfo({ ...userInfo, hasBusiness: false });
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (!userInfo?.isLogged) setFirstReportContent({
            title: t('Get started'),
        });
        else if (userInfo?.hasBusiness) setFirstReportContent({
            title: (t("Let's create a report")),
            titleBr: (t("Hello ")) + userInfo.username + (t(" welcome to Adsnap!")),
            text1:(t("It seems like this is your first time visiting.")),
            text2:(t("Don 't worry, getting started is easy!")),
            text3:(t("Click the button below to begin and experience the AdSnap difference now.")),        

        });
        else setFirstReportContent({
            title: (t("Let's make your first report")),
            titleBr:(t("0 reports saved")),
            text1:(t("It seems that you have not available reports to show in your account.")),
            text2: (t("Click the button to create a brand new one!")),
            text3:'',        
        });
    }, [userInfo]);

    useEffect(() => {
        if (firstReportContent.title !== '') setIsLoading(false);
    }, [firstReportContent]);

    useEffect(() => {
        if (!userInfo?.isLogged) setUserInfo({ ...userInfo });
        else fetchUserAccounts();
    }, []);


    useEffect(() => {
        getNumOfReports()
        .then((res) => {
            console.log(res);
            const numOfReports = res?.data?.data?.numOfReports;
            if(numOfReports > 0)
                navigate(`/${lang}/my-reports`);
            else
                setRenderPage(true); // Set loading to false once data is fetched
        })
        .catch(err => {
            console.error(err);
        });
    }, []);

        /* Validate Tool Ownership */
    useEffect(() => {
        get_my_subscriptions(userId)
        .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                if(!lois_subs?.tools?.some(tool => tool?.active) && !permission) window.location.assign(payments_URL);
                else setHasCheckOwnership(true)
        }).catch((err) => console.log('error', err));
    }, [userId]);


    return (
        <PageFull>
            <Helmet>
                <title>Adsnap | Welcome </title>
            </Helmet>
            { renderPage && hasCheckOwnership?
                <FirstReport>
                        <PageAlwaysVisible>
                            <FirstReportWrapper>
                                <FirstReportIllu src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/firstReport/Welcome.svg" />
                                <PageTitle>
                                    <h1>{firstReportContent.title}</h1>
                                    <h5>{firstReportContent?.text1}</h5>
                                    <h5>{firstReportContent?.text2}</h5>
                                    <h5>{firstReportContent?.text3}</h5>
                                </PageTitle>
                                <Actions_ end>
                                    <Focus_ text={t("New report")} id={"AdSnap_First_Report_1"} onClick={handleNavigate} iconRight="Next"/>
                                </Actions_>
                            </FirstReportWrapper>
                        </PageAlwaysVisible>
                </FirstReport>
            : null
            }
        </PageFull>
    );
}
