import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Steps_Actions, Builder_, Google_Search, Section_Title, Toggle_Stat, CardsSixty } from 'monica-alexandria';
import Snippet_Toggle_Donot from '../snippets/Snippet_Donot';
import { useTranslation } from 'react-i18next';

const WizardInstagramPages = styled.div``;
const InstagramWrapper  = styled.div`
    max-height: 60vh;  /* Adjust the height as needed */
    overflow-y: auto;
    padding-right: 15px;  /* To avoid content getting */
`;


export default function Wizard_Instagram_Page(props) {

    const { t } = useTranslation();

    const [displayedInstaPages, setDisplayedInstaPages] = useState(props.instagramPages);

    const [searchResultsInstaPages, setSearchResultsInstaPages] = useState();



    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsInstaPages?.input) {

                let indexToRemove = searchResultsInstaPages?.data.findIndex(obj => obj.id === 0);

                // If the index is found (not -1), remove the object
                if (indexToRemove !== -1) {
                    searchResultsInstaPages?.data.splice(indexToRemove, 1);
                }
                setDisplayedInstaPages(searchResultsInstaPages?.data);
            }
            else setDisplayedInstaPages(props.instagramPages);
        };

        applySearchResults();
    }, [searchResultsInstaPages]);

    return (
        <WizardInstagramPages>
            <h3>{t("Instagram insights")}</h3>

            <InstagramWrapper>
                {props.instagramPages.length > 15 ?
                <Builder_ >
                    <Google_Search file={props.instagramPages} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Instagram Page')} setResults={(data) => setSearchResultsInstaPages(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                </Builder_> 
                : null }
                <Section_Title text={t('Instagram Organic')} />
                <CardsSixty>
                    {displayedInstaPages.map((item, i) => {
                        const isAdded = props.selectedSections.some((obj) => obj.type === 'instagram' && obj.sectionData?.find((sd) => sd.type === 'instagramPage')?.id === item.instagram_business_account?.id);
                        return (
                            <Toggle_Stat
                                key={i}
                                id={i}
                                text={item.instagram_business_account?.username}
                                onClick={() =>
                                    !isAdded ? props.addSection("meta", item) : props.removeSection("meta")
                                }
                                active={isAdded}
                            />
                        );
                    })}
                </CardsSixty>
            </InstagramWrapper>
            <Steps_Actions onClick_Main={props.onClick_Main} onClick_Grey={props.onClick_Grey} icon_Grey="Back" icon_Main="Next" text_Grey={t("Back")} text_Main={t("Next")}/>
        </WizardInstagramPages>
    )
}
