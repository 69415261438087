import axios from "axios"
import { config } from "../../config"

const get_basic_analytics = (dates) =>{
    return new Promise((resolve,reject) =>{
        
        let params = {};
        if ( dates?.sinceDate ) params.since = dates.sinceDate;
        if ( dates?.untilDate ) params.until = dates.untilDate;

        axios.get(`${config.LOIS_BACKEND_URL}/analytics/getBasicAnalytics`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}




export {
    get_basic_analytics,
};
