import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { SlideOptions, SlideOptionsWrapper } from '../../../../../../styles/components/SlideActions';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Snippet_Vertical_Actions } from '../snippets/Snippet_Vertical_Actions';
import { useTranslation } from 'react-i18next';
import Snippet_Block_Overlay from '../snippets/Snippet_Block_Overlay';
import Edit_Block from '../edit/Edit_Block';


const BlockGraph = styled(SlideOptions)`
    height: 30rem !important;
    width: 100%;
    background: ${(p) => p.theme.low};
    border-radius: ${(p) => p.presetStyle.radius};

    .recharts-cartesian-axis-tick-line,
    text {
        fill: ${(p) => (p.variant == 'main' ? p.presetStyle.color.textLight : p.variant == 'focus' ? p.presetStyle.color.textLight : p.variant == 'focusInverted' ? p.presetStyle.color.focus : p.variant == 'mainInverted' ? p.presetStyle.color.main : p.presetStyle.color.textDark)};
    }

    .recharts-yAxis {
        transform: translateX(-3rem);
    }

    .recharts-surface{
        transform: ${p => 
            p.transformCase === 1 ? 'scaleX(1.05)'
            :p.transformCase=== 2 ? 'scaleX(1.10)'
            :'scaleX(1.12)'
        };
        height: 26rem !important;
    }

    .recharts-surface,
    .recharts-responsive-container,
    .recharts-wrapper {
        cursor: pointer;
        position: relative;
    }
`;

const BlockGraphWrapper = styled(SlideOptionsWrapper)`
    width: 100%;
    height: 100%;
    border: 1px solid ${(p) => p.presetStyle.color.background};
    background: ${(p) => (p.variant == 'main' ? p.presetStyle.color.main : p.variant == 'focus' ? p.presetStyle.color.focus : p.presetStyle.color.grey)};
    border-radius: ${(p) => p.presetStyle.radius};
    padding: 1rem 2rem;
    overflow: hidden;
`;

const BlockGraphTitle = styled.div`
    padding-bottom: 15px;

    h6 {
        font-family: ${(p) => p.presetStyle.font.family} !important;
        color: ${(p) => (p.variant == 'main' ? p.presetStyle?.color.textLight : p.variant == 'focus' ? p.presetStyle?.color.textLight : p.variant == 'focusInverted' ? p.presetStyle?.color.focus : p.variant == 'mainInverted' ? p.presetStyle?.color.main : p.presetStyle?.color.textDark)};
        transform: translate(-1rem, -.5rem);
    }
`;

export default function Block_Graph(props) {

    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    const [slider, setSlider] = useState(false);
    const [interval, setInterval] = useState(0);
    const [dataKey, setDataKey] = useState(null);
    const [transformCase, setTrasformCase] = useState(props.rowLen);

    useEffect(() => {
        if (props.data?.length > 0) {
            if(props.data.length <= 7) {
                setInterval(props.data.length);
            }
            else if(props.data.length > 60) {
                setInterval(29);
            }
            else {
                setInterval(6);
            }
            if(props.data[0].hasOwnProperty("date")) setDataKey("date");
            else setDataKey("end_time");
        }
    }, [props.data]);

    const open_close_slider = () => {
        setSlider(false);
        // props.setActiveBlock(false);
    };

    function getMonthAbbreviation(monthNumber) {
        const months = [
            t('Jan'), 
            t('Feb'), 
            t('Mar'), 
            t('Apr'), 
            t('May'), 
            t('Jun'),
            t('Jul'), 
            t('Aug'), 
            t('Sep'), 
            t('Oct'), 
            t('Nov'), 
            t('Dec')
        ];
        
        if (monthNumber < 1 || monthNumber > 12) {
            return "Invalid month number";
        }
        
        return months[monthNumber - 1];
    }

    function formatXAxis1(dateString) {
        // Split the date string into year, month, and day
        const [year, month, day] = dateString.split('-');
    
        // Remove leading zeros if present
        const formattedDay = day.replace(/^0+/, '');
        const formattedMonth = month.replace(/^0+/, '');
    
        // Return the formatted date
        return `${formattedDay} ${getMonthAbbreviation(formattedMonth)}`;
    }
    const formatXAxis2 = (tickItem) => {
        // Assuming tickItem is in the format 'YYYY-MM-DDTHH:mm:ss+0000'
        const date = new Date(tickItem);
        const month = date.getMonth() + 1; // Months are zero-indexed
        const day = date.getDate();
        return `${day} ${getMonthAbbreviation(month)}`;
    };

    function formatYAxis(number) {
        // Check if the number is one thousand or more but less than a million
        if (number >= 1000 && number < 1000000) {
            return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        // Check if the number is one million or more
        else if (number >= 1000000) {
            return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        // Handle numbers less than 1000 with up to two decimal places
        else if (!Number.isInteger(number)) {
            return number.toFixed(2);
        }
        // Handle integer values under 1000
        return number;
    }

    function calculateXAxisInterval(dataLength, transformCase) {
        let maxTicks;
        if(transformCase >= 3)
            maxTicks = 4
        else 
            maxTicks = 5
        // Ensure there's a division by zero check
        if (dataLength <= maxTicks) return 0;  // If data length is 5 or fewer, show all ticks
        return Math.ceil(dataLength / maxTicks) - 1;  // Calculate interval to fit roughly 5 labels
      }

      useEffect(() => {
        setTrasformCase(props.rowLen);
      }, [props.rowLen]);

    return (
        <BlockGraph transformCase={transformCase} className={props.className} presetStyle={reportStyle} actions={props.actions} variant={props.variant} id={props.id} >
            {slider ? 
            <Edit_Block 
                onClick={() => open_close_slider()} 
                onBack={() => open_close_slider()}
                title={props.title}       
                titlePlaceholder={props.titlePlaceholder} 
                titleDefaultValue={props.titleDefaultValue} 
                setBlock={props.setBlock} 
                id={props.id} 
                displayedName={props.displayedName}
                element={props.element} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                rowType={props.rowType} 
                variant={props.variant} 
            /> :null}
            <Snippet_Vertical_Actions onEdit={() => setSlider(!slider)} onDelete={props.onDelete} />
            <BlockGraphWrapper viewMode={props.viewMode} actions={props.actions} variant={props.variant} presetStyle={reportStyle} onClick={props.onActions}>
                <ResponsiveContainer width="100%" height="100%">
                    <BlockGraphTitle presetStyle={reportStyle} variant={props.variant} >
                        <h6>{props.title}</h6>
                    </BlockGraphTitle>
                    <LineChart                     
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 10,
                            bottom: 5,
                        }}
                    >
                        <Line strokeWidth={3} type="monotone" dataKey="value"
                        stroke={props.variant == 'main' ? reportStyle?.color?.textLight : props.variant == 'focus' ? reportStyle?.color?.textLight : props.variant == 'focusInverted' ? reportStyle?.color?.focus : props?.variant == 'mainInverted' ? reportStyle?.color?.main : reportStyle?.color?.textDark}
                         dot={false} 
                        />
                        <YAxis tickLine={false} axisLine={false} domain={['auto', 'auto']} tickFormatter={formatYAxis}/>
                        <XAxis
                            dataKey={dataKey}
                            tickLine={false}
                            axisLine={false}
                            interval={calculateXAxisInterval(props.data?.length, transformCase)} 
                            tickFormatter={dataKey === "date" ? formatXAxis1 : formatXAxis2} // Format X-axis ticks
                        />
                    </LineChart>
                </ResponsiveContainer>
            </BlockGraphWrapper>
            {!props.viewMode ?
                <Snippet_Block_Overlay stylePreset={reportStyle} text={t('Click to interact')} />
            :
                null
            }
        </BlockGraph>
    );
}
