export default function FlexHeightEqualizer() {
    const flexItems = document.querySelectorAll('.height-equalizer');
    let maxHeight = 0;

    // Reset the height to compute the natural height
    flexItems.forEach(item => {
        item.style.height = 'auto';
    });

    // Find the maximum height
    flexItems.forEach(item => {
        if (item.offsetHeight > maxHeight) {
            maxHeight = item.offsetHeight;
        }
    });

    // Apply the maximum height to all items
    flexItems.forEach(item => {
        item.style.height = `${maxHeight}px`;
    });
}

// Run the function on load and on resize
window.onload = FlexHeightEqualizer;
window.onresize = FlexHeightEqualizer;