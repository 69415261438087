import { Card, Grey_Link, device } from 'monica-alexandria';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Block_Static from '../../../sources/meta/pages/creator/components/block/Block_Static';
import InstaLogo from '../../../assets/insta.png';
import FbLogo from '../../../assets/fb.png';
import MetaLogo from '../../../assets/MetaLogo.png';
import Block_Map from '../../../sources/meta/pages/creator/components/block/Block_Map';
import Block_Gender from '../../../sources/meta/pages/creator/components/block/Block_Gender';
import Block_Ages from '../../../sources/meta/pages/creator/components/block/Block_Ages';
import { useTranslation } from 'react-i18next';
import { tab } from '@testing-library/user-event/dist/tab';
import { H2 } from '../../../styles/components/LandingText';
import i18n from '../../../localization/i18next';
import { useNavigate } from 'react-router-dom';

const LandingSum = styled.section`
    .LandingSumMap {
        grid-column: span 2;
    }

    @media ${device.lg} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.sm} {
        .LandingSumMap {
            grid-column: span 1;
        }
    }
`;

const LandingSumLogo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 5rem;

    img {
        width: 2.5rem;
    }

    h2 {
        margin-bottom: 0;
    }
`;

const LandingSumGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;

    @media ${device.lg} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`;

const LandingSumBox = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem;
    background-color: ${(p) => p.theme.low};

    div {
        cursor: default !important;
    }
`;

const LandingDemoBox = styled(Card)`
    grid-column: span 4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    background-color: ${(p) => p.theme.low};

    h4 {
        grid-column: span 2;
    }

    div,
    g,
    path,
    svg {
        cursor: default !important;
    }

    @media ${device.lg} {
        grid-column: span 2;
    }

    @media ${device.sm} {
        grid-column: span 1;
        grid-template-columns: 1fr;

        h4 {
        grid-column: span 1;
    }
    }
`;

const LandingSumButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
`;

export default function Landing_Sum(props) {

    const {t} = useTranslation()
    const lang = i18n.language ? i18n.language : 'en';
    const navigate = useNavigate();

    const LandingDemographics = [
        { group: 'GR', value: 543 },
        { group: 'GB', value: 319 },
        { group: 'FR', value: 164 },
        { group: 'DE', value: 252 },
    ];

    const LandingAges = [
        { group: '18-24', value: 554 },
        { group: '25-34', value: 321 },
        { group: '35-44', value: 173 },
        { group: '45-54', value: 253 },
        { group: '55-64', value: 167 },
        { group: '65 +', value: 226 },
    ];

    const LandingGenders = [
        { group: 'female', value: 587 },
        { group: 'male', value: 392 },
        { group: 'unknown', value: 143 },
    ];

    return (
        <LandingSum>
            <LandingSumGrid>
                <LandingSumBox>
                    <h4>{t("Meta Ads KPIs")}</h4>
                    <Block_Static
                        actions={false}
                        variant="focus"
                        title="Spend"
                        value="€ 183,50"
                        viewMode
                        trend
                        comparedValue="+ 17,18%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="focus"
                        title="Clicks"
                        value="21.051"
                        viewMode
                        trend
                        comparedValue="+ 30,17%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="focus"
                        title="Website Purchases"
                        value="821"
                        viewMode
                        trend
                        comparedValue="+ 35,03%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="focus"
                        title="ROAS"
                        value="6.27"
                        viewMode
                        trend
                        comparedValue="+ 83,24%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                </LandingSumBox>
                <LandingSumBox>
                    <h4>{tab("Facebook and Instagram pages KPIs")}</h4>
                    <Block_Static
                        actions={false}
                        variant="main"
                        title="Post Likes"
                        value="2043"
                        viewMode
                        trend
                        comparedValue="+ 73,24%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="main"
                        title="Followers"
                        value="305425"
                        viewMode
                        trend
                        comparedValue="+ 67 Followers"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="main"
                        title="Post Saves"
                        value="1530"
                        viewMode
                        trend
                        comparedValue="+ 89,61%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                    <Block_Static
                        actions={false}
                        variant="main"
                        title="Page Engagement"
                        value="938"
                        viewMode
                        trend
                        comparedValue="+ 88,04%"
                        sliderFlowHelper={{
                            shouldOpen: false,
                            openSlider: '',
                            previousSlider: '',
                            metadata: {},
                            closeNested: false,
                            openNested: false,
                        }}
                    />
                </LandingSumBox>
                <LandingSumBox className="LandingSumMap">
                    <h4>{t("World stats")}</h4>
                    <Block_Map actions={false} kpiName="" demographics={LandingDemographics} variant="mainInverted" maxValue={500} dataIdentifier="group" viewMode />
                </LandingSumBox>
                <LandingDemoBox>
                    <h4>{t("Demographic Analysis")}</h4>
                    <Block_Gender actions={false} kpiName={t("Genders")} json={LandingGenders} variant="mainInverted" maxValue={500} dataIdentifier="group" viewMode />
                    <Block_Ages actions={false} kpiName={t("Ages")} json={LandingAges} variant="mainInverted" dataIdentifier="group" viewMode />
                </LandingDemoBox>
            </LandingSumGrid>
            <LandingSumButton>
                <Grey_Link size="Large" text={t("Explore all features")} iconRight="Next" onClick={()=> navigate(`/${lang}/features`)}/> 
            </LandingSumButton>
        </LandingSum>
    );
}
