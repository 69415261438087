import React from 'react';
import { Slider_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import Style_Preset_Editor from '../style/Style_Preset_Editor';


export default function Edit_Logo(props) {
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} title={t('Edit Logo')} text="Done">
            <Style_Preset_Editor availableMedia={props.availableMedia} removeMedia={props.removeMedia} addMedia={props.addMedia}/>
        </Slider_>
    );
}