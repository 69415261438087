import { Main_ } from "monica-alexandria";
import React from "react";
import styled from "styled-components";

const Google = styled.button`
    display: flex;
    align-items: center;
    gap: .5rem;
    background: var(--grey);
    border-radius: var(--normalRadius);
    border: 1px solid var(--white);
    padding: var(--normalPads);

    span {
        color: var(--black);
        font-size: var(--normalText);

    }

    img{
        width: 2rem;
        height: 2rem;
    }

    &:hover {
        background: var(--greyLight);
        border: 1px solid var(--greyLight);
        cursor: pointer;
    }

`;

export const Google_ = (props) => {
  return (
    <Google onClick={props.onClick} id={props.id} className={props.className + ' ' + 'Google'} >
      {props.icon ? (
        <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/googleLogo.png" />
      ) : null}
      {props.text ? <span>{props.text}</span> : null}
    </Google>
  );
};