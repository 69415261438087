import React from 'react'
import { Single_ } from 'monica-alexandria'
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import GoogleLoginEL from '../../assets/GoogleLoginEL.png'
import GoogleLoginEN from '../../assets/GoogleLoginEN.png'

import { useTranslation } from 'react-i18next';
export default function CONNECTION_ERROR() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const lang = i18n.language || 'en';

  return (
    <Single_
        backgroundImage={lang === "en" ? GoogleLoginEN : GoogleLoginEL}
        iconMain="Rocket"
        onClickGrey="-"
        onClickMain={() => navigate(`/${lang}/integrations`)}
        subtitle={t("Your account didn't connect properly due missing permissions.")}
        text={t("Please try again and make sure you select the checkbox while logging in your Google account in order to give our application the necessary permissions. If you are having trouble please contact our Support team!")}
        textMain={t("Try again")}
        title={t("Problem with connecting Google account")}
    /> )
}
