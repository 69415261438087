import React from 'react'
import styled from 'styled-components'
import { Builder_, Input_, Steps_Actions, Dropdown_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const WizardTitle = styled.div``;

export default function Wizard_Title(props) {
    const { t } = useTranslation();

    return (
        <WizardTitle>
            <Builder_ title={t('Name your report')}>
                <Input_ defaultValue={props.reportTitle} placeholder={t('Report name')} onChange={props.onTitle} />
            </Builder_>
            <Builder_ title={t('Configure the currency of your report')}>
                <Dropdown_>
                    <select onChange={props.onCurreny}>
                        <option value="EUR">€ (EUR)</option>
                        <option value="USD">$ (USD)</option>
                        <option value="GBP">£ (GBP)</option>
                    </select>
                </Dropdown_>
            </Builder_>
            {props.reportTitle ?
            <Steps_Actions onClick_Main={props.onClick_Main} onClick_Grey={props.onClick_Grey} icon_Grey="Back" icon_Main="Next" text_Grey={t("Back")} text_Main={t("Next")}/>
            : <h3>{t("Please give a title")}</h3>
            }

        </WizardTitle>
    )
    }
