import React from 'react';
import styled from 'styled-components';
import { Cta_, Focus_, device } from 'monica-alexandria';
import { H5 } from '../../../styles/components/LandingText';
import { useTranslation } from 'react-i18next';
import HeaderReport from '../../../assets/features/HeaderReport.svg'

const FeaturesHeader = styled.div``;

const FeaturesHeaderWrapper = styled.div`
    margin: 2rem 4rem;
    background: ${(p) => p.theme.low};
    box-shadow: ${(p) => p.theme.outFocus};
    border-radius: var(--normalRadius);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10rem 4rem;
    position: relative;


    @media ${device.lg} {
    grid-template-columns: 1fr;
    }
`;

const FeaturesHeaderHero = styled.div`
    h1 {
        font-size: 7rem;
        line-height: 1;
        margin-bottom: 2rem;
    }

    button {
        margin-top: 2rem;
        width: 50%;
        justify-content: center;
        min-width: auto;
    }

    @media ${device.md} {
      h1{
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }

    @media ${device.sm} {
      padding: 6rem 2rem;

      h1{
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 2rem;
      }

      button{
        width: 100%;
      }
    }

`;

const FeaturesHeaderReport = styled.div`
    position: absolute;
    right: 4rem;
    top: 2rem;
    height: 100%;
    width: 40%;

    @media ${device.lg} {
    position: static;
    width: 100%;
    margin: 0 auto;
    transform: translateY(8rem);
    }
`;

export default function Features_Header(props) {

    const {t} = useTranslation()

    return (
        <FeaturesHeader>
            <FeaturesHeaderWrapper>
                <FeaturesHeaderHero>
                    <h1>
                        {t("Get beautiful reports")}.
                        <br />
                        {t("Really fast")}.
                    </h1>
                    <H5>
                        {t("Select between the most popular KPIs. Fetch data in real time from facebook,instagram pages, Business managers and Google Ads accounts. Showcase the results from your Meta and Google ad accounts")}. <br />{t("For you or your clients")}!
                    </H5>
                    <Cta_ size="large" text={t("Start 15-day trial")} onClick={props.onBuy} />
                </FeaturesHeaderHero>
                <FeaturesHeaderReport>
                    <img src={HeaderReport} />
                </FeaturesHeaderReport>
            </FeaturesHeaderWrapper>
        </FeaturesHeader>
    );
}
