import axios from "axios"
import { config } from "../../config"


const getReport = (reportId) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/automatedReports/getReport?id=${reportId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

export {
    getReport
}