import React from 'react'
import styled from 'styled-components'
import { H2 } from '../../../styles/components/LandingText'
import ProductHunt from '../../../assets/landing/productHunt.png'
import { useTranslation } from 'react-i18next'
import { device } from 'monica-alexandria'

const LandingRatings = styled.section`
    width: 50%;
    img{
        max-width: 20rem;
    }

    @media ${device.lg} {
      width: 100%;
    }
`

export default function Landing_Ratings() {

    const {t} = useTranslation()

  return (
    <LandingRatings>
        <H2>{t("Essential for Digital marketers like you!")}</H2>
        <img src={ProductHunt} />
    </LandingRatings>
  )
}
