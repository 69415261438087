import React from 'react';
import styled from 'styled-components';

const ColorPicker = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 1rem;

    input {
        padding: 0;
        margin: 0;
        height: 4rem;
        width: 8rem;
        cursor: pointer;
        border: none;
        outline: 0;
    }
`;

export const Color_Picker = (props) => {
    return (
        <ColorPicker id={props.id} className={props.className + 'ColorPicker'} key={props.key}>
            <input type="color" value={props.selectedColor} onChange={props.onChange} />
            <h5>{props.text}</h5>
        </ColorPicker>
    );
};
