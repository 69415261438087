import { deepClone } from "../../../../../helpers/DeepCloneObject";


export const update_row_name = (creator, setCreator, section, row, name, idTypeHelper) => {
    let updatedReportSections = deepClone(creator.report?.sections);
    
    updatedReportSections
        .find((s) => s.type === section.type && s.source === section.source && s?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id === section?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id)
        .rows.find((r) => r.type === row.type).rowTitle = name;
        
    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const update_row_elements_color = (creator, setCreator, section, row, color, idTypeHelper) => {
    let updatedReportSections = deepClone(creator.report?.sections);
    
    let updatedElements = updatedReportSections
        .find((s) => s.type === section.type && s.source === section.source && s?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id === section?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id)
        .rows.find((r) => r.type === row.type).elements
    

    updatedElements.forEach((el) => el.variant = color)

    updatedReportSections
        .find((s) => s.type === section.type && s.source === section.source && s?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id === section?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id)
        .rows.find((r) => r.type === row.type).elements = updatedElements

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}

export const add_row_to_section = (creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, t, sectionPosition) => {
    let updatedRepostSection = deepClone(creator.report.sections);
    
    let targetSection = updatedRepostSection
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId);

    const position = targetSection?.rows?.length;
    let newRow = {};
    /* Could change to switch if more types are implemented */
    if ( rowType === "comment" ) {
        newRow = {
            title: (t("Comment")),
            text: (t("Write a comment")),
            position: position,
            type: rowType,
            path: "", 
            preview: "",
        };
    }
    else if( rowType === "media") {
        newRow = {
            title: (t("Media")),
            position: position,
            type: rowType,
            grid: "split",
            image: null,
        };
    }
    else {
        newRow = {
            elements: [],
            isCompared: true,
            position: position,
            rowTitle: `${rowType?.charAt(0)?.toUpperCase() + rowType?.slice(1)} indicators`,
            type: rowType,
        };
    }

    targetSection?.rows.push(newRow)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedRepostSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
        restructureSectionElements: true,
        // TODO: code provide by christos to help me implement add comment -> edit comment (the feature is made using my way for the moment)
        // just pointing here to review after debugging and testing to wipe out this piece of code
        // sliderFlowHelper: !rowType === "comment" ? prev.sliderFlowHelper : {
        //     ...prev.sliderFlowHelper,
        //     shouldOpen: true,
        //     openSlider: "comment",
        //     previousSlider: "",
        //     metadata: {sectionType, sectionId, idTypeHelper, row: newRow, position},
        //     closeNested: true,
        //     openNested: true,
        // }
    }))
}


export const remove_row_from_section = (creator, setCreator, rowType, sectionType, sectionId, idTypeHelper, rowPosition, sectionPosition) => {

    let updatedRepostSection = deepClone(creator.report.sections);
    const rowTypesToSearchByPosition = ["comment", "static", "graphical", "demographics", "media"];

    const indexToRemove = updatedRepostSection
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .findIndex(obj=> !rowTypesToSearchByPosition.includes(rowType) ? obj.type === rowType : obj.type === rowType && obj.position === rowPosition);

    updatedRepostSection 
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .splice(indexToRemove, 1);

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedRepostSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
        restructureSectionElements: true,
        shouldFetch: false
    }));
}