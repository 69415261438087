import { Grey_Link, Main_Plain, Red_ } from 'monica-alexandria';
import React from 'react';
import styled from 'styled-components';

const SnippetHorizontalActions = styled.div`
    gap: 1rem;
    display: ${(p) => (p.viewMode ? 'none' : 'flex')};

    .GreyLink {
        cursor: grab !important;
    }
`;

export const Snippet_Horizontal_Actions = (props) => {
    return (
        <SnippetHorizontalActions className={props.className} viewMode={props.viewMode}>
            {/* <Grey_Link iconLeft="Ham" noPadding /> */}
            {!props.hideEdit ? <Main_Plain iconLeft="Edit" noPadding onClick={props.onEdit} /> : null}
            <Red_ iconLeft="Delete" noPadding onClick={props.onDelete} />
        </SnippetHorizontalActions>
    );
};
