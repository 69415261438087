import { CardsSixty, On_Stat, Section_Title, Slider_, Toggle_Stat, Google_Search, Builder_ , Partial_Loader, CardsFull, On_Click_Card, CardsForty} from 'monica-alexandria';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Error_Message from '../creator/Creator_Error_Message';
import Slider_Expand from '../slider/Slider_Expand';

const AdAccountWrapper = styled.div``;

export default function Add_Section_Google(props) {

    const { t } = useTranslation();
    const sectionsLen = props.selectedSections?.length;

    // For searching 

    const [displayedManagers, setDisplayedManagers] = useState(props.managerAccounts);
    const [searchResultsManagers, setSearchResultsManagers] = useState();

    const [displayedAdAccounts, setDisplayedAdAccounts] = useState(props.adAccounts);
    const [searchResultsAdAccounts, setSearchResultsAdAccounts] = useState();

    const [displayedCampaigns, setDisplayedCampaigns] = useState([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);
    const [searchResultsCampaigns, setSearchResultsCampaigns] = useState();


    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsManagers?.input) setDisplayedManagers(searchResultsManagers?.data);
            else setDisplayedManagers(props.managerAccounts);
        };

        applySearchResults();
    }, [searchResultsManagers]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsAdAccounts?.input) setDisplayedAdAccounts(searchResultsAdAccounts?.data);
            else setDisplayedAdAccounts(props.adAccounts);
        };

        applySearchResults();
    }, [searchResultsAdAccounts]);


    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsCampaigns?.input) setDisplayedCampaigns(searchResultsCampaigns?.data);
            else setDisplayedCampaigns(filteredCampaigns);
        };

        applySearchResults();
    }, [searchResultsCampaigns]);


    useEffect(() => {
        setFilteredCampaigns(props.campaigns?.filter((camp) => camp?.customerId === props.selectedSections[sectionsLen-1]?.sectionData[0]?.id));
        setDisplayedCampaigns(props.campaigns?.filter((camp) => camp?.customerId === props.selectedSections[sectionsLen-1]?.sectionData[0]?.id));
        
    }, [props.campaigns, props.selectedSections]);

    useEffect(() => {        
        setDisplayedAdAccounts(props.adAccounts);
    }, [props.adAccounts]);

    const [activeTab, setActiveTab] = useState(false); // Manage active tab state


    useEffect(() => {        
        setDisplayedAdAccounts(props.adAccounts);
    }, [props.adAccounts]);

    useEffect(() => {
        if(props.managerAccounts.length === 0 && props.accessibleAdAccounts.length !== 0) {
            setActiveTab("withoutManager")
        }
        else if(props.managerAccounts.length !== 0 && props.accessibleAdAccounts.length === 0) {
            setActiveTab("withManager")
        }
        else {
            setActiveTab(false)
        }
    }, []);


    // useEffect(() => {
    //     if(props.managerAccounts.length === 0) setActiveTab("withoutManager")
        
    // }, [props.managerAccounts, props.accessibleAdAccounts]);
    console.log('activeTab', activeTab);
    return (
        <Slider_ 
            onClick={props.onClick} text={t('Done')} title={t('Select Account')} iconGreyLeft="Back" grey={t('Back')} 
            onGrey={() => {
                if (activeTab !== false && props.managerAccounts.length !== 0 && props.accessibleAdAccounts.length !== 0) {
                    setActiveTab(false);
                    props.resetGoogleVariables();
                } 
                else {
                    props.onBack();
                }
              }}
        >

            {activeTab === "withManager"  ?  
            <>
                {props.managerAccounts?.length > 15 && 
                    <Builder_ >
                        <Google_Search file={props.managerAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true} placeholder={t('Search Google Manager Account')} setResults={(data) => setSearchResultsManagers(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                    </Builder_> 
                }

                {displayedManagers?.length > 0 &&
                <>
                    <Section_Title text={t('Select Google Manager Account')} />
                    <CardsSixty>
                    {!props.selectedManager ?
                        <>
                            {displayedManagers?.map((item, i) => {
                                const isAdded = item?.id === props.selectedManager?.id;
                                return (
                                    <Toggle_Stat key={i} id={i} text={item.descriptiveName} active={isAdded}
                                        onClick={() => {
                                            !isAdded ? props.addSource(item, "google") : props.removeSource("google");
                                        }}
                                    />
                                )   
                            })}
                        </>
                        :
                        <Toggle_Stat key={props.selectedManager?.id} id={0} text={props.selectedManager?.descriptiveName} active={true}
                            onClick={() => props.removeSource('google')}
                        />
                    }
                    </CardsSixty>

                    {props.selectedManager && 
                    <AdAccountWrapper>
                        {props.adAccounts.length > 15 ?
                            <Builder_ >
                                <Google_Search file={props.adAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true}      placeholder={t('Search Ad Account')} setResults={(data) => setSearchResultsAdAccounts(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                            </Builder_> 
                        : null }
                        <Section_Title text={t('Select Google Ad Account')} />
                        {props.adAccountLoader ? <Partial_Loader/> :
                        <CardsSixty>
                            {!props.selectedAdAccount ?
                            <>
                                {displayedAdAccounts
                                ?.filter((adAccount) => adAccount?.parentManagerInfo?.id === props.selectedManager?.id)
                                ?.map((item, i) => {
                                    const isAdded = props.selectedSections.some((obj) => obj.source === 'google' && obj.sectionData?.find((sd) => sd.type === 'googleAdAccount').id === item.id);
                                    return (
                                        <Toggle_Stat id={i} key={i} text={item?.descriptiveName} active={isAdded}
                                            onClick={() => (!isAdded ? props.addSection(item, "paid", "google") : props.removeSection(item, "paid", "google"))} 
                                        />
                                    )
                                })}
                            </>
                            : 
                            <Toggle_Stat key={props.selectedAdAccount?.id} id={0} text={props.selectedAdAccount?.descriptiveName} active={true}
                                onClick={() => props.removeSection(props.selectedAdAccount, "paid", "google")}
                            />
                            }
                        </CardsSixty>
                        }
                    </AdAccountWrapper>
                    }

                </> 
                }
            </> 
            
           : activeTab === "withoutManager"  ?

            <>
                {/* {props.accessibleAdAccounts.length > 15 ?
                    <Builder_ title={t('Search for Ad Accounts')}>
                        <Google_Search file={props.adAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true}      placeholder={t('Search Ad Account')} setResults={(data) => setSearchResultsAdAccounts(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                    </Builder_> 
                    : 
                    null 
                } */}
                {props.accessibleAdAccounts.length > 0 ? <Section_Title text={t('Google Ad Accounts')} /> : null} 
                {props.adAccountLoader ? <Partial_Loader/> :
                <CardsSixty>
                    {!props.selectedAdAccount ?
                    <>
                        {props.accessibleAdAccounts?.map((item, i) => {
                            const isAdded = props.selectedSections.some((obj) => obj.source === 'google' && obj.sectionData?.find((sd) => sd.type === 'googleAdAccount').id === item.id);
                            return (
                                <Toggle_Stat id={i} key={i} text={item?.descriptiveName} active={isAdded}
                                    onClick={() => (!isAdded ? props.addSection(item, "paid", "google") : props.removeSection(item, "paid", "google"))} 
                                />
                            )
                        })}
                    </>
                    :
                    <Toggle_Stat 
                        key={props.selectedAdAccount?.id} id={0} text={props.selectedAdAccount?.descriptiveName} active={true}
                        onClick={() => props.removeSection(props.selectedAdAccount, "paid", "google")}
                    />
                    }
                </CardsSixty>
                }
            </>
           :  <Builder_>
                    <CardsForty>
                        <On_Click_Card subtitle={t('Manager accounts')} onClick={() => setActiveTab('withManager')} />
                        <On_Click_Card subtitle={t('Accessible accounts')} onClick={() => setActiveTab('withoutManager')} />
                    </CardsForty>
                </Builder_> 
            }

            {activeTab !== false && props.selectedAdAccount && filteredCampaigns?.length >= 0 &&
                <Slider_Expand
                    filteredCampaigns={filteredCampaigns}
                    setSearchResults={setSearchResultsCampaigns}
                    loadingCampaigns={props.campaignLoader}
                    displayedCampaigns={displayedCampaigns}
                    selectedSections={props.selectedSections}
                    addCampaign={props.addCampaign}
                    removeCampaign={props.removeCampaign}
                    title={t('Specific Campaign (Optional)')}
                    currentSectionIndex={sectionsLen - 2}
                />
            }
            
        </Slider_>
    )
}
