import styled, { keyframes } from 'styled-components';

const revealActions = keyframes`
   from {
      transform: translateX(0rem);
    }
    to {
      transform: translateX(-4rem);
    }
`;

const hideActions = keyframes`
   from {
      transform: translateX(-4rem);
    }
    to {
      transform: translateX(0rem);
    }
`;

export const SlideOptions = styled.div`
    position: relative;
    cursor: pointer;

    .SnippetBlockOverlay {
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
    }

    &:hover {
        .SnippetBlockOverlay {
            opacity: ${(props) => (props.actions === false || !props.viewMode || props.actions === 'initial' ? '1' : '0')};
            transition: opacity 0.5s ease-in-out;
        }
    }
`;

export const SlideOptionsActions = styled.div``;

export const SlideOptionsOverlay = styled.div``;

export const SlideOptionsWrapper = styled.div`
    height: 100%;
    width: 100%;
    transition: transform 0.5s;
    position: relative;
    animation: ${(props) => (props.actions === 'initial' ? 'none' :props.viewMode ? 'none'  :props.actions === true ? revealActions : 'none' )} 0.5s forwards;
`;
