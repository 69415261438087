import { device } from 'monica-alexandria';
import styled from 'styled-components';

export const CreateCustomField = styled.div`
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr auto;
    align-items: center;

    @media ${device.sm} {
        grid-template-columns: 1fr auto;

        div:first-of-type {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
`;
