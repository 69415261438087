import {
    Main_Plain,
    Grey_,
    PageAlwaysVisible,
    PageDesktopOnly,
    PageForty,
    PageTitle,
    Facebook_,
    PageSubtitle,
    PageIllu,
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../../localization/i18next';
import store from '../../../../redux/store';
import { new_report_validation } from '../../helpers/pageValidations_TEST';
import {
    ConnectFacebookActions,
    ConnectFacebookActionsButtonWrapper,
    ConnectFacebookActionsRight,
} from './CONNECT_FACEBOOK.css';
import { AuthContext } from '../../../../services/authentication/Guard';
import { check_user_status_on_landing } from '../../../../services/api/Accounts';
import { Helmet } from 'react-helmet';
import { ClickHandler } from '../../../../styles/components/ClickHandler.css';
import { checkPermissions } from '../../../../helpers/checkPermissions';
import { get_my_subscriptions } from '../../../../services/api/Users';
const clients = require('../../../../config/index').config

export default function CONNECT_FACEBOOK() {
    const selections = store.getState().metaSource?.selection_info;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const lang = i18n.language ? i18n.language : 'en';

    const [checkedAccRequest, setCheckedAccRequest] = useState(false);
    const [navigateBackURL, setNavigateBackURL] = useState()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectURL = queryParams.get('redirectURL');

    const planName = process.env.REACT_APP_PLAN_NAME;
    const auth = useContext(AuthContext)
    const userId = auth?.user?.sub;
    const [userInfo, setUserInfo] = useState({
        userID: '',
        username: '',
        accessToken: '',
        state: 'unchecked',
        shouldLogin: false,
    });

    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const permission = checkPermissions('Knight', auth.user.roles);
    const initActions = () => {
        new_report_validation(selections)
            .then((res) => {
                checkLoginState();
            }).catch((err) => {});
    };

    const login = () => {
        window.FB.login(
            function (response) {
                if (response.status === 'connected') {
                    setUserInfo({
                        ...userInfo,
                        userID: response.authResponse.userID,
                        accessToken: response.authResponse.accessToken,
                        state: 'connected',
                        shouldLogin: true,
                    });
                } else
                    setUserInfo({
                        ...userInfo,
                        state: 'disconnected',
                        shouldLogin: false,
                    });
            },
            {
                scope: 'ads_management,business_management,ads_read,instagram_basic,instagram_manage_insights,pages_show_list, pages_read_engagement,read_insights',
                extras: {
                    setup: {
                        external_business_id: '12345',
                        timezone: 'Europe/Athens',
                        currency: 'EUR',
                        business_vertical: 'ECOMMERCE',
                    },
                    business_config: {
                        business: {
                            name: 'Adsnap Doitforme',
                        },
                    },
                },
            }
        );
    };

    const checkLoginState = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                setUserInfo({
                    ...userInfo,
                    userID: response.authResponse.userID,
                    accessToken: response.authResponse.accessToken,
                    state: 'connected',
                    shouldLogin: false,
                });
            } else setUserInfo({ ...userInfo, userID: '', accessToken: '', state: 'disconnected', shouldLogin: false });
        });
    };

    const getUsername = () => {
        window.FB.api('/me', function (response) {
            setUserInfo({ ...userInfo, username: response.name });
        });
    };

    const saveInfoAndNavigate = () => {

        console.error(userInfo)
        store.dispatch({
            type: 'SAVE_FACEBOOK_USER',
            payload: {
                userID: userInfo.userID,
                accessToken: userInfo.accessToken,
                username: userInfo.username,
            },
        });
        
        if ( redirectURL ) window.location.assign(redirectURL); 
        else navigate(`/${lang}/meta/report-template`);
    };

    const connctToDifferentAccount = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                window.FB.logout(function (response) {
                    if (response.status === 'unknown') {
                        setUserInfo({
                            ...userInfo,
                            userID: '',
                            accessToken: '',
                            state: 'disconnected',
                            username: '',
                            shouldLogin: false,
                        });
                        login();
                    } 
                });
            } else login();
        });
    };


    useEffect(() => {
        const checkAccounts = () => {
            check_user_status_on_landing(userId)
            .then((res) => {
                setCheckedAccRequest(true);
                if ( res.data.data.hasReports ) setNavigateBackURL(`/${lang}/my-reports`);
                else setNavigateBackURL(`/${lang}/welcome`)
            })
            .catch(err=>{
                setCheckedAccRequest(true);
                setNavigateBackURL(`/${lang}/welcome`)
            })
        }

        if ( !checkedAccRequest ) checkAccounts()
    })

    useEffect(() => {
        if (userInfo?.shouldLogin) saveInfoAndNavigate();
    }, [userInfo.username]);

    useEffect(() => {
        if (userInfo.state == 'connected') {
            getUsername();
        }
    }, [userInfo.state]);

    useEffect(() => {
        initActions();
    }, []);


    /* Validate Tool Ownership */
    useEffect(() => {
        get_my_subscriptions(userId)
        .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                console.log('lois subs', lois_subs)
                if(!lois_subs?.tools?.some(tool => tool?.active) && !permission) window.location.assign(payments_URL);
                else setHasCheckOwnership(true)
        }).catch((err) => console.log('error', err));
    }, [userId]);

    return (
        <>
        <Helmet>
            <title>Adsnap | Connect to Facebook</title>
        </Helmet>
        { hasCheckOwnership && checkedAccRequest && <PageForty>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t('Connect account')}</h1>
                </PageTitle>
                <PageSubtitle>
                    <p>
                    {t("Connect your app with Facebook to access the necessary data for automated reports. By connecting, you'll be able to retrieve information from your Facebook and Instagram accounts seamlessly")}
                    </p>
                </PageSubtitle>
                <ConnectFacebookActions>
                    <Grey_
                        text={t("Back")}
                        iconLeft="Back"
                        id={"AdSnap_Connect_Facebook_1"}
                        onClick={() => navigate(navigateBackURL)}
                    />
                    <ConnectFacebookActionsRight>
                        <ClickHandler noClick={!(userInfo?.state !== 'unchecked' &&
                        (userInfo?.state == 'disconnected' ||
                            (userInfo?.state == 'connected' &&
                                userInfo?.username)))} >
                            <ConnectFacebookActionsButtonWrapper>
                                    <Facebook_
                                        icon
                                        size="medium"
                                        id={"AdSnap_Connect_Facebook_2"}
                                        text={
                                            userInfo?.state == 'connected' &&
                                            userInfo?.username
                                                ? `${t('Connect as')} ${
                                                      userInfo.username
                                                  }`
                                                : (t('Continue with Facebook'))
                                        }
                                        onClick={() =>
                                            userInfo.state == 'connected'
                                                ? saveInfoAndNavigate()
                                                : login()
                                        }
                                    />
                            </ConnectFacebookActionsButtonWrapper>
                        </ClickHandler>
                        {process.env.REACT_APP_USE_DUMMY_DATA == 'OFF' &&
                        userInfo?.state == 'connected' &&
                        userInfo?.username ? (
                            <Main_Plain
                                text={t('Connect to a different account')}
                                id={"AdSnap_Connect_Facebook_4"}
                                onClick={() => connctToDifferentAccount()}
                                noPadding
                                className="DifferentAccount"
                            />
                        ) : null}
                        {process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ? ( //DUMMY
                            <Main_Plain /* Dummy */
                                text={t('Connect to a different account')}
                                id={"AdSnap_Connect_Facebook_5"}
                                onClick={() => connctToDifferentAccount()}
                                noPadding
                                className="DifferentAccount"
                            />
                        ) : null}
                    </ConnectFacebookActionsRight>
                </ConnectFacebookActions>
            </PageAlwaysVisible>
            <PageDesktopOnly>
                <PageIllu>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/FbLogin.svg" />
                </PageIllu>
            </PageDesktopOnly>
            </PageForty> }
        </>
    );
}
