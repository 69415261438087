// This page makes sign in mandatory.
// Any page that uses this page as as a parent in Router, is a protected page.
// That means that only logged-in users can access it.
// The decision whether the user should navigate to login is made here.
// GuardedRoute is informed (via shouldRedirect prop) and acts accordingly.

import axios from 'axios'
import { React, useEffect, useState, createContext } from 'react';
import { GuardedRoute } from './Guard';
import { useNavigate } from 'react-router-dom';
import store from '../../redux/store';


////////////////////////////////////////////////////////////////////
// Informs GuardedRoute that user must log in before continuing  ///
////////////////////////////////////////////////////////////////////
export const Protector = ({role, children}) =>  {
    const navigate = useNavigate();
    const [mutex, setMutex] = useState(false);

    const login = () => {
        window.FB.login(
            
            function (response) {
                if (response.status === 'connected') {
                    store.dispatch({
                        type: 'SAVE_FACEBOOK_USER',
                        payload: {
                            userID: response.authResponse.userID,
                            accessToken: response.authResponse.accessToken,
                            username: response.authResponse.username,
                        },
                    });

                    navigate(0); 
                }
            },
            {
                scope: 'ads_management,business_management,ads_read,instagram_basic,instagram_manage_insights,pages_show_list, pages_read_engagement,read_insights',
                extras: {
                    setup: {
                        external_business_id: '12345',
                        timezone: 'Europe/Athens',
                        currency: 'EUR',
                        business_vertical: 'ECOMMERCE',
                    },
                    business_config: {
                        business: {
                            name: 'Adsnap Doitforme',
                        },
                    },
                },
            }
        );
    };

    useEffect(() => {
        axios.interceptors.response.use(response => {
            const accessedAccounts = store.getState()?.app?.accessedAccounts;

            if (response?.data?.error?.code == '190' ) {
               setMutex(true)
            }
            // new access token for google account -> save to cache
            if (response?.headers['x-new-access-token-google']) {
                console.log(accessedAccounts);

                const newAccessToken = response.headers['x-new-access-token-google'];
                console.log(newAccessToken);
                const googleAccount = accessedAccounts.google;
                console.log(googleAccount);
                store.dispatch({
                    type: 'SAVE_GOOGLE_USER_PROFILE',
                    payload: {
                        userId: googleAccount.userId,
                        accessToken: newAccessToken,
                        username: googleAccount.username,
                    },
                });
            }

            return response;
        })
    },[])


    useEffect(() => {
        if (mutex) login();
    },[mutex])

    return (
        <GuardedRoute shouldRedirect={true} role={role}>
            {children}
        </GuardedRoute>
    );
}


