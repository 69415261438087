export const getDateFlexibleDaysEarlier = (inputDate, daysDiff) => {
    // Parse the input date string to a Date object
    const originalDate = new Date(inputDate);
  
    // Calculate the date 30 days earlier
    const newDate = new Date(originalDate);
    newDate.setDate(originalDate.getDate() - parseInt(daysDiff));
  
    // Format the new date to match the original format (yyyy-MM-dd)
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}
  
