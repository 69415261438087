import axios from "axios";
import { config } from "../../../../config";


export const retrieveApiKeys = (domain) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.LOIS_BACKEND_URL}/woocommerce/api/retrieveWoocommerceApiKeys?domain=${domain}`)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });     
    })     
}




