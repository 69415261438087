import { deepClone } from "../../../../../helpers/DeepCloneObject";

/* Structure sectionElements from the presetStructure sections */
export const structure_section_elements = async (creator, triggerSource, accessedAccounts) => {

    const acceptableSectinoElementRowTypes = ['static', 'graphical', 'demographics']; // Remove or Add all valid for production
    const fetchedPages = deepClone(creator.metaHelper.pages);
    let metaHelper = deepClone(creator.metaHelper);
    let sectionElements = [];
    let sectionsToMap = [];
    switch ( triggerSource ) {
        case ("preset"): {
            sectionsToMap = deepClone(creator.presetStructureHelper?.datafilledStructure?.sections);
            break;
        }
        case ("report"): {
            sectionsToMap = deepClone(creator.report?.sections);
            break;
        }
    }
    for ( const [index, section] of sectionsToMap.entries() ) { 

        if ( section.type === "overview" ) {

            for ( const row of section.rows ) {

                const existinSectionElement = creator.sectionElements.find((obj) => obj.type === "overview");

                if ( acceptableSectinoElementRowTypes.includes(row.type) ) {
                    sectionElements.push({
                        name: "Overview",
                        type: "overview", 
                        id: "overview", 
                        row: "static", 
                        kpis: existinSectionElement?.kpis ? existinSectionElement?.kpis : [],
                    })
                }
            }
            continue;
        }

        switch ( section.source ) {
            case ("meta"): {
                switch ( section.type ) {
                    case ('paid'): {
                        const sectionAdAccount = section.sectionData?.find(obj => obj.type === "adAccount");
                        const accessToken = accessedAccounts?.meta?.accessToken;

                        
                        if(!sectionAdAccount?.id) continue;

                        // Check if any campaign is selected for this ad account

                        let sectionCampaigns = section.campaigns;
                        
                        for ( const row of section.rows ) {
                           
                            const existinSectionElement = creator.sectionElements.find((obj) => obj.source === "meta" && obj.type === "paid" &&  obj.row === row.type && obj.id === sectionAdAccount.id);

                            if ( acceptableSectinoElementRowTypes.includes(row.type) ) {
                                sectionElements.push({
                                    name: sectionAdAccount.name,
                                    type: "paid", 
                                    id: sectionAdAccount.id, 
                                    source: "meta",
                                    accessToken: accessToken,
                                    row: row.type, 
                                    kpis: existinSectionElement?.kpis ? existinSectionElement?.kpis : [],
                                    campaigns: sectionCampaigns,

                                })
                            }
                        }
                        break;
                    }
                    case ('facebook'): {
                        const sectionPageInfo = section.sectionData?.find(obj => obj.type === "facebookPage");
                        const accessToken = fetchedPages?.facebookPages?.find((obj) => obj?.id === sectionPageInfo?.id)?.access_token;
                        const sectionName = fetchedPages?.facebookPages?.find((obj) => obj?.id === sectionPageInfo?.id)?.name;

                        if ( !accessToken ) continue;

                        for ( const row of section.rows ) {
        
                            const existinSectionElement = creator.sectionElements.find((obj) => 
                                obj.type === "facebook" && 
                                obj.row === row.type && 
                                obj.id === sectionPageInfo.id    
                            );
             
                            if ( acceptableSectinoElementRowTypes.includes(row.type) ) {
                                sectionElements.push({
                                    name: sectionName,
                                    type: "facebook", 
                                    id: sectionPageInfo.id, 
                                    source: "meta",
                                    accessToken: accessToken,
                                    row: row.type, 
                                    kpis: existinSectionElement?.kpis ? existinSectionElement?.kpis : [],
                                })
                            }
                        }
                        break;
                    }
                    case ('instagram'): {
                        const sectionPageInfo = section.sectionData?.find(obj => obj?.type === "instagramPage");
                        const accessToken = fetchedPages?.instagramPages?.find((obj) => obj.instagram_business_account?.id === sectionPageInfo?.id)?.access_token;
                        const sectionName = fetchedPages?.instagramPages?.find((obj) => obj.instagram_business_account?.id === sectionPageInfo?.id)?.instagram_business_account?.username;
            
                        if ( !accessToken ) continue;

                        for ( const row of section.rows ) {

                            const existinSectionElement = creator.sectionElements.find((obj) => obj.type === "instagram" &&  obj.row === row.type && obj.id === sectionPageInfo.id);

                            if ( acceptableSectinoElementRowTypes.includes(row.type) ) {
                                sectionElements.push({
                                    name: sectionName,
                                    type: "instagram", 
                                    id: sectionPageInfo.id, 
                                    source: "meta",
                                    accessToken: accessToken,
                                    row: row.type, 
                                    kpis: existinSectionElement?.kpis ? existinSectionElement?.kpis : [],
                                })
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case("google"): {
                const sectionAdAccount = section.sectionData?.find(obj => obj.type === "googleAdAccount");
                const accessToken = accessedAccounts?.google?.accessToken;
                console.log(section);
                
                if(!sectionAdAccount?.id) continue;

                // Check if any campaign is selected for this ad account

                let sectionCampaigns = section.campaigns;
                
                for ( const row of section.rows ) {
                   
                    const existinSectionElement = creator.sectionElements.find((obj) => obj.source === "google" && obj.type === "googlePaid" &&  obj.row === row.type && obj.id === sectionAdAccount.id);

                    if ( acceptableSectinoElementRowTypes.includes(row.type) ) {
                        sectionElements.push({
                            name: sectionAdAccount.name,
                            type: "googlePaid", 
                            id: sectionAdAccount.id, 
                            source: "google",
                            accessToken: accessToken,
                            row: row.type, 
                            kpis: existinSectionElement?.kpis ? existinSectionElement?.kpis : [],
                            campaigns: sectionCampaigns,
                            parentManager: section?.parentManager
                        })
                    }
                }
                break;
            }
        }
    }

    return {updatedSectionElements: sectionElements, updatedMetaHelper: metaHelper};
}

