export const format_date = (date, format) => {
    /* THIS IS A UTILITY TO DISPLAY DATES ACCORDING TO THE SELECTED DATE FORMAT. 
    NOTE THAT WE ARE ALWAYS USING THE YYYY-MM-DD FORMAT IN OUR CODE */
    if(!date || !format) return false
    const dateparts = date.split('-');
    const formatparts = format.split('-');
    const newDateparts = [];

    const year = dateparts[0];
    const month = dateparts[1];
    const day = dateparts[2];

    for ( const part of formatparts ) {
        switch ( part ) {
            case ('YYYY'): {
                newDateparts.push(year);
                break;
            }
            case ('MM'): {
                newDateparts.push(month);
                break;
            }
            case ('DD'): {
                newDateparts.push(day);
                break;
            }
        } 
    }

    const formattedDate = newDateparts.join('-')

    return formattedDate;
}