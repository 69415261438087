import axios from "axios";
import { config } from "../../../../config";


export const googleLogin = (args) => {
    return new Promise((resolve, reject) => {

        let params = {};
        if ( args?.shouldSelectAccount ) params.shouldSelectAccount = true;

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/login`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });     
    })     
}


export const loginCallback = (code) => {
    return new Promise((resolve, reject) => {

        const params = {
            authenticationCode: code,
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/callback`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}


export const getGoogleConnections = (googleId) => {
    return new Promise((resolve, reject) => {

        const params = {
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/googleConnections`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const removeGoogleConnection = (googleId) => {
    return new Promise((resolve, reject) => {

        const params = {
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/deleteConnection`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}


export const getCustomerResourceNames = (accessToken, googleId) => {
    return new Promise((resolve, reject) => {

        const params = {
            accessToken: accessToken,
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getCustomerResourceNames`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}


export const getCustomerClients = (resource_name, accessToken, googleId) => {
    return new Promise((resolve, reject) => {

        const params = {
            resource_name: resource_name,
            accessToken: accessToken,
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getCustomerClients`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}


export const getClientCampaigns = (clientId, managerId, accessToken, googleId) => {
    return new Promise((resolve, reject) => {
        const resource_name = `customers/${clientId}`

        const params = {
            resource_name: resource_name,
            managerId: managerId,
            accessToken: accessToken,
            googleId: googleId,
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getClientCampaigns`, {params})
            .then((response) => {
                console.log(response);
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const getAdAccountKpisStatic = (clientId, managerId, campaignIds, accessToken, dateFrom, dateTo, googleId) => {
    return new Promise((resolve, reject) => {
        const resource_name = `customers/${clientId}`

        const params = {
            resource_name: resource_name,
            managerId: managerId,
            campaignIds: campaignIds, 
            accessToken: accessToken,
            since: dateFrom,
            until: dateTo,
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getAdAccountKpisStatic`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const getAdAccountKpisDemographics = (clientId, managerId, campaignIds, accessToken, dateFrom, dateTo, googleId) => {
    return new Promise((resolve, reject) => {
        const resource_name = `customers/${clientId}`

        const params = {
            resource_name: resource_name,
            managerId: managerId,
            campaignIds: campaignIds, 
            accessToken: accessToken,
            since: dateFrom,
            until: dateTo,
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getAdAccountKpisDemographics`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const getAdAccountKpisGraphical = (clientId, managerId, campaignIds, accessToken, dateFrom, dateTo, googleId) => {
    return new Promise((resolve, reject) => {
        const resource_name = `customers/${clientId}`

        const params = {
            resource_name: resource_name,
            managerId: managerId,
            campaignIds: campaignIds, 
            accessToken: accessToken,
            since: dateFrom,
            until: dateTo,
            googleId
        }

        axios.get(`${config.LOIS_BACKEND_URL}/google/api/getAdAccountKpisGraphical`, {params})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            });
    })
}


