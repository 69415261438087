import React, { useContext, useEffect, useState } from 'react';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Edit_Block from '../edit/Edit_Block';
import { fixNumbers } from '../../../../../../helpers/fixNumbers';
import { SlideOptions, SlideOptionsWrapper } from '../../../../../../styles/components/SlideActions';
import Snippet_Block_Overlay from '../snippets/Snippet_Block_Overlay';
import currencies from '../../../../../../data/currencies.json';
import { blockToSkipOverviewUpdate, currencyBlocks , rateList} from '../../data/special-cases';
import { Snippet_Vertical_Actions } from '../snippets/Snippet_Vertical_Actions';

const BlockStatic = styled(SlideOptions)`
    background: ${(p) => p.theme.low};
    border-radius: ${(p) => p.presetStyle?.radius};
    min-height: 8.5rem;
`;

const BlockStaticWrapper = styled(SlideOptionsWrapper)`
    border: 1px solid ${(p) => p.presetStyle?.color.background};
    background: ${(p) => (p.variant == 'main' ? p.presetStyle?.color.main : p.variant == 'focus' ? p.presetStyle?.color.focus : p.presetStyle?.color.grey)};
    border-radius: ${(p) => p.presetStyle?.radius};
    padding: 1rem 2rem;
`;

const BlockStaticTop = styled.div`
    h6 {
        font-family: ${(p) => p.presetStyle?.font.family};
        color: ${(p) => (p.variant == 'main' ? p.presetStyle?.color.textLight : p.variant == 'focus' ? p.presetStyle?.color.textLight : p.variant == 'focusInverted' ? p.presetStyle?.color.focus : p.variant == 'mainInverted' ? p.presetStyle?.color.main : p.presetStyle?.color.textDark)};
    }
`;

const BlockStaticMain = styled.div`
    h1 {
        font-family: ${(p) => p.presetStyle?.font.family};
        margin-top: 1rem;
        color: ${(p) => (p.variant == 'main' ? p.presetStyle?.color.textLight : p.variant == 'focus' ? p.presetStyle?.color.textLight : p.variant == 'focusInverted' ? p.presetStyle?.color.focus : p.variant == 'mainInverted' ? p.presetStyle?.color.main : p.presetStyle?.color.textDark)};
    }
`;

const BlockStaticFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const BlockStaticCompare = styled.div`
    border-radius: ${(p) => p.presetStyle?.radius};
    background: ${(p) => p.presetStyle?.color.background};
    padding: var(--smallPads);
    transform: translateX(1rem);
    h6 {
        font-family: ${(p) => p.presetStyle?.font.family};
        color: ${(p) => (p.trend == 'down' ? 'var(--error)' : 'var(--success)')};
    }
`;

export default function Block_Static(props) {
    const { t } = useTranslation();
    
    const { reportStyle } = useContext(ReportStyleContext);
    const [slider, setSlider] = useState(false);

    const showCurrency = props.value || props.value === 0 || props.value === '0';

    const open_close_slider = () => {
        setSlider(false);
        props.setActiveBlock(false);
    };

    useEffect(() => {
        if ( props.sliderFlowHelper?.openSlider === "edit-block" && props.element === props.sliderFlowHelper.metadata?.id ) setSlider(true);
    },[props.sliderFlowHelper]);

    return (
        <BlockStatic className={props.className} presetStyle={reportStyle} actions={props.actions} id={props.id}>
            {slider ? 
            <Edit_Block 
                elementCategory={props.elementCategory} 
                sectionName={props.sectionName} 
                rowName={props.rowName} 
                onClick={() => open_close_slider()} 
                onBack={() => open_close_slider()}
                title={props.title} 
                value={props.value} 
                titlePlaceholder={props.titlePlaceholder} 
                titleDefaultValue={props.titleDefaultValue} 
                valueDefaultValue={props.valueDefaultValue} 
                valuePlaceholder={props.valuePlaceholder} 
                setBlock={props.setBlock} 
                id={props.id} 
                element={props.element} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                rowType={props.rowType} 
                comparedValueDefaultValue={props.comparedValueDefaultValue} 
                comparedValuePlaceholder={props.comparedValuePlaceholder} 
                comparedValue={props.comparedValue} 
                showCurrency={props.showCurrency}
                displayedValue={props.displayedValue}
                displayedName={props.displayedName}
                showCompare={props.showCompare}
                variant={props.variant} 
                /> : null}
            <Snippet_Vertical_Actions onEdit={() => setSlider(!slider)} onDelete={props.onDelete} hideEdit={blockToSkipOverviewUpdate.includes(props.element) ? true : false} 
            />
            <BlockStaticWrapper actions={props.actions} presetStyle={reportStyle} variant={props.variant} onClick={props.onActions} viewMode={props.viewMode}>
                <BlockStaticTop presetStyle={reportStyle} variant={props.variant}>
                    <h6>{props.title}</h6>
                </BlockStaticTop>
                {/*  TODO: need to change things for decimal number, should add an if-statement and change things in fixNumbers for custom kpis*/}
                <BlockStaticMain presetStyle={reportStyle} variant={props.variant}>
                {currencyBlocks.includes(props.element?.originalName) || (props.elementCategory === "custom" && props.showCurrency) ? (
                        <h1>
                            {fixNumbers(props.value, true) || 0} {showCurrency ? currencies[props?.currency]?.symbol : ''}
                        </h1>
                    ) : rateList.includes(props?.element?.originalName) ? (
                        <h1>{fixNumbers(props.value) || 0}% </h1>
                    ) : (
                        <h1>{fixNumbers(props.value) || 0}</h1>
                    )}

                </BlockStaticMain>
                {props.trend ? (
                    <BlockStaticFooter>
                        <BlockStaticCompare presetStyle={reportStyle} trend={props.trend}>
                            <h6>{props.comparedValue}</h6>
                        </BlockStaticCompare>
                    </BlockStaticFooter>
                ) : null}
            </BlockStaticWrapper>
            {!props.viewMode ?
                <Snippet_Block_Overlay stylePreset={reportStyle} text={t('Click to interact')} />
            :
                null
            }
        </BlockStatic>
    );
}
