import { Card, Main_Plain, Toggle_, Toggle_Stat } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CardOnElement = styled(Card)`
    padding: 2rem;
    background: ${(p) => (p.onReport ? p.theme.low : p.theme.background)};
    border: ${(p) => (p.onReport ? `2px dashed ${p.theme.high}` : 'none')};
    cursor: pointer;
    display: ${(p) => (p.viewMode ? 'none' : 'flex')};
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        box-shadow: ${(p) => (p.onReport ? `none` : p.theme.outFocus)};
    }
`;

const CardOnElementAction = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CardOnElementMid = styled.div`
    h3 {
        color: ${(p) => (p.onReport ? p.theme.high : p.theme.color)};
        margin-bottom: ${(p) => (p.onReport ? '0' : '2rem')};
    }
`;

export const Card_On_Element = (props) => {
    const { t } = useTranslation();

    return (
        <CardOnElement onClick={props.onClick} viewMode={props.viewMode} >
            <CardOnElementMid active={props.active} >
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </CardOnElementMid>
            {props.children}
            <CardOnElementAction>{props.toggle ? <Toggle_ active={props.active} /> : <Main_Plain text={props.add} onClick={props.onAdd} iconLeft={props.iconAdd ? props.iconAdd : "Add" } />}</CardOnElementAction>
        </CardOnElement>
    );
};
