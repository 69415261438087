/* Initial state of store */

import { redirect } from "react-router-dom";


export const initialState = {
  selectedLanguage: '',
  country:'Greece',
  theme:'Light',
  user: {},

  app: {
    
    accessedAccounts: {
      meta: {
        userId: "",
        username: "",
        accessToken: "",
      },
      google: {
        userId: "",
        username: "",
        accessToken: "",
      },
    },
    redirectUrl: "",

    creator: {
      preSelected: {
        dates: { // YYYY-MM-DD Will always be used and send to server. Date format changes the visual aspect of dates
          dateFrom: "",
          dateTo: "",
          dateFormat: "", 
        },
        presetStructure: "",
        presetStyle: "",
        template: "",
      },
    },
  }
}

export function rootReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case 'LANGUAGE_UPDATE': {
      return { ...state, selectedLanguage: payload };
    }
    case 'COUNTRY_UPDATE': {
      return { ...state, country: payload };
    }
    case 'THEME_UPDATE': {
      return { ...state, theme: payload };
    }
    case 'KEYCLOAK': {
      return { ...state, user: payload };
    }
    case 'SAVE_TRANSLATIONS': {
      return { ...state, translations: payload};
    }
    case 'SAVE_FACEBOOK_USER': {
      return { ...state, app: {...state?.app, accessedAccounts: {...state?.app?.accessedAccounts, meta: payload} }};
    }
    case 'SAVE_CREATOR_DATES': {
      return { ...state, app: {...state?.app, creator: {...state?.app?.creator, preSelected: {...state?.app?.creator?.preSelected, dates: payload}} }};
    }
    case 'SAVE_CREATOR_DATES_RANGE': {
      return { ...state, app: {...state?.app, creator: {...state?.app?.creator, preSelected: {...state?.app?.creator?.preSelected, dates: {...state?.app?.creator?.preSelected.dates, dateFrom: payload.dateFrom, dateTo: payload.dateTo}}} }};
    }
    case 'SAVE_CREATOR_DATES_FORMAT': {
      return { ...state, app: {...state?.app, creator: {...state?.app?.creator, preSelected: {...state?.app?.creator?.preSelected, dates: {...state?.app?.creator?.preSelected.dates, dateFormat: payload}}} }};
    }
    case 'SAVE_PRESELECTED_TEMPLATE': {
      return { ...state, app: {...state?.app, creator: {...state?.app?.creator, preSelected: {...state?.app?.creator?.preSelected, template: payload}} }};
    }

    
    case 'SAVE_GOOGLE_USER_PROFILE': {
      return { ...state, app: {...state?.app, accessedAccounts: {...state?.app?.accessedAccounts, google: payload} }};
    }
    case 'SAVE_REDIRECT_URL': {
      return { ...state, app: {...state?.app, redirectUrl: payload }};
    }
    // case 'SAVE_GOOGLE_CLIENT_ACCOUNT': {
    //   return { ...state, googleSource: {...state?.googleSource, clientAccount: payload }};
    // }
    // case 'SAVE_GOOGLE_DATES': {
    //   return { ...state, googleSource: {...state?.googleSource, dates: payload }};
    // }  
    // case 'SAVE_GOOGLE_REPORT': {
    //   return { ...state, googleSource: {...state?.googleSource, report: payload }};
    // }    
    default: return state;
  }
}

export default rootReducer;
