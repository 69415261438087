import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Creator_Title_Row from '../creator/Creator_Title_Row'
import { ReportStyleContext } from '../../../../context/ReportStyleContext'
import { Builder_, Dropdown_, Input_, Slider_ } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { config } from '../../../../../../config'

const RowMedia = styled.div``

const RowMediaBody = styled.div`
    
`

const RowMediaGrid = styled.div`
display: grid;
    grid-template-columns: ${p => p.grid === 'full' ? '1fr' :p.grid === 'split' ? '1fr 1fr' : '1fr 1fr 1fr' };

    img{
        width: 100%;
    }
`

export default function Row_Media(props) {
    const {t} = useTranslation()
    const {reportStyle} = useContext(ReportStyleContext);
    const [base64Image, setBase64Image] = useState(false);


    useEffect(() => {
        const fetchImage = async () => {
          try {
            const response = await axios.get(`${config.LOIS_BACKEND_URL}/media/base64?url=${props.image}`);
            const image = response.data.data;
            const base64data = btoa(
            new Uint8Array(image.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
            );
            const base64String = `data:image/jpeg;base64,${base64data}`;
            setBase64Image(base64String);

          } catch (error) {
            console.error('Error fetching image:', error);
          }
        };
        if(props.pdf === true && props.image !== null)
            fetchImage();
      }, []);

  return (
        <RowMedia key={props.key} id={props.id}>
            <RowMediaBody presetStyle={reportStyle}>
                <Creator_Title_Row viewMode={props.viewMode} title={props.title} onDelete={props.onRemoveMedia} onEdit={props.onEditMedia} />
                <RowMediaGrid grid={props.grid}>
                    {props.image ? <img src={props.pdf ? base64Image: props.image} /> :  null }
                </RowMediaGrid>
            </RowMediaBody>
        </RowMedia>
  )
}
