export const config = {
        BACKEND_URL: process.env.REACT_APP_BACKEND_PROTOCOL +
                        process.env.REACT_APP_BACKEND_HOST +
                        process.env.REACT_APP_BACKEND_PORT,
        KEYMAKER: process.env.REACT_APP_KEYMAKER_URL_PROTOCOL +
                        process.env.REACT_APP_KEYMAKER_URL_HOST +
                        process.env.REACT_APP_KEYMAKER_URL_PORT,
        GRIPHOOK_BACKEND_URL: process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_PORT,
        ERISED_BACKEND_URL: process.env.REACT_APP_ERISED_BACKEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_BACKEND_HOST +
                        process.env.REACT_APP_ERISED_BACKEND_PORT,
        LOIS_BACKEND_URL: process.env.REACT_APP_LOIS_BACKEND_PROTOCOL +
                        process.env.REACT_APP_LOIS_BACKEND_HOST +
                        process.env.REACT_APP_LOIS_BACKEND_PORT,
        erised: process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_FRONTEND_HOST +
                        process.env.REACT_APP_ERISED_FRONTEND_PORT,
        minio:  process.env.REACT_APP_MINIO_PROTOCOL +
                        process.env.REACT_APP_MINIO_HOST,
        griphook: process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT,
        akihiko: process.env.REACT_APP_AKIHIKO_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_HOST +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_PORT,
        lois: process.env.REACT_APP_LOIS_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_LOIS_FRONTEND_HOST +
                        process.env.REACT_APP_LOIS_FRONTEND_PORT,
        whitecastle:    process.env.REACT_APP_WHITECASTLE_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_WHITECASTLE_FRONTEND_HOST +
                        process.env.REACT_APP_WHITECASTLE_FRONTEND_PORT,
        syrax:          process.env.REACT_APP_SYRAX_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_SYRAX_FRONTEND_HOST +
                        process.env.REACT_APP_SYRAX_FRONTEND_PORT
}
      



