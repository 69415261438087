import { Slider_ } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Creator_Error_Message(props) {
    const { t } = useTranslation();

    if (props.noSlider) {
        switch (props.error?.code) {
            case 190: {
                return (
                    <>
                        <p>{props.error?.translatedMessage}</p>
                    </>
                );
                break;
            }
            case 200: {
                return (
                    <>
                        <p>{props.error?.translatedMessage}</p>
                    </>
                );
                break;
            }
            case 2500: {
                return (
                    <>
                        <p>{props.error?.translatedMessage}</p>
                    </>
                );
                break;
            }
            case 500: {
                return (
                    <>
                        <p>{props.error?.translatedMessage}</p>
                    </>
                );
                break;
            }
            case 501: {
                return (
                    <>
                        <p>{props.error?.translatedMessage}</p>
                    </>
                );
                break;
            }
        }
    }

    switch (props.error?.code) {
        case 100: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
        case 190: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
        case 200: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
        case 2500: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
        case 500: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
        case 501: {
            return (
                <Slider_ onClick={props.onClick} text={t('Done')} title={props.sliderTitle}>
                    <p>{props.error?.translatedMessage}</p>
                </Slider_>
            );
            break;
        }
    }
}
