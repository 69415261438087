import React, { useContext } from 'react';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Title_Row from '../creator/Creator_Title_Row';

const RowComments = styled.div`
    display: flex;
    align-items: flex-start;
`;

const RowCommentsBody = styled.div`
    h6 {
        border-radius: ${(p) => p.presetStyle.radius};
        display: inline-block;
        color: ${(p) => p.presetStyle.color.textDark};
        font-family: ${(p) => p.presetStyle.font.family};
    }

    p {
        color: ${(p) => p.presetStyle.color.textDark};
        font-family: ${(p) => p.presetStyle.font.family};
    }
`;

export default function Row_Comments(props) {
    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);

    return (
        <RowComments id={props.id}>
            <RowCommentsBody presetStyle={reportStyle}>
                <Creator_Title_Row viewMode={props.viewMode} title={props.title} onDelete={props.onDeleteComment} onEdit={props.onEditComment} />
                <p>{props.text}</p>
            </RowCommentsBody>
        </RowComments>
    );
}
