import { device } from 'monica-alexandria';
import styled from 'styled-components';

export const ReportBrand = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
        height: 4rem;
    }
`;

export const ReportCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
`;

export const ReportCardsResponsive = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

export const ReportMetaKpi = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;

    @media ${device.md} {
        grid-template-columns: 1fr ;
    }
`;
export const ReportDate = styled.div`
    text-align: right;
`;

export const ReportInfo = styled.div`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: var(--focus);
    grid-column-start: 1;
    grid-column-end: ${(p) => p.hasOrganicSpend ? '2' : '4'}; ;

    h1,
    h6 {
        color: var(--white) !important;
    }
    border-radius: 1rem;

    @media (max-width: 768px) {
    /* Adjust styling for smaller screens */
    grid-column: span 3; // Display this component across all columns
    /* padding: 1rem; // Adjust padding */
  }
`;

export const ReportInfoFocus = styled.div`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: ${(p) => p.theme.low};
    //grid-column-end: ${(p) => p.isOrganic ? '4' : '3'};
    //grid-column-start: ${(p) => p.isOrganic ? '1' : '2'};
    display: ${(p) => p.hasOrganicSpend ? 'block' : 'none'};

    h1,
    h6 {
        color: var(--focus) !important;
    }
    border-radius: 1rem;

    @media (max-width: 768px) {
    /* Adjust styling for smaller screens */
    grid-column: span 3; // Display this component across all columns
    /* padding: 1rem; // Adjust padding */
    margin-top: 0;
  }
`;

export const PreviewInfoFocus = styled.div`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: var(--greyLight);
    //grid-column-end: ${(p) => p.isOrganic ? '4' : '3'};
    //grid-column-start: ${(p) => p.isOrganic ? '1' : '2'};
    display: ${(p) => p.hasOrganicSpend ? 'block' : 'none'};

    h1,
    h6 {
        color: var(--focus) !important;
    }
    border-radius: 1rem;
`;

export const ReportInfoMain = styled.div`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: ${(p) => p.theme.low};
    grid-column-start: ${(p) => p.isOrganic ? '1' : '3'}; 
    grid-column-end: ${(p) => p.isOrganic ? '4' : '3'}; 
    display: ${(p) => p.hasOrganicSpend ? 'block' : 'none'};


    h1,
    h6 {
        color: var(--main) !important;
    }
    border-radius: 1rem;

    @media (max-width: 768px) {
    /* Adjust styling for smaller screens */
    grid-column: span 3; // Display this component across all columns
    /* padding: 0; // Adjust padding */
    margin-top: 0;
  }
`;


export const PreviewInfoMain = styled(ReportInfoMain)`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: var(--greyLight);
    grid-column-start: ${(p) => p.isOrganic ? '1' : '3'}; 
    grid-column-end: ${(p) => p.isOrganic ? '4' : '3'}; 
    display: ${(p) => p.hasOrganicSpend ? 'block' : 'none'};


    h1,
    h6 {
        color: var(--main) !important;
    }
    border-radius: 1rem;
`;

export const ReportSection = styled.div`
    background: ${(p) => p.theme.low};
    padding: 2rem 4rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-top: 4rem;
    position: relative;
`;

export const PreviewSection = styled(ReportSection)`
    background: var(--greyLight);
`;

export const ReportSectionTitle = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    div {
        display: flex;
        gap: 1rem;
        align-items: center;
        img {
            height: 3rem;
        }
    }
`;

export const ReportGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;
`;

export const ReportGridResponsive = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

export const ReportDemoCard = styled.div`
    background-color: ${(p) =>
        p.preview ? 'var(--white)' : p.theme.background};
    padding: 2rem 4rem;
    border-radius: 1rem;

    h6 {
        margin-bottom: 2rem;
    }
`;

export const ReportMetaImg = styled.div`
    img {
        width: 100%;
    }
`;

export const ReportLeft = styled.div`
    h1 {
        color: ${(p) => (p.paid ? 'var(--focus)' : 'var(--main)')} !important;
    }
`;

export const ReportDays = styled.div`
    border-right: 2px solid var(--grey);
    padding-right: 1rem;

    h4 {
        font-size: 6rem;
        color: ${(p) => (p.paid ? 'var(--focus)' : 'var(--main)')} !important;
        line-height: 5rem;
    }

    h5 {
        color: var(--greyDark);
        text-align: center;
    }
`;

export const ReportDaysResponsive = styled.div`
    border-right: 2px solid var(--grey);
    padding-right: 1rem;

    h4 {
        font-size: 6rem;
        color: ${(p) => (p.paid ? 'var(--focus)' : 'var(--main)')};
        line-height: 5rem;
    }

    h5 {
        color: var(--greyDark);
        text-align: center;
    }
    @media ${device.md} {
        display: flex;
        border-right: none;

        h5 {
            display: flex;
            align-self: flex-end;
        }
    }
`;

export const ReportStat = styled.div`
    background: ${(p) => p.theme.background};
    padding: 2rem 4rem;
    border-radius: 2rem;
`;

export const PreviewStat = styled(ReportStat)`
    background: var(--white);
`;


export const PreviewInfo = styled.div`
    margin-top: 2rem;
    padding: 2rem 4rem;
    background: var(--focus);
    grid-column-start: 1;
    grid-column-end: ${(p) => p.hasOrganicSpend ? '2' : '4'}; ;

    h1,
    h6 {
        color: var(--white) !important;
    }
    border-radius: 1rem;
`;
