import { Actions_, Builder_, Input_, Red_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const EditCoupon = styled.div``;

const SliderSection = styled.div`
    padding-bottom: 3rem;

    h3 {
        padding-bottom: 2rem;
    }
`;

const AddButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;


export default function Edit_Coupon(props) {

    const { t } = useTranslation();

    useEffect(() => {   
        props.setSliderText(t('Edit coupon'));
        props.setPayload({...props.payload, type: "edit"})
    }, []);

    return (
        <EditCoupon>
            <Builder_ title="Name"> 
                <Input_
                    placeholder = {props.activeCoupon?.name}
                    onChange = {(e) => {props.setPayload({...props.payload, name: e.target.value})}}
                />
            </Builder_>
            <Builder_ title="Active days"> 
                <Input_
                    placeholder = {props.activeCoupon?.activeDays}
                    onChange = {(e) => {props.setPayload({...props.payload, activeDays: parseInt(e.target.value)})}}
                />
            </Builder_>
            <Builder_ title="Uses left"> 
                <Input_
                    placeholder = {props.activeCoupon?.usesLeft}
                    onChange = {(e) => {props.setPayload({...props.payload, usesLeft: parseInt(e.target.value)})}}
                />
            </Builder_>
        </EditCoupon>
    );
}
