import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Steps_Actions, Builder_, Google_Search, Section_Title, Toggle_Stat, CardsSixty } from 'monica-alexandria';
import Snippet_Toggle_Donot from '../snippets/Snippet_Donot';
import { useTranslation } from 'react-i18next';

const WizardFacebookPages = styled.div``;
const FacebookWrapper = styled.div`
    max-height: 60vh;  /* Adjust the height as needed */
    overflow-y: auto;
    padding-right: 15px;  /* To avoid content getting */
`;


export default function Wizard_Facebook_Page(props) {

    const { t } = useTranslation();

    const [displayedFbPages, setDisplayedFbPages] = useState(props.facebookPages);

    const [searchResultsFbPages, setSearchResultsFbPages] = useState();



    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsFbPages?.input) {

                let indexToRemove = searchResultsFbPages?.data.findIndex(obj => obj.id === 0);

                // If the index is found (not -1), remove the object
                if (indexToRemove !== -1) {
                    searchResultsFbPages?.data.splice(indexToRemove, 1);
                }
                setDisplayedFbPages(searchResultsFbPages?.data);
            }
            else setDisplayedFbPages(props.facebookPages);
        };

        applySearchResults();
    }, [searchResultsFbPages]);

    return (
        <WizardFacebookPages>
            <h3>{t("Facebook insights")}</h3>

            <FacebookWrapper>
            {props.facebookPages.length > 15 ?
                <Builder_ >
                    <Google_Search file={props.facebookPages} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Facebook Page')} setResults={(data) => setSearchResultsFbPages(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                </Builder_> 
                : null }
                <Section_Title text={t('Facebook Organic')} />
                <CardsSixty>
                    {displayedFbPages.map((item, i) => {
                        let isAdded = props.selectedSections.some((obj) => obj.type === 'facebook' && obj.sectionData?.find((sd) => sd.type === 'facebookPage').id === item.id);
                        return (
                            <Toggle_Stat
                                key={i}
                                id={i}
                                text={item.name}
                                onClick={() =>
                                    !isAdded ? props.addSection("meta", item) : props.removeSection("meta")
                                }
                                active={isAdded}
                            />
                        );
                    })}        
                </CardsSixty>
            </FacebookWrapper>
            <Steps_Actions onClick_Main={props.onClick_Main} onClick_Grey={props.onClick_Grey} icon_Grey="Back" icon_Main="Next" text_Grey={t("Back")} text_Main={t("Next")}/>
        </WizardFacebookPages>
    )
}
