export const convertDateToUnixTime = (dateString) => {
    try {
        const dateObject = new Date(dateString);
        const unixTime = dateObject.getTime(); // Returns time in milliseconds
        return unixTime;
    } catch ( error ) {
        return false;
    }
}
  
export const convertUnixTimeToDate = (unixTime) => {
    try {
        if (typeof unixTime === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(unixTime)) {
            return unixTime; // Return input if it's already in the expected format
        }
        
        const dateObject = new Date(unixTime);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it's zero-based
        const day = String(dateObject.getDate()).padStart(2, '0');
        const dateStr = `${year}-${month}-${day}`;
        return dateStr;
    } catch ( error ) {
        return false;
    }
}
