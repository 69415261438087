import { Button, IconSvg } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import i18n from '../../../localization/i18next';

const NavbarHam = styled.div`
    width: 100%;
`

const NavbarHamList = styled.div`
    padding: 1rem 1.5rem;
    background: ${p => p.theme.low};
    h6{
        margin-bottom: 1rem;
        color: var(--greyDark);
    }
`

const NavbarHamButton = styled(Button)`
    width: 100%;
    background: ${p => p.isOpen ? p.theme.mid : 'transparent' };
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
        color: ${p => p.theme.color};
    }

    svg{
        transition: transform .5s ease;
        fill: ${p => p.theme.color};
    }

    &:hover{
        span{
        color: var(--main);
    }

    svg{
        transition: transform .5s ease;
        fill: var(--main);
    }
    }
`

export default function Navbar_Ham(props) {

    const lang = i18n.language ? i18n.language : 'en';
    const {t} = useTranslation()
    const navigate = useNavigate();

    const [navItem, setNavItem] = useState({status: false, item: ''})

  return (
    <NavbarHam>
        <NavbarHamButton isOpen={navItem.status} onClick={()=> navigate(`/${lang}/features`)}>
            <span>{t("Features")}</span>
            <IconSvg Icon="Next"/>
        </NavbarHamButton>
        <NavbarHamButton isOpen={navItem.status} onClick={()=> navigate(`/${lang}/pricing`)}>
            <span>{t("Pricing")}</span>
            <IconSvg Icon="Next"/>
        </NavbarHamButton>
    </NavbarHam>
  )
}
