import { Card, Main_Plain, Red_ } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'
import MetaLogo from '../../../../../../assets/MetaLogo.png'
import FbLogo from '../../../../../../assets/fb.png'
import InstaLogo from '../../../../../../assets/insta.png'
import GoogleLogo from '../../../../../../assets/googleNetwork/pngegg.png'
import { useTranslation } from 'react-i18next'

const WizardPreview = styled.div``

const WizardPreviewBox = styled(Card)`
    background: ${p => p.theme.low};
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 2rem;
`

const WizardPreviewLeft = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
`

const WizardPreviewRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button{
        background: ${p => p.theme.background};
        border-radius: var(--normalRadius);
    }
`

const WizardPreviewLogo = styled.div`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0.5rem;

    img{
        width: 100%;
    }
`

const WizardPreviewText = styled.div`
    margin-top: 1.5rem;
`

const WizardPreviewList = styled.div`
    display: flex;

    ul{

        li{
            display: flex;
            align-items: center;
            gap: .5rem;
        }
    }
`

const WizardPreviewIndi = styled.div`
    border-left: 1px solid ${p => p.theme.color};
    position: relative;

    div{
        height:9px;
        width: 1px;
        background: ${p => p.theme.low};
        position: absolute;
        bottom: 0;
        left: -1px;
    }
`

const WizardPreviewIndiLine = styled.div`
    width: .5rem;
    height: 1px;
    background: ${p => p.theme.color};
`

export default function Wizard_Preview(props) {
    // console.log(props);

    const {t} = useTranslation()

  return (
    <WizardPreview>
        {props.selectedSections?.map((s, index) => {
            if(s.source === "meta" && s.type === "paid" && s.sectionTitle !== "Ad Account") {
                return (
                    <WizardPreviewBox key={index}>
                        <WizardPreviewLeft>
                            <WizardPreviewLogo>
                                <img src={MetaLogo} />
                            </WizardPreviewLogo>
                            <WizardPreviewText>
                                <h5>{s?.sectionTitle}</h5>
                                <WizardPreviewList>
                                    <WizardPreviewIndi>
                                        <div></div>
                                    </WizardPreviewIndi>
                                    <ul>
                                        {s?.campaigns?.map((campaign, i) => {
                                            return (
                                                <li key={i}>
                                                <WizardPreviewIndiLine></WizardPreviewIndiLine>
                                                <h6>{campaign.name}</h6>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </WizardPreviewList>
                            </WizardPreviewText>
                        </WizardPreviewLeft>
                        <WizardPreviewRight>
                            {/* <Main_Plain iconLeft="Recycle" text={t("Add Campaign")} /> */}
                            {/* <Red_ iconLeft="Delete" text={t("Remove")} /> */}
                        </WizardPreviewRight>
                    </WizardPreviewBox>
                ) 
            }
            else if(s.source === "google" && s.sectionTitle !== "Google Ad Account") {
                return (
                    <WizardPreviewBox key={index}>
                        <WizardPreviewLeft>
                            <WizardPreviewLogo>
                                <img src={GoogleLogo} />
                            </WizardPreviewLogo>
                            <WizardPreviewText>
                                <h5>{s?.sectionTitle}</h5>
                                <WizardPreviewList>
                                    <WizardPreviewIndi>
                                        <div></div>
                                    </WizardPreviewIndi>
                                    <ul>
                                        {s?.campaigns?.map((campaign, i) => {
                                            return (
                                                <li key={i}>
                                                <WizardPreviewIndiLine></WizardPreviewIndiLine>
                                                <h6>{campaign.name}</h6>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </WizardPreviewList>
                            </WizardPreviewText>
                        </WizardPreviewLeft>
                        <WizardPreviewRight>
                            {/* <Main_Plain iconLeft="Recycle" text={t("Add Campaign")} /> */}
                            {/* <Red_ iconLeft="Delete" text={t("Remove")} /> */}
                        </WizardPreviewRight>
                    </WizardPreviewBox>
                ) 
            }
            else if (s.type === "facebook" && s.sectionTitle !== "Facebook page" && s.sectionTitle !== "Facebook Page") {
                return (
                    <WizardPreviewBox key={index}>
                        <WizardPreviewLeft>
                            <WizardPreviewLogo>
                                <img src={FbLogo} />
                            </WizardPreviewLogo>
                            <WizardPreviewText>
                                <h5>{s?.sectionTitle}</h5>
                            </WizardPreviewText>
                        </WizardPreviewLeft>
                        <WizardPreviewRight>
                            {/* <Main_Plain iconLeft="Recycle" text={t("Change")} /> */}
                            {/* <Red_ iconLeft="Delete" text={t("Remove")} /> */}
                        </WizardPreviewRight>
                    </WizardPreviewBox>
                )
            }
            else if(s.type === "instagram" && s.sectionTitle !== "Instagram page" && s.sectionTitle !== "Instagram Page") {
                return (
                    <WizardPreviewBox key={index}>
                        <WizardPreviewLeft>
                            <WizardPreviewLogo>
                                <img src={InstaLogo} />
                            </WizardPreviewLogo>
                            <WizardPreviewText>
                                <h5>{s?.sectionTitle}</h5>
                            </WizardPreviewText>
                        </WizardPreviewLeft>
                        <WizardPreviewRight>
                            {/* <Main_Plain iconLeft="Recycle" text={t("Change")} /> */}
                            {/* <Red_ iconLeft="Delete" text={t("Remove")} /> */}
                        </WizardPreviewRight>
                    </WizardPreviewBox>
                )
            }
        })}        
    </WizardPreview>
  )
}
