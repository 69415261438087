import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Snippet_Horizontal_Actions } from '../snippets/Snippet_Horizontal_Actions';

const SlitterSectionLine = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`;

const SlitterSectionLineWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 4rem 2rem;
    width: 100%;

    h4 {
        white-space: nowrap;
        color: ${(p) => p.presetStyle.color.main};
        font-family: ${(p) => p.presetStyle.font.family};
    }
`;

const SlitterSectionLineLine = styled.div`
    height: 2px;
    width: 100%;
    background: ${(p) => p.presetStyle.color.main};
`;

export default function Slitter_Section_Line(props) {
    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    return (
        <SlitterSectionLine>
            <Snippet_Horizontal_Actions />
            <SlitterSectionLineWrapper presetStyle={reportStyle}>
                <SlitterSectionLineLine presetStyle={reportStyle}></SlitterSectionLineLine>
            </SlitterSectionLineWrapper>
        </SlitterSectionLine>
    );
}
