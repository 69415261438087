import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Block_Static from '../block/Block_Static';
import Report_Inner_Title from '../creator/Creator_Title_Row';
import Creator_Plus from '../creator/Creator_Plus';
import { device , IconSvg} from 'monica-alexandria';
import FlexHeightEqualizer from '../../../../../../utils/FlexHeightEqualizer';

export const ValidatorTip = styled.div`
display: grid;
grid-template-columns: var(--smallIcon) 1fr;
align-items: flex-start;
grid-gap: 0.5rem;
padding: 0.5rem 0rem;

h6 {
    color: var(--greyDark) !important;
}

svg {
    width: var(--smallIcon);
    fill: var(--greyDark) !important;
}
`;

const RowStatic = styled.div``;

const RowStaticGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(33% - 2rem), 1fr));
    grid-gap: 2rem;

    @media ${device.md} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.xs} {
        grid-template-columns: 1fr;
    }
`;

export default function Row_Static(props) {
    const {t} = useTranslation();

    const [activeBlock, setActiveBlock] = useState('initial');

    const toggle_actions = (item) => {
        if (item == activeBlock) {
            setActiveBlock(false);
        } else {
            setActiveBlock(item);
        }
    };

    useEffect(() => {
        FlexHeightEqualizer();
    },[props.blocks, props.trend])


    const findTrend = (value) => {
        if (!value) return false;
        if (typeof value === 'number') value = value.toString();
        const valueToArray = value.split('');
        if (valueToArray[0] === '-') return 'down';
        else return 'up';
    };

    return (
        <RowStatic>
            <Report_Inner_Title viewMode={props.viewMode} title={props.rowTitle} onEdit={props.onEdit} onDelete={props.onDelete} />
            {props.compare && props.sectionType !== 'overview' ? <ValidatorTip><IconSvg Icon="Tip" /><h6>{t("Compared to previous period")}</h6></ValidatorTip>
            :null}
            <RowStaticGrid>
                {props.blocks.map((item, i) => {
                    const actions = activeBlock === 'initial' ? 'initial' : activeBlock === item.originalName ;
                    return (
                        <Block_Static
                            className="height-equalizer"
                            sliderFlowHelper={props.sliderFlowHelper}
                            actions={actions}
                            onActions={() => toggle_actions(item.originalName)}
                            setActiveBlock={setActiveBlock}
                            key={i}
                            id={i}
                            currency={props.currency}
                            element={item}
                            elementCategory={item.category}
                            sectionName={props.sectionName}
                            sectionType={props.sectionType}
                            sectionId={props.sectionId}
                            rowName={props.rowTitle}
                            rowType={props.rowType}
                            title={item.displayedName}
                            value={item.displayedValue}
                            titleDefaultValue={item.givenName}
                            valueDefaultValue={item.value}
                            variant={item.variant}
                            titlePlaceholder={props.staticTitlePlaceholder}
                            valuePlaceholder={props.staticValuePlaceholder}
                            comparedValueDefaultValue={item.comparedValue}
                            comparedValuePlaceholder={props.comparedValuePlaceholder}
                            comparedValue={item.displayedCompareValue}
                            showCurrency={item.showCurrency}
                            viewMode={props.viewMode}
                            setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                props.setBlock(originalName, value, property, sectionType, sectionId, rowType);
                            }}
                            onDelete={() => props.onRemoveBlock(item, 'static')}
                            trend={findTrend(item.displayedCompareValue)}
                            displayedValue={item.displayedValue}
                            displayedName={item.displayedName}
                            showCompare={props.compare}
                        />
                    );
                })}
                <Creator_Plus className="height-equalizer" viewMode={props.viewMode} inGrid onClick={props.onOpenRowElement} title={t('KPI Element')} text={t('')} />
            </RowStaticGrid>
        </RowStatic>
    );
}
