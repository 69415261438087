import React, {useState, useEffect} from 'react'
import { PageAlwaysVisible, PageDesktopOnly, PageDesktopPadding, PageSixty, PageTitle, Slider_, Steps_, Steps_Actions, device } from 'monica-alexandria'
import Wizard_Meta_Ad_Account from './Wizard_Meta_Ad_Account';
import Wizard_Facebook_Page from './Wizard_Facebook_Page';
import Wizard_Instagram_Page from './Wizard_Instagram_Page';
import Wizard_Title from './Wizard_Title';
import Wizard_Dates from './Wizard_Dates';
import Wizard_Google_Ad_Account from './Wizard_Google_Ad_Account';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Wizard_Preview from './Wizard_Preview';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../../../../localization/i18next';

const Wizard = styled(PageSixty)`
    position: fixed;
    top: 6rem;
    left: 0;
    height: calc(100vh - 6rem);
    max-height: calc(100vh - 6rem);
    overflow-y: auto;
    width: 100vw;
    z-index: 5;
    background: ${p => p.theme.background};
`

const WizardContainer = styled.div`
    position: fixed;
    top: 6rem;
    left: 0;
    height: calc(100vh - 6rem);
    width: 100vw;
    z-index: 7;
    padding: 1rem 4rem;

    @media ${device.md} {
        padding: 4rem 2rem;
    }   
`

export default function Wizard_(props) {
    
    const {t} = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';

    const [stepsCounter, setStepsCounter] = useState(0);
    const defaultStepList = [                        
        <Wizard_Title
            onClick_Grey={()=> navigate(`/${lang}/integrations`)}
            onClick_Main={() => {setStepsCounter(stepsCounter + 1)}}
            reportTitle={props.reportTitle}
            onTitle={props.onTitle}
            onCurrency={props.onCurrency}
        />,
        <Wizard_Dates 
            onClick_Grey={() => setStepsCounter(stepsCounter - 1)}  
            onClick_Main={() => {
                props.onClickDates(); 
                setStepsCounter(stepsCounter + 1);
                if(props.flow.length == 0) {
                    props.onInitial();
                }
            }}
            onDates={props.onDates}
            onDateFormat={props.onDateFormat}
            currentFormat={props.currentFormat}
            dateFrom={props.dateFrom}
            dateTo={props.dateTo}
            acceptableRange={props.acceptableRange}
        />
    ];
        // Available components
    const componentsMap = {
        paid:         
        <Wizard_Meta_Ad_Account 
            onClick_Grey={() => {
                setStepsCounter(stepsCounter - 1);
                if(props.currentSectionIndex > 0)
                    props.onBack();
            }} 
            onClick_Main={() => {
                if(stepsCounter  < props.flow.length + 2)
                    setStepsCounter(stepsCounter + 1); 
                props.onInitial();
            }}
            businessAccounts={props.businessAccounts}
            selectedBusiness={props.selectedBusiness}
            addSource={props.addSource}
            removeSource={props.removeSource}
            addSection={props.addSection}
            removeSection={props.removeSection}
            errors={props.errors}
            businessCursor={props.businessCursor}
            adAccounts={props.metaAdAccounts}
            laodingAdAccounts={props.laodingAdAccounts}
            selectedSections={props.selectedSections}
            addCampaign={props.addCampaign}
            removeCampaign={props.removeCampaign}
            campaigns={props.metaCampaigns}
            currentSectionIndex={props.currentSectionIndex}
            campaignLoader={props.campaignLoader}
        />,
        facebook:       
        <Wizard_Facebook_Page 
            onClick_Grey={() => {
                setStepsCounter(stepsCounter - 1); 
                if(props.currentSectionIndex > 0)
                    props.onBack();            
            }} 
            onClick_Main={() => {
                if(stepsCounter  < props.flow.length + 2)
                    setStepsCounter(stepsCounter + 1); 
                props.onInitial();
            }}            
            facebookPages={props.facebookPages}
            addSection={props.addSection}
            removeSection={props.removeSection}
            errors={props.errors}
            selectedSections={props.selectedSections}                    
        />,
        instagram:         
        <Wizard_Instagram_Page 
            onClick_Grey={() => {
                setStepsCounter(stepsCounter - 1); 
                if(props.currentSectionIndex > 0)
                    props.onBack();
            }} 
            onClick_Main={() => {
                if(stepsCounter  < props.flow.length + 2)
                    setStepsCounter(stepsCounter + 1); 
                props.onInitial();
            }}
            instagramPages={props.instagramPages}
            addSection={props.addSection}
            removeSection={props.removeSection}
            errors={props.errors}
            selectedSections={props.selectedSections}                    
        />,
        googlePaid: 
        <Wizard_Google_Ad_Account 
            onClick_Grey={() => {
                setStepsCounter(stepsCounter - 1); 
                if(props.currentSectionIndex > 0)
                    props.onBack();
            }} 
            onClick_Main={() => {
                if(stepsCounter  < props.flow.length + 2)
                    setStepsCounter(stepsCounter + 1); 
                props.onInitial();
            }}
            accessibleAdAccounts={props.googleAccessibleAdAccounts}
            managerAccounts={props.managerAccounts}
            selectedManager={props.selectedManager}
            addSource={props.addSource}
            removeSource={props.removeSource}
            currentSectionIndex={props.currentSectionIndex}
            selectedSections={props.selectedSections}                    
            adAccounts={props.googleAdAccounts}
            
            removeSection={props.removeSection}
            addSection={props.addSection}
            adAccountLoader={props.loaderGoogleAdAccounts}
            addCampaign={props.addCampaign}
            removeCampaign={props.removeCampaign}

            campaigns={props.googleCampaigns}
            campaignLoader={props.loaderGoogleCampaigns}

        />
    };

    // Map flow to components
    const stepsComponents = props.flow?.map(step => componentsMap[step]);

    return (
        <Wizard>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t("Create report")}</h1>
                </PageTitle>
                <Steps_
                    currentStep={stepsCounter}
                    startToFinish
                    steps={[...defaultStepList, ...stepsComponents]}
                />
            </PageAlwaysVisible>
            <PageDesktopOnly>
                    <PageDesktopPadding>
                        <Wizard_Preview selectedSections={props.selectedSections} />
                    </PageDesktopPadding>
            </PageDesktopOnly>
        </Wizard>
    )
}



                        