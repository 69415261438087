import { Sidebar_, Sidebar_Box } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../localization/i18next';

export default function Toolbar_() {
   // const {lang} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';

  return (
    <Sidebar_>
      <Sidebar_Box 
        text = {t('Home')}
        icon = 'Home'
        onClick = {()=>navigate(`/${lang}/akihiko/lois`)}
      />
      <Sidebar_Box 
        text = {t('Translations')}
        icon = 'Flag'
        onClick = {()=>navigate(`/${lang}/akihiko/lois/translations`)}
      />
      <Sidebar_Box 
        text = {t('Coupons')}
        icon = 'Flag'
        onClick = {()=>navigate(`/${lang}/akihiko/lois/coupons`)}
      />
      <Sidebar_Box 
        text = {t('Analytics')}
        icon = 'Flag'
        onClick = {()=>navigate(`/${lang}/akihiko/lois/analytics`)}
      />
    </Sidebar_>
  )
}
