import { useLocation } from "react-router-dom";
import store from "../redux/store";
import { useEffect } from "react";

export function checkAndResetOldLocalStorage(){
    /* .app was intrucduced during the latest stages of development. 
    It's good enough to check if the user has old localStorage schema stored by checking app's existance. */
    if (!localStorage.getItem('localStorageCleared') && !store.getState()?.app) {
        localStorage.clear();
        localStorage.setItem('localStorageCleared', 'true'); // Set a flag in local storage
    }
    
}


export function checkAndResetCreator(){

    const keepCreatorPathnames = [
        "/meta/source-business-manager",
        "/meta/ad-account",
        "/meta/dates",
        "/meta/creator",
    ];

    const pathname = window.location.pathname;
    const pathnameArray = pathname.split('/');
    const endpoint = `/${pathnameArray[2]}/${pathnameArray[3]}`;
    
    if ( !keepCreatorPathnames.includes(endpoint) ) {
        store.dispatch({type: "RESET_CREATOR_PROPERTY"});
    } 

    return;
}



export const CheckLocalStorageOnRouteChange = () => {
    const location = useLocation();
  
    useEffect(() => {
      checkAndResetCreator();
    }, [location.pathname]);
  
    return null;
};
  