import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Creator_Title_Row from '../creator/Creator_Title_Row';
import Block_Map from '../block/Block_Map';
import Block_Ages from '../block/Block_Ages';
import Creator_Plus from '../creator/Creator_Plus';
import { useTranslation } from 'react-i18next';
import Block_Gender from '../block/Block_Gender';
import { ThemeContext, device } from 'monica-alexandria';

import { type } from '@testing-library/user-event/dist/type';
const RowDemograph = styled.div``;

const RowDemographGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

const RowDemopgraphBlockWrapper = styled.div`
    grid-row: ${p => p.isCountries ? 'span 2' : 'span 1'};

    @media ${device.md} {
        grid-row: ${p => p.isGenders ? 'span 2' : 'span 1'};
    }
`

export default function Row_Demograph(props) {
    const { t } = useTranslation();

    const [activeBlock, setActiveBlock] = useState('initial');
    
    const toggle_actions = (item) => {
        if (item == activeBlock) {
            setActiveBlock(false);
        } else {
            setActiveBlock(item);
        }
    };




    const {theme} = useContext(ThemeContext);

;

    return (
        <RowDemograph>
            <Creator_Title_Row title={props.rowTitle} viewMode={props.viewMode} onEdit={() => props.onEdit()} onDelete={() => props.onDelete()}/>
            <RowDemographGrid>
                { props.blocks?.map((item, index) => {

                    const actions = activeBlock === 'initial' ? 'initial' : activeBlock === item ;
                    return <RowDemopgraphBlockWrapper key={index} isGenders={item.type === 'genders'} isCountries={item.type === 'countries' }>
                        { item.type === "ages" ? <Block_Ages 
                            json={item?.values} 
                            kpiName={item.displayedName}
                            dataIdentifier="group" 
                            text={t('Click to interact')} 
                            onDelete={() => props.onRemoveBlock(item, 'demographics')}
                            id={index}
                            actions={actions}
                            onActions={() => toggle_actions(item)}
                            setActiveBlock={setActiveBlock}
                            viewMode={props.viewMode}
                            setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                props.setBlock(originalName, value, property, sectionType, sectionId, rowType);
                            }}
                            element={item}
                            elementCategory={item.category}
                            sectionName={props.sectionName}
                            sectionType={props.sectionType}
                            sectionId={props.sectionId}
                            rowName={props.staticRowName}
                            rowType={props.rowType}
                        /> : null }
                        { item.type === "genders" ? <Block_Gender 
                            json={item?.values} 
                            kpiName={item.displayedName}
                            dataIdentifier="group" 
                            dataKey="value" 
                            text={t('Click to interact')} 
                            onDelete={() => props.onRemoveBlock(item, 'demographics')}
                            id={index}
                            viewMode={props.viewMode}
                            actions={actions}
                            onActions={() => toggle_actions(item)}
                            setActiveBlock={setActiveBlock}
                            setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                props.setBlock(originalName, value, property, sectionType, sectionId, rowType);
                            }}
                            element={item}
                            elementCategory={item.category}
                            sectionName={props.sectionName}
                            sectionType={props.sectionType}
                            sectionId={props.sectionId}
                            rowName={props.staticRowName}
                            rowType={props.rowType}
                        /> : null }
                        { item.type === "countries" ? <Block_Map
                            // maxValue={Math.max(...item?.values?.map(item => item.value))} // calculate the max value
                            demographics={item?.values} // Replace with your actual data
                            dataIdentifier="group" 
                            kpiName={item?.displayedName}
                            theme={theme} // or "dark" based on your theme
                            preview={false} // or true based on your requirements
                            onDelete={() => props.onRemoveBlock(item, 'demographics')}
                            id={index}
                            viewMode={props.viewMode}
                            actions={actions}
                            onActions={() => toggle_actions(item)}
                            setActiveBlock={setActiveBlock}
                            setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                props.setBlock(originalName, value, property, sectionType, sectionId, rowType);
                            }}                       
                            element={item}
                            elementCategory={item.category}
                            sectionName={props.sectionName}
                            sectionType={props.sectionType}
                            sectionId={props.sectionId}
                            rowName={props.staticRowName}
                            rowType={props.rowType}
                        /> : null }
                    </RowDemopgraphBlockWrapper>
                })}
                <Creator_Plus viewMode={props.viewMode} inGrid onClick={props.onOpenRowElement} title={t('KPI Element')} text={t('')} />
            </RowDemographGrid>
        </RowDemograph>
    );
}
