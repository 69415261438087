import { Actions_, Builder_, Input_, Red_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const EditKpi = styled.div``;

const SliderSection = styled.div`
    padding-bottom: 3rem;

    h3 {
        padding-bottom: 2rem;
    }
`;

const AddButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default function Edit_Kpi(props) {
    const { t } = useTranslation();

    /* Kpi data */
    const kpiIndex = props?.report?.campaigns[props.data.index].kpis.findIndex(item => item?.originalKey === props.data.kpi?.originalKey);
    const [isActive, toggleActive] = useState(true);
    const [storedKpiIndexAfterRemoval, setStoredKpiIndexAfterRemoval] = useState();

    const addKpi = () => {
        let campaigns = props.report?.campaigns;
        let kpis = campaigns[props.data.index]?.kpis;
        if (storedKpiIndexAfterRemoval !== -1) kpis.splice(storedKpiIndexAfterRemoval, 0, props?.data?.kpi);
        props.setReport({...props.report, campaigns})
    }

    const removeKpi = () => {
        setStoredKpiIndexAfterRemoval(kpiIndex);
        let campaigns = props.report?.campaigns;
        const kpis = campaigns[props.data.index]?.kpis;
        if (kpiIndex !== -1) kpis.splice(kpiIndex, 1); 
        props.setReport({...props.report, campaigns})
    }

    const updateKpiKey = (newKey) => {
        let campaigns = props.report?.campaigns;
        const kpis = campaigns[props.data.index]?.kpis;
        if (kpiIndex !== -1) kpis[kpiIndex].key = newKey; 
        props.setReport({...props.report, campaigns})
    }

    const updateKpiValue = (newValue) => {
        let campaigns = props.report?.campaigns;
        const kpis = campaigns[props.data.index]?.kpis;
        if (kpiIndex !== -1) kpis[kpiIndex].value = newValue; 
        props.setReport({...props.report, campaigns})
    }

    useEffect(() => {
        props.setSliderText(t('Edit KPI'));
    }, []);

    return (
        <EditKpi>
            <Builder_ title={t("KPI status")}>
                <Toggle_Stat 
                    text={isActive ? (t('Added')) : (t('Removed'))} 
                    active={isActive ? true : false} 
                    id={"AdSnap_Edit_Meta_Slot_1"}
                    onClick={ isActive ? 
                                () => {removeKpi(); toggleActive(!isActive)} 
                                : 
                                () => {addKpi(); toggleActive(!isActive)}} 
                    />
            </Builder_>
            <Builder_ title={t("Change original KPI values")} split>
                <Input_ 
                    placeholder={t('Name')} 
                    value={props.data.kpi?.key} 
                    onChange={(e) => updateKpiKey(e.target.value)} 
                />
                <Input_ 
                    placeholder={t('Value')} 
                    value={props.data.kpi?.value} 
                    onChange={(e) => updateKpiValue(e.target.value)} 
                />
            </Builder_>
    </EditKpi>
    );
}
