import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const getCurrentDate = () => {
    // Get current date
    let currentDate = new Date();

    // Get year, month, and day
    let year = currentDate.getFullYear(); // Getting last two digits of the year
    let month = currentDate.getMonth() + 1; // Adding 1 because month is zero-indexed
    let day = currentDate.getDate();

    // Formatting month and day to have leading zeros if necessary
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    // Formatted date in YY-MM-DD format
    let formattedDate = `${year}-${month}-${day}`;

    return formattedDate; // Output example: "24-02-26" for February 26, 2024

}

export const generatePDF = (reportTitle, setPdfLoader) => {

    const input = document.querySelector('#target-div-for-pdf-creation');
    html2canvas(input).then((canvas) => {

        const imgData = canvas.toDataURL('image/jpeg');
        const imgWidth = 210; // This corresponds to A4 width
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const currDate = getCurrentDate();
        const titleWithoutDots = reportTitle.replace(/\./g, '_');

        const title = "Adsnap" + "-" + titleWithoutDots + "-" + currDate;
        console.log(title);
        // Convert dimensions from mm to pt
        let widthInPt = imgWidth * 2.83465;
        let heightInPt = imgHeight * 2.83465;

        // Create a pdf with custom dimensions
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: [widthInPt, heightInPt],
        });
        pdf.addImage(imgData, 'JPEG', 0, 0, widthInPt, heightInPt);
        pdf.save(title);
        setPdfLoader(false);
    });

};