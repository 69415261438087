import { Actions_, Builder_, Input_, Red_, Textarea_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const CommentAds = styled.div``;


export default function Edit_Comment(props) {
    const { t } = useTranslation();


    const [commentsLength, setCommentsLength] = useState(0)

    const edit_comment = (comment) => {
        let campaigns = props.report.campaigns;
        campaigns[props.data?.index].comment = comment; 
        props.setReport({...props.report, campaigns});
        setCommentsLength(comment.length)
    }
    
    useEffect(() => {
        props.setSliderText(t('Google Ads'));
    }, []);

    return (
        <CommentAds>
            <Builder_ title={t("Add some comments for the report regarding this section")}>
                <Textarea_
                    valueLength={commentsLength} 
                    limit={170} 
                    placeholder={t("Optional field")} 
                    rows="5" 
                    defaultValue={props.report?.comment} 
                    onChange={(event) => edit_comment(event.target.value)} 
                />
            </Builder_>
        </CommentAds>
    );
}
