import { CardsSixty, Input_, On_Stat, Section_Title, Slider_, Toggle_Stat } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Edit_Splitter_Text_Section(props) {
    const { t } = useTranslation();
    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Edit Splitter Text')}>
            <Section_Title text={t('Edit text')} />
            <Input_ value={props.section.sectionTitle} onChange={props.onEdit} />
        </Slider_>
    );
}
