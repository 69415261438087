import { _Nav } from 'monica-alexandria';
import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import CONNECT_FACEBOOK from '../pages/connectFacebook/CONNECT_FACEBOOK';
import { Protector } from '../../../services/authentication/Protector';
import store from '../../../redux/store';
import CREATOR from '../pages/creator/CREATOR';
import REPORT_TEMPLATE from '../pages/reportTemplate/REPORT_TEMPLATE';


export default function META_ROUTER() {

    const language = store.getState().selectedLanguage ? store.getState().selectedLanguage : 'en'

    return (
        <Routes>
            <Route exact path="/" element={
                <Navigate to={{pathname: `/${language}/`,}}/>
            }/>
            <Route path="/connect-to-facebook" element={<Protector role="Doer"><CONNECT_FACEBOOK /></Protector>}/>
            <Route path="/report-template" element={<Protector role="Doer"><REPORT_TEMPLATE /></Protector>}/>
            <Route path="/creator" element={<Protector role="Doer"><CREATOR /></Protector>}/>
            <Route path="/creator/:id/:mode" element={<Protector role="Doer"><CREATOR /></Protector>}/>

        </Routes>
    )
}