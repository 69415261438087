import React from 'react';
import styled from 'styled-components';
import { H2, H3, H5, P } from '../../../styles/components/LandingText';
import { Button, Card, Cta_, Focus_, IconSvg, device } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const LandingPrices = styled.section`
    h2 {
        text-align: center;
        color: var(--focus);
    }

    .Header {
        text-align: center;
        margin-bottom: 2rem;
    }

    .Description {
        text-align: center;

        max-width: 60rem;
        margin: 0 auto;
        margin-bottom: 10rem;
    }
`;

const LandingPricesGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;

    @media ${device.lg} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

const LandingPricesBox = styled(Card)`
    border: 0;
    box-shadow: none;
    padding: 4rem;
    background: ${p => p.action 
    ? `linear-gradient(to bottom right, var(--focus) -20%, var(--gloom), var(--greyDark))` 
    : `linear-gradient(to bottom right, var(--main) -20%, var(--gloom), var(--greyDark))`};


    h3 {
        text-align: center;
        color: var(--white);
        margin-bottom: 2rem;
    }

    .Sep {
        /* height: 2px;
        background: var(--white);
        width: 100%; */
    }

    .Price {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: relative;
    }

    .Price p {
        color: var(--white);
    }

    .Main {
        font-size: 7rem;
        font-weight: 900;
        font-family: 'fontBold';
    }

    .Top {
        height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .Discount {
        position: relative;

        p {
            color: var(--grey);
            text-align: center;
            font-size: 2rem;
        }

        .Cross {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 2px;
            width: 7rem;
            background: var(--grey);
            transform: translate(-50%, -50%) rotate(5deg);
        }
    }

    ul {
        margin-top: 3rem;
    }

    @media ${device.lg} {
        grid-column: ${p => p.action ? 'span 2' : 'span 1'};
    }

    @media ${device.md} {
        grid-column: span 1;
    }
`;

const LandingPricesItem = styled.li`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    gap: 1rem;
    margin-top: 1rem;

    svg {
        fill: ${(p) => (p.not ? 'var(--greyDark)' : 'var(--white)')};
        height: 1.4rem;
        margin-bottom: 5px;
    }

    h5 {
        color: ${(p) => (p.not ? 'var(--greyDark)' : 'var(--white)')};
    }

    .Bar {
        grid-column: span 2;
        height: 3px;
        border-radius: 2px;
        width: 100%;
        background: var(--greyDark);
        position: relative;
        overflow: hidden;

        .BarInner {
            background: var(--white);
            width: ${(p) => (p.width ? p.width : '100%')};
            height: 100%;
        }
    }
`;

const LandingPricesCta = styled.div`
    margin-top: 5rem;
    h4,
    h6 {
        color: var(--white) !important;
        text-align: center;
    }
`;

const LandingPricesCtaBox = styled.div`
    margin-bottom: 2rem;
    text-align: center;
    border: 2px dashed var(--white);
    border-radius: 1rem;
    padding: 2rem;
`;

const LandingPricesCtaButton = styled(Button)`
    margin: 0 auto 1rem auto;
    border-radius: 4rem;
    width: 100%;
    justify-content: center;
    span {
        font-size: 1.8rem;
        color: var(--focus);
    }
`;

export default function Landing_Prices(props) {

    const {t} = useTranslation();

    return (
        <LandingPrices>
            <H2>{t("Exclusive Offer for Our Early Adopters!")}</H2>
            <H3 className="Header">{t("Unlock our Pro Plan for Just €9.90 - Limited Time Only!")}</H3>
            <P className="Description">{t("As a thank you for believing in us, early adopters can now access our Pro Plan, regularly €19.90, for the price of our Basic Plan. Keep this special rate for as long as your subscription remains active.")}</P>
            <LandingPricesGrid>
                <LandingPricesBox>
                    <H3>Entry</H3>
                    <div className="Top">
                        <div className="Discount">
                            <p></p>
                        </div>
                        <div className="Price">
                            <p className="Main">€9,90</p>
                            <p className="Sub">/{t("month")}</p>
                        </div>
                    </div>
                    <div className="Sep"></div>
                    <ul>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited reports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Pdf exports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Multiple Meta sources on report")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="100%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("White label")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="0%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("Edit KPI values")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="0%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("Upload images")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="0%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("Custom templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="0%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("Custom styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="0%" not>
                            <IconSvg Icon="Xicon" />
                            <h5>{t("Custom KPIs")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        
                    </ul>
                </LandingPricesBox>
                <LandingPricesBox>
                    <H3>Standard</H3>
                    <div className="Top">
                        <div className="Discount">
                            <div className="Cross"></div>
                            <p>€15,90</p>
                        </div>
                        <div className="Price">
                            <p className="Main">€9,90</p>
                            <p className="Sub">/{t("month")}</p>
                        </div>
                    </div>
                    <div className="Sep"></div>
                    <ul>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited reports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Pdf exports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Multiple Meta sources on report")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("White label")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Edit KPI values")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Upload images")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="50%">
                            <IconSvg Icon="Check" />
                            <h5>{t("5 Custom templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="40%">
                            <IconSvg Icon="Check" />
                            <h5>{t("10 Custom KPIs")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem width="30%">
                            <IconSvg Icon="Check" />
                            <h5>{t("2 Custom styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        
                        
                    </ul>
                </LandingPricesBox>
                <LandingPricesBox action>
                    <H3>Pro</H3>
                    <div className="Top">
                        <div className="Discount">
                            <div className="Cross"></div>
                            <p>€20,90</p>
                        </div>
                        <div className="Price">
                            <p className="Main">€9,90</p>
                            <p className="Sub">/{t("month")}</p>
                        </div>
                    </div>
                    <div className="Sep"></div>
                    <ul>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited reports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Pdf exports")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Multiple Meta sources on report")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Prebuilt styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("White label")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Edit KPI values")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Upload images")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited Custom templates")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited Custom KPIs")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Unlimited Custom styles")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                        <LandingPricesItem>
                            <IconSvg Icon="Check" />
                            <h5>{t("Multi Google Ads Sources")}</h5>
                            <div className="Bar">
                                <div className="BarInner"></div>
                            </div>
                        </LandingPricesItem>
                    </ul>
                    <LandingPricesCta>
                        <LandingPricesCtaBox>
                            <h4>
                                {t("This is a one-time opportunity to enjoy top-tier features for a fraction of the cost")}. <br /> {t("A gift for being part of our early journey")}.
                            </h4>
                        </LandingPricesCtaBox>
                        <LandingPricesCtaButton onClick={props.onBuy}>
                            <span>{t("ACTIVATE 15-DAY TRIAL")}</span>
                        </LandingPricesCtaButton>
                        <h6>*{t("Offer valid for a limited period. Terms and conditions apply")}.</h6>
                    </LandingPricesCta>
                </LandingPricesBox>
            </LandingPricesGrid>
        </LandingPrices>
    );
}
