import axios from "axios"
import { config } from "../../../../config"


const get_accessed_preset_styles = () =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/presetstyles/getAccessedPresetStyles`)
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}

// const upload = () => {
    
//     let form = new window.FormData();
//     let data = {};

//     if (img) form.append('file', img);
    
//     form.append('data', JSON.stringify(data));

//     return new Promise((resolve, reject) => {
//         axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstyles/uploadLogo`, form, {})
//         .then(res => {
//             resolve(res);
//         })
//         .catch(() => {
//             console.error('EDIT LANDING')
//             resolve(false);
//         })
//     })
// }


const create_preset_style = (reportStyle, createAction, file) =>{

    let form = new window.FormData();
    let data = {            
        payload: reportStyle,
        createAction, createAction,
    };

    if (file) form.append('file', file);
    
    form.append('data', JSON.stringify(data));

    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstyles/createPresetStyle`, form, {})
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}



const update_preset_style = (reportStyle, file) =>{

    let form = new window.FormData();
    let data = {            
        payload: reportStyle,
    };

    if (file) form.append('file', file);
    
    form.append('data', JSON.stringify(data));
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstyles/updatePresetStyle`, form, {})
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}



const deletePresetStyle = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstyles/deletePresetStyle`, {
            presetStyleId: id
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}







export {
    get_accessed_preset_styles,
    create_preset_style,
    update_preset_style,
    deletePresetStyle,
};