import React, { useContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { useTranslation } from 'react-i18next';
import { Snippet_Vertical_Actions } from '../snippets/Snippet_Vertical_Actions';
import { SlideOptions, SlideOptionsWrapper } from '../../../../../../styles/components/SlideActions';
import { scaleLinear } from 'd3-scale';
import Snippet_Block_Overlay from '../snippets/Snippet_Block_Overlay';
import Edit_Block from '../edit/Edit_Block';


const BlockMap = styled(SlideOptions)`
    -ms-grid-row-span: 2;
    grid-column: span 2;
    background: ${(p) => p.theme.low};
    border-radius: ${(p) => p.presetStyle.radius};
    min-height: 8.5rem;
    height: 100%;
`;

const BlockMapTitle = styled.h6`
    font-family: ${(p) => p.presetStyle.font.family};
    color: ${(p) => p.presetStyle.color.textDark} !important;
`;

const BlockMapWrapper = styled(SlideOptionsWrapper)`
    border: 1px solid ${(p) => p.presetStyle.color.background};
    background: ${(p) => (p.variant == 'main' ? p.presetStyle.color.main : p.variant == 'focus' ? p.presetStyle.color.focus : p.presetStyle.color.grey)};
    border-radius: ${(p) => p.presetStyle.radius};
    padding: 1rem 2rem;
    transition: transform 0.5s;
`;

// The React component
export default function Block_Map(props) {

    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    const [slider, setSlider] = useState(false);
    
    // State for maximum value, set with dummy data for now
    const [maxValue, setMaxValue] = useState();

    useEffect(() => {
        if(props.demographics) {
            const max = Math.max(...props.demographics?.map(item => item.value))
            setMaxValue(max);
        }

    }, [props.demographics]);

    const open_close_slider = () => {
        setSlider(false);
    };

    // URL for the geography data
    const geoUrl = 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/report/WorldMapAlpha2.json';

    const colorScale = scaleLinear()
        .domain([0, maxValue]) //right is the max value of the kpi stat we are intrested
        .range([reportStyle.color?.background, reportStyle.color?.main]);

    // Render the map
    return (
        <BlockMap presetStyle={reportStyle} actions={props.action} key={props.key} id={props.id}>
            {slider ? 
            <Edit_Block 
                onClick={() => open_close_slider()} 
                onBack={() => open_close_slider()}
                title={props.title}       
                titlePlaceholder={props.titlePlaceholder} 
                titleDefaultValue={props.titleDefaultValue} 
                setBlock={props.setBlock} 
                id={props.id} 
                displayedName={props.kpiName}
                element={props.element} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                rowType={props.rowType} 
            /> :null}
            <Snippet_Vertical_Actions onEdit={() => setSlider(!slider)} onDelete={props.onDelete} />
            <BlockMapWrapper viewMode={props.viewMode} variant={props.variant} actions={props.actions} presetStyle={reportStyle} onClick={props.onActions}>
                <BlockMapTitle presetStyle={reportStyle}>{props.kpiName}</BlockMapTitle>
                <ComposableMap
                    projection="geoMercator"
                    projectionConfig={{
                        center: [0, 35],
                        scale: 127,
                    }}
                >
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                            geographies.map((geo, i) => {
                                const isos = props.demographics?.find((item) => item?.[props.dataIdentifier] === geo.id)?.[props.dataIdentifier];

                                const color = colorScale(props.demographics?.find((c) => c?.[props.dataIdentifier] === isos)?.value);
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        stroke={reportStyle.color.grey}
                                        fill={isos ?  color : reportStyle.color.background}
                                        // fill={isos ? colorScale(15) : reportStyle.color.background}
                                        style={{
                                            default: {
                                                outline: 'none',
                                            },
                                            hover: {
                                                outline: 'none',
                                            },
                                            pressed: {
                                                outline: 'none',
                                            },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
            </BlockMapWrapper>
            {!props.viewMode ?
                <Snippet_Block_Overlay stylePreset={reportStyle} text={t('Click to interact')} />
            :
                null
            }
        </BlockMap>
    );
}
