export const fixNumbers = (value, showDecimalsIfNotExist) => {
   
    if ( value === 0 ) return showDecimalsIfNotExist ? "0,00" : "0";
    if ( value === "" || !value ) return showDecimalsIfNotExist ? "" : "";
    if ( value && typeof value === 'string' && !isNaN(value?.replace(',','.')) ) value = value?.replace(',','.');
    if ( isNaN(value) && isNaN(parseFloat(value)) ) return value;

    let float_value = parseFloat(value);
    float_value = float_value.toFixed(2);
    
    let first_part = float_value.toString().split('.')[0];
    let second_part = float_value.toString().split('.')[1];

    first_part = first_part.split("").reverse()
    let tempArr = []
    let counter = 0;
    for ( let char of first_part ) {
        if (counter == 3) {
            tempArr.push(".");
            tempArr.push(char);
            counter = 0;
        }
        else tempArr.push(char);
        counter++
    }
    
    let finalValue = tempArr.reverse().join("")
    if ( second_part === '00' && !showDecimalsIfNotExist ) return finalValue;
    else return finalValue+","+second_part;

    // try {
    //     var numberToReturn = numberFormatter(value, {forceShowDecimals: showDecimalsIfNotExist})
    // } catch (error) {
    //     var numberToReturn = 0;
    // }

    // return numberToReturn

}