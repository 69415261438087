import { Actions_, Builder_, CardsSixty, Confirmation_, Google_Search, Input_, Main_Plain, Red_, Section_Title, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { CreateCustomField } from '../../../../../../styles/components/Create.css';

const SelectKpis = styled.div``;


export default function Select_Kpis(props) {
    
    const { t } = useTranslation();

    /* Warning about custom kpis */
    const [warning, setWarning] =  useState(false);

    const yesConfirm = () =>{
        setWarning(false)
        props.closeSlider(false)
    }

    /* For custom kpi */
    const [customInputs, setCustomInputs] = useState({
        key: '',
        originalKey: '',
        value: '',
    });


    /* Search */ 
    const [searchResults, setSearchResults] = useState([]);
    const [displayedResults, setDisplayedResults] = useState([]);


    /* Handle kpis */
    const addKpi = (kpi) => {

        if ( !kpi?.originalKey || !kpi?.key ) {props.alertMessage(t("Stat name is required"), "Error");return;}
        let campaigns = props.report?.campaigns;
        let kpis = campaigns[props.data.index]?.kpis;
        kpis.push(kpi);
        props.setReport({...props.report, campaigns})
        props.alertMessage(kpi?.key + " " + t('kpi has been added'), "Success");
    }


    const removeKpi = (kpi) => {
        let campaigns = props.report?.campaigns;
        let kpis = campaigns[props.data.index]?.kpis;
        const keyToRemove = kpi.originalKey;
        const indexToRemove = kpis.findIndex(item => item.originalKey === keyToRemove);
        if (indexToRemove !== -1) kpis.splice(indexToRemove, 1); 
        props.setReport({...props.report, campaigns})
    }


    useEffect(() => {
        if ( searchResults.input ) setDisplayedResults(searchResults.data);
        else setDisplayedResults(props?.googleResponses?.campaignData[props.data.index].kpis);
    },[searchResults])

    useEffect(() => {
        props.setSliderText(t('Add KPIs'));
    }, []);


    console.log('displayed',displayedResults);
    console.log('googleResponses',props.googleResponses);

    return (
        <SelectKpis>
            {warning &&
            <Confirmation_ 
                message={t('It appears that you have failed to add a custom field. Are you sure you wish to close the slider?')} 
                yes={t('Yes')} 
                no={t('No')}
                onYes = {yesConfirm}
                onNo = {()=>setWarning(false)}
            /> 
           }

           { !props.report?.campaigns[props.data.index]?.kpis || props.report?.campaigns[props.data.index]?.kpis?.length === 0 
                    ? <><p>Something went wrong</p></> 
                    : <><Builder_ title={t("Custom KPI")}>
                            <CreateCustomField>
                                <Input_
                                    placeholder={t('Stat name')}
                                    value={customInputs.key}
                                    onChange={(event) =>
                                        setCustomInputs({
                                            ...customInputs,
                                            key: event.target.value,
                                            originalKey: event.target.value,
                                        })
                                    }
                                />
                                <Input_
                                    placeholder={t('Value')}
                                    value={customInputs.value}
                                    onChange={(event) =>
                                        setCustomInputs({
                                            ...customInputs,
                                            value: event.target.value,
                                        })
                                    }
                                />
                                <Main_Plain 
                                    id={"AdSnap_Google_Select_Kpis_2"} 
                                    noPadding 
                                    iconLeft="Add" 
                                    text={t('Add')} 
                                    onClick={() => addKpi(customInputs)} 
                                />
                            </CreateCustomField>
                        </Builder_>

                        <Google_Search
                            file={props?.googleResponses?.campaignData[props.data.index].kpis}
                            fileKeys={['key']}
                            defaultValue={''}
                            sanitize={true}
                            placeholder={t('Search kpi')}
                            setResults={(data) => setSearchResults(data)}
                            debounceTime={750}
                            minMatchCharacters={1}
                            maxMatchCharacters={50}
                            disabled={false}
                            isCaseSensitive={false}
                            maxAcceptedScore={0.6}
                        />

                        <Section_Title text={t('Default')} />

                        <CardsSixty>
                            {displayedResults?.map((kpi) => {
                                return <Toggle_Stat 
                                            id={"AdSnap_Google_Select_Kpis_3"} 
                                            text={kpi?.key} 
                                            active={props.report.campaigns[props.data.index].kpis.some((item) => item.originalKey === kpi?.originalKey) 
                                                    ? true 
                                                    : false
                                            } 
                                            onClick={props.report.campaigns[props.data.index].kpis.find((item) => item.originalKey === kpi?.originalKey)
                                                ? () => removeKpi(kpi) 
                                                : () => addKpi(kpi)} 
                                />;
                            })}
                        </CardsSixty></>}
        </SelectKpis>
    );
}
