import { CardsSixty, On_Stat, Section_Title, Slider_, Toggle_Stat, Google_Search, Builder_ , Partial_Loader} from 'monica-alexandria';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Error_Message from '../creator/Creator_Error_Message';
import Slider_Expand from '../slider/Slider_Expand';

const AdAccountSource = styled.div``;

const AdAccountWrapper = styled.div``;

const CampaignWrapper = styled.div``;

export default function Add_Section_Meta(props) {
    
    const { t } = useTranslation();

    const fetchBusinessesError = props.errors?.fetchBusinesses?.error;
    const fetchAdAccountsError = props.errors?.fetchAdAccounts?.find((ac) => ac?.extraInfo?.parentBusinessId === props.selectedBusiness?.id)?.error;

    // for search bars
    const [displayedBusinesses, setDisplayedBusinesses] = useState(props.businessAccounts);
    const [displayedAdAccounts, setDisplayedAdAccounts] = useState(props.adAccounts);
    const [displayedCampaigns, setDisplayedCampaigns] = useState([]);

    const [searchResults1, setSearchResults1] = useState();
    const [searchResults2, setSearchResults2] = useState();
    const [searchResults3, setSearchResults3] = useState();

    const [filteredCampaigns, setFilteredCampaigns] = useState([]);

    const sectionsLen = props.selectedSections?.length;

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults1?.input) setDisplayedBusinesses(searchResults1?.data);
            else setDisplayedBusinesses(props.businessAccounts);
        };

        applySearchResults();
    }, [searchResults1]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults2?.input) setDisplayedAdAccounts(searchResults2?.data);
            else setDisplayedAdAccounts(props.adAccounts);
        };

        applySearchResults();
    }, [searchResults2]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults3?.input) setDisplayedCampaigns(searchResults3?.data);
            else setDisplayedCampaigns(filteredCampaigns);
        };

        applySearchResults();
    }, [searchResults3]);

    // Use Effect waiting to fetch ad accounts
    useEffect(() => {
        setDisplayedAdAccounts(props.adAccounts)
    }, [props.adAccounts]);

    useEffect(() => {

        setFilteredCampaigns(props.campaigns?.filter((camp) => camp?.account_id === props.selectedSections[sectionsLen-1]?.sectionData[0]?.id));
        setDisplayedCampaigns(props.campaigns?.filter((camp) => camp?.account_id === props.selectedSections[sectionsLen-1]?.sectionData[0]?.id));
        
    }, [props.campaigns, props.selectedSections]);

    const onDone = () => {
        props.onClick();
    }

    return (
        <Slider_ onClick={onDone} iconGreyLeft="Back" grey={t('Back')} onGrey={props.onBack} text={t('Done')} title={t('Select Account')}>
            <AdAccountSource>
                {props.businessAccounts?.length > 15 ?
                    <>
                        <Builder_ title={t('Search for Business Manager Account')}>
                            <Google_Search file={props.businessAccounts} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Business Manager')} setResults={(data) => setSearchResults1(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                        </Builder_> 
                        
                    </>
                    :
                    null
                }
                <Section_Title text={t('Facebook Business Manager')} />
                {fetchBusinessesError ? (
                    <Creator_Error_Message error={fetchBusinessesError} errorType={'fetchBusinesses'} onClick={props.onClick} noSlider={true} />
                ) : (
                    <CardsSixty>
                        {!props.selectedBusiness ?
                        <>
                        {displayedBusinesses?.map((item, i) => {
                            const isAdded = item.id === props.selectedBusiness?.id;
                            return <Toggle_Stat key={i} id={i} text={item.name} onClick={() => (!isAdded ? props.addSource(item, 'meta') : props.removeSource('meta'))} active={isAdded} />;
                        })}
                        </>
                        :
                        <>
                        <Toggle_Stat
                            key={props.selectedBusiness?.id}
                            id={0}
                            text={props.selectedBusiness?.name}
                            onClick={() => props.removeSource('meta')}
                            active={true}
                        />
                        </>
                        }
                    </CardsSixty>
                )}
            </AdAccountSource>

            {props.selectedBusiness && (
                <AdAccountWrapper>
                    {props.adAccounts.length > 15 ?
                        <Builder_ >
                            <Google_Search file={props.adAccounts} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Ad Account')} setResults={(data) => setSearchResults2(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                        </Builder_> 
                    : null }
                    <Section_Title text={t('Select Facebook Ad Account')} />
                    {fetchAdAccountsError ? (
                        <Creator_Error_Message error={fetchAdAccountsError} errorType={'fetchAdAccounts'} onClick={props.onClick} noSlider={true} />
                    ) : <>
                        {props.laodingAdAccounts ? <Partial_Loader/> :
                        <CardsSixty>
                            {!props.selectedAdAccount ?
                                <>
                                {displayedAdAccounts
                                    ?.filter((adAccount) => adAccount?.parentBusiness?.id === props.selectedBusiness?.id)
                                    ?.map((item, i) => {
                                        const isAdded = props.selectedSections.some((obj) => obj.type === 'paid' && obj.sectionData?.find((sd) => sd.type === 'adAccount').id === item.id);
                                        return <Toggle_Stat 
                                            key={i} 
                                            id={i} 
                                            text={item.name} 
                                            onClick={() => (!isAdded ? props.addSection(item, "paid", "meta") : props.removeSection(item, "paid", "meta"))} 
                                            active={isAdded} 
                                        />;
                                    })}
                                </>
                            : 
                                <Toggle_Stat
                                    key={props.selectedAdAccount?.id}
                                    id={0}
                                    text={props.selectedAdAccount?.name}
                                    onClick={() => props.removeSection(props.selectedAdAccount, "paid", "meta")}
                                    active={true}
                                />
                            }
                        </CardsSixty>
                        }
                        </>
                    }
                </AdAccountWrapper>
            )}
        {props.selectedAdAccount && filteredCampaigns?.length >= 0 ?
            <Slider_Expand
                filteredCampaigns={filteredCampaigns}
                setSearchResults={setSearchResults3}
                loadingCampaigns={props.loadingCampaigns}
                displayedCampaigns={displayedCampaigns}
                selectedSections={props.selectedSections}
                addCampaign={props.addCampaign}
                removeCampaign={props.removeCampaign}
                title={t('Specific Campaign (Optional)')}
                currentSectionIndex={sectionsLen - 2}
            />
            : null}

        </Slider_>
    );
}
