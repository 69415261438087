import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Creator_Plus from '../creator/Creator_Plus';
import { useTranslation } from 'react-i18next';
import Creator_Title_Section from '../creator/Creator_Title_Section';
import Add_Meta_Row_Static from '../add/Add_Meta_Row_Static';
import Add_Meta_Row from '../add/Add_Meta_Row';
import Row_Comments from '../row/Row_Comments';
import Row_Demograph from '../row/Row_Demograph';
import MetaLogo from '../../../../../../assets/MetaLogo.png';
import FbLogo from '../../../../../../assets/fb.png';
import InstaLogo from '../../../../../../assets/insta.png';
import Creator_Import_Section from '../creator/Creator_Import_Section';
import Row_Static from '../row/Row_Static';
import Add_Meta_Row_Demographics from '../add/Add_Meta_Row_Demographics';
import Add_Meta_Row_Demographics_Charts from '../add/Add_Meta_Row_Demographics_Chart';
import Edit_Element_Comments from '../edit/Edit_Element_Comments';
import Row_Media from '../row/Row_Media';
import Row_Graph from '../row/Row_Graph';
import Add_Meta_Row_Graphical from '../add/Add_Meta_Row_Graphical';
import Edit_Media from '../edit/Edit_Media';
import GoogleLogo from '../../../../../../assets/googleNetwork/pngegg.png';

const SectionMeta = styled.div`
    margin: 2rem 2rem 0 2rem;
    position: relative;
`;


const SectionMetaWrapper = styled.div`
    padding: ${(p) => (p.hasSource ? '0rem' : '2rem')}; // change from 0rem to 2rem based on if the source can been selected
`;


export default function Section_Meta(props) {
    const { t } = useTranslation();
    const [slider, setSlider] = useState({
        status: false,
        active: '',
    });

    const open_close_slider = (active, item) => {
        setSlider({ ...slider, status: true, active: active, item: item ? item : false });
        
    };  

    const [comments, setComments] = useState();
    const [lenOfComments, setLenOfComments] = useState();
    const [media, setMedia] = useState();

    useEffect(() => {
        const comms = props.sectionRows?.filter((row) => row.type === 'comment');
        setComments(comms);
        setLenOfComments(comms.length);
        const med = props.sectionRows?.filter((row) => row.type === 'media');
        setMedia(med);
    }, [props.sectionRows]);

    const matchmakeLogo = (source, type) => {
        if (source === "google") return GoogleLogo
        if (source === "meta" && type === "paid") return MetaLogo;
        if (type === "facebook") return FbLogo;
        if (type === "instagram") return InstaLogo;
    }


    const matchmakeColor = (sectionType, colorType) => {
        if ( colorType === "firstColor" ) {
            if (sectionType === "paid") return "var(--metaOne)";
            if (sectionType === "facebook") return "var(--facebookOne)";
            if (sectionType === "instagram") return "var(--instagramOne)";
        } else {
            if (sectionType === "paid") return "var(--metaTwo)";
            if (sectionType === "facebook") return "var(--facebookTwo)";
            if (sectionType === "instagram") return "var(--instagramTwo)";
        }
    }


    const matchmakeTitle = (type) => {
        if (type === "paid") return (t("Import a Meta Ad Account"));
        if (type === "facebook") return (t("Import a Facebook Account"));
        if (type === "instagram") return (t("Import an Instagram Account"));
    }

    const matchmakeText = (type) => {
        if (type === "paid") return (t("Consolidate your advertising efforts by streaming data from Meta Ads. Track the performance of your Facebook, Instagram, Messenger, and Audience Network campaigns in one place."));
        if (type === "facebook") return (t("Capture and analyze organic engagement metrics from your Facebook Page. Track audience interactions, post reach, and engagement to refine your content strategy."));
        if (type === "instagram") return (t("Gather insights from your Instagram account. Monitor post and story performance, follower growth, and engagement trends to enhance your organic Instagram presence."));
    }

    const sliderName = (type) => {
        if(type === "paid") return 'section-source-paid'
        if(type === "facebook") return 'section-source-page'
        if(type === "instagram") return 'section-source-page'
    }


    // if ( !props.hasSource ) return (
    //     <Creator_Import_Section 
    //         img={matchmakeLogo(props.sectionType)} 
    //         firstColor={matchmakeColor(props.sectionType, "firstColor")}
    //         secondColor={matchmakeColor(props.sectionType, "secondColor")}
    //         onDelete={() => props.removeSectionBeforeSourceSelection()} 
    //         onClick={() => props.selectSource(sliderName(props.sectionType))} 
    //         title={matchmakeTitle(props.sectionType)} 
    //         text={matchmakeText(props.sectionType)} 
    //         textBtn={t('Click to import')} 
    //         iconBtn="Add" 
    //     /> 
    // )

    const checkDemographics = (source, type) => {
        if(source === "meta" && type === "paid")
            return true;
        else if(source === "google")
            return true;
        else 
            return false;
    }
    
    const checkGraphical = (source, type) => {
        if(type === "overview")
            return false;
        else 
            return true;
    }

    return (
        <SectionMeta>
            {/* Slider */}    
            {slider.status && slider.active == 'comment' ? 
            <Edit_Element_Comments 
                onClick={() => open_close_slider('row')} 
                viewMode={props.viewMode}
                comment={comments[lenOfComments-1]}
                onTitleEdit={(e) => props.onTitleEdit(e, comments[lenOfComments-1])}
                onTextEdit={(e) => props.onTextEdit(e, comments[lenOfComments-1])}
                onImageEdit={(comment) => props.onImageEdit(comment)}
            /> : null}
            {/* TODO: This is the Media slider <Edit_Media /> */}
            {slider.status && slider.active == 'media' && (
            <Edit_Media
                viewMode={props.viewMode}
                row={media[media.length-1]}
                onClick={() => open_close_slider('row')} 
                onTitleEdit={(e) => props.onTitleEdit(e, media[media.length-1])}
                onGridEdit={(gridValue) => props.onGridEdit(gridValue, media[media.length-1])}
                availableMedia={props?.availableMedia}
                onSelectImage={(imgName) => props.onSelectImage(imgName, media[media.length-1])}
                removeMedia={(imgName) => props.removeMedia(imgName)}
                addMedia={(imgName) => props.addMedia(imgName)}
            />     
            )}
            {slider.status && slider.active == 'static' ? 
            <Add_Meta_Row_Static 
                errors={props.errors} 
                onClick={() => open_close_slider('')} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                elements={props.staticRowBlocks} 
                allElements={props.allStaticBlocks} 
                addBlock={(item) => props.onAddBlock(item, 'static')} 
                removeBlock={(item) => props.onRemoveBlock(item, 'static')} 
                // compare={props.compare} 
                // toggleCompareOption={(action) => props.toggleOption('compare', action, 'static')} 
                setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                    props.onSetBlock(originalName, value, property, sectionType, sectionId, rowType);
                }}
                showCurrency={props.showCurrency}
                loader={props.dataLoader}

            /> : null}
            {slider.status && slider.active == 'row'  ? 
            <Add_Meta_Row 
                onClick={() => open_close_slider('')} 
                sectionRows={props.sectionRows} 
                hasDemographics={checkDemographics(props.sectionSource, props.sectionType)}
                hasGraphical={checkGraphical(props.sectionSource, props.sectionType)}
                isStaticAdded={props.sectionRows?.some((obj) => obj.type === 'static')} 
                isGraphicalAdded={props.sectionRows?.some((obj) => obj.type === 'graphical')} 
                isDemographicsAdded={props.sectionRows?.some((obj) => obj.type === 'demographics')} 
                onAdd={(rowType) => props.onAddRow(rowType)} 
                onRemove={(rowType) => props.onRemoveRow(rowType)} 
                onAddSlider={(type, item) => {
                    open_close_slider(type, item)
                }} 
                sliderFlowHelper={props.sliderFlowHelper}
                onAddComment={() => open_close_slider('comment')}
                onAddMedia={() => open_close_slider('media')}

                
            /> : null}
            {slider.status && slider.active == 'demographics' ? 
            <Add_Meta_Row_Demographics 
                errors={props.errors} 
                onClick={() => open_close_slider('')} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                reportElements={props.demographicsRowBlocks} 
                allDemographicAvailable={props.allDemographicAvailable}
                onAddDemographic={(item) => {
                    open_close_slider('choose-demographic-chart', item)
                }} 
                onRemoveDemographic={(item) => props.onRemoveDemographic(item)} 
                loader={props.dataLoader}

            /> : null}
            {slider.status && slider.active == 'choose-demographic-chart' && (
            <Add_Meta_Row_Demographics_Charts
                onClick={() => open_close_slider('')} 
                onBack={() => open_close_slider('demographics')}
                kpi={slider?.item}
                elements={props.demographicsRowBlocks} 
                genderDemographicAvailable={props.genderDemographicAvailable}
                countryDemographicAvailable={props.countryDemographicAvailable}
                ageDemographicAvailable={props.ageDemographicAvailable}
                onAddDemographic={(chartType) => props.onAddDemographicChart(slider?.item, chartType)} 
                onRemoveDemographic={(chartType) => props.onRemoveDemographicChart(slider?.item, chartType)} 
            />
            )}
            {slider.status && slider.active == 'graphical' ? 
            <Add_Meta_Row_Graphical 
                errors={props.errors} 
                onClick={() => open_close_slider('')} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                elements={props.graphicalRowBlocks} 
                allElements={props.allGraphicalBlocks} 
                addBlock={(item) => props.onAddBlock(item, 'graphical')} 
                removeBlock={(item) => props.onRemoveBlock(item, 'graphical')} 
                // compare={props.compare} 
                // toggleCompareOption={(action) => props.toggleOption('compare', action, 'static')} 
                setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                    props.onSetBlock(originalName, value, property, sectionType, sectionId, rowType);
                }}
                showCurrency={props.showCurrency}
                loader={props.dataLoader}

            /> : null}
            {/* End of Slider */}

            <SectionMetaWrapper hasSource={props.hasSource}>
                <Creator_Title_Section campaigns={props.campaigns} img={matchmakeLogo(props.sectionSource, props.sectionType)} viewMode={props.viewMode} title={props.sectionName} onEdit={() => props.onEditSection()} onDelete={props.onRemoveSection} />
                {props.sectionRows?.map((row, index) => {
                    switch (row.type) {
                        case 'static': {
                            return <Row_Static 
                                key={index}
                                compare={row.isCompared}
                                sliderFlowHelper={props.sliderFlowHelper}
                                viewMode={props.viewMode}
                                blocks={props.staticRowBlocks}
                                currency={props.currency}
                                sectionName={props.sectionName}
                                sectionType={props.sectionType}
                                sectionId={props.sectionId}
                                rowTitle={props.staticRowTitle}
                                rowType="static"
                                titlePlaceholder={props.staticTitlePlaceholder}
                                valuePlaceholder={props.staticValuePlaceholder}
                                comparedValuePlaceholder={props.comparedValuePlaceholder}
                                setBlock={(element, value, property, sectionType, sectionId, rowType) => {
                                    props.onSetBlock(element, value, property, sectionType, sectionId, rowType);
                                }}
                                onRemoveBlock={(item, type) => props.onRemoveBlock(item, type)}
                                onOpenRowElement={() => open_close_slider('static')}
                                onBack={() => open_close_slider()}
                                onEdit={() => props.onEditRow(row)}
                                onDelete={() => props.onRemoveRow(row.type, row.position)}
                                />
                            
                        }
                        case 'demographics': {
                            return <Row_Demograph 
                                key={index}
                                viewMode={props.viewMode}
                                sectionName={props.sectionName}
                                sectionType={props.sectionType}
                                sectionId={props.sectionId}
                                rowTitle={props.demographicsRowTitle}
                                rowType="demographics"
                                blocks={props.demographicsRowBlocks}
                                genderDemographicAvailable={props.genderDemographicAvailable}
                                countryDemographicAvailable={props.countryDemographicAvailable}
                                ageDemographicAvailable={props.ageDemographicAvailable}
                                onAddDemographic={(chartType) => props.onAddDemographicChart(slider?.item, chartType)} 
                                onRemoveDemographic={(item, chartType) => props.onRemoveDemographicChart(item, chartType)} 
                                onRemoveBlock={(item, type) => props.onRemoveBlock(item, type)}
                                setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                    props.onSetBlock(originalName, value, property, sectionType, sectionId, rowType);
                                }}
                                onEdit={() => props.onEditRow(row)}
                                onDelete={() => props.onRemoveRow(row.type, row.position)}
                                onOpenRowElement={() => open_close_slider('demographics')}
                            />;
                        }
                        case 'comment': {
                            return <Row_Comments 
                                key={index}
                                viewMode={props.viewMode} 
                                title={row.title ? row.title : 'Comment'} 
                                text={row.text} 
                                onDeleteComment={() => props.onRemoveComment('comment', row.position)} 
                                onEditComment={() => props.onUpdateComment(row)} 
                                />;
                        }
                        case 'media': {
                            return (<Row_Media
                                key={index}
                                viewMode={props.viewMode} 
                                title={row.title}
                                image={row?.image} 
                                grid={row?.grid}
                                onRemoveMedia={() => props.onRemoveRow('media', row.position)}
                                onEditMedia={() => props.onEditMedia(row)} 
                                pdf={props.pdf}

                            />)
                        }
                        case 'graphical' : {
                            return <Row_Graph 
                                key={index}
                                viewMode={props.viewMode} 
                                compare={row.isCompared}
                                sliderFlowHelper={props.sliderFlowHelper}
                                blocks={props.graphicalRowBlocks}
                                currency={props.currency}
                                sectionName={props.sectionName}
                                sectionType={props.sectionType}
                                sectionId={props.sectionId}
                                rowTitle={props.graphicalRowTitle}
                                rowType="graphical"
                                titlePlaceholder={props.staticTitlePlaceholder}
                                valuePlaceholder={props.staticValuePlaceholder}
                                comparedValuePlaceholder={props.comparedValuePlaceholder}
                                setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                    props.onSetBlock(originalName, value, property, sectionType, sectionId, rowType);
                                }}
                                onRemoveBlock={(item, type) => props.onRemoveBlock(item, type)}
                                onEdit={() => props.onEditRow(row)}
                                onDelete={() => props.onRemoveRow(row.type, row.position)}
                                onBack={() => open_close_slider()}
                                onOpenRowElement={() => open_close_slider('graphical')}
                            />
                        }
                    }
                })}   
                   
                <Creator_Plus viewMode={props.viewMode} onClick={() => open_close_slider('row')} title={t('Section Row')} text={t('Effortlessly integrate vital components like numeric KPIs, graphs or comments. This tool simplifies enriching your section with critical data and personalized insights, tailored to each section.')} />
            </SectionMetaWrapper>
        </SectionMeta>
    );
}
