import { Card, Cta_, device } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const PricingHeader = styled(Card)`
    margin: 2rem 4rem;
    padding: 10rem 4rem;
    background: linear-gradient(to bottom right, var(--focus), var(--gloomDark), var(--gloom), var(--mainDark));

    h1{
        color: var(--white);
        text-align: center;

        span{
            display: block;
        }

        .Early{
            font-size: 8rem;
        }

        .Sale{
            font-size: 5rem;
            color: var(--focus);
        }

        .Only{
            font-size: 2rem;
            font-family: 'fontLight', sans-serif;
        }

        .Price{
            font-size: 10rem;
            color: var(--focus);

            span{
                display: inline-block;
                font-size: 3rem;
            }
        }

        button{
            margin: 0 auto 2rem auto;
        }

        .Forever{
            font-size: 2rem;
            font-family: 'fontLight', sans-serif;
            margin-bottom: 2rem;
        }

        .Terms{
            font-size: 1.4rem;
            font-family: 'fontLight', sans-serif;
        }
    }

    @media ${device.md} {

        .Early{
            font-size: 5rem !important;
        }
    }

    @media ${device.sm} {
        padding: 5rem 2rem;
        margin: 2rem;

        .Early{
            font-size: 3.5rem !important;
        }
    }
`

export default function Pricing_Header(props) {

    const {t} = useTranslation();

  return (
    <PricingHeader>
        <h1>
            <span className='Early'>{t("Early Adopters Sale")}</span>
            <span className='Only'>{t("only for")}</span>
            <span className='Price'>€9<span>,90</span></span>
            <Cta_ size="large" text={t("Unlock All Adsnap's Features")} onClick={props.onBuy}/>
            <span className='Forever'>{t("per month, forever")}</span>
            <span className='Terms'>*{t("Offer valid for a limited period. Terms and conditions apply.")}</span>
        </h1>
    </PricingHeader>
  )
}
