import { Builder_, CardsSixty, Checkbox_, Google_Search, Input_, On_Stat, Section_Title, Slider_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card_On_Element } from '../card/Card_On_Element';

export default function Add_Meta_Row(props) {
    console.log(props);

    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Section Rows')}>
            {(!props.isStaticAdded || (!props.isGraphicalAdded && props.hasGraphical)|| (!props.isDemographicsAdded && props.hasDemographics)) ?
            <>
            <Section_Title text={t('Indicators')} />
            <CardsSixty>
                {!props.isStaticAdded ? 
                <Card_On_Element 
                    toggle 
                    title={t('Static')} 
                    text={t('This function allows for the inclusion of fixed numerical values, providing clarity and accuracy in showcasing essential metrics and statistics.')} 
                    onClick={() => {
                        /* bill: maybe find a way to merge those lines + remove log('remove') */
                        !props.isStaticAdded ? props.onAddSlider('static') : console.log('remove');
                        !props.isStaticAdded ? props.onAdd('static') : props.onRemove('static');
                    }} 
                    active={props.isStaticAdded} 
                /> 
                : null
                }
                {!props.isGraphicalAdded && props.hasGraphical?
                <Card_On_Element 
                    toggle 
                    title={t('Graphical')} 
                    text={t('This feature allows you to insert engaging, easy-to-interpret graphic elements that vividly convey key numerical insights and trends.')} 
                    onClick={() => {
                        !props.isGraphicalAdded ? props.onAddSlider('graphical') : console.log('remove');
                        !props.isGraphicalAdded ? props.onAdd('graphical') : props.onRemove('graphical');
                    }} 
                    active={props.isGraphicalAdded} 
                />
                : null
                }
                { props.hasDemographics && !props.isDemographicsAdded ? 
                <Card_On_Element 
                    toggle 
                    title={t('Demographs')} 
                    text={t('This feature enables you to present rich, insightful demographic breakdowns, such as age, gender, location, and interests, enhancing the depth and relevance of your analysis.')} 
                    onClick={() => {
                        !props.isDemographicsAdded ? props.onAddSlider('demographics') : console.log('remove');
                        !props.isDemographicsAdded ? props.onAdd('demographics') : props.onRemove('demographics');
                    }} 
                    active={props.isDemographicsAdded} /> 
                : null }
            </CardsSixty> 
             </>
            : null }
            <Section_Title text={t('Extra')} />
            <CardsSixty>
                <Card_On_Element title={t('Comment')} text={t("This action allows you to insert comments, providing valuable context or suggestions alongside the report data. It's an essential tool for interactive communication and annotation within the report.")} add={t('Add')} onClick={() => {props.onAdd('comment'); props.onAddComment();}} />
                <Card_On_Element title={t('Media')} text={t("This action allows you to insert images, in order to fully customize your report.")} add={t('Add')} onClick={() => {props.onAdd('media'); props.onAddMedia(); }} />
            </CardsSixty>
        </Slider_>
    );
}