import React, { useContext, useEffect, useState } from 'react';
import Report_Top from './Report_Top';
import Report_Title from './Report_Title';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Card_On_Element } from '../card/Card_On_Element';
import { useTranslation } from 'react-i18next';
import idTypeHelperJson from '../../data/id-type-helper.json';
import Splitter_Section_Text from '../section/Splitter_Text';
import Splitter_Section_Line from '../section/Section_Line';
import Section_Meta from '../section/Section_Meta';
import Creator_Plus from '../creator/Creator_Plus';





const ReportData = styled.div`
    padding-bottom: ${p => p.viewMode ? '2rem' : '0'};
    background: ${(p) => p.presetStyle?.color?.background};
    position: relative;
    width: 100%;

    .ReportProtectiveGlass{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 20;
    }
`;


export default function Report_Data(props) {
    
    const { reportStyle } = useContext(ReportStyleContext);
    const { t } = useTranslation(); 
    const [localSection, setLocalSection] = useState(props.sections);

    useEffect(() => {
        setLocalSection(props.sections)
    }, [props.sections]);
    


    return (
        <ReportData key={"report_data"} className={props.className} presetStyle={reportStyle} viewMode={props.viewMode} id={props.id}>
            {/* {props.viewMode ? <div className='ReportProtectiveGlass'></div> : null} */}
            <Report_Top pdf={props.pdf} onDates={props.onDates} onLogo={props.onLogo} brand={props.brand} dateFrom={props.dateFrom} dateTo={props.dateTo} viewMode={props.viewMode} />
            <Report_Title title={props.title} onClick={props.onClickTitle} viewMode={props.viewMode} />
            
            { !(localSection)?.some((section) => section.type === "overview") ? 
                <Card_On_Element 
                    onClick={props.onClickCardOnElement}
                    viewMode={props.viewMode} 
                    onReport 
                    toggle
                    title={t('Overview Section')} 
                    text={t('A brief yet informative summary. It covers key metrics like Reporting Duration and Total Ad Spend, offering essential insights into campaign performance in a concise format.')} 
                /> 
                : null 
            }
             {localSection
                ?.filter((s) => s.source === 'meta' || s.source === 'graphs' || s.type === 'overview' || s.source === "google")
                ?.map((section, index) => {

                    const sectionType = section.type;

                    let idTypeHelper = idTypeHelperJson[sectionType];

                    if (sectionType === 'splitter-text') {
                        // Splitter Text
                        return <Splitter_Section_Text 
                                    text={section.sectionTitle} 
                                    onDelete={(section, sectionType) => {props.onDeleteSplitterSection(section, sectionType)}}
                                    onEdit={(section) => props.onEditSplitterSection(section)} 
                                    viewMode={props.viewMode} 
                                />;
                    }
                    if (sectionType === 'splitter-line') {
                        // Splitter Line
                        return <Splitter_Section_Line 
                                    onDelete={(section, sectionType) => {props.onDeleteSplitterSection(section, sectionType)}}
                                    viewMode={props.viewMode} 
                                />;
                    }

                    let sectionId = section.sectionData?.find((obj) => obj?.type === idTypeHelper)?.id;
                    const sectionTitle = section.sectionTitle;
                    const sortedSectionRows = section.rows.sort((a, b) => a.position - b.position);

                    const staticRow = section?.rows?.find((row) => row.type === 'static');
                    const staticRowTitle = staticRow?.rowTitle;
                    const staticRowBlocks = staticRow?.elements;
                    const allStaticBlocks = props.creatorSectionElements?.find((obj) => obj.source === section.source && obj.type === sectionType && obj.row === 'static' && obj.id === sectionId )?.kpis;

                    const graphicalRow = section?.rows?.find((row) => row.type === 'graphical');
                    const graphicalRowTitle = graphicalRow?.rowTitle;
                    const graphicalRowBlocks = graphicalRow?.elements;
                    const allGraphicalBlocks = props.creatorSectionElements?.find((obj) => obj.type === sectionType && obj.row === 'graphical' && obj.id === sectionId )?.kpis;

                    const demographicsRow = section?.rows?.find((row) => row.type === 'demographics');
                    const demographicsRowTitle = demographicsRow?.rowTitle;
                    const demographicsRowBlocks = demographicsRow?.elements;
                    const allDemographicsBlocks = props.creatorSectionElements?.find((obj) => obj.type === sectionType && obj.row === 'demographics' && obj.id === sectionId )?.kpis
                    const demographicAvailableKpis = allDemographicsBlocks?.find((item) => item.type === "available")?.kpis;
                    const allDemographicAvailable = demographicAvailableKpis?.find((item) => item.type === "uniqueAll")?.kpis;
                    const genderDemographicAvailable = demographicAvailableKpis?.find((item) => item.type === "gendersAvailable")?.kpis;
                    const countryDemographicAvailable = demographicAvailableKpis?.find((item) => item.type === "countriesAvailable")?.kpis;
                    const ageDemographicAvailable = demographicAvailableKpis?.find((item) => item.type === "agesAvailable")?.kpis;

                    let stillSourceless = (sectionTitle === 'Ad Account' && sectionType === 'paid') || (sectionTitle === 'Facebook page' && sectionType === 'facebook') || (sectionTitle === 'Instagram page' && sectionType === 'instagram');
                    stillSourceless = false;
                    // find how many comment there are in the section, so you can compute the position of the next
                    const indexOfNextComment = (section.rows.filter((obj) => obj.type === 'comment')).length;

                    const sectionCampaigns = section?.campaigns;

                    return (
                        <Section_Meta
                            key={index}
                            pdf={props.pdf}
                            currency={props.currency}
                            errors={props.errors}
                            viewMode={props.viewMode}
                            sliderFlowHelper={props.sliderFlowHelper}
                            hasSource={!stillSourceless}
                            // section props
                            sectionName={sectionTitle}
                            sectionType={sectionType}
                            sectionId={sectionId}
                            sectionSource={section.source}
                            // rows
                            sectionRows={sortedSectionRows}
                            // static row
                            staticRowTitle={staticRowTitle}
                            staticRowBlocks={staticRowBlocks}
                            allStaticBlocks={allStaticBlocks}
                            // graphic row
                            graphicalRowTitle={graphicalRowTitle}
                            graphicalRowBlocks={graphicalRowBlocks}
                            allGraphicalBlocks={allGraphicalBlocks}
                            // demographic row
                            demographicsRowTitle={demographicsRowTitle}
                            demographicsRowBlocks={demographicsRowBlocks}
                            allDemographicAvailable={allDemographicAvailable}
                            genderDemographicAvailable={genderDemographicAvailable}
                            countryDemographicAvailable={countryDemographicAvailable}
                            ageDemographicAvailable={ageDemographicAvailable}

                            indexOfNextComment={indexOfNextComment}
                            campaigns={sectionCampaigns}

                            staticTitlePlaceholder={t('KPI name')}
                            staticValuePlaceholder={t('KPI value')}
                            comparedValuePlaceholder={t('KPI compared value')}
                            compare={props.compare}
                            selectSource={(sliderName, info) => props.selectSource(sliderName, section, info)}
                            removeSectionBeforeSourceSelection={() => props.removeSectionBeforeSourceSelection(section)}
                            onSetBlock={(element, value, property, sectionType, sectionId, rowType) => props.onSetBlock(element, value, property, sectionType, sectionId, rowType, idTypeHelper)}
                            onAddBlock={(block, rowType) => props.onAddBlock(block, sectionType, sectionId, rowType, idTypeHelper, section.position)}
                            onRemoveBlock={(block, rowType) => props.onRemoveBlock(block, sectionType, sectionId, rowType, idTypeHelper, section.position)}
                            onAddRow={(rowType) => props.onAddRow(rowType, sectionType, sectionId, idTypeHelper, section.position)}
                            onRemoveRow={(rowType, rowPosition) => props.onRemoveRow(rowType, sectionType, sectionId, idTypeHelper, rowPosition, section.position)}
                            onRemoveSection={() => props.onRemoveSection(sectionType, sectionId, idTypeHelper, section.position )}
                            onRemoveDemographic={(item) => props.onRemoveDemographic(item, sectionType, sectionId, idTypeHelper)}
                            onAddDemographicChart={(item, chartType) => props.onAddDemographicChart(item, chartType, sectionType, sectionId, idTypeHelper)}
                            onRemoveDemographicChart={(item, chartType) => props.onRemoveDemographicChart(item, chartType, sectionType, sectionId, idTypeHelper)}
                            onUpdateComment={(row) => props.onUpdateComment(row, sectionType, sectionId, idTypeHelper, section.position)}
                            onRemoveComment={(rowType, rowPosition) => props.onRemoveComment(rowType, sectionType, sectionId, idTypeHelper, rowPosition, section.position)}
                            onEditSection={() => props.onEditSection(section, idTypeHelper)}
                            onEditRow={(row) => props.onEditRow(section, idTypeHelper, row, sectionType, sectionId)}
                            onTitleEdit={(e, comment) => props.onTitleEdit(e, sectionType, sectionId, idTypeHelper, comment, section.position)}
                            onTextEdit={(e, comment) => props.onTextEdit(e, sectionType, sectionId, idTypeHelper, comment, section.position)}
                            onImageEdit={(path, preview, comment) => props.onImageEdit(path, preview, sectionType, sectionId, idTypeHelper, comment)}
                            onClickCreatorPlus={props.onClickCreatorPlus}
                            setSliderShowHelper={props.setSliderShowHelper}

                            //media
                            onEditMedia={(row) => props.onEditMedia(row, sectionType, sectionId, idTypeHelper, section.position)}
                            availableMedia={props.availableMedia}
                            onGridEdit={(gridValue, row) => props.onGridEdit(gridValue, sectionType, sectionId, idTypeHelper, row, section.position)}
                            onSelectImage={(imgName, row) => props.onSelectImage(imgName, sectionType, sectionId, idTypeHelper, row, section.position)}
                            removeMedia={(imgName) => props.removeMedia(imgName)}
                            addMedia={(imgName) => props.addMedia(imgName)}

                            dataLoader={props.dataLoader}
                            />  
                    )
            })}
             
            <Creator_Plus 
                viewMode={props.viewMode} 
                title={t('Add Source Section')} 
                text={t('Instantly incorporate key sources like Meta Ads, Facebook, Instagram and Google Ads into your report. This button also allows you to enhance your report with modules, gaps, and lines for a comprehensive and well-structured presentation.')} 
                onClick={props.onClickCreatorPlus} 
            />

        </ReportData>
    );
}