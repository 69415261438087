import { Builder_, CardsTools, Input_, On_Click_Card, PageTitle, PageTools, Slider_ } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { findMissing, getTranslations, updateTranslations } from '../../../services/api/Translations';
import Toolbar_ from '../../partials/navigation/Toolbar_';

import _ from 'lodash';
import { Keymaker_ } from '../../partials/keymaker/Keymaker_';

export default function TRANSLATIONS() {

  
  return (
    <PageTools>
      <Toolbar_/>
      <PageTitle>
        <h1>Available languages</h1>
      </PageTitle>

      <Keymaker_ />
      
    </PageTools>
  )
}
