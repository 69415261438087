import React from 'react'
import styled from 'styled-components'
import { Card_On_Element } from '../../../sources/meta/pages/creator/components/card/Card_On_Element'
import Card_On_Source from '../../../sources/meta/pages/creator/components/card/Card_On_Source'
import { H2, H3, P } from '../../../styles/components/LandingText'
import { device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const LandingFor = styled.section`

`

const LandingForH3 = styled(H3)`
  margin: 4rem 0 2rem 0;
  color: var(--greyDark) !important;
`

const LandingForGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;

    @media ${device.lg} {
      grid-template-columns: 1fr;
    }
`

export default function Landing_For(props) {

  const {t} = useTranslation()

    const landingReport = {
        title: "Reach",
        value: '15',
        sliderFlowHelper: {
          // Additional for slider-to-slider ux implementation.
          shouldOpen: false, // Should the slider at "openSlider" open?
          openSlider: "", // Which slider to open
          previousSlider: "", // Which slider was the previous one? Could help
          metadata: {}, // Assign data that you want to pass that will help with logic.
          closeNested: false, // May be helpful
          openNested: false, // May be helpful
      },
    }

  return (
    <LandingFor>
        <H2>{t("Discover the simplest solution for all your reporting requirements")}</H2>
        <P>{t("Adsnap is a user-friendly and automated marketing reporting tool designed to significantly reduce your workload, allowing you to craft stunning reports effortlessly and quickly. Discover how Adsnap can benefit your business by choosing the category that aligns best with your needs.")}</P>
        <LandingForH3>{t("Can you relate?")}</LandingForH3>
        <LandingForGrid>
            <Card_On_Source firstColor="var(--focus)" secondColor="var(--main)" title={t("Freelancer")} text={t("As a freelancer, Adsnap will be your most valuable asset. Our tool in less than a minute gathers and analyzes data from Meta & Google. You can customize all the KPIs even the dynamic ones! Add manually your custom KPIs, add screenshots to your Reports and offcourse white label all the report! Last but not Least import Multiple Meta Ad Accounts & Google Ads in one report. Create detailed facebook and Google ads reports.Showcase easy-to-understand reports for Instagram and Facebook organic pages.")} textBtn={t("Start 15-day trial")} onClick={props.onBuy} />
            <Card_On_Source firstColor="var(--mainLight)" secondColor="var(--mainDark)" title={t("Small Agencies")} text={t("Adsnap is the ideal facebook and Google ads reporting tool for generating internally a multi Ad Account report for your team leader or Senior Digital marketer. Share insights and reports daily within the team to ensure that goals are achieved. Manage multiple clients' data seamlessly in one report. Facebook and Googleads reporting for multiple clients in one report is finally made easy. Another advantage of Adsnap for small agencies is that with a single pro subscription you can generate unlimited white label reports for all Meta Ad and Google Ad Accounts and Businesses.Integrate as many Business Managers & Meta Sources you want.")} textBtn={t("Start 15-day trial")} onClick={props.onBuy} />
            <Card_On_Source firstColor="var(--mainDark)" secondColor="var(--focus)" title={t("Small Businesses")} text={t("Even if you are not a marketer, Adsnap helps you check if your Social media & Digital marketing efforts are working properly. In just three clicks generate a report with in-depth analytics that will help your business make informed decisions about their digital marketingon Facebook, Instagram and Google Ads. Easily track the return on investment of your digital marketing campaigns. This helps in adapting strategies in real-time, keeping your business competitive.")} textBtn={t("Start 15-day trail")} onClick={props.onBuy} />
        </LandingForGrid>
    </LandingFor>
  )
}
