import { Grey_Link, Main_, Main_Plain, Red_, Section_Title } from 'monica-alexandria';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Snippet_Horizontal_Actions } from '../snippets/Snippet_Horizontal_Actions';

const CreatorTitleSection = styled.div`
    display: grid;
    align-items: flex-start;
    grid-template-columns: ${(p) => (p.viewMode ? '1fr' : 'auto 1fr')};
    margin-bottom: 1rem;
    margin-top: 2rem;
    gap: 1rem;

    .Actions{
        margin-top: 10px;
    }
`;

const CreatorTitleSectionText = styled.div`
    padding: 1rem 2rem 1rem 1rem;
    background: ${(p) => p.presetStyle?.color.grey};
    border-radius: ${(p) => p.presetStyle?.radius};

    ul{
        position: relative;
        margin-left: 1rem;

        aside{
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            background: ${(p) => p.presetStyle?.color.textDark};
            height: calc(100% - 9px);
        }

        li{
            display: flex;
            align-items: flex-start;
            gap: .5rem;

            h6 {
                color: ${(p) => p.presetStyle?.color.textDark};
                font-family: ${(p) => p.presetStyle?.font.family};
            }
        }
    }
`;

const CreatorTitleSectionTop = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    h5 {
        color: ${(p) => p.presetStyle?.color.textDark};
        font-family: ${(p) => p.presetStyle?.font.family};
    }

    img {
        height: 1.7rem;
    }
`

const CreatorTitleSectionTree = styled.div`
    width: 1rem;
    height: 1px;
    background: ${(p) => p.presetStyle?.color.textDark};
    margin-top: 8px;
`

export default function Creator_Title_Section(props) {
    
    const { reportStyle } = useContext(ReportStyleContext);

    return (
        <CreatorTitleSection viewMode={props.viewMode}>
            <Snippet_Horizontal_Actions className="Actions" hideEdit={props.hideEdit} viewMode={props.viewMode} onEdit={props.onEdit} onDelete={props.onDelete} />
            <CreatorTitleSectionText presetStyle={reportStyle}>
                <CreatorTitleSectionTop presetStyle={reportStyle}>
                    {props.img ? <img src={props.img} /> : null}
                    <h5>{props.title}</h5>
                </CreatorTitleSectionTop>
                {props.campaigns && props.campaigns.some(campaign => campaign.name) ? 
                <ul>
                    <aside></aside>
                    <main>
                    {props.campaigns?.map((campaign, index) => (
                                <li key={index}>
                                    <CreatorTitleSectionTree presetStyle={reportStyle}></CreatorTitleSectionTree>
                                    <h6>{campaign.name}</h6>
                                </li>
                            ))}
                    </main>
                </ul>
                :
                    null
                }
            </CreatorTitleSectionText>
        </CreatorTitleSection>
    );
}
