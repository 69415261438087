import axios from "axios"
import { config } from "../../../../config"

const create_report = (reportData) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/createReport`, {
            reportData: reportData
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const createReport = (reportData) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/createReport`, {
            reportData: reportData
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_report = (id) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getReport?id=${id}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const getReport = (id) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getReport?id=${id}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const delete_report = (id, userId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/deleteReport`, {data: {id, userId, source: "meta"}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const deleteReport = (id) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/deleteReport`, {reportId: id})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



// By userId - account pair
const get_reports = (userId, accountId) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getReports?userId=${userId}&accountId=${accountId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetch_last_templates = (userId, account) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getLastTemplates?userId=${userId}&account=${account}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_organic_reports = (accName, accType) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getOrganicReports?&accountName=${accName}&accountType=${accType}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const update_report = (reportData, id) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/updateReport`, {
            reportData: reportData,
            id: id
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const updateReport = (reportId, reportData) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/meta/reports/updateReport`, {
            reportData: reportData,
            reportId: reportId,
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const getReports = () =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getReports`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const getNumOfReports = () =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getNumberOfReports`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const getDoitReports = () =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/reports/getDoitReports`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

export {
    create_report,
    createReport,
    update_report,
    updateReport,
    get_report,
    getReport,
    getReports,
    delete_report,
    deleteReport,
    get_reports,
    fetch_last_templates,
    get_organic_reports,
    getNumOfReports,
    getDoitReports,
}