import { deepClone } from "../../../../../helpers/DeepCloneObject";
import { get_campaigns } from "../../../services/api/MetaApi";
import { getClientCampaigns } from "../../../../google/services/api/GoogleApi";


export const update_section_name = (creator, setCreator, section, name, idTypeHelper) => {
    let updatedReportSections = deepClone(creator.report?.sections);

    updatedReportSections
        .find((s) => s.type === section.type && s.source === section.source && s?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id === section?.sectionData?.find((sd) => sd.type === idTypeHelper)?.id)
        .sectionTitle = name;

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}

export const remove_section_from_report = (creator, setCreator, sectionType, sectionId, idTypeHelper) => {

    let updatedReportSections = deepClone(creator.report.sections);

    updatedReportSections = updatedReportSections
        .filter((obj) => 
            !(
                obj.type === sectionType && 
                obj.sectionData?.find((sd) => sd.type === idTypeHelper).id === sectionId
            )
        )

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
        restructureSectionElements: true,
        shouldFetch: false
    }))
}

/* Add and Remove function are responsible to add/remove a section from report's sections */
export const handle_section_change = async (creator, setCreator, action, section, sectionType, sectionSource, accessedAccounts) => {
    const reportSections = deepClone(creator.report.sections);
    let updatedSectionElements = deepClone(creator.sectionElements);
    var updatedReportSections = [];
    let metaHelper = creator?.metaHelper;

    if (sectionType === "overview") {
        if (action==="add") {
            const {updatedSections, newSectionElement} = add_overview(reportSections, section)
            updatedReportSections = updatedSections;
            if ( !updatedSectionElements.some((section) => section.type === "overview") ) updatedSectionElements.push(newSectionElement);
        }
        if (action==="remove") updatedReportSections = remove_overview(reportSections);
    }

    switch ( sectionSource ) {
        case ("meta"): {
            switch (sectionType) {
                case ("paid"): {
                    if (action==="add") {
                        let campaigns;
                        let allFetchedCampaigns = creator.metaHelper?.campaigns;
                        const parentBusiness = metaHelper?.selectedBusiness
                        updatedReportSections = add_meta_paid(reportSections, section, accessedAccounts, parentBusiness);
                        
                        setCreator((prev) => ({
                            ...prev, 
                            report: {...prev.report, sections: updatedReportSections},
                            metaHelper: {
                                ...prev.metaHelper,
                                selectedAdAccount: section,
                            }
                        }));

                        if(! allFetchedCampaigns?.some((campaign) => campaign.account_id === section.account_id)) {
                            setCreator((prev) => ({
                                ...prev,
                                loaders: {
                                    ...prev.loaders,
                                    campaigns: true,
                                }
                            }))
                            var campaignsResponse = await get_campaigns(section.id, accessedAccounts.meta.accessToken);
                            
                            campaigns = [...allFetchedCampaigns, ...campaignsResponse?.data?.data?.myCampaigns || []];
                        }
                        else {
                            campaigns = allFetchedCampaigns;
                        }

                        setCreator((prev) => ({
                            ...prev,
                            loaders: {
                                ...prev.loaders,
                                campaigns: false,
                            },
                            metaHelper: {
                                ...prev.metaHelper,
                                campaigns: campaigns,
                            }
                        }));
                    }
                    if (action==="remove") { 
                        updatedReportSections = remove_meta_paid(reportSections, section);
                        setCreator((prev) => ({
                            ...prev, 
                            metaHelper: {
                                ...prev.metaHelper,
                                selectedAdAccount: null,
                            }
                        }));
                    }
                    
                    break;
                }
                case ("facebook"): {
                    if (action==="add") updatedReportSections = add_facebook(reportSections, section);
                    if (action==="remove") updatedReportSections = remove_facebook(reportSections, section);
                    break;
                }
                case ("instagram"): {
                    if (action==="add") updatedReportSections = add_instagram(reportSections, section);
                    if (action==="remove") updatedReportSections = remove_instagram(reportSections, section);
                    break;
                }
            }
            break;
        }
        case ("google"): {
            if(action === "add") {
                const parentManager = creator?.googleHelper?.selectedManager;
                updatedReportSections = add_google(reportSections, section, accessedAccounts, parentManager);
                let campaigns;
                let allFetchedCampaigns = creator.googleHelper?.campaigns;

                setCreator((prev) => ({
                    ...prev, 
                    report: {...prev.report, sections: updatedReportSections},
                    googleHelper: {
                        ...prev.googleHelper,
                        selectedAdAccount: section,
                    }
                }));

                if(! allFetchedCampaigns?.some((campaign) => campaign.customerId === section.id)) {
                    setCreator((prev) => ({
                        ...prev,
                        loaders: {
                            ...prev.loaders,
                            googleCampaignsLoader: true,
                        }
                    }))
                    var campaignsResponse = await getClientCampaigns(section.id, parentManager?.id, accessedAccounts.google.accessToken, accessedAccounts.google.userId);
                    campaigns = [...allFetchedCampaigns, ...campaignsResponse?.data?.data?.clientCampaigns || []];
                }
                else {
                    campaigns = allFetchedCampaigns;
                }

                setCreator((prev) => ({
                    ...prev,
                    loaders: {
                        ...prev.loaders,
                        googleCampaignsLoader: false,
                    },
                    googleHelper: {
                        ...prev.googleHelper,
                        campaigns: campaigns,
                    }
                }));
            }
            if(action === "remove") {
                updatedReportSections = remove_google(reportSections, section);
                setCreator((prev) => ({
                    ...prev, 
                    googleHelper: {
                        ...prev.googleHelper,
                        selectedAdAccount: null,
                    }
                }));
            }
            break;
        }
        case ("graphs"): {
            switch (sectionType) {
                case ("splitter-text"): {
                    if (action==="add") updatedReportSections = add_splitter_text(reportSections);
                    if (action==="remove") updatedReportSections = remove_splitter_text(reportSections, section);
                    break;
                }
                case ("splitter-line"): {
                    if (action==="add") updatedReportSections = add_splitter_line(reportSections);
                    if (action==="remove") updatedReportSections = remove_splitter_line(reportSections, section);
                    break;
                }
            }
            break;
        }
    }

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSections},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
        sectionElements: sectionType === "overview" ? updatedSectionElements : creator?.sectionElements,
    }))

}


export const remove_emptysource_section = (creator, setCreator, section) => {

    const sectionPosition = section.position;
    let datafilledStructure = creator.presetStructureHelper?.datafilledStructure 
        ? deepClone(creator.presetStructureHelper?.datafilledStructure)
        : deepClone(creator.presetStructure);

    let reportSections =  deepClone(creator.report?.sections);

    let shouldTriggerFlow = true; 
    
    datafilledStructure.sections = datafilledStructure.sections?.filter((s) => s.position !== sectionPosition);
    reportSections = reportSections?.filter((s) => s.position !== sectionPosition);

    const emtpyAdAccount = !datafilledStructure?.sections?.some((s) => s.type === "paid");
    
    for ( const s of datafilledStructure?.sections ) {
        if ( s.type === "paid" && s.sectionTitle === "Ad Account" ) shouldTriggerFlow = false;
        if ( s.type === "facebook" && s.sectionTitle === "Facebook page" ) shouldTriggerFlow = false;
        if ( s.type === "instagram" && s.sectionTitle === "Instagram page" ) shouldTriggerFlow = false;
    }

    setCreator((prev) => ({
        ...prev,
        presetStructureHelper: {
            ...prev.presetStructureHelper,
            datafilledStructure,
            triggerPresetStructureChangeCheck: shouldTriggerFlow 
                ? !prev.presetStructureHelper.triggerPresetStructureChangeCheck 
                : prev.presetStructureHelper.triggerPresetStructureChangeCheck
        },
        report: {
            ...prev.report,
            sections: reportSections ,
        },
        metaHelper: {
            ...prev.metaHelper,
            selectedBusiness: emtpyAdAccount ? null : prev.metaHelper.selectedBusiness,
            selectedAdAccounts: emtpyAdAccount ? [] : prev.metaHelper.selectedAdAccounts,
        }
    }))
};



/* *** Helper functions for "handle_section_change" *** */
const add_overview = (reportSections, section) => {
    /* We also add static row and 2 blocks by default */
    const newMetaPaidSection = {
        // position: reportSections?.length,
        position: -1,
        rows: [
            {
                "type": "static",
                "rowTitle": "Static indicators",
                "position": 0,
                "isCompared": true,
                "elements": [
                    {
                        "position": 0,
                        "originalName": "days_reporting",
                        "givenName": "Days reporting",
                        "displayedName": "Days reporting",
                        "variant": "mainInverted",
                        "category": "General",
                        "recommended": false,
                        "value": 0,
                        "displayedValue": null
                    },
                    {
                        "position": 1,
                        "originalName": "ads_cost",
                        "givenName": "Ads cost",
                        "displayedName": "Ads cost",
                        "variant": "focus",
                        "category": "General",
                        "recommended": false,
                        "value": 0,
                        "displayedValue": null
                    },
                    {
                        "position": 2,
                        "originalName": "agency_cost",
                        "givenName": "Agency cost",
                        "displayedName": "Agency cost",
                        "variant": "main",
                        "category": "General",
                        "recommended": false,
                        "value": 0,
                        "displayedValue": null
                    }
                ]
            }
        ],
        sectionData: [
            {
                type: "overview",
                name: "Overview",
                id: "overview",
            }
        ],
        sectionTitle: section?.name,
        type: "overview",
    }
    
    const updatedReportSections = [...reportSections, newMetaPaidSection];
    const newSectionElement = {
        name: "Overview",
        type: "overview",
        id: "overview",
        row: "static",
        kpis: []
    }

    return {updatedSections: updatedReportSections, newSectionElement} ;
}

const remove_overview = (reportSections) => {
    const updatedReportSections = reportSections.filter((obj) => obj.type !== 'overview');
    return updatedReportSections
}

const add_meta_paid = (reportSections, section, accessedAccounts, parentBusiness) => {
    const newMetaPaidSection = {
        position: reportSections?.length,
        rows: [],
        sectionData: [
            {
                type: "adAccount",
                name: section?.name,
                id: section?.id,
            },
            {
                type: "facebookAccount",
                name: accessedAccounts?.meta?.username,
                id: accessedAccounts?.meta?.userId,
                accessToken: accessedAccounts?.meta?.accessToken,
            }
        ],
        sectionTitle: section?.name,
        source: "meta",
        type: "paid",
        parentBusiness 
    }
    
    const updatedReportSections = [...reportSections, newMetaPaidSection];

    return updatedReportSections;
}

const remove_meta_paid = (reportSections, section) => {
    const sectionId = section.id;
    const updatedReportSections = reportSections.filter((obj) => 
        obj.sectionData?.find(sd => sd.type === "adAccount")?.id !== sectionId
    );
    return updatedReportSections
}

const add_facebook = (reportSections, section) => {
    const newFacebookSection = {
        position: reportSections?.length,
        rows: [],
        sectionData: [{
            type: "facebookPage",
            name: section?.name,
            id: section?.id,
            accessToken: section?.access_token,
        }],
        sectionTitle: section?.name,
        source: "meta",
        type: "facebook",
    }
    
    const updatedReportSections = [...reportSections, newFacebookSection];

    return updatedReportSections;
}

const remove_facebook = (reportSections, section) => {
    const sectionId = section.id;
    const updatedReportSections = reportSections.filter((obj) => 
        obj.sectionData?.find(sd => sd.type === "facebookPage")?.id !== sectionId
    );
    return updatedReportSections
}

const add_instagram = (reportSections, section) => {
    const newInstagramSection = {
        position: reportSections?.length,
        rows: [],
        sectionData: [{
            type: "instagramPage",
            username: section?.instagram_business_account?.username,
            id: section?.instagram_business_account?.id,
            accessToken: section?.access_token,
        }],
        sectionTitle: section?.instagram_business_account?.username,
        source: "meta",
        type: "instagram",
    }
    
    const updatedReportSections = [...reportSections, newInstagramSection];

    return updatedReportSections;
}

const remove_instagram = (reportSections, section) => {
    const sectionId = section.instagram_business_account?.id;
    const updatedReportSections = reportSections.filter((obj) => 
        obj.sectionData?.find(sd => sd.type === "instagramPage")?.id !== sectionId
    );    
    return updatedReportSections
}


const add_splitter_text = (reportSections) => {
    const newSplitterTextSection = {
        position: reportSections?.length,
        sectionTitle: "",
        source: "graphs",
        type: "splitter-text",
    }
    
    const updatedReportSections = [...reportSections, newSplitterTextSection];

    return updatedReportSections;
}

const remove_splitter_text = (reportSections, section) => {
    const sectionPosition = section?.position;
    const updatedReportSections = reportSections.filter((obj) => obj.position !== sectionPosition);    
    return updatedReportSections
}




const add_splitter_line = (reportSections) => {
    const newSplitterLineSection = {
        position: reportSections?.length,
        source: "graphs",
        type: "splitter-line",
    }
    
    const updatedReportSections = [...reportSections, newSplitterLineSection];

    return updatedReportSections;
}

const remove_splitter_line = (reportSections, section) => {
    const sectionPosition = section?.position;
    const updatedReportSections = reportSections.filter((obj) => obj.position !== sectionPosition);    
    return updatedReportSections
}

const add_google = (reportSections, section, accessedAccounts, parentManager) => {
    const newGoogleSection = {
        position: reportSections?.length,
        rows: [],
        sectionData: [
            {
                type: "googleAdAccount",
                name: section?.descriptiveName,
                id: section?.id,
            },
            {
                type: "googleUser",
                name: accessedAccounts?.google?.username,
                id: accessedAccounts?.google?.userId,
                accessToken: accessedAccounts?.google?.accessToken,
            }
        ],
        sectionTitle: section?.descriptiveName,
        source: "google",
        type: "googlePaid",
        parentManager
    }
    
    const updatedReportSections = [...reportSections, newGoogleSection];

    return updatedReportSections;
}

const remove_google = (reportSections, section) => {
    const sectionId = section.id;
    const updatedReportSections = reportSections.filter((obj) => 
        obj.sectionData?.find(sd => sd.type === "googleAdAccount")?.id !== sectionId
    );
    return updatedReportSections
}