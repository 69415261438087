// This page works as an authentication toolbox and gateway between the App and the Auth Service.
// Any page that may need authentication tools or user credentials, 
// must set this page as a parent (in Router.js)

import { React, useEffect, useState, createContext } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import store from '../../redux/store'
import { expireSession, get_user_settings_from_database_and_save_user_to_cache } from './SessionService';
import { checkPermissions } from '../../helpers/checkPermissions';
import i18n from '../../localization/i18next';
import { config } from '../../config/index';
import { deleteLocalStorage } from './LocalStorageService';

const protocol = process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL;
const url = process.env.REACT_APP_ERISED_FRONTEND_HOST;
const port = process.env.REACT_APP_ERISED_FRONTEND_PORT;

const clients = require('../../config/index').config
const client = process.env.REACT_APP_CLIENT;

const authClient = process.env.REACT_APP_AUTH_CLIENT;

export const AuthContext = createContext();

////////////////////////////////////////////////////////////////////
// Performs authentication before proceeding, loads user credentials
////////////////////////////////////////////////////////////////////
export const GuardedRoute = ({children, ...props}) =>  {

    const { keycloak } = useKeycloak();
    const [ loadedUser, showSpinner] = useState({auth: false, session: false})
    const lang = i18n.language ? i18n.language : 'en';

    let currentUrl = window.location.pathname;
    let userCached = store.getState()?.user?.user;

    const generate_redirect_link = (clientName) => {
        return(`${clients[clientName]}${currentUrl}`)
    }

    // Redirects to central app for login. After login, returns to client of choice.
    // Queryparams will be forwarded to client and each client decides how to use them.
    const login = (params) => {  

        if ( params ) {
            var {clientName, redirectUri} = params;
        } else {
            var clientName, redirectUri;
        }
            
            // If no client is specified, after login we will stay on central application
        if (!clientName) clientName = authClient;
        if (!redirectUri) redirectUri = clients[authClient];

        // deletes user credentials from cache
        expireSession();
        deleteLocalStorage();

        if (window.location.href.includes('/woocommerce')) window.location.assign(`${clients[clientName]}/${lang}/auth/?redirectUri=${window.location.href}`);
        else window.location.assign(`${clients[clientName]}/${lang}/auth/?redirectUri=${config.lois}/${lang}/welcome`);
    }

    const logout = (clientName) => {

        if (!clientName) clientName = authClient;

        // deletes user credentials from cache
        expireSession();

        let logoutUrl = keycloak.createLogoutUrl({redirectUri : clients[authClient],
                                                locale: lang});
        window.location.assign(logoutUrl);
    }

    const register = () => {

        // deletes user credentials from cache
        expireSession();

        let registerUrl = keycloak.createLoginUrl({action:'register', redirectUri : generate_redirect_link('lois'),
                                                locale: lang})
        window.location.assign(registerUrl)
    }

    const check_permissions = (roles) => {

        if (!checkPermissions(props.role, roles)) {
            window.location.assign(`${clients[client]}/${lang}/access-denied`);
        }
    }

    useEffect(() => {
        if(!keycloak.authenticated && props.shouldRedirect) {
            deleteLocalStorage();
            login({clientName: authClient, redirectUri: window?.location?.href})
        }
        keycloak.loadUserInfo().then((user) => {
            if(!user.email_verified) window.location.assign(`${protocol}${url}${port}/${lang}/verify-account?redirectUrl=${generate_redirect_link('lois')}`)
            store.dispatch({type:'KEYCLOAK', payload: {user, token: keycloak.token}});
            check_permissions(user?.roles);
            get_user_settings_from_database_and_save_user_to_cache(keycloak, user)
                .then(data => showSpinner({auth: keycloak.authenticated, session: data}))
        }).catch(() => {
            expireSession();
            showSpinner({...loadedUser, session: true});
        })
    }, [])

    useEffect(() => {
        keycloak.onTokenExpired = () => {
            keycloak.updateToken()
                .then(() => store.dispatch({type:'KEYCLOAK', payload: {user: store.getState().user?.user, token: keycloak.token}}))
                .catch(err=> {})
        };
    },[keycloak])
    
    if (!loadedUser.session) return <></>
    if (props.shouldRedirect && !keycloak.authenticated) return <></>
    else return (<AuthContext.Provider value={
                                        { login: (params) => login(params),
                                        register: () => register(),
                                        logout: () => logout(),
                                        login_with_custom_parameters: (params) => login(params),
                                        authenticated: keycloak.authenticated,
                                        user: loadedUser.session}
                                        }>{children}</AuthContext.Provider>)
}


