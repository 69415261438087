import axios from "axios"
import { config } from "../../../../config"
import { reject } from "lodash"



const fbLogin = (fbResponse) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.LOIS_BACKEND_URL}/meta/api/fbLogin?`, {fbResponse})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
} 

const fbConnections = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fbConnections`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
} 

const removeFbConnection = (fbId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/deleteConnection?fbId=${fbId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
} 

const get_campaigns = (adAccountId, accessToken) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getCampaigns?adAccountId=${adAccountId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
} 

const my_businesses = (userID, accessToken) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getBusinesses?userID=${userID}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_ownded_ad_accounts = (businessId, accessToken) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getAdAccounts?businessId=${businessId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_ownded_facebook_pages = (businessId, accessToken) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getFacebookPages?businessId=${businessId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_instagram_business_accounts = (facebook_response, accessToken) =>{
    return new Promise((resolve,reject) =>{
        let fb_pages = [];
        for ( let page of facebook_response ) {
            fb_pages.push(page.id);
        }
        fb_pages.join(',');
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getInstagramBusinessAccounts?fb_pages=${fb_pages}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const getMyOrganicPages = (accessToken, pagesCursor) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getOrganicPages?&accessToken=${accessToken}&cursor=${pagesCursor}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const get_facebook_fields = (adAccountId, accessToken, fields, dates) =>{
    return new Promise((resolve,reject) =>{

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getFacebookFields?fields=${fields}&sinceDate=${dates.sinceDate}&untilDate=${dates.untilDate}&adAccountId=${adAccountId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_facebook_metric = (fbPageId, pageAccessToken, metric, period) =>{
    return new Promise((resolve,reject) =>{

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getFacebookMetric?metric=${metric}&period=${period}&fbPageId=${fbPageId}&pageAccessToken=${pageAccessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_facebook_metric_by_date = (fbPageId, pageAccessToken, metric, since, until) =>{
    return new Promise((resolve,reject) =>{

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getFacebookMetric?metric=${metric}&since=${since}&until=${until}&fbPageId=${fbPageId}&pageAccessToken=${pageAccessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_instagram_fields = (igPageId, accessToken, fields) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getInstagramFields?fields=${fields}&igPageId=${igPageId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const get_instagram_metric = (igPageId, accessToken, metric, metric_type, period, since, until) =>{
    return new Promise((resolve,reject) =>{

        let query = `igPageId=${igPageId}&`
            +`accessToken=${accessToken}&`
            +`metric=${metric}&`
            +`${metric_type ? `metric_type=${metric_type}&` : ""}`
            +`period=${period}&`
            +`${since ? `since=${since}&` : ""}`
            +`${until ? `until=${until}` : ""}`
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getInstagramMetric?${query}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const get_facebook_fields_demographics = (adAccountId, accessToken, fields, dates) =>{
    return new Promise((resolve,reject) =>{

        let query = `fields=${fields}&`
            +`sinceDate=${dates.sinceDate}&`
            +`untilDate=${dates.untilDate}&`
            +`adAccountId=${adAccountId}&`
            +`accessToken=${accessToken}`

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getFacebookFieldsDemographics?${query}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_ads_chronical = (adAccountId, accessToken, fields, dates, time_increment) =>{
    return new Promise((resolve,reject) =>{

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getAdsChronical?fields=${fields}&sinceDate=${dates.sinceDate}&untilDate=${dates.untilDate}&adAccountId=${adAccountId}&accessToken=${accessToken}&time_increment=${time_increment}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const fetchAdsKpis = (adAccountId, accessToken, sinceDate, untilDate) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            adAccountId, 
            accessToken, 
            sinceDate, 
            untilDate,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchAdsKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchAdsPages = (businessId, accessToken) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            businessId, 
            accessToken,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchAdsPages`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchFacebookPageKpis = (pageId, accessToken, since, until) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId,
            accessToken,
            since,
            until,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchFacebookPageKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchInstagramPageKpis = (pageId, accessToken, since, until) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId,
            accessToken,
            since,
            until,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchInstagramPageKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchMyBusinesses = (userID, accessToken, cursor) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchMyBusinesses?userID=${userID}&accessToken=${accessToken}&cursor=${cursor}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchMetaPaidKpis = (adAccountId, campaignsIds, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            adAccountId, 
            campaignsIds,
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchMetaPaidKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchFacebookKpis = (pageId, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId, 
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchFacebookKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const fetchInstagramKpis = (pageId, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId, 
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchInstagramKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const fetchOverviewKpis = (dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchOverviewKpis`)
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                resolve(false);
            })
    })
}

const get_ad_accounts = (businessId, accessToken) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/getAdAccounts?businessId=${businessId}&accessToken=${accessToken}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const fetchMetaPaidDemographics = (adAccountId, campaignsIds, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const url = (`${config.LOIS_BACKEND_URL}/meta/api/fetchMetaPaidDemographics`);
        const reqConfig = {
            params: {
                adAccountId,
                campaignsIds,
                accessToken,
                dateFrom,
                dateTo,
            }
        };
        
        axios.get(url, reqConfig)
        .then(res => {
            resolve(res);
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const dummy_request_to_facebook = (accessToken) => {
    return new Promise((resolve,reject) =>{
        const url = (`${config.LOIS_BACKEND_URL}/meta/api/dummyFacebookRequest`);

        const reqConfig = {
            params: {
                accessToken,
            }
        };
        axios.get(url, reqConfig)
        .then(res => {
            resolve(res);
        })
        .catch((err) =>
        resolve(false)) ;
    })
}

const fetchGraphicalMetaKpis = (adAccountId, campaignsIds, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            adAccountId, 
            campaignsIds, 
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchGraphicalMetaKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const fetchGraphicalFacebookKpis = (pageId, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId, 
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchGraphicalFacebookKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const fetchGraphicalInstagramKpis = (pageId, accessToken, dateFrom, dateTo) =>{
    return new Promise((resolve,reject) =>{

        const params = {
            pageId, 
            accessToken, 
            dateFrom, 
            dateTo,
        };

        axios.get(`${config.LOIS_BACKEND_URL}/meta/api/fetchGraphicalInstagramKpis`, {params})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


export {
    get_campaigns,
    my_businesses,
    get_ownded_ad_accounts,
    get_ownded_facebook_pages,
    get_instagram_business_accounts,
    getMyOrganicPages,
    get_facebook_fields,
    get_facebook_metric,
    get_instagram_fields,
    get_instagram_metric,
    get_facebook_fields_demographics,
    get_ads_chronical,
    get_facebook_metric_by_date,
    fetchAdsKpis, // New
    fetchAdsPages, // New
    fetchFacebookPageKpis, // New
    fetchInstagramPageKpis, // New
    fetchMyBusinesses, // V2_New 
    fetchMetaPaidKpis, // V2_New
    fetchFacebookKpis, // V2_New
    fetchInstagramKpis, // V2_New
    fetchOverviewKpis, // V2_New
    get_ad_accounts, // V2_New
    fetchMetaPaidDemographics, // V2_New
    dummy_request_to_facebook,
    fetchGraphicalMetaKpis,
    fetchGraphicalFacebookKpis,
    fetchGraphicalInstagramKpis,
    fbLogin,
    fbConnections,
    removeFbConnection,
};