import { IconSvg, Main_Plain } from 'monica-alexandria';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';

const CreatorPlus = styled.div`
    cursor: pointer;
    border: 2px dashed ${(p) => p.theme.high};
    padding: 2rem;
    border-radius: ${(p) => (p.presetStyle?.radius ? 'var(--smallRadius)' : 'none')};
    overflow: hidden;
    background: ${(p) => p.theme.low};
    margin: ${(p) => (p.inGrid ? '0' : '2rem 0')};
    display: flex;
    align-items: center;
    display: ${(p) => (p.viewMode ? 'none' : 'block')};
`;

const CreatorPlusWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 2rem;

    main {
        h3 {
            color: var(--greyDark);
        }
    }

    aside {
        background: ${(p) => p.theme.mid};
        height: 5rem;
        width: 5rem;
        padding: 1rem;
        border-radius: 50%;

        svg {
            width: 100%;
            fill: var(--greyDark);
        }
    }
`;

const CreatorPlusActions = styled.div`
    margin-top: 2rem;
`;

export default function Creator_Plus(props) {
    const { reportStyle } = useContext(ReportStyleContext);

    return (
        <CreatorPlus className={props.className} viewMode={props.viewMode} inGrid={props.inGrid} presetStyle={reportStyle} onClick={props.onClick}>
            <CreatorPlusWrapper>
                <aside>
                    <IconSvg Icon="Add" />
                </aside>
                <main>
                    <h3>{props.title}</h3>
                    <p>{props.text}</p>
                    {props.actions ? (
                        <CreatorPlusActions>
                            <Main_Plain text={props.main} iconRight={props.iconMain} onClick={props.onMain} noPadding />
                        </CreatorPlusActions>
                    ) : null}
                </main>
            </CreatorPlusWrapper>
        </CreatorPlus>
    );
}
