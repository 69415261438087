import axios from "axios"
import { config } from "../../../../config"



const get_accessed_templates = () =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/templates/getAccessedTemplates`)
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}

const create_template = (template) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/templates/createTemplate`, {
            payload: template,
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}

const update_template = (template) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/templates/updateTemplate`, {
            payload: template,
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}


const deleteTemplate = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/templates/deleteTemplate`, {
            templateId: id
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}

export {
    get_accessed_templates,
    create_template,
    update_template,
    deleteTemplate,
};