import React, { useState, useEffect } from 'react'
import { Steps_Actions, Section_Title , CardsSixty, Toggle_Stat, Partial_Loader, Google_Search, Builder_} from 'monica-alexandria'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import Creator_Error_Message from '../creator/Creator_Error_Message';
import Snippet_Toggle_Donot from '../snippets/Snippet_Donot';
import Slider_Expand from '../slider/Slider_Expand';

const WizadMetaAdAccount = styled.div``;
const BusinessManagerWrapper = styled.div``;
const MetaAddAccountWrapper = styled.div``;
const MetaCampaignWrapper = styled.div``;


export default function Wizad_Meta_Ad_Account(props) {
    console.log(props);

    const { t } = useTranslation();
    const fetchBusinessesError = props.errors?.fetchBusinesses?.error;
    const fetchAdAccountsError = props.errors?.fetchAdAccounts?.find((ac) => ac?.extraInfo?.parentBusinessId === props.selectedBusiness?.id)?.error;

    const [displayedBusinesses, setDisplayedBusinesses] = useState(props.businessAccounts);
    const [displayedAdAccounts, setDisplayedAdAccounts] = useState(props.adAccounts);
    const [displayedCampaigns, setDisplayedCampaigns] = useState([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);

    const [searchResultsBusiness, setSearchResultsBusiness] = useState();
    const [searchResultsAdAccounts, setSearchResultsAdAccounts] = useState();
    const [searchResultsCampaigns, setSearchResultsCampaigns] = useState();

    const currSection = props.selectedSections[props.currentSectionIndex + 1];
    const [currBusiness, setCurrBusiness] = useState(currSection.parentBusiness);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsBusiness?.input) {
                let indexToRemove = searchResultsBusiness?.data.findIndex(obj => obj.id === 0);

                // If the index is found (not -1), remove the object
                if (indexToRemove !== -1) {
                    searchResultsBusiness?.data.splice(indexToRemove, 1);
                }
                setDisplayedBusinesses(searchResultsBusiness?.data);
            }
            else setDisplayedBusinesses(props.businessAccounts);
        };

        applySearchResults();
    }, [searchResultsBusiness]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsAdAccounts?.input) setDisplayedAdAccounts(searchResultsAdAccounts?.data);
            else setDisplayedAdAccounts(props.adAccounts);
        };

        applySearchResults();
    }, [searchResultsAdAccounts]);


    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsCampaigns?.input) setDisplayedCampaigns(searchResultsCampaigns?.data);
            else setDisplayedCampaigns(filteredCampaigns);
        };

        applySearchResults();
    }, [searchResultsCampaigns]);

    useEffect(() =>{
        setDisplayedBusinesses(props.businessAccounts);
    }, [props.businessAccounts]);

    useEffect(() => {        
        setDisplayedAdAccounts(props.adAccounts);
    }, [props.adAccounts]);

    useEffect(() => {
        setFilteredCampaigns(props.campaigns?.filter((camp) => camp?.account_id === currSection.sectionData[0]?.id));
        setDisplayedCampaigns(props.campaigns?.filter((camp) => camp?.account_id === currSection.sectionData[0]?.id));
    }, [props.campaigns, props.selectedSections]);

    return (
        <WizadMetaAdAccount>
            <h3>{t("Meta Ads")}</h3>
            {/*  search */}
            <BusinessManagerWrapper>
                {props.businessAccounts?.length > 15 ?
                <>
                    {props?.businessCursor !== false ?
                            <Partial_Loader/> :
                    <Builder_ >
                        <Google_Search file={props.businessAccounts} fileKeys={['name']} defaultValue={''} sanitize={true} placeholder={t('Search Business Manager')} setResults={(data) => setSearchResultsBusiness(data)} debounceTime={750} minMatchCharacters={3} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.8} />
                    </Builder_> 
                    }
                </>
                :
                null
                }
                <Section_Title text={t('Select Facebook Business Manager')} />
                {fetchBusinessesError ? 
                    <Creator_Error_Message error={fetchBusinessesError} errorType={'fetchBusinesses'} onClick={props.onClick} noSlider={true} />
                    :              
                    <CardsSixty>
                        {!currBusiness ?
                            <>
                            {displayedBusinesses?.map((item, i) => {
                                const isAdded = item.id === currBusiness?.id;
                                return (
                                    <Toggle_Stat 
                                        key={i} 
                                        id={i} 
                                        text={item.name} 
                                        onClick={() => !isAdded ? (props.addSource(item, 'meta'), setCurrBusiness(item)): props.removeSource(item, 'meta')} 
                                        active={isAdded} 
                                    />
                                )
                            })}
                            </>
                            :
                            <Toggle_Stat
                                key={currBusiness?.id}
                                id={0}
                                text={currBusiness?.name}
                                onClick={() => {
                                    props.removeSource('meta', currSection);
                                    setCurrBusiness(null);
                                    if(currSection?.sectionTitle !== "Ad Account") {
                                        props.removeSection("meta");
                                    }
                                }}
                                active={true}
                            />
                        }
                    </CardsSixty>   
                }
            </BusinessManagerWrapper>

            {currBusiness ? 
                <MetaAddAccountWrapper>
                    {props.adAccounts?.length > 15 ?
                    <Builder_ >
                        <Google_Search file={props.adAccounts} fileKeys={['name']} defaultValue={''} sanitize={true} placeholder={t('Search Ad Account')} setResults={(data) => setSearchResultsAdAccounts(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                    </Builder_> 
                    : null }
                    <Section_Title text={t('Select Facebook Ad Account')} />
                    {fetchAdAccountsError ? 
                        <Creator_Error_Message error={fetchAdAccountsError} errorType={'fetchAdAccounts'} onClick={props.onClick} noSlider={true} />
                        : 
                        <>
                        {props.laodingAdAccounts ? <Partial_Loader/> :
                        <CardsSixty>
                            {props.selectedSections[props.currentSectionIndex+1]?.sectionTitle === "Ad Account"?
                            <>
                            {displayedAdAccounts
                                ?.filter((adAccount) => adAccount?.parentBusiness?.id === props.selectedBusiness?.id)
                                ?.map((item, i) => {
                                    const isAdded = props.selectedSections?.some((obj) => obj.type === 'paid' && obj.sectionData?.find((sd) => sd.type === 'adAccount').id === item.id);
                                    return (
                                        <Toggle_Stat 
                                            key={i} id={i} 
                                            text={item.name}       
                                            onClick={() => !isAdded ? props.addSection("meta", item)  : props.removeSection("meta") }
                                            active={isAdded} 
                                        />    
                                    )                                                                
                            })}
                            </>
                            : 
                            <Toggle_Stat
                                key={props.selectedSections[props.currentSectionIndex+1]?.id}
                                id={0}
                                text={props.selectedSections[props.currentSectionIndex+1]?.sectionTitle}
                                onClick={() => props.removeSection("meta")}
                                active={true}
                            />
                            }
                        </CardsSixty>
                        }
                        </>
                    }                             
                </MetaAddAccountWrapper>
                : null }
                <MetaCampaignWrapper>
                    {props.selectedSections[props.currentSectionIndex+1]?.sectionTitle !== "Ad Account" && filteredCampaigns?.length >= 0 ?
                        <Slider_Expand
                            filteredCampaigns={filteredCampaigns}
                            setSearchResults={setSearchResultsCampaigns}
                            loadingCampaigns={props.campaignLoader}
                            displayedCampaigns={displayedCampaigns}
                            selectedSections={props.selectedSections}
                            addCampaign={props.addCampaign}
                            removeCampaign={props.removeCampaign}
                            title={t('Specific Campaign (Optional)')}
                            position={props.currentSectionIndex}
                            currentSectionIndex={props.currentSectionIndex}
                        />
                        : null}
                </MetaCampaignWrapper>
            <Steps_Actions onClick_Main={props.onClick_Main} onClick_Grey={props.onClick_Grey} icon_Grey="Back" icon_Main="Next" text_Grey={t("Back")} text_Main={t("Next")}/>
        </WizadMetaAdAccount>
    )
}
