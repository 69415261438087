import { Actions_, Builder_, Input_, Red_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const EditTitle = styled.div``;

const SliderSection = styled.div`
    padding-bottom: 3rem;

    h3 {
        padding-bottom: 2rem;
    }
`;

const AddButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export default function Edit_Title(props) {
    const { t } = useTranslation();


    const handleInput = (e) => {
        if ( props?.report?.title?.length <= 70 || props?.report?.title === undefined ) props.setReport({...props.report, title: e})
    }

    useEffect(() => {
        props.setSliderText(t('Edit title'));
    }, []);

    return (
        <EditTitle>
            <Builder_ title={t("Report title")}>
                <Input_ value={props?.report?.title} placeholder={t('Add report title')} onChange={(e) => handleInput(e.target.value)}/>
            </Builder_>
        </EditTitle>
    );
}
