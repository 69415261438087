import { Actions_, Builder_, Input_, Red_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const ViewCoupon = styled.div``;

const SliderSection = styled.div`
    padding-bottom: 3rem;

    h3 {
        padding-bottom: 2rem;
    }
`;

const AddButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;


export default function View_Coupon(props) {
    const { t } = useTranslation();

 

    useEffect(() => {   
        props.setSliderText(t(props.activeCoupon?.name));
    }, []);

    return (
        <ViewCoupon>
            <Builder_ title="Name"> 
                <Input_
                    value = {props.activeCoupon?.name}
                />
            </Builder_>
            <Builder_ title="Active days"> 
                <Input_
                    value = {props.activeCoupon?.activeDays}
                />
            </Builder_>
            <Builder_ title="Uses left"> 
                <Input_
                    value = {props.activeCoupon?.usesLeft}
                />
            </Builder_>
        </ViewCoupon>
    );
}
