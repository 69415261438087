import { Upload_Avatar } from 'monica-alexandria';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


const ReportUploadLogo = styled.div`
    //margin: 2rem 1rem;
`;

export default function Report_Upload_Logo() {

    const { t } = useTranslation();
    const [imageSrc, setImageSrc] = useState(['https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg']);
    const [uploaded, setUploaded] = useState(false);
    const [messages, setMessages] = useState({
        errors: [],
        successes: [],
    });

    const delete_pic = () => {
        localStorage.removeItem('logoImage');
        setUploaded(false);
    };

    function getAvatarImage() {
        if (imageSrc?.length && imageSrc[0]?.preview) {
            localStorage.setItem('logoImage', imageSrc[0]?.preview);
            return imageSrc[0]?.preview;
        } else {
            return 'https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/DefaultAvatar.jpg';
        }
    }

    const upload_pic = (files) => {
        setImageSrc(files);
        setUploaded(true);
    };

    const handleBoomRejected = (type) => {
        let reason = '';
        switch (type) {
            case 'videoSize': {
                reason = (t('Invalid video size'));
                break;
            }
            case 'fileMimetype': {
                reason = (t('File must be png,jpg or jpeg'));
                break;
            }
            case 'fileSize': {
                reason = (t('Invalid image size. Image must be less than 2MB'));
                break;
            }
        }
        setMessages({
            ...messages,
            errors: [(t('Could not upload asset. Reason')) + ': ' + reason],
        });
    };

    return (
        <ReportUploadLogo>
            <Upload_Avatar
                accept="image/jpeg, image/png, image/jpg"
                boomRejected={(type) => handleBoomRejected(type)}
                delete={uploaded ? t("Delete") : false}
                errors={messages.errors}
                getFilesFromChild={(files) => upload_pic(files)}
                helpers={['Acceptable files: png,jpg,jpeg']}
                img={getAvatarImage()}
                onDelete={uploaded ? delete_pic : {}}
                tips={['Preferable ratio 1:1']}
                upload="Upload Logo"
                title={t("You may choose to upload a logo to appear on the report.")}
                uploaded={uploaded}
            />
        </ReportUploadLogo>
    );
}
