import { Builder_, Dates_, Dropdown_, Slider_ } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18n from '../../../../../../localization/i18next';

const EditReportDates = styled.div`   
`
export default function Edit_Report_Dates(props) {
    const [showButton, setShowButton] = useState(true); 

    const lang = i18n.language ? i18n.language : 'en';

    const { t } = useTranslation();

    const [dateRange, setDateRange] = useState({
        minDate: null,
        maxDate: new Date(),
    });
    const initialState = !props.initLoad
    ? [props.dateFrom ? new Date(props.dateFrom).toISOString() : '', props.dateTo ? new Date(props.dateTo).toISOString() : '']
    : ["", ""];

    // state the secures the user has set dates
    const [validDateState, setValidDateState] = useState(true);
    const [renderedDates, setRenderedDates] = useState(initialState);
  

    const acceptableRange = props.acceptableRange || 1000;

    const formatDate = (date) => {
        const localeDateFormat = new Intl.DateTimeFormat().formatToParts(new Date());

        const dateFormat = localeDateFormat
            .map((part) => {
                if (part.type === 'day') {
                    return 'dd';
                } else if (part.type === 'month') {
                    return 'mm';
                } else if (part.type === 'year') {
                    return 'yyyy';
                }
                return part.value;
            })
            .join('/');

        let format = dateFormat.split('//');

        let dateParts = date.toLocaleDateString().split('/');
        let formattedDate = [];

        let indexes = { month: -1, day: -1, year: -1 };
        let counter = 0;
        for (let part of format) {
            if (part.includes('dd')) indexes.day = counter;
            if (part.includes('mm')) indexes.month = counter;
            if (part.includes('yyyy')) indexes.year = counter;
            counter++;
        }

        formattedDate.push(dateParts[indexes.year]);
        formattedDate.push(dateParts[indexes.month] && dateParts[indexes.month].length > 1 ? dateParts[indexes.month] : `0${dateParts[indexes.month]}`);
        formattedDate.push(dateParts[indexes.day] && dateParts[indexes.day].length > 1 ? dateParts[indexes.day] : `0${dateParts[indexes.day]}`);

        return formattedDate?.length > 0 ? formattedDate.join('-') : '';
    };

    const handleDateRanges = (selections) => {
        const earlierDate = new Date(selections[0].getTime() - acceptableRange * 24 * 60 * 60 * 1000);
        const laterDate = new Date(selections[0].getTime() + acceptableRange * 24 * 60 * 60 * 1000);
        setDateRange({
            minDate: earlierDate,
            maxDate: laterDate < new Date() ? laterDate : new Date(),
        });
    };

    const onChange = (selections) => {
        if (selections[0] && selections[0] !== null && selections[1] && selections[1] !== null) {
            setRenderedDates(selections);
            if (selections.length === 2)
                props.onDates({
                    dateFrom: formatDate(selections[0]),
                    dateTo: formatDate(selections[1]),
                });
            setDateRange({
                minDate: null,
                maxDate: new Date(),
            });
        } else {
            handleDateRanges(selections);
            props.onDates({
                dateFrom: formatDate(selections[0]),
                dateTo: formatDate(selections[0]),
            });
        }
    };

    useEffect(() => {
        // if dates are valid then enable the button to proceed
        if(renderedDates[0] !== "" && renderedDates[1] !== "")
            setValidDateState(false)
    }, [renderedDates]);
      
    return (
        <Slider_ onGrey={props.onClick} iconGreyLeft="Back" grey={t("Back")} onClick={props.onClick} title={t('Report period')} text={t("Set period")} disabled={validDateState}>
            <EditReportDates>
                <Builder_ title={t('Set the report date range')}>
                    <Dates_ allowPartialRange={true} selectRange={true} dateFullCellRender={(date) => date.date()} onChange={onChange} value={renderedDates} minDate={dateRange.minDate} maxDate={dateRange.maxDate} />
                </Builder_>
                <Builder_ title={t('Set date format')}>
                    <Dropdown_>
                        <select value={props.currentFormat} onChange={props.onDateFormat}>
                            <option value={'YYYY-MM-DD'}>
                                {t("YYYY/MM/DD")}
                            </option>
                            <option value={'DD-MM-YYYY'}>
                                {t("DD/MM/YYYY")}
                            </option>
                            <option value={'MM-DD-YYYY'}>
                                {t("MM/DD/YYYY")}
                            </option>
                        </select>
                    </Dropdown_>
                </Builder_>
            </EditReportDates>
        </Slider_>
    );
}
