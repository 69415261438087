import { ThemeContext, Upload_Avatar } from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportDaysResponsive, ReportGridResponsive, ReportLeft, ReportMetaImg, ReportMetaKpi, ReportSection, ReportSectionTitle, ReportStat } from '../../../../../../styles/reports/Report.css';
import { t } from 'i18next';
import GoogleLogo from '../../../../../../assets/googleNetwork/googleLogo.png';
import MetaAdsLight from '../../../../../../assets/light/MetaAds.png';
import MetaAdsDark from '../../../../../../assets/dark/MetaAds.png';
import {fixNumbers} from '../../../../../../helpers/fixNumbers';

const Report = styled.div`
    //margin: 2rem 1rem;
`;

export default function Report_(props) {
  
    const { theme } = useContext(ThemeContext);
   
    return (
        <Report>
            { props.report?.campaigns?.map((campaign) => {
                return <>
                    <ReportSection>

                        <ReportSectionTitle>
                            <div>
                                <img src={GoogleLogo} />
                                <h4>{campaign?.campaign?.name}</h4>
                            </div>
                            <h4> { "tempValue" } </h4>
                        </ReportSectionTitle>
                        
                        <ReportGridResponsive>
                            <ReportDaysResponsive paid>
                                <h4>{fixNumbers(props.report?.days)}</h4>
                                <h5>{t('days')}</h5>
                            </ReportDaysResponsive>
                            <p>{ campaign?.comment 
                                    ? campaign?.comment
                                    : (t("In the Meta Ads Performance Overview, you'll see a concise snapshot of your ads' performance within your chosen date range. This includes impressions, clicks, engagement rate, and more. It's your one-stop shop for understanding how your ads are performing and where there's room for improvement"))}
                            </p>
                        </ReportGridResponsive>
                        
                        <ReportMetaKpi>
                            { campaign?.kpis?.map((kpi) => (
                                <ReportStat>
                                    <ReportLeft paid>
                                        <h6>{kpi.key}</h6>
                                        <h1>{fixNumbers(kpi.value)}</h1>
                                        <h6>{kpi?.originalKey !== kpi?.key ? kpi?.originalKey : ''}</h6>
                                    </ReportLeft>
                                </ReportStat>
                            ))}
                        
                        </ReportMetaKpi>

                        
                    </ReportSection>

                    <ReportMetaImg>{ theme === 'dark' 
                                            ? <img src={MetaAdsDark} /> 
                                            : <img src={MetaAdsLight} /> }
                    </ReportMetaImg>
                </>
            })}
        </Report>
    );
}
