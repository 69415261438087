import {Confirmation_,  Cta_, Builder_, CardsFull, CardsSixty, Google_Search, On_Click_Card, PageFull, Section_Title, Slider_ } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card_On_Element } from '../pages/creator/components/card/Card_On_Element';
import MetaLogo from '../../../assets/MetaLogo.png';
import FbLogo from '../../../assets/fb.png';
import InstaLogo from '../../../assets/insta.png';
import store from '../../../redux/store';
import GoogleLogo from '../../../assets/googleNetwork/pngegg.png'
import { update_template } from '../services/api/Templates';
import { useNavigate } from 'react-router-dom';

const SelectTemplate = styled.div``;

const SelectTemplatePreset = styled.div``

const SelectTemplateSaved = styled.div``

const SelectTemplatesPreviewList = styled.div`
    margin-bottom: 2rem;
`

const SelectTemplatesPreviewItem = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: .5rem;

    img{
        width: 2rem;
        height: auto;
    }
`

const SelectTemplatesPreviewColors = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;
`

const SelectTemplateColor = styled.div`
    background: ${p => p.color};
    height: 2rem;
    width: 2rem;
    box-shadow: ${p => p.theme.out};
    border: 1px solid var(--grey);
    border-radius: .5rem;
`

export default function     Select_Templates(props) {
    const { t } = useTranslation();

    const [displayedPresets, setDisplayedPresets] = useState(props.templates);
    const [searchResults, setSearchResults] = useState([]);

    const [isOptions, setIsOption] = useState({open: false, index: -1});

    const [preBuiltTemplate, setPreBuiltTemplate] = useState(false);

    useEffect(() => {
        if ( searchResults.input ) setDisplayedPresets(searchResults.data);
        else setDisplayedPresets(props.templates)
    },[searchResults, props.templates]);

    const chooseLogo = (section) => {
        if(section.source === "google")
            return GoogleLogo;
        else if (section.source === "meta") {
            if(section.type === "paid")
                return MetaLogo;
            else if(section.type === "facebook")
                return FbLogo;
            else if(section.type === "instagram")
                return InstaLogo;
        }
    }

    const chooseText = (title) => {
        if(title === "Ad Account")
            return "Meta Ad Account"
        else if (title === "Google Ad Account")
            return "Google Ad Account"
        else return title

    }

    const make_template_prebuilt = (template) => {
        let newPayload = template

        newPayload.isPrebuilt = true;
        newPayload.userIds = []

        newPayload.presetStructureId.isPrebuilt = true;
        newPayload.presetStructureId.userIds = [];

        update_template(newPayload);
        props.refresh();
    }

    console.error(props?.permission)

    return ( 
        <SelectTemplate>
            {preBuiltTemplate && <Confirmation_ message={t('Are you sure you want to make this template available for all users?')} no={t("No")} onNo={() => setPreBuiltTemplate(false)} onYes={() => make_template_prebuilt(preBuiltTemplate)} yes={t("Yes")} />}

            { props.templates?.length > 9 ? <Builder_ title={t('Search for a template')}>
                <Google_Search
                    file={props.templates}
                    fileKeys={['presetStyleId.brand', 'presetStructureId.title']}
                    defaultValue={''}
                    sanitize={true}
                    placeholder={t('Search Preset')}
                    setResults={(data) => setSearchResults(data)}
                    debounceTime={750}
                    minMatchCharacters={1}
                    maxMatchCharacters={50}
                    disabled={false}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                />
            </Builder_> : null }


            { displayedPresets?.filter((template) => template.isPrebuilt)?.length > 0 ? 
            <SelectTemplatePreset>
                <CardsSixty>
                    {displayedPresets?.filter((template) => template.isPrebuilt)?.map((template, index) => {
                        return  <Card_On_Element
                            onClick={() => {props.onApply(template); setIsOption({...isOptions, open: !isOptions.open, index})}}
                            view={t('Use')} 
                            isOptions={index === isOptions.index && isOptions.open && isOptions.type === "preset"} 
                            imgSmall="https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg" 
                            title={template.presetStructureId?.duplicate 
                                    ? `${template.presetStructureId.title} (${template.presetStructureId?.duplicate})` 
                                    : template.presetStructureId?.title
                                } 
                            subtitle={template.presetStyleId?.duplicate 
                                ? `${template.presetStyleId?.brand} (${template.presetStyleId?.duplicate})`
                                : template.presetStyleId?.brand
                            }
                            add={t("Use")}
                            iconAdd="EyeOpen"
                        >
                            <SelectTemplatesPreviewList>
                                <SelectTemplatesPreviewColors>
                                    {Object.entries(template.presetStyleId?.color).map(([key, value]) => 
                                        <SelectTemplateColor key={key} color={value}></SelectTemplateColor>
                                    )}
                                </SelectTemplatesPreviewColors>
                                {template.presetStructureId?.sections
                                    ?.filter((section, index) => index < 5 && section.type !== 'overview') // Take the first 4 sections
                                    .map((section, i) => (
                                        <SelectTemplatesPreviewItem key={i}>
                                            <img src={chooseLogo(section)} />
                                            <p>{chooseText(section.sectionTitle)}</p>
                                        </SelectTemplatesPreviewItem>
                                    ))}
                                {template.presetStructureId?.sections
                                    ?.filter((section, index) => index >= 5 && section.type !== 'overview') // Take the sections beyond the first 4
                                    .length > 0 && ( // Check if there are remaining sections
                                        <SelectTemplatesPreviewItem>
                                            <p>+{template.presetStructureId.sections.filter((section) => section.type !== 'overview').slice(4).length} more</p>
                                        </SelectTemplatesPreviewItem>
                                    )}
                            </SelectTemplatesPreviewList>
                        </Card_On_Element>
                        
                    })}
                </CardsSixty>
            </SelectTemplatePreset> : null}

            { displayedPresets?.filter((template) => !template.isPrebuilt)?.length > 0 ? <SelectTemplateSaved>
                <Section_Title text={t('My templates')} />
                <CardsSixty>
                    {displayedPresets?.filter((template) => !template.isPrebuilt)?.map((template, index) => {
                        return <On_Click_Card
                            onClick={() => setIsOption({...isOptions, open: !isOptions.open, index, type: "saved"})} 
                            view={t('Use')} 
                            onView={() => {props.onApply(template); setIsOption({...isOptions, open: !isOptions.open, index})}}
                            delete={t('Delete')} 
                            onDelete={() => {props.onDelete(template); setIsOption({...isOptions, open: !isOptions.open, index})}}
                            options 
                            isOptions={index === isOptions.index && isOptions.open && isOptions.type === "saved"} 
                            imgSmall={
                                template?.presetStyleId?.logo === "Moon" || template?.presetStyleId?.logo === "Sun" ?
                                "https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg" 
                                :  template?.presetStyleId?.logo
                            }
                            title={template.presetStructureId?.duplicate 
                                    ? `${template.presetStructureId.title} (${template.presetStructureId?.duplicate})` 
                                    : template.presetStructureId?.title
                                } 
                            subtitle={template.presetStyleId?.duplicate 
                                ? `${template.presetStyleId?.brand} (${template.presetStyleId?.duplicate})`
                                : template.presetStyleId?.brand
                            }
                            extraOptions={
                                props.permission ? 
                                    <Cta_ noPadding text={t('Publish')} onClick={() => setPreBuiltTemplate(template)} />
                                : null
                            }
                        >
                            <SelectTemplatesPreviewList>
                                <SelectTemplatesPreviewColors>
                                    {template?.presetStyleId?.color && Object?.entries(template?.presetStyleId?.color).map(([key, value]) => 
                                        <SelectTemplateColor key={key} color={value}></SelectTemplateColor>
                                    )}
                                </SelectTemplatesPreviewColors>
                                {template.presetStructureId?.sections
                                    ?.filter((section, index) => index < 5 && section.type !== 'overview') // Take the first 4 sections
                                    .map((section, i) => (
                                        <SelectTemplatesPreviewItem key={i}>
                                            <img src={chooseLogo(section)} />
                                            <p>{section.sectionTitle}</p>
                                        </SelectTemplatesPreviewItem>
                                    ))}
                                {template.presetStructureId?.sections
                                    ?.filter((section, index) => index >= 5 && section.type !== 'overview') // Take the sections beyond the first 4
                                    .length > 0 && ( // Check if there are remaining sections
                                        <SelectTemplatesPreviewItem>
                                            <p>+{template.presetStructureId.sections.filter((section) => section.type !== 'overview').slice(4).length} more</p>
                                        </SelectTemplatesPreviewItem>
                                    )}
                            </SelectTemplatesPreviewList>

                        </On_Click_Card>
                    })}
                </CardsSixty>
            </SelectTemplateSaved> : null}
        </SelectTemplate>
    );
}
