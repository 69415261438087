import { Builder_, Dropdown_, Input_, Section_Title, Upload_Logo, Red_ , Upload_Media, Confirmation_} from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import Style_Preset_Editor_Colors from './Style_Preset_Editor_Colors';
import axios from 'axios';
import { config } from '../../../../../../config';
import { FileContext } from '../../../../context/FileContext';
import store from '../../../../../../redux/store';
import { deleteMedia, upLoadMedia } from '../../../../../../services/api/Media';

const StylePresetEditor = styled.div``;

const EditMediaGrid = styled.div``

const EditMediaList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

const EditMediaItem = styled.div`
    width: 100%;
    position: relative;
    border: 1px solid ${p => p.theme.background};
    background: ${p => p.theme.low};
    
    img{
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        cursor: pointer;
    }

`

export default function Style_Preset_Editor(props) {
    const { t } = useTranslation();
    const { reportStyle, setReportStyle } = useContext(ReportStyleContext);

    const { file, setFile } = useContext(FileContext);
    const [confirmation, setConfirmation] = useState(false);
    const [selectedImage, setSelectedImage] = useState();

    const [fontFamily, setFontFamily] = useState(reportStyle.font.family);

    const [borderRadius, setBorderRadius] = useState(reportStyle.radius);

    const change_radius = (event) => {
        const newRadius = event.target.value;
        setReportStyle((prev) => ({
            ...prev,
            radius: newRadius === 'none' ? false : newRadius,
        }));
        setBorderRadius(newRadius);
    };

    const change_font_family = (event) => {
        const newFontFamily = event.target.value;
        setReportStyle((prev) => ({
            ...prev,
            font: {
                family: newFontFamily,
            },
        }));
        setFontFamily(newFontFamily)
    };
    
    const change_logo = (imageName) => {
        console.log(imageName);
        setReportStyle((prev) => ({
            ...prev,
            logo: imageName,
        }));
    };

    const upload_pic = async (files) => {
        if (files?.length && files[0]?.preview) {
            console.log(files[0]);
            //here call the uploadmedia with correct arguments
            upLoadMedia('me', files[0])
            .then(res => {
                if(res.data.code === 200) {
                    props.addMedia(res.data?.data?.filename);
                }
            })
            .catch((err) => {console.log(err);})
        }
    };


    const handleBoomRejected = (type) => {

        console.log('TYPe', type);
        let reason = '';
        switch (type) {

            case 'fileMimetype': {
                reason = (t('File must be png, jpeg, mp4, mpeg, webm'));
                break;
            }
            case 'fileSize': {
                reason = (t('Invalid image size. Image must be less than 6MB'));
                break;
            }
        }
        // alertMessage(alerts, setAlerts, t('Could not upload image. Reason')+": "+reason,'Error');
    };
    

    const handleRedButton = (imageName) => {
        setSelectedImage(imageName);
        setConfirmation(true);
    }
    
    const deleteImage = (img) => {
        deleteMedia(img)
        .then(res => {
            if(res.data.code === 200) {
                props.removeMedia(img);
            }
        })
        .catch((err) => {console.log(err);})
    }

    console.log(reportStyle);

    return (
        <StylePresetEditor>
            {confirmation ? 
            <Confirmation_ 
                message={t('If you delete this image it will be removed from all the reports you have used it. Are you sure you want to delete this image from your collection?')} 
                yes={t('Yes')} no={t('No')} 
                onYes={() => {
                    deleteImage(selectedImage);
                    setConfirmation(false);
                }} 
                onNo={() => {
                    setConfirmation(false);

                }} 
            />: null} 
            <Section_Title text={t('Live Editor')} />
            <Builder_ title={t("Enter your brand name")}>
                <Input_ placeholder={t("Brand name")} required value={reportStyle?.brand} onChange={(e) => setReportStyle((prev) => ({ ...prev, brand: e.target.value }))} />
            </Builder_>
            {/* <Builder_ title={t("Upload your logo")}>
                <Upload_Logo 
                    upload={t('Upload')} 
                    img={img ? img : doitformeLogo} 
                    getFilesFromChild={(files) => upload_pic(files)} 
                    boomRejected={(type) => handleBoomRejected(type)}
                    uploaded={imgStatus}
                />
            </Builder_> */}
            <EditMediaGrid>
                <Builder_ title={t("Select logo")}>
                    <Upload_Media 
                        // accept="image/jpeg,  image/jpg, "
                        upload={t("Upload")}
                        getFilesFromChild={(files) => upload_pic(files)}
                        delete={t("Delete")}
                        boomRejected={(type) => handleBoomRejected(type)}
                        helpers={[(t('Acceptable files png, jpg, jpeg, mp4, mpeg, webm less than 7mb'))]}
                        maxFiles="1"
                        maxSize="6000000"
                        type ='media'
                    />
                </Builder_>
                <EditMediaList>
                    {props.availableMedia?.map((imageName, i) => {
                        return(
                            <EditMediaItem  id={i} key={i}>
                                <img onClick={() => change_logo(imageName)} src={imageName}/>
                                <Red_ iconLeft="Delete" text="Delete" onClick={() => handleRedButton(imageName)}/>
                            </EditMediaItem>
                        )
                    })}
            </EditMediaList>
        </EditMediaGrid>
            <Builder_ title={t("Font family")}>
                <Dropdown_>
                    <select value={fontFamily} onChange={change_font_family}>
                        <option value="">Default</option>
                        <option value="Arial">Arial</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="Trebuchet">Trebuchet</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Garamond">Garamond</option>
                        <option value="Courier New">Courier New</option>
                    </select>
                </Dropdown_>
            </Builder_>
            <Builder_ title={t("Border radius")}>
                <Dropdown_>
                    <select value={borderRadius} onChange={change_radius}>
                        <option value="none">None</option>
                        <option value="3rem">Large</option>
                        <option value="1rem">Small</option>
                    </select>
                </Dropdown_>
            </Builder_>
            <Builder_ title={t("Colors")}>
                <Style_Preset_Editor_Colors />
            </Builder_>
        </StylePresetEditor>
    );
}
