// This file has contain one validation function per page
// Each function return true as long as the required data that
// are needed for the page's business logic exists and false otherwise

export const business_manager_validation = (selections) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( !selections?.account_business?.name ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const reports_validation = (selections) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( !selections?.account_business?.name || !selections?.facebook_business?.name ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}

export const report_type_validation = (selections) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( !selections?.account_business?.name ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}

export const new_report_validation = (selections) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( !selections?.account_business?.name ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const select_preset_validation = (facebookUser) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)
        
        if ( 
            !facebookUser?.userID || 
            !facebookUser?.accessToken || 
            !facebookUser?.username
            // !selections?.account_business?.name    
        ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const source_page_validation = (facebook_user) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( 
            !facebook_user?.userID || 
            !facebook_user?.accessToken || 
            !facebook_user?.username
        ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const ad_account_validation = (facebookUserInfo, sectionAccessData) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( 
            !sectionAccessData?.some((obj) => obj.type === 'paid' && obj.businessAccount?.id ) ||
            !facebookUserInfo?.accessToken 
        ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const report_dates_validation = (facebookUserInfo) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( 
            !facebookUserInfo?.accessToken 
         ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}


export const builder_validation = (facebook_user, selections) => {
    return new Promise(async(resolve)=>{

        if ( process.env.REACT_APP_USE_DUMMY_DATA == 'ON' ) return resolve(true)

        if ( 
            !selections?.account_business?.name ||
            !selections?.facebook_business?.id || 
            !facebook_user.accessToken ||
            !selections?.facebook_adAccount?.id 
         ) {
            resolve(false);
            return;
        }
        resolve(true);
        return;
    })
}