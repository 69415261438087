import React from 'react'
import styled from 'styled-components'
import { Cta_, Focus_, device } from 'monica-alexandria'
import { H5 } from '../../../styles/components/LandingText'
import { useTranslation } from 'react-i18next'
import HeaderReport from '../../../assets/landing/HeaderReport.svg'

const LandingHeader = styled.div``

const LandingHeaderWrapper = styled.div`
  margin: 2rem 4rem;
  background: ${p => p.theme.low};
  box-shadow: ${p => p.theme.outFocus};
  border-radius: var(--normalRadius);
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;

  @media ${device.lg} {
    grid-template-columns: 1fr;
    }

  @media ${device.sm} {
    margin: 2rem 2rem;
    }
`

const LandingHeaderImg = styled.img`
  position: absolute;
  width: 50%;
  max-width: 70rem;
  right: -1rem;
  top: -1rem;

  @media ${device.lg} {
    position: static;
    width: 100%;
    margin: 0 auto;
  }
`

const LandingHeaderHero = styled.div`
  max-width: 108rem;
  margin: 0 auto;
  padding: 10rem 4rem;

  h1{
    font-size: 7rem;
    line-height: 1;
    margin-bottom: 2rem;
  }

  button{
    margin-top: 2rem;
    width: 50%;
    justify-content: center;
    min-width: auto;
  }

  @media ${device.md} {
      h1{
        font-size: 5rem;
        line-height: 1;
        margin-bottom: 2rem;
      }
    }

    @media ${device.sm} {
      padding: 6rem 2rem;

      h1{
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 2rem;
      }

      button{
        width: 100%;
      }
    }
`

export default function Landing_Header(props) {

  const {t} = useTranslation()

  return (
    <LandingHeader>
      <LandingHeaderWrapper>
        <LandingHeaderHero>
          <h1>{t("Meta & Google Ads Reporting.")}<br/>{t("Stunning visuals, fully customizable metrics.")}</h1>
          <H5>{t("Enjoy unlimited reports and streamline your ad performance analysis.")}<br/>{t("Experience the Adsnap difference today!")}</H5>
          <Cta_ size="large" text={t("Start for Free")}  onClick={props.onBuy}/>
        </LandingHeaderHero>
        <LandingHeaderImg src={HeaderReport} />
      </LandingHeaderWrapper>
    </LandingHeader>
  )
}
