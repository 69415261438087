import { deepClone } from "../../../../../helpers/DeepCloneObject";
import { get_ad_accounts, get_campaigns } from "../../../services/api/MetaApi";
import apiErrors from '../data/api-errors';
import { getCustomerClients } from "../../../../google/services/api/GoogleApi";

export const add_source = async (creator, setCreator, source, type, accessedAccounts) => {
    switch (type) {
        case("meta"): {
            
            var metaHelper = deepClone(creator.metaHelper);
            metaHelper.selectedBusiness = source;

            setCreator((prev) => ({ 
                ...prev, 
                metaHelper
            }));

            const fetchedAdAccounts = deepClone(creator.metaHelper?.adAccounts);
            /* Check if we have already fetched adAccounts for this business by searching ad account parentBusiness ids */
            if ( !fetchedAdAccounts.some((adAccount) => adAccount.parentBusiness?.id === source?.id) ) {
                const businessId = source?.id;
                const metaAccessToken = accessedAccounts?.meta?.accessToken;

                setCreator((prev) => ({
                    ...prev,
                    loaders: {
                        ...prev.loaders,
                        adAccounts: true
                    }
                }));
                const fetchAdAccountsResponse = await get_ad_accounts(businessId, metaAccessToken);
                
                /* Fetch adAccoutns for this business */
                let fetchedAdAccounts = fetchAdAccountsResponse?.data?.data?.myAdAccounts;

                /* Check for errors */
                var fetchedAdAccountsError = false;

                fetchedAdAccountsError = apiErrors(fetchAdAccountsResponse, creator.t);

 
                if ( fetchedAdAccountsError ) break;

                /* Assign business to the new parentBusiness property of all fetched AdAccounts */
                const newAdAccounts = fetchedAdAccounts?.map((adAccount) => {
                    return {...adAccount, parentBusiness: source}
                })

                /* Combine the array of newly fetched AdAccounts with the array of the previsously fetched AdAccounts */
                if ( newAdAccounts && newAdAccounts?.length > 0 ) {
                    metaHelper.adAccounts = [...metaHelper.adAccounts, ...newAdAccounts];
                }
                setCreator((prev) => ({
                    ...prev, 
                    metaHelper,
                    errors: !fetchedAdAccountsError ? prev.errors : {
                        ...prev.errors,
                        fetchAdAccounts: !fetchedAdAccountsError ? prev.errors.fetchAdAccounts : 
                        [...prev.errors.fetchAdAccounts, { 
                            extraInfo: {
                                ...prev.errors.fetchAdAccounts.extraInfo,
                                parentBusinessId: source.id
                            },
                            error: fetchedAdAccountsError ? fetchedAdAccountsError : false
                        }],
                    },
                    loaders: {
                        ...prev.loaders,
                        adAccounts: false,
                    }
                }))
            }       
            break;
        }
        case("google"): {
            var googleHelper = deepClone(creator.googleHelper);
            googleHelper.selectedManager = source;

            setCreator((prev) => ({ 
                ...prev, 
                googleHelper
            }));

            const fetchedAdAccounts = deepClone(creator.googleHelper?.adAccounts);

            if ( !fetchedAdAccounts.some((adAccount) => adAccount.parentManagerInfo?.id === source?.id) ) {
                const resourceName = source?.resourceName;
                const googleAccessToken = accessedAccounts?.google?.accessToken;

                // loader init here
                setCreator((prev) => ({
                    ...prev, 
                    loaders: {
                        ...prev.loaders,
                        googleAdAccountLoader: true,
                    }
                }))
                const fetchAdAccountsResponse = await getCustomerClients(resourceName, googleAccessToken, accessedAccounts.google.userId);
                let customerClients = fetchAdAccountsResponse?.data?.data?.customerClients;

                const parentManagerInfo = {
                    id: source.id, name: source.descriptiveName
                }
                const newAdAccounts = customerClients?.map((client) => ({
                    ...client.customerClient,
                    parentManagerInfo
                  }));

                if ( newAdAccounts && newAdAccounts?.length > 0 ) {
                    googleHelper.adAccounts = [...googleHelper.adAccounts, ...newAdAccounts];
                }

                setCreator((prev) => ({
                    ...prev, 
                    googleHelper,
                    loaders: {
                        ...prev.loaders,
                        googleAdAccountLoader: false,
                    }
                }))
            }

            break;
        }
    }
}

export const remove_source = (creator, setCreator, type) => {
    switch (type){
        case ("meta"): {
            let metaHelper = JSON.parse(JSON.stringify(creator.metaHelper));
            let reportSections = JSON.parse(JSON.stringify(creator.report.sections));
            /* If we remove the source while an ad account is selected we will remove the ad account too! (if any has been selected) */
            if(metaHelper.selectedAdAccount) {
                reportSections.pop();
                metaHelper.selectedAdAccount = null;
            }

            /* Assign selected business */
            metaHelper.selectedBusiness = null;

            setCreator((prev) => ({
                ...prev, 
                metaHelper,
                report: {
                    ...prev.report,
                    sections: reportSections,
                }
            }));
            break;
        }
        case ("google"): {
            let googleHelper = JSON.parse(JSON.stringify(creator.googleHelper));
            let reportSections = JSON.parse(JSON.stringify(creator.report.sections));

            if(googleHelper.selectedAdAccount) {
                reportSections.pop();
                googleHelper.selectedAdAccount = null;
            }

        //     /* Assign selected business */
            googleHelper.selectedManager = null;

            setCreator((prev) => ({
                ...prev, 
                googleHelper,
                report: {
                    ...prev.report,
                    sections: reportSections,
                }
            }));
            break;
        }
    }

}

// CAMPAIGN FUNC FOR COMPONENT WIZARD


export const add_campaign = (creator, setCreator, campaign, section) => {
    console.log(campaign, section);

    let datafilledStructure = creator.presetStructureHelper?.datafilledStructure 
        ? deepClone(creator.presetStructureHelper?.datafilledStructure)
        : deepClone(creator.presetStructure);

    let s = datafilledStructure.sections?.find((s) => s.sectionTitle === section.sectionTitle);

    const sectionCampaigns = s.campaigns;
    if (sectionCampaigns) {
        s.campaigns.push(campaign);
    }
    else {
        s.campaigns = [campaign];
    }
    // console.log(sectionCampaigns);
    // s.campaigns = metaHelper.selectedCampaigns;   
    setCreator((prev) => ({
        ...prev,
        presetStructureHelper: {
            ...prev.presetStructureHelper,
            datafilledStructure
        }
    }))
} 

export const remove_campaign = (creator, setCreator, campaign, section) => {
    let metaHelper = JSON.parse(JSON.stringify(creator.metaHelper));



    let datafilledStructure = creator.presetStructureHelper?.datafilledStructure 
        ? deepClone(creator.presetStructureHelper?.datafilledStructure)
        : deepClone(creator.presetStructure);

    let s = datafilledStructure.sections?.find((s) => s.sectionTitle === section.sectionTitle);


    s.campaigns = s.campaigns?.filter((camp) => camp.id !== campaign.id);   
    

    setCreator((prev) => ({
        ...prev,
        metaHelper,
        presetStructureHelper: {
            ...prev.presetStructureHelper,
            datafilledStructure
        }
    }))
}

// CAMPAIGN FUNC FOR COMPONENT ADD_SECTION_META / ADD_SECTION_GOOGLE

export const add_campaign_to_section = (creator, setCreator, campaign) => {
    // find corresponding section of reports
    let reportSections = deepClone(creator.report.sections);

    let matchingSection = reportSections[reportSections.length - 1];
    if(matchingSection) { 
        if(!matchingSection.campaigns) matchingSection.campaigns = [campaign]
        else matchingSection.campaigns.push(campaign);
    }

    setCreator((prev) => ({ 
        ...prev,
        report: {
            ...prev.report,
            sections: reportSections,
        }
    }))
}

export const remove_campaign_from_section = (creator, setCreator, campaign) => {

    let reportSections = deepClone(creator.report.sections);

    let matchingSection = reportSections[reportSections.length - 1];
    if(matchingSection) {
        matchingSection.campaigns = matchingSection.campaigns?.filter((c) => c?.id !== campaign?.id);
        
    }
    else {
        console.log("matching section not found for this campaign");
    }

    setCreator((prev) => ({ 
        ...prev,
        report: {
            ...prev.report,
            sections: reportSections,
        }
    }))
}