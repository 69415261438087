import React, { useState } from 'react';
import styled from 'styled-components';
import Landing_Prices from '../landing/components/Landing_Prices';
import Pricing_Header from './components/Pricing_Header';
import { device } from 'monica-alexandria';
import Foot_ from '../../components/footer/Foot_';
import store from '../../redux/store';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { get_my_subscriptions} from '../../services/api/Users';

const clients = require('../../config/index').config;

const Pricing = styled.div`
    section {
        padding: 10rem 4rem;
    }

    h2 {
        margin-bottom: 5rem;
    }

    @media ${device.sm} {
        section {
            padding: 1rem 2rem;
        }
    }
`;

export default function PRICING() {

    const navigate = useNavigate();

    const lang = store.getState().selectedLanguage ? store.getState().selectedLanguage : 'en';
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;

    const user = store.getState().user.user;

    const chooseRedirect = () => {
        get_my_subscriptions(user?.sub)
            .then((res) => {
                    let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                    if(lois_subs?.tools?.every(tool => tool?.active)) return navigate(`/${lang}/welcome`);
                    else window.location.assign(payments_URL);
            }).catch((err) => console.log('error', err));
    };

    return (
        <Pricing>
            <Helmet>
                <title>Adsnap | Pricing</title>
            </Helmet>
            <Pricing_Header onBuy={() => chooseRedirect()} />
            <Landing_Prices onBuy={() => chooseRedirect()} />
            <Foot_ />
        </Pricing>
    );
}
