import React from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import Report from '../../assets/Report.jpg';
import styled from 'styled-components';
import Front_Side from './Front_Side';
import Box_ from './Box_';
import { Canvas } from 'react-three-fiber';

const CanvasReport = styled.div`
    height: calc(100%);
`;

export default function Canvas_Report() {
    return (
        <CanvasReport className="Canvas">
            <Canvas>
                <PerspectiveCamera makeDefault position={[0, 0, 17]} fov={75} near={0.1} far={1000} />
                <Box_ />
                <Front_Side imageUrl={Report} />
                <OrbitControls enableZoom={false} enableRotate={true} rotateSpeed={2} minPolarAngle={Math.PI / 4} maxPolarAngle={(3 * Math.PI) / 4} />
            </Canvas>
        </CanvasReport>
    );
}
