import axios from "axios"
import { config } from "../../config"


const getPriceIdByName = (planName) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.GRIPHOOK_BACKEND_URL}/plans/getPriceIdByName?plan=${planName}`)
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const create_portal_session = (payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.GRIPHOOK_BACKEND_URL}/checkout/editSubscription`, {
            payload
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

const first_time_user = (userId, product_priceId, status) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.ERISED_BACKEND_URL}/subscriptions/getSubscription`, {
            userId:userId,
            priceId:product_priceId,
            status:status
        })
        .then(res => {
            resolve(res)
        })
        .catch(err=>{
            resolve(false)
        })
    })
}

export {
    getPriceIdByName,
    first_time_user,
    create_portal_session
}