import {AlertContext, Alert_, CardsForty, CardsTools, Confirmation_, On_Click_Card, PageAlwaysVisible, PageDesktopPadding, PageTitle, PageTools, PageToolsSixty, Slider_ } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import Toolbar_ from '../../partials/navigation/Toolbar_';
import { useTranslation } from 'react-i18next';
import { create_coupon, delete_coupon, edit_coupon, get_coupons } from '../../../services/api/Coupons';
import Edit_Coupon from './components/editCoupon';
import Create_Coupon from './components/createCoupon';
import View_Coupon from './components/viewCoupon';

export default function COUPONS() {
  
  const { t } = useTranslation();

  const { alerts, setAlerts } = useContext(AlertContext);
  
  const [ coupons, setCoupons ] = useState([]);
  const [ selectedCouponId, setSelectedCouponId ] = useState(false);
  const [ triggerFetch, setTriggerFetch ] = useState(false);
  const [ payload, setPayload ] = useState({});

  // Used for handling sliders
  const [ slider, toggleSlider ] = useState(false);
  const [ activeSlider, setActiveSlider ] = useState('');
  const [ activeCoupon, setActiveCoupon ] = useState();
  const [ sliderText, setSliderText ] = useState(""); 

  const [warning, setWarning] =  useState(false); // Confirmation

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
          },
      ]);
  };

  const handleSliderClick = (slider, item) => {
    toggleSlider(true);
    setActiveSlider(slider);
    if (item) setActiveCoupon(item);
  };

  const closeSlider = () => {
      toggleSlider(false);
  };

  const handleViewOptions = (coupon) =>{
    setSelectedCouponId((prevSelectedId) =>
      prevSelectedId === coupon._id ? false : coupon._id
    );
  }

  const yesConfirm = () =>{
    deleteCoupon()
    setWarning(false);
  }

  const handleDone = () => {
    if ( payload.type === 'create' ) {delete payload.type; createCoupon(payload)}
    else if ( payload.type === 'edit' ) {delete payload.type; editCoupon(payload)} 
    else return  
  }

  const createCoupon = (coupon) => {
    create_coupon(coupon).then((res) => {setTriggerFetch(!triggerFetch)}).catch(err=>{alertMessage('Error', "Something went wrong")})
  }

  const editCoupon = (coupon) => {
    edit_coupon(selectedCouponId, coupon).then((res) => {setTriggerFetch(!triggerFetch)}).catch(err=>{alertMessage('Error', "Something went wrong")})
  }


  const deleteCoupon = () => {
    delete_coupon(selectedCouponId).then((res) => {setTriggerFetch(!triggerFetch)}).catch(err=>{alertMessage('Error', "Something went wrong")})
  }


  useEffect(() => {
    const fetchCoupons = () => {
      get_coupons().then(res=>setCoupons(res?.data?.data?.coupons)).catch(err=>{alertMessage('Error', "Something went wrong while fetching coupons")})
    }

    fetchCoupons();
  },[triggerFetch])

  console.log('payload',payload);
  
  return (
    <PageToolsSixty>
      <Alert_
          messages={alerts}
      />
      { warning && <Confirmation_ 
          message={t('Are you sure you want to delete this coupon?')} 
          yes={t('Yes')} 
          no={t('No')}
          onYes = {yesConfirm}
          onNo = {()=>setWarning(false)}
      /> }
      { slider && <Slider_
          onClick={() => {closeSlider(); handleDone();}}
          onClose={() => closeSlider()}
          text={t('Done')}
          title={sliderText}
      >
          {activeSlider === 'view_coupon' && (
              <View_Coupon
                  setSliderText={setSliderText}
                  closeSlider={closeSlider}
                  activeCoupon={activeCoupon}
              />
          )}
          {activeSlider === 'create_coupon' && (
              <Create_Coupon
                  setSliderText={setSliderText}
                  closeSlider={closeSlider}
                  payload={payload}
                  setPayload={setPayload}
              />
          )}
          {activeSlider === 'edit_coupon' && (
              <Edit_Coupon
                  setSliderText={setSliderText}
                  closeSlider={closeSlider}
                  activeCoupon={activeCoupon}
                  payload={payload}
                  setPayload={setPayload}
              />
          )}
      </Slider_> } 
      <PageAlwaysVisible>
        <Toolbar_ />
        <PageTitle>
            <h1>{t('Coupons')}</h1>
        </PageTitle>
        { coupons?.length > 0 && <CardsTools>
          {coupons?.map((coupon,key)=> {    
            const isSelected = selectedCouponId === coupon._id; 
            return( <On_Click_Card
                        icon="Report" 
                        isOptions = {isSelected}
                        edit={"Edit"}
                        onView = {() => handleSliderClick("view_coupon", coupon)}
                        onCLickEdit={true}
                        onEdit={() => handleSliderClick('edit_coupon', coupon)}
                        options
                        onDelete = {() => setWarning(true)}
                        delete="Delete"
                        view="View"
                        // onLoadImg={function noRefCheck(){}}
                        subtitle={coupon.name}           
                        onClick={() => handleViewOptions(coupon)}
                    />
              );
          })} 
        </CardsTools> }
      </PageAlwaysVisible>
      <PageAlwaysVisible>
      <PageDesktopPadding>
            <CardsForty>
                <On_Click_Card icon="AddPlan" title={t('Create coupon')} onClick={() => {handleSliderClick("create_coupon")}} />
            </CardsForty>
        </PageDesktopPadding>
    </PageAlwaysVisible>
    </PageToolsSixty>
  )
}
