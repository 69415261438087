import React from 'react';
import { Slider_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import Style_Preset_Saved from '../style/Style_Preset_Saved';
import Style_Preset_Prebuilt from '../style/Style_Preset_Prebuilt';
import Style_Preset_Editor from '../style/Style_Preset_Editor';

export default function Edit_Style_Preset(props) {
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} title={t('Edit Style Preset')} text="Done">
            <Style_Preset_Prebuilt preBuilt={props.preBuilt} />
            <Style_Preset_Saved accessed={props.accessed} onDelete={(style) => props.onDelete(style)} />
            <Style_Preset_Editor availableMedia={props.availableMedia} addMedia={props.addMedia} removeMedia={props.removeMedia}/>
        </Slider_>
    );
}
