import { Builder_, CardsSixty, Google_Search, On_Click_Card, Section_Title } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StructurePresetSaved = styled.div``;

export default function Structure_Preset_Saved(props) {
    const { t } = useTranslation();

    const [displayedPresets, setDisplayedPresets] = useState(props.accessed);
    const [searchResults, setSearchResults] = useState([]);

    const [isOptions, setIsOption] = useState({open: false, index: -1});

    useEffect(() => {
        if ( searchResults.input ) setDisplayedPresets(searchResults.data);
        else setDisplayedPresets(props.accessed)
    },[searchResults, props.accessed])

    return (
        <StructurePresetSaved>
            <Section_Title text={t('Saved presets')} />
            { props.accessed?.length > 9 ? <Builder_ title={t('Search for a preset')}>
                <Google_Search
                    file={props.accessed}
                    fileKeys={['title']}
                    defaultValue={''}
                    sanitize={true}
                    placeholder={t('Search Preset')}
                    setResults={(data) => setSearchResults(data)}
                    debounceTime={750}
                    minMatchCharacters={1}
                    maxMatchCharacters={50}
                    disabled={false}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                />
            </Builder_> : null }
            <CardsSixty>
            {displayedPresets?.map((structure, index) => {
                    return <On_Click_Card 
                        onClick={() => setIsOption({...isOptions, open: !isOptions.open, index})} 
                        view={t('Use')} 
                        onView={() => {props.onApply(structure); setIsOption({...isOptions, open: !isOptions.open, index})}}
                        delete={t('Delete')} 
                        onDelete={() => {props.onDelete(structure); setIsOption({...isOptions, open: !isOptions.open, index})}}
                        options 
                        isOptions={index === isOptions.index && isOptions.open} 
                        imgSmall="https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg" 
                        subtitle={structure?.duplicate ? `${structure.title} (${structure.duplicate})` : structure.title} 
                    />
                })}
            </CardsSixty>
        </StructurePresetSaved>
    );
}
