import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { useTranslation } from 'react-i18next';
import { Color_Picker } from '../../../../../../library/form/Color_Picker';

const StylePresetEditorColors = styled.ul`
    li {
        margin-bottom: 1rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

export default function Style_Preset_Editor_Colors() {
    const { t } = useTranslation();

    const { reportStyle, setReportStyle } = useContext(ReportStyleContext);

    const change_color = (colorType) => (event) => {
        const newColor = event.target.value;
        setReportStyle((prev) => ({
            ...prev,
            color: {
                ...prev.color,
                [colorType]: newColor,
            },
        }));
    };

    return (
        <StylePresetEditorColors>
            <li>
                <Color_Picker text={t('Primary')} onChange={change_color('main')} selectedColor={reportStyle?.color.main} />
            </li>
            <li>
                <Color_Picker text={t('Secondary')} onChange={change_color('focus')} selectedColor={reportStyle?.color.focus} initialColor={reportStyle?.color.focus} />
            </li>
            <li>
                <Color_Picker text={t('Report background')} onChange={change_color('background')} selectedColor={reportStyle?.color.background} initialColor={reportStyle?.color.background} />
            </li>
            <li>
                <Color_Picker text={t('Card background')} onChange={change_color('grey')} selectedColor={reportStyle?.color.grey} initialColor={reportStyle?.color.grey} />
            </li>
            <li>
                <Color_Picker text={t('Text color')} onChange={change_color('textDark')} selectedColor={reportStyle?.color.textDark} initialColor={reportStyle?.color.textDark} />
            </li>
            <li>
                <Color_Picker text={t('Text overlay')} onChange={change_color('textLight')} selectedColor={reportStyle?.color.textLight} initialColor={reportStyle?.color.textLight} />
            </li>
        </StylePresetEditorColors>
    );
}
