import { create_preset_structure, update_preset_structure } from "../../../services/api/PresetStructures";
import { create_preset_style, update_preset_style } from "../../../services/api/PresetStyles";
import { createReport, updateReport } from "../../../services/api/Reports"
import deepEqual from "deep-equal";
import { create_template, update_template } from "../../../services/api/Templates";
import { deepClone } from "../../../../../helpers/DeepCloneObject";
import { get_accessed_preset_structures } from "../../../services/api/PresetStructures";
import { get_accessed_preset_styles } from "../../../services/api/PresetStyles";
import { get_accessed_templates } from "../../../services/api/Templates";
import { template } from "lodash";


/* 
    Functions related to the finilization of the report.
    The finalization process is about deciding what are we going to do with the presets/template and create/update the report.
    The exported (main) functions are responsible for this flow process, the rest of the functions are used as helpers for the main functions.
*/


/* 
    The purpose of this function is to assign an "action" to the presets.
    Actions define what are we going to do with the presets.
    For example, we can simply use an existing preset for our report, create a new one or a duplicate one.
    In the end it triggers the confirmation helper. pop up may show up or not depending on the actions. 
    We next proceed to the "handle_preset_actions" function.
*/
export const start_report_finalization_process = (creator, setCreator, reportStyle, from) => {

    let structureAction = "";
    let styleAction = "";

    const report = deepClone(creator.report);
    const structure = deepClone(creator.currentTemplate?.presetStructureId);
    const style = deepClone(reportStyle)


    const fetchedAccessedPresets = deepClone(creator.fetchedPresets?.accessed);
    const fetchedPrebuiltPresets = deepClone(creator.fetchedPresets?.preBuilt);


    const fetchedPresetStructures = fetchedAccessedPresets.structures.concat(fetchedPrebuiltPresets.structures);
    const fetchedPresetStyles = fetchedAccessedPresets.styles.concat(fetchedPrebuiltPresets.styles);

    const structureTitle = report?.title;

    const styleTitle = {
        name: style?.name,
        brand: style?.brand,
        duplicate: style?.duplicate,
    };

    const isStyleTitleUnique = !fetchedPresetStyles.some((fetchedStyle) => 
        fetchedStyle?.brand === styleTitle?.brand &&
        (fetchedStyle?.duplicate === styleTitle?.duplicate || 
        (fetchedStyle?.duplicate === "" && !styleTitle?.duplicate ))
    );

    // check for style
    for(const tempStyle of fetchedPrebuiltPresets.styles) {


        const checkFormattedMatchingStyle = format_style_for_deep_equal_check(tempStyle);
        const checkFormattedCurrentStyle = format_style_for_deep_equal_check(style); 

        const isEqual = deepEqual(checkFormattedMatchingStyle, checkFormattedCurrentStyle);

        if(isEqual) {
            styleAction = "use-prebuilt";
            break;
        }
    }

    for(const tempStyle of fetchedAccessedPresets.styles) {

        const checkFormattedMatchingStyle = format_style_for_deep_equal_check(tempStyle);
        const checkFormattedCurrentStyle = format_style_for_deep_equal_check(style); 

        const isEqual = deepEqual(checkFormattedMatchingStyle, checkFormattedCurrentStyle);

        if(isEqual) {
            styleAction = "keep";
            break;
        }
    }
    if(styleAction === "") 
        styleAction = "create";
    
    // check for structure
    for(const tempStructure of fetchedPrebuiltPresets.structures) {

        const checkFormattedMatchingStructure = format_structure_for_deep_equal_check_from_preset(tempStructure);
        const checkFormattedCurrentStructure = format_structure_for_deep_equal_check_from_report(report); 
        
        const isEqual = deepEqual(checkFormattedMatchingStructure, checkFormattedCurrentStructure);
        if(isEqual) {
            structureAction = "use-prebuilt";
            break;
        }
    }

    for(const tempStructure of fetchedAccessedPresets.structures) {

        const checkFormattedMatchingStyle = format_structure_for_deep_equal_check_from_preset(tempStructure);
        const checkFormattedCurrentStyle = format_structure_for_deep_equal_check_from_report(report); 

        const isEqual = deepEqual(checkFormattedMatchingStyle, checkFormattedCurrentStyle);

        if(isEqual) {
            structureAction = "keep";
            break;
        }
    }

    if(structureAction === "") 
        structureAction = "create";


    if(!style?.isPrebuilt && styleAction === "create" && creator?.currentTemplate)
        styleAction = "create-or-overwrite";
    const showConfirmationStyle = styleAction === "create-or-overwrite";

    if(!structure?.isPrebuilt && structureAction === "create" && creator?.currentTemplate)
        structureAction = "create-or-overwrite";

    const showConfirmationStructure = structureAction === "create-or-overwrite";
    const isPresetActionsProcessFinished = !showConfirmationStyle;

    setCreator((prev) => ({
        ...prev, 
        confirmationHelper: {
            ...prev.confirmationHelper,
            showConfirmationStyle,
            showConfirmationStructure,
            isPresetActionsProcessFinished: !creator?.confirmationHelper?.isPresetActionsProcessFinished,
            styleAction,
            structureAction,
        },
    }))

}


/* 
    The purpose of this function is to handle the actions that were previously assigned to presets.
    This mean that we may proceed to some request(s) related to presets/templates before saving the report.
*/
export const handle_preset_actions = (creator, setCreator, reportStyle, setReportStyle, reportId, setReportId, file) => {

    const confirmationHelper = deepClone(creator.confirmationHelper);
    const report = deepClone(creator.report);
    const style = deepClone(reportStyle);
    const structure = deepClone(creator.currentTemplate?.presetStructureId);

    const stylePayload = style;
    const structurePayload = format_structure_to_save_from_report(report);
    const styleAction = confirmationHelper.styleAction;
    const structureAction = confirmationHelper.structureAction;

    let finalStyleId = "";
    let finalStructureId = "";

    let requests = [];

    console.log('style',styleAction);
    console.log('structure',structureAction);


    if ( styleAction === "create" ) requests.push(create_preset_style(stylePayload, false,  file));
    if ( styleAction === "create-from-prebuilt" ) requests.push(create_preset_style(stylePayload, "from-prebuilt"));
    if ( styleAction === "use-prebuilt" ) finalStyleId = style._id;
    if ( styleAction === "create-duplicate" ) requests.push(create_preset_style(stylePayload, "duplicate")); 
    if ( styleAction === "overwrite" ) requests.push(update_preset_style(stylePayload, file));
    if ( styleAction === "keep" ) finalStyleId = style._id;

    if ( structureAction === "create" ) requests.push(create_preset_structure(structurePayload));
    if ( structureAction === "create-from-prebuilt" ) requests.push(create_preset_structure(structurePayload, "from-prebuilt"));
    if ( structureAction === "use-prebuilt" ) finalStructureId = structure._id;
    if ( structureAction === "create-duplicate" ) requests.push(create_preset_structure(structurePayload, "duplicate"));
    if ( structureAction === "overwrite" ) requests.push(update_preset_structure(structurePayload, structure?._id))
    if ( structureAction === "keep" ) finalStructureId = structure._id;

    if ( requests?.length > 0 ) {
        Promise.all(requests)
            .then((responses) => {
                const presets = responses.map((res) => {return res.data?.data});
                const presetStyleResponse = presets.find((p) => p.presetStyle)?.presetStyle;
                if(presetStyleResponse)
                    setReportStyle(presetStyleResponse);
                const presetStructureResponse = presets.find((p) => p.presetStructure)?.presetStructure;
                finalStyleId = presetStyleResponse?._id ? presetStyleResponse._id : finalStyleId;
                finalStructureId = presetStructureResponse?._id ? presetStructureResponse._id : finalStructureId;
                const finalStyle = presetStyleResponse ? presetStyleResponse : style ;
                const finalStructure = presetStructureResponse ? presetStructureResponse : structure;
                find_template_id(creator, setCreator, {finalStyleId, finalStructureId}, finalStyle, finalStructure, styleAction, structureAction)
                    .then((template) => {
                        if ( template ) {
                            save_report(creator, template._id, template, reportId, setReportId);
                            let requests = [
                                get_accessed_preset_styles(),
                                get_accessed_preset_structures(),
                                get_accessed_templates(),
                            ];

                            Promise.all(requests)
                            .then(([presetStyleResponse, presetStructureResponse, templateResponse]) => {
                                const prebuiltPresetStyles = presetStyleResponse?.data?.data?.preBuiltPresetStyles;
                                const accessedPresetStyles = presetStyleResponse?.data?.data?.accessedPresetStyles;
                                const prebuiltPresetStructures = presetStructureResponse?.data?.data?.preBuiltPresetStructures;
                                const accessedPresetStructures = presetStructureResponse?.data?.data?.accessedPresetStructures;
                                const preBuiltTemplates = templateResponse?.data?.data?.preBuiltTemplates;
                                const accessedTemplates = templateResponse?.data?.data?.accessedTemplates;

                                setCreator((prev) => ({
                                    ...prev, 
                                    fetchedPresets: {
                                        ...prev.fetchedPresets, 
                                        accessed: {...prev?.fetchedPresets?.accessed, styles: accessedPresetStyles, structures: accessedPresetStructures, templates: accessedTemplates}
                                    }, 
                                }))
                            })

                        }

                    });
            }).catch( err => console.log('err',err));
    } else {
        if (finalStyleId || finalStructureId) find_template_id(creator, setCreator, {finalStyleId, finalStructureId}, style, structure, styleAction, structureAction )
            .then((template) => {
                if ( template ) save_report(creator, template._id, template, reportId, setReportId);
            });
    }

}         


/* 
    We need a templateId in order to fill the templateId property of the report document we are about to save.
    This function will return try to match an existing template based on the presets. 
    It either match or create a new one and return the templateId.
*/
const find_template_id = (creator, setCreator, presetIds, style, structure, styleAction, structureAction) => {
    return new Promise((resolve) => {
        const fetchedAccessedTemplates = deepClone(creator.fetchedPresets?.accessed?.templates);
        const fetchedPreBuiltTemplates = deepClone(creator.fetchedPresets?.preBuilt?.templates);
        const templates = [...fetchedAccessedTemplates, ...fetchedPreBuiltTemplates];

        const fetchedAccessedStructures = deepClone(creator.fetchedPresets?.accessed?.structures);
        const fetchedPreBuiltStructures = deepClone(creator.fetchedPresets?.preBuilt?.structures);
        const structures = [...fetchedAccessedStructures, ...fetchedPreBuiltStructures];

        const fetchedAccessedStyle = deepClone(creator.fetchedPresets?.accessed?.styles);
        const fetchedPreBuiltStyle = deepClone(creator.fetchedPresets?.preBuilt?.styles);
        const styles = [...fetchedAccessedStyle, ...fetchedPreBuiltStyle];

        const styleId = presetIds.finalStyleId;
        const structureId = presetIds.finalStructureId;

        const currTemplate = creator.currentTemplate;

        // Based on styleId and structureId we construct a template
        // if this template is prebuilt keep things as they are
        // if this template is accessed show confirmation
        // else the template is completely new so we create it

        // KEEP PREBUILT
        const isTemplatePrebuilt = fetchedPreBuiltTemplates
            .find((template) => template.presetStyleId?._id === styleId && template.presetStructureId?._id === structureId);
        

        if(isTemplatePrebuilt) {
            console.log('keep current template');
            return resolve(isTemplatePrebuilt);
        }

        // UPDATE TEMPLATE THAT THE USER HAS ALREADY CREATED

        if(currTemplate?.isPrebuilt === false && structureAction !== "create") {
            currTemplate.presetStyleId = style;
            currTemplate.presetStructureId = structure;
            update_template(currTemplate)
            .then((res) => {
                console.log('update current template');
                let template = res.data?.data?.template;
                template.presetStyleId = style;
                template.presetStructureId = structure;
                setCreator((prev) => ({
                    ...prev,
                    currentTemplate: template
                }))
                if ( template ) return resolve(template);
                else return resolve(false);
            })
            .catch( err => {return resolve(false)})
            return;
        }

        // CREATE NEW TEMPLATE
        const template = {
            presetStyleId: style,
            presetStructureId: structure,
        }
        console.log(template);
        create_template(template)
            .then((res) => {
                console.log('will create new template');
                let template = res.data?.data?.template;
                template.presetStyleId = style;
                template.presetStructureId = structure;
                setCreator((prev) => ({
                    ...prev,
                    currentTemplate: template
                }))
                if ( template ) return resolve(template);
                else return resolve(false);
            })
            .catch( err => {return resolve(false)})    
    })
}

const save_report = (creator, templateId, template, reportId, setReportId) => {

    let payload = deepClone(creator.report);
    const localReportId = creator?.report?._id || reportId ? deepClone(creator?.report?._id) || reportId : null;
    const finalizationType = localReportId ? "update" : "create";

    payload.templateId = templateId;
    payload.template = template;
    console.log('Report saved');
    switch ( finalizationType ) {
        case ("create"): {
            payload.admin = false;
            create_report(payload, setReportId);
            break;
        }
        case ("update"): {
            update_report(localReportId, payload);
            break
        }
    }

}

const create_report = (payload, setReportId) => {
    createReport(payload)
        .then((res) => {
            console.log('res',res);
            setReportId(res?.data?.data?.report?._id);
    
        })
}

const update_report = (reportId, payload) => {
    updateReport(reportId, payload)
        .then((res) => {
            console.log('res',res);
        })
}


/*
    Modifications in the objects are required in order to be able to tell if the user has modified or not an existing id.
    This function removes style properties that are always going to break the deepEqual check.
*/
const format_style_for_deep_equal_check = (style) => {
    let clonedStyle = deepClone(style);
    delete clonedStyle?._id;
    delete clonedStyle?.userIds;
    delete clonedStyle?.duplicate;
    delete clonedStyle?.__v;
    delete clonedStyle?.createdAt;
    delete clonedStyle?.updatedAt;
    delete clonedStyle?.isPrebuilt;
    
    return clonedStyle;
}

/*
    Modifications in the objects are required in order to be able to tell if the user has modified or not an existing id.
    This function modifies the report obj in order to be in the same shape as e preset structure. 
    That way we can tell if the current report matches an existing structure perfectly of if it has modified one even a little bit.
*/
const format_structure_for_deep_equal_check_from_report = (report) => {
    delete report.dateFrom;
    delete report.dateTo;
    delete report.dateFormat;
    delete report.templateId;
    delete report.userIds;
    delete report.title;
    delete report.createdAt;
    delete report.updatedAt;
    delete report.__v;
    delete report._id;
    delete report.template;
    delete report.admin;

    for ( let section of report.sections ) {
        const sectionType = section.type;
        const shouldReformTitle = sectionType === "paid" || sectionType === "facebook" || sectionType === "instagram" || sectionType === "googlePaid";
        let sectionTitle = "";

        if ( sectionType === "paid" ) sectionTitle = "Ad Account";
        if ( sectionType === "facebook" ) sectionTitle = "Facebook page";
        if ( sectionType === "instagram" ) sectionTitle = "Instagram page";
        if ( sectionType === "googlePaid" ) sectionTitle = "Google Ad Account";

        if( section.parentManager ) delete section.parentManager;
        if( section.parentBusiness ) delete section.parentBusiness;

        if ( shouldReformTitle ) section.sectionTitle = sectionTitle;
        
        if ( section.sectionData ) delete section.sectionData;
        if ( section.campaigns ) delete section.campaigns;
        for ( let row of section.rows ) {
            if ( row?.elements && row?.elements?.length > 0 ) {
                row.elements = row.elements.map((element) => {
                    delete element?.values;
                    delete element?.value;
                    delete element?.displayedValue;
                    delete element?.comparedValue;
                    delete element?.displayedCompareValue;
                    delete element?.previousValue;
                    return element;
                }) 
            }
            if( row.type === "media") {
                delete row.image; // of course we don't want to compare structure with acutal value of image
                delete row.grid;
                delete row.title;
                delete row.position;
            }
        }
    }
    return report;
}

/*
    Modifications in the objects are required in order to be able to save a new preset structure.
    Input: report, Output: the preset structure we are about to create.
*/
const format_structure_to_save_from_report = (report) => {
    delete report.dateFrom;
    delete report.dateTo;
    delete report.dateFormat;
    delete report.admin;
    for ( let section of report.sections ) {
        if ( section.type === "paid" ) section.sectionTitle = "Ad Account";
        if ( section.type === "facebook" ) section.sectionTitle = "Facebook page";
        if ( section.type === "instagram" ) section.sectionTitle = "Instagram page";
        if ( section.type === "googlePaid" ) section.sectionTitle = "Google Ad Account";

        if ( section.sectionData ) {
            if ( section.type === "paid" ) section.sectionData = [
                {
                    "type": "adAccount",
                    "name": "adAccount",
                    "id": "",
                },
                {
                    "type": "facebookAccount",
                    "name": "",
                    "id": "",
                    "accessToken": "",
                }
            ]
            if ( section.type === "facebook" ) section.sectionData = [
                {
                    "type": "facebookPage",
                    "name": "facebookPage",
                    "id": "",
                    "accessToken": ""
                }
            ]
            if ( section.type === "instagram" ) section.sectionData = [
                {
                    "type": "instagramPage",
                    "name": "instagramPage",
                    "id": "",
                    "accessToken": ""
                }
            ]
            if ( section.type === "googlePaid" ) section.sectionData = [
                {
                    "type": "googleAdAccount",
                    "name": "googleAdAccount",
                    "id": "",
                },
                {
                    "type": "googleUser",
                    "name": "",
                    "id": "",
                    "accessToken": "",
                }
            ]
        }
        if ( section.campaigns ) delete section.campaigns;
        if ( section.parentBusiness ) delete section.parentBusiness; 
        if ( section.parentManager ) delete section.parentManager; 

        if ( section.rows && section.rows.length > 0 ) {
            for ( let row of section.rows ) {
                if ( row?.elements && row?.elements?.length > 0 ) {
                    row.elements = row.elements.map((element) => {
                        delete element?.values
                        delete element?.value;
                        delete element?.displayedValue;
                        delete element?.comparedValue;
                        delete element?.displayedCompareValue;
                        delete element?.previousValue;
                        return element;
                    }) 
                }
                if( row.type === "media") {
                    row.image = null; // of course we don't want to save to the structure the image itself
                }
            }
        }
    }

    return report;
}

/*
    Modifications in the objects are required in order to be able to tell if the user has modified or not an existing id.
    This function modifies the preset structure obj in order to be in the same shape as a modified by similar logic function report object. 
    That way we can tell if the current report matches an existing structure perfectly of if it has modified one even a little bit.
*/
const format_structure_for_deep_equal_check_from_preset = (preset) => {
    if ( !preset ) return false;
    delete preset.createdAt;
    delete preset.updatedAt;
    delete preset.isPrebuilt;
    delete preset.userIds;
    delete preset.__v;
    delete preset._id;
    delete preset.duplicate;
    delete preset.description;
    delete preset.title;

    for ( let section of preset.sections ) {
        if ( section.sectionData ) delete section.sectionData;
        for ( let row of section.rows ) {
            if ( row?.elements && row?.elements?.length > 0 ) {
                row.elements = row.elements.map((element) => {
                    delete element?.value;
                    delete element?.values; // for demographics
                    delete element?.displayedValue;
                    delete element?.comparedValue;
                    delete element?.displayedCompareValue;
                    delete element?.previousValue;
                    return element;
                }) 
            }
            if( row.type === "media") {
                delete row.image; // of course we don't want to compare structure with acutal value of image
                delete row.grid;
                delete row.title;
                delete row.position;
            }
        }
    }

    return preset;
}
