import { device } from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, YAxis, XAxis } from 'recharts';
import styled from 'styled-components';
import { SlideOptions, SlideOptionsWrapper } from '../../../../../../styles/components/SlideActions';
import Snippet_Block_Overlay from '../snippets/Snippet_Block_Overlay';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Snippet_Vertical_Actions } from '../snippets/Snippet_Vertical_Actions';
import { useTranslation } from 'react-i18next';
import Edit_Block from '../edit/Edit_Block';

const BlockAges = styled(SlideOptions)`
    height: 100%;
    width: 100%;
    background: ${(p) => p.theme.low};
    border-radius: ${(p) => p.presetStyle.radius};

    .recharts-surface,
    .recharts-responsive-container,
    .recharts-wrapper {
        cursor: pointer;
        position: relative;
    }

    .recharts-responsive-container {
        height: calc(16rem) !important;
    }
`;

const BlockAgesWrapper = styled(SlideOptionsWrapper)`
    border: 1px solid ${(p) => p.presetStyle.color.background};
    background: ${(p) => (p.variant == 'main' ? p.presetStyle.color.main : p.variant == 'focus' ? p.presetStyle.color.focus : p.presetStyle.color.grey)};
    border-radius: ${(p) => p.presetStyle.radius};
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BlockAgesValues = styled.div`
    display: grid;
    grid-template-columns: repeat(${(p) => p.ageElements}, 1fr);
    justify-content: space-between;
    align-items: center;

    p {
        text-align: center;
    }

    @media ${device.md} {
        p {
            font-size: 1rem;
        }
    }
`;

const BlockAgesTitle = styled.h6`
    font-family: ${(p) => p.presetStyle.font.family};
    color: ${(p) => p.presetStyle.color.textDark} !important;
`;

const BlockAgesValue = styled.div`
    p {
        color: ${(p) => p.presetStyle.color.textDark};
    }
`;

export default function Block_Ages(props) {
    const { reportStyle } = useContext(ReportStyleContext);
    const [slider, setSlider] = useState(false);


    const open_close_slider = () => {
        setSlider(false);
    };

    function formatYAxis(number) {
        if(!number)
            return number
        // Check if the number is one thousand or more but less than a million
        if (number >= 1000 && number < 1000000) {
            return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        // Check if the number is one million or more
        else if (number >= 1000000) {
            return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        // Handle numbers less than 1000 with up to two decimal places
        else if (!Number.isInteger(number)) {
            return number.toFixed(2);
        }
        // Handle integer values under 1000
        return number;
    }

    return (
        <BlockAges presetStyle={reportStyle} actions={props.actions} id={props.id} >
            {slider ? 
            <Edit_Block 
                onClick={() => open_close_slider()} 
                onBack={() => open_close_slider()}
                title={props.title}       
                titlePlaceholder={props.titlePlaceholder} 
                titleDefaultValue={props.titleDefaultValue} 
                setBlock={props.setBlock} 
                id={props.id} 
                displayedName={props.kpiName}
                element={props.element} 
                sectionType={props.sectionType} 
                sectionId={props.sectionId} 
                rowType={props.rowType} 
            /> : null }
            <Snippet_Vertical_Actions onEdit={() => setSlider(!slider)} onDelete={props.onDelete} />
            <BlockAgesWrapper viewMode={props.viewMode} variant={props.variant} presetStyle={reportStyle} actions={props.actions} onClick={props.onActions}>
                <BlockAgesTitle presetStyle={reportStyle}>{props.kpiName}</BlockAgesTitle>
                <div>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={props.json}>
                            <Bar dataKey="value" fill={reportStyle.color.main} />
                            <YAxis axisLine={false} tickLine={false} tickFormatter={formatYAxis}/>
                            <XAxis dataKey="group" axisLine={false} tickLine={false}/>
                        </BarChart>
                    </ResponsiveContainer>

                </div>
            </BlockAgesWrapper>
            {!props.viewMode ?
                <Snippet_Block_Overlay stylePreset={reportStyle} text={props.text} />
            :
                null
            }
        </BlockAges>
    );
}
