import { Main_Plain, device } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import store from '../../../../../../redux/store';
import { FileContext } from '../../../../context/FileContext';
import axios from 'axios';
import { config } from '../../../../../../config';

const ReportTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${device.sm} {
        flex-direction: column;
        padding: 1rem 0rem 1rem 2rem;
    }
`;

const ReportTopBrand = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 2rem;

    img {
        width: 3rem;
    }

    h6 {
        color: ${(p) => p.presetStyle?.color?.textDark};
        font-family: ${(p) => p.presetStyle?.font?.family};
    }

    @media ${device.sm} {
        padding-left: 0;
    }
`;

const ReportTopRange = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const ReportTopRangeDates = styled.div`
    display: flex;
    align-items: center;
    margin: ${(p) => (!p.presetStyle?.radius ? '0rem' : '1rem 2rem 1rem 1rem')};
    border-radius: ${(p) => p.presetStyle?.radius};
    background: ${(p) => p.presetStyle?.color.grey};
    padding: 1.5rem 2rem;

    h6 {
        color: ${(p) => p.presetStyle?.color.textDark};
        font-family: ${(p) => p.presetStyle?.font.family};
    }

    @media ${device.sm} {
        width: 100%;

        h6{
            width: 100%;
        }
    }
`;

export default function Report_Top(props) {

    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    const doitformeLogo = "https://genius1071.friktoriaservers.net/doitforme/logo/iconDoItForMe.png";
    const logo = ( !reportStyle.logo || reportStyle?.logo === "Moon" || reportStyle?.logo === "Sun")? doitformeLogo
    :   reportStyle?.logo;

    const [base64Image, setBase64Image] = useState(false);

    useEffect(() => {
        const fetchImage = async () => {
          try {
            const response = await axios.get(`${config.LOIS_BACKEND_URL}/media/base64?url=${logo}`);
            const image = response.data.data;
            const base64data = btoa(
            new Uint8Array(image.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
            );
            const base64String = `data:image/jpeg;base64,${base64data}`;
            setBase64Image(base64String);

          } catch (error) {
            console.error('Error fetching image:', error);
          }
        };
        if(props.pdf === true)
            fetchImage();
      }, [logo]);
    
    return (
        <ReportTop>
            <ReportTopBrand presetStyle={reportStyle}>
                {!props.viewMode ? <Main_Plain iconLeft="Edit" noPadding onClick={props.onLogo} /> : null}
                <img src={props.pdf ? base64Image : logo} 
                />
                <h6>{props.brand}</h6>
            </ReportTopBrand>
            <ReportTopRange>
                {!props.viewMode ? <Main_Plain iconLeft="Edit" noPadding onClick={props.onDates} /> : null}
                <ReportTopRangeDates presetStyle={reportStyle}>
                    <h6>
                        {props.dateFrom} {props.dateTo && props.dateFrom !== props.dateTo ? t('to') : ''} {props.dateFrom !== props.dateTo && props.dateTo}
                    </h6>
                </ReportTopRangeDates>
            </ReportTopRange>
        </ReportTop>
    );
}
