import {AlertContext, Alert_, CardsForty, CardsTools, On_Click_Card, PageAlwaysVisible, PageDesktopPadding, PageTitle, PageToolsSixty, Slider_ } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import Toolbar_ from '../../partials/navigation/Toolbar_';
import { useTranslation } from 'react-i18next';
import { get_basic_analytics } from '../../../services/api/Analytics';
import Basic_Analytics from './components/Basic_Analytics';
import Select_Dates from './components/Select_Dates';

export default function ANALYTICS() {
  
	const { t } = useTranslation();

	/* Analytics */
	const [analytics, setAnalytics] = useState();
	const [dates, setDates] = useState({
        sinceDate: '',
        untilDate: '',
    });

	/* For alerts */
	const { alerts, setAlerts } = useContext(AlertContext);

	const alertMessage = (message, status) => {
		setAlerts([
			...alerts,
			{
				message: {
					text: message,
					status: status,
				},
			},
		]);
	};

	// Used for handling sliders
	const [ slider, toggleSlider ] = useState(false);
	const [ activeSlider, setActiveSlider ] = useState('');
	const [ activeCoupon, setActiveCoupon ] = useState();
	const [ sliderText, setSliderText ] = useState(""); 

	const handleSliderClick = (slider, item) => {
		toggleSlider(true);
		setActiveSlider(slider);
		if (item) setActiveCoupon(item);
	};

	const closeSlider = () => {
		toggleSlider(false);
	};
	


	const initActions = () => {
		get_basic_analytics(dates)
			.then((res) => {
				console.log('res',res);
				setAnalytics(res.data?.data?.basicAnalytics);
			}).catch((error) => {
				console.error(error);
			})
	}


	useEffect(() => {
		initActions()
	},[dates])


  return (
    <PageToolsSixty>
      <Alert_
          messages={alerts}
      />

      { slider && <Slider_
          onClick={() => closeSlider()}
          onClose={() => closeSlider()}
          text={t('Done')}
          title={sliderText}
      >
          {activeSlider === 'select_dates' && (
              <Select_Dates
                  setSliderText={setSliderText}
                  closeSlider={closeSlider}
                  dates={dates}
				  setDates={setDates}
              />
          )}
      </Slider_> } 

      <PageAlwaysVisible>
        <Toolbar_ />
        <PageTitle>
            <h1>{t('Analytics')}</h1>
        </PageTitle>
			
		{ analytics && <Basic_Analytics analytics={analytics} /> }
		

      </PageAlwaysVisible>

	  <PageAlwaysVisible>
	  <PageDesktopPadding>
            <CardsForty>
			<On_Click_Card 
				onClick={() => handleSliderClick('select_dates')}
				title={t('Select dates')}
				icon={'Flag'}	
			/>
            </CardsForty>
        </PageDesktopPadding>
      </PageAlwaysVisible>
    </PageToolsSixty>
  )
}
