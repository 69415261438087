import { Builder_, Checkbox_, Dropdown_, Input_, Slider_ } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Edit_Section(props) {
    const { t } = useTranslation();

    const findPlaceholder = () => {
        if ( props.matchingSectionElement?.name === props.section?.sectionTitle ) return "";
        else return props.matchingSectionElement?.name;
    }
  
    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Edit section')}>
            <Builder_ title={t('Edit the title of the section')}>
                <Input_ placeholder={""} defaultValue={props.section?.sectionTitle} onChange={(e) => props.onEditName(e.target.value)} />
            </Builder_>
        </Slider_>
    );
}
