import React, { useState } from 'react';
import { Builder_, Google_Search, Section_Title, CardsSixty, Toggle_Stat, Partial_Loader, IconSvg } from 'monica-alexandria';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Slider_Empty from './Slider_Empty';

const SliderExpand = styled.div``;

const SliderExpandTitle = styled.div`
    background: ${p => p.theme.mid};
    border-radius: var(--smallRadius);
    padding: var(--normalPads);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 2rem;
`

const SliderExpandWrapper = styled.div`
    
`

const SliderExpandButton = styled.div`

`

const SliderExpandButtonIcon = styled.div`
   svg{
        width: 1.3rem;
        fill: ${p => p.theme.color};
        transform: ${p => p.reveal ? 'rotate(180deg)' : 'rotate(0deg)'};
        transition: all .5s;
   }
`


export default function Slider_Expand(props) {

    console.log(props);
    const { t } = useTranslation();
    const [reveal, toggleReveal] = useState(false);


    return ( 
        <SliderExpand>
            <SliderExpandTitle onClick={() => toggleReveal(!reveal)}>
                <h4>{props.title}</h4>
                <SliderExpandButtonIcon reveal={reveal}>
                    <IconSvg Icon="Dropdown" />    
                </SliderExpandButtonIcon>                
            </SliderExpandTitle>
            {reveal ?
                <SliderExpandWrapper>
                    {props.filteredCampaigns.length > 15 ?
                        <Builder_ >
                            <Google_Search
                                file={props.filteredCampaigns}
                                fileKeys={['name']}
                                defaultValue={''}
                                sanitize={true}
                                placeholder={t('Search Campaign')}
                                setResults={(data) => props.setSearchResults(data)}
                                debounceTime={750}
                                minMatchCharacters={1}
                                maxMatchCharacters={50}
                                disabled={false}
                                isCaseSensitive={false}
                                maxAcceptedScore={0.6}
                            />
                        </Builder_>
                        : null}
                    {/* <Section_Title text={t('Specific Campaign (Optional)')} /> */}

                    {props.loadingCampaigns ? <Partial_Loader /> :
                        <>
                            {props.filteredCampaigns.length !== 0 ? (
                                <CardsSixty>
                                    {props.displayedCampaigns.map((campaign, i) => {
                                        const isAdded = props.selectedSections[props.currentSectionIndex + 1]?.campaigns?.find((obj) => obj.id === campaign.id);
                                        return (
                                            <Toggle_Stat
                                                key={i}
                                                text={campaign.name}
                                                active={isAdded}
                                                onClick={() => {
                                                    !isAdded ? props.addCampaign(campaign, props.selectedSections[props.currentSectionIndex + 1]) : props.removeCampaign(campaign, props.selectedSections[props.currentSectionIndex + 1]);
                                                }}
                                            />
                                        );
                                    })}
                                </CardsSixty>
                            ) : (
                                <Slider_Empty text={t("No campaigns for this account")} />
                            )}
                        </>
            }
                </SliderExpandWrapper>
            :
                null
            }
        </SliderExpand>
    )
}
