import React from 'react';
import styled from 'styled-components';
import { PreviewSection, PreviewStat, ReportCards, ReportDays, ReportGrid, ReportLeft, ReportMetaImg, ReportSectionTitle } from '../../../../../../styles/reports/Report.css';
import { t } from 'i18next';
import GoogleLogo from '../../../../../../assets/googleNetwork/googleLogo.png';
import MetaAdsLight from '../../../../../../assets/light/MetaAds.png';
import MetaAdsDark from '../../../../../../assets/dark/MetaAds.png';
import {fixNumbers} from '../../../../../../helpers/fixNumbers';

const Preview = styled.div`
    //margin: 2rem 1rem;
`;

export default function Preview_(props) {
   
    return (
        <Preview>
            { props.report?.campaigns?.map((campaign) => {
                return <>
                    <PreviewSection>

                        <ReportSectionTitle>
                            <div>
                                <img src={GoogleLogo} />
                                <h4>{campaign?.campaign?.name}</h4>
                            </div>
                            <h4> { "tempValue" } </h4>
                        </ReportSectionTitle>
                        
                        <ReportGrid>
                            <ReportDays paid>
                                <h4>{fixNumbers(props.report?.days)}</h4>
                                <h5>{t('days')}</h5>
                            </ReportDays>
                            <p>{ campaign?.comment 
                                    ? campaign?.comment
                                    : (t("In the Meta Ads Performance Overview, you'll see a concise snapshot of your ads' performance within your chosen date range. This includes impressions, clicks, engagement rate, and more. It's your one-stop shop for understanding how your ads are performing and where there's room for improvement"))}
                            </p>
                        </ReportGrid>
                        
                        <ReportCards>
                            { campaign?.kpis?.map((kpi) => (
                                <PreviewStat>
                                    <ReportLeft paid>
                                        <h6>{kpi.key}</h6>
                                        <h1>{fixNumbers(kpi.value)}</h1>
                                        <h6>{kpi?.originalKey !== kpi?.key ? kpi?.originalKey : ''}</h6>
                                    </ReportLeft>
                                </PreviewStat>
                            ))}
                        
                        </ReportCards>

                        
                    </PreviewSection>

                    <ReportMetaImg>{ <img src={MetaAdsLight} /> }
                    </ReportMetaImg>
                </>
            })}
        </Preview>
    );
}
