import { AlertContext, Confirmation_, Facebook_, IconSvg, Main_Plain, Partial_Loader, Red_ } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { removeFbConnection } from '../../../sources/meta/services/api/MetaApi'
const ChangeFbConnection = styled.div`
`


const Connection = styled.div`
    border-radius: var(--smallRadius);
    box-shadow: ${(p) => p.theme.out};
    padding: var(--largePads);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .connected{
        color: var(--success);
    }
`

const ConnectionsWrapper = styled.div`
    padding-bottom: 2rem;

`
const AccountActions = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

const ConnectionSuccess = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  svg {
    fill: var(--success);
    width: 1.5rem;
  }

`

export const Change_Fb_Connection = ({loadingFacebook, facebookConnections, setFacebookConnections, chooseFacebookAccount, loginToFacebook, selectedFacebookAccount}) => {
  const {t} = useTranslation();

  const [connectionToDlt, setConnectionToDlt] = useState(false);
  const { alerts, setAlerts } = useContext(AlertContext);
  const filteredFbConnections = facebookConnections.filter(connection => connection.fbId !== selectedFacebookAccount?.userId )


  const alertMessage = (message, status) => {
      setAlerts([
          ...alerts,
          {
              message: {
                  text: message,
                  status: status,
              },
          },
      ]);
  };


  const deleteFbConnection = () => {
    removeFbConnection(connectionToDlt.fbId)
    .then((res) => {
      setFacebookConnections(facebookConnections.filter(connection => connection?.fbId !== res.data.data.deletedUser?.fbId ));
      alertMessage((t("Connection deleted!")),'Success');
    })
    .catch((error) => console.error(error))


    setConnectionToDlt(false)
  }

  return (
    <ChangeFbConnection>
      {connectionToDlt !== false  ?
       <Confirmation_
          message={t("Are you sure you want to delete this connection?")}
          no={t("No")}
          onNo={() => setConnectionToDlt(false)}
          onYes={deleteFbConnection}
          yes={t("Yes")}
      /> : null}
      {loadingFacebook ? (
          <Partial_Loader />
      ) : (
        <ConnectionsWrapper>
          {selectedFacebookAccount?.username  ? 
            <Connection>
              <h5>{selectedFacebookAccount?.username}</h5>
              <ConnectionSuccess onClick={() => {        
                const initObj = {
                  userId: "",
                  accessToken: "",
                  username: "",
                };
                chooseFacebookAccount(initObj)
                }}
              >
                <IconSvg Icon='Check' />
                <h6 className='connected'>{t('Connected')}</h6>
              </ConnectionSuccess>
            </Connection> 
          : null }
          {filteredFbConnections.map((connection, i) => (
            <Connection key={i}>
            <h5>{connection.name}</h5>
            {/* <p className='connected'>Connected</p> */}
              <AccountActions>
                  <Main_Plain text={t('Connect')} onClick={() => chooseFacebookAccount(connection)} noPadding/>
                  <Red_ noPadding iconLeft='Delete' onClick={() => setConnectionToDlt(connection)} />
              </AccountActions>
          </Connection>
          )) }
          </ConnectionsWrapper>
      )}
      <Facebook_
            icon size="medium" id="Connect_Facebook_Button" text={t('Connect new account')}
            onClick={loginToFacebook}
        />
    </ChangeFbConnection>
  )
}
