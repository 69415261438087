export function calculateDaysDifference(date_start, date_stop) {
    // Convert the date strings to Date objects
    const startDate = new Date(date_start);
    const stopDate = new Date(date_stop);
  
    // Calculate the time difference in milliseconds
    const timeDifference = stopDate - startDate;
  
    // Convert the time difference to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    
    const daysDifferencePlusOne = daysDifference+1;

    return daysDifferencePlusOne;
}
