import { Card, Main_Plain } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const CardOnSource = styled(Card)`
    cursor: pointer;
    box-shadow: ${p => p.theme.out};

    &:hover{
      box-shadow: ${p => p.theme.outFocus};
    }
`

const CardOnSourceTop = styled.div`
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, ${p => p.firstColor}, ${p => p.secondColor});
`

const CardOnSourceImg = styled.img`
  height: 8rem;
`
const CardOnSourceContent = styled.div`
  background: ${p => p.theme.background};
  padding: 2rem;
  border-radius: 1.5rem;
  transform: translateY(-2rem);
  height: calc(100% - 13rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardOnSourceDesc = styled.div`

  h3{
    margin-bottom: 2rem;
  }
`

const CardOnSourceActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
`

export default function Card_On_Source(props) {

  return (
    <CardOnSource onClick={props.onClick} >
      <CardOnSourceTop firstColor={props.firstColor} secondColor={props.secondColor}>
        <CardOnSourceImg src={props.img} />
      </CardOnSourceTop>
      <CardOnSourceContent>
        <CardOnSourceDesc>
          <h3>{props.title}</h3>
          <p>{props.text}</p>
        </CardOnSourceDesc>
        <CardOnSourceActions>
          <Main_Plain text={props.textBtn} iconLeft={props.iconBtn} noPadding/>
        </CardOnSourceActions>
      </CardOnSourceContent>
    </CardOnSource>
  )
}
