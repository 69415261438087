import React, { useState, useEffect, useContext } from 'react';
import { googleLogin, getGoogleConnections } from '../../sources/google/services/api/GoogleApi';
import { PageFull, Main_, Confirmation_, Partial_Loader, Main_Loader, Main_Plain, Grey_, Grey_Link, PageForty, PageAlwaysVisible, PageTitle, Red_, Slider_, Builder_, On_Click_Card, Actions_, AlertContext, Alert_ } from 'monica-alexandria';
import styled from 'styled-components';
import { Google_ } from '../../sources/google/pages/connect/components/Google_Button';
import { useTranslation } from 'react-i18next';
import store from '../../redux/store';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { fbLogin, fbConnections } from '../../sources/meta/services/api/MetaApi';
import { Change_Google_Connection } from './components/Change_Google_Connection';
import { Change_Fb_Connection } from './components/Change_Fb_Connection';

const SectionWrapper = styled.div`
    box-shadow: ${(p) => p.theme.out};
    padding: var(--largePads);
    margin-bottom: 2rem;
    border-radius: var(--smallRadius);
    position: relative;
    margin-bottom: 2rem;
`;



const GoogleAccount = styled.div`
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    gap: .5rem;
    background: ${(p) => p.theme.low};
    border-radius: var(--smallRadius);
    padding: var(--normalPads);
    
    img{
        width: 3.5rem;
    }
`;
const Container = styled.div`
    /* display: flex;
    justify-content: space-between; */
    gap: 2rem;
    cursor: pointer;
    /* padding-bottom: 2rem; */
`;



const Column = styled.div`
    display: flex;
    flex-direction: column;
`;


const NotConnected = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const SectionTitle = styled.h3`
    padding-bottom: 1rem;
`;

const AccountName = styled.p`
    margin: 0;
`;

const AccountActions = styled.div`
    display: flex;
    align-items: center;
`
const TestWrapper = styled.div`
    display: flex;
    justify-content: right;
`


const Connection = styled.div`
    border-radius: var(--smallRadius);
      box-shadow: ${(p) => p.theme.out};
   /* background: ${(p) => p.theme.mid}; */
   padding: var(--normalPads);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .connected{
        color: var(--success);
    }
`

const ConnectionsWrapper = styled.div`
    padding-bottom: 2rem;

`

const CardWrapper = styled.div`
    margin-bottom: 2rem;
`

const CreateButton = styled.div`
    .disabled{
        pointer-events: none;
        opacity: 0.5;
    }

`

export default function INTEGRATIONS() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let redirectURL = queryParams.get('redirectURL');
    const lang = i18n.language || 'en';
    const selectedTemplate = store.getState()?.app?.creator?.preSelected?.template;
    const sections = selectedTemplate?.presetStructureId?.sections || [];
    const sources = [...new Set(sections.filter(obj => obj.source).map(obj => obj.source))];

    const [googleConnections, setGoogleConnections] = useState([]);
    const [selectedGoogleAccount, setSelectedGoogleAccount] = useState(null);
    const [facebookConnections, setFacebookConnections] = useState([]);
    const [selectedFacebookAccount, setSelectedFacebookAccount] = useState(null);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [loadingFacebook, setLoadingFacebook] = useState(false);

    const { alerts, setAlerts } = useContext(AlertContext);

    const [confirmation, setConfirmation] = useState(false);


    const [changeConnection, setChangeConnection] = useState(false)

    const connectToGoogleAccount = () => {

        if(redirectURL) {
            store.dispatch({
                type: 'SAVE_REDIRECT_URL',
                payload: redirectURL,
            });
        }
        
        googleLogin({ shouldSelectAccount: true })
            .then((response) => {
                window.location.assign(response?.data?.data?.authenticationURL);
            })
            .catch(error => { console.error(error); });
    };
    
    const loginToFacebook = () => {
        window.FB.login(
            function (response) {
                console.log(response);
                fbLogin(response)
                .then((res) => {
                    console.log('RESSSSSSSSSS FB', res);
                    chooseFacebookAccount(res.data.data.fbUser);
                    setFacebookConnections([...facebookConnections, res.data.data.fbUser])
                })
                .catch((error) => console.error(error));
            },
            {
                scope: 'ads_management,business_management,ads_read,instagram_basic,instagram_manage_insights,pages_show_list, pages_read_engagement,read_insights',
                extras: {
                    setup: {
                        external_business_id: '12345',
                        timezone: 'Europe/Athens',
                        currency: 'EUR',
                        business_vertical: 'ECOMMERCE',
                    },
                    business_config: {
                        business: {
                            name: 'Adsnap Doitforme',
                        },
                    },
                },
            }
        );
    };

    const chooseGoogleAccount = (connection) => {
        console.error(connection);
        const googleObject = {
            userId: connection.googleId,
            accessToken: connection.accessToken,
            username: connection.name,
        };
        store.dispatch({
            type: 'SAVE_GOOGLE_USER_PROFILE',
            payload: googleObject,
        });
        setSelectedGoogleAccount(googleObject);
        // setChangeConnection(false);
    };

    const chooseFacebookAccount = (connection) => {
        console.error('cONNECTION',connection);
        const fbObject = {
            userId: connection.fbId,
            accessToken: connection.accessToken,
            username: connection.name,
        };
        store.dispatch({
            type: 'SAVE_FACEBOOK_USER',
            payload: fbObject,
        });
        setSelectedFacebookAccount(fbObject);
        // setChangeConnection(false);
    };

    useEffect(() => {
        const fetchGoogleConnections = async () => {
            setLoadingGoogle(true);
            try {
                const googleRes = await getGoogleConnections();
                setGoogleConnections(googleRes.data.data.validConnections);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingGoogle(false);
            }
        };

        const fetchFacebookConnections = async () => {
            setLoadingFacebook(true);
            try {
                const fbRes = await fbConnections();
                setFacebookConnections(fbRes.data.data.validConnections);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingFacebook(false);
            }
        };

        const redirectUrlFromCache = store.getState()?.app?.redirectUrl;
        console.log(redirectUrlFromCache);
        if(redirectUrlFromCache) {
            navigate(`/${lang}/integrations?redirectURL=${redirectUrlFromCache}`);
            // after navigation we remove this url from cache
            store.dispatch({
                type: 'SAVE_REDIRECT_URL',
                payload: "",
            });
        }

        fetchGoogleConnections();
        fetchFacebookConnections();
    }, []);

    const fbState = () => {

        if(sources.includes("meta") && !selectedFacebookAccount?.userId) 
            return false;
        else 
            return true;
    }

    const googleState = () => {
        if(sources.includes("google") && !selectedGoogleAccount?.userId) 
            return false;
        else 
            return true;
    }

    useEffect(() =>{
        // const accessedAccounts = store.getState().app?.accessedAccounts;
        // setSelectedFacebookAccount({...selectedFacebookAccount, userId: accessedAccounts?.meta?.userId || '', username: accessedAccounts?.meta?.username, accessToken:  accessedAccounts?.meta?.accessToken })
        // setSelectedGoogleAccount({...selectedGoogleAccount, userId: accessedAccounts?.google?.userId || '', username: accessedAccounts?.google?.username, accessToken:  accessedAccounts?.google?.accessToken })
        const initObj = {
            userId: "",
            accessToken: "",
            username: "",
        };
        store.dispatch({
            type: 'SAVE_GOOGLE_USER_PROFILE',
            payload: initObj,
        });

        store.dispatch({
            type: 'SAVE_FACEBOOK_USER',
            payload: initObj,
        });
    }, [])
  
    return (
        !loadingFacebook
        && !loadingGoogle ?
        (<PageForty>
            {confirmation !== false  ?
                <Confirmation_
                    message={t("When connecting your Google Account please carefully review and check the box that allows our application to access your Google Ads data.")}
                    no={t("Back")}
                    onNo={() => setConfirmation(false)}
                    onYes={connectToGoogleAccount}
                    yes={t("Continue")}
                /> : null}
            <Alert_ messages={alerts} />
             {changeConnection !== false ? 
                <Slider_
                    title = { changeConnection==='google' ?  (t('Available Google Connections'))  :  (t('Available Facebook Connections'))}
                    done = {t('Done')}
                    onClick = {() => setChangeConnection(false)}
                    onClose = {() => setChangeConnection(false)}
                >

                    <Builder_>
                        {changeConnection==='google' ? 
                            <Change_Google_Connection 
                                loadingGoogle={loadingGoogle} 
                                googleConnections={googleConnections}
                                setGoogleConnections={(connection) => setGoogleConnections(connection)}
                                connectToGoogleAccount={connectToGoogleAccount}
                                chooseGoogleAccount={chooseGoogleAccount}
                                selectedGoogleAccount={selectedGoogleAccount}
                                setConfirmation={(value) => setConfirmation(value)}
                            /> : 
                            <Change_Fb_Connection 
                                loadingFacebook={loadingFacebook}
                                facebookConnections={facebookConnections}
                                setFacebookConnections={(connection) => setFacebookConnections(connection)}
                                chooseFacebookAccount={chooseFacebookAccount}
                                loginToFacebook={loginToFacebook}
                                selectedFacebookAccount={selectedFacebookAccount}
                            /> 
                        }
                    </Builder_>
                </Slider_> : null }


            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t('Integrations')}</h1>
                </PageTitle>
                {sources.includes("google") && <>
                { selectedGoogleAccount?.accessToken  ? 
                    <SectionWrapper>
                        <Container onClick={()=>setChangeConnection('google')}>
                            <Column>
                                <SectionTitle>{t('You are connected as')}</SectionTitle>
                                {loadingGoogle ? (
                                    <Partial_Loader />
                                ) : (
                                    <GoogleAccount>
                                        <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/googleLogo.png' />
                                        <AccountName>{selectedGoogleAccount?.username}</AccountName>
                                    </GoogleAccount>
                                )} 
                            </Column>
                            <TestWrapper>
                                <Main_Plain iconLeft='Recycle' onClick={()=>setChangeConnection('google')} text={t('Change')} />
                            </TestWrapper> 
                        </Container>
                    </SectionWrapper>
                    :  
                    googleConnections?.length === 0 ?
                        <CardWrapper>
                            <On_Click_Card
                                imgSmall = 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/googleLogo.png' 
                                subtitle={t('Connect new account')}
                                onClick={() => setConfirmation(true)}
                            />
                        </CardWrapper>
                    :  
                    <CardWrapper>
                        <On_Click_Card
                            imgSmall = 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/googleLogo.png' 
                            subtitle={t('Connect')}
                            onClick={()=>setChangeConnection('google')}
                        />
                    </CardWrapper>
                }
                </>}
            {sources.includes("meta") && <>
            { selectedFacebookAccount?.username ? 
                <SectionWrapper>
                    <Container  onClick={()=>setChangeConnection('fb')}>
                        <Column>
                            <SectionTitle>{t('You are connected as')}</SectionTitle>
                            {loadingFacebook ? (
                                <Partial_Loader />
                            ) : (
                                <GoogleAccount>
                                    <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/FacebookBlueLogo.png' />
                                    <AccountName>{selectedFacebookAccount?.username}</AccountName>
                                </GoogleAccount>
                            )}
                        </Column>
                      
                        <TestWrapper>
                            <Main_Plain iconLeft='Recycle' onClick={()=>setChangeConnection('fb')} text={t('Change')} />
                        </TestWrapper> 
                    </Container>
                </SectionWrapper>
                : 
                facebookConnections?.length === 0  ? 
                <On_Click_Card
                    imgSmall = 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/FacebookBlueLogo.png' 
                    subtitle={t('Connect new account')}
                    onClick={loginToFacebook}
                />
                : 
                <On_Click_Card
                    imgSmall = 'https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/FacebookBlueLogo.png' 
                    subtitle={t('Connect')}
                    onClick={()=>setChangeConnection('fb')}
                />            
            }
            </>}
    
            <Actions_>
                <Grey_ iconLeft='Back' onClick={()=>navigate(-1)} text={t('Back')} />
                <CreateButton>
                    <Main_ 
                    className={!(fbState() && googleState()) ? 'disabled' :''} 
                    text={!redirectURL ? (t('Create report')) :  (t('Edit report')) } 
                    iconRight='Next' 
                    onClick={() =>  {
                        if(!redirectURL)
                            navigate(`/${lang}/creator`)
                        else
                            navigate(redirectURL)

                    }} />
                </CreateButton>
               
            </Actions_>
           
            </PageAlwaysVisible>
        </PageForty>)
        : <Main_Loader />
    );
}
