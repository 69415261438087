import styled from "styled-components";

export const ConnectGoogleActions = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    justify-content: space-between;
    margin-top: 4rem;
`

export const ConnectGoogleActionsRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .DifferentAccount{
        margin-right: .5rem;
    }
`

export const ConnectGoogleActionsButtonWrapper = styled.div`
    margin-bottom: 1rem;
`

