import { Grey_Link, Main_Plain, Red_ } from 'monica-alexandria';
import React from 'react';
import styled from 'styled-components';

const SnippetVerticalActions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: ${(p) => (p.viewMode ? 'none' : 'flex')};
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .GreyLink {
        cursor: grab !important;
    }
`;

export const Snippet_Vertical_Actions = (props) => {
    return (
        <SnippetVerticalActions viewMode={props.viewMode}>
            {/* <Grey_Link iconLeft="Ham" noPadding /> */}
            {/* {!props.hideEdit ? <Main_Plain iconLeft="Edit" noPadding onClick={props.onEdit} /> : null} */}
            { !props.hideEdit ? <Main_Plain iconLeft="Edit" noPadding onClick={props.onEdit} /> : null }
            <Red_ iconLeft="Delete" noPadding onClick={props.onDelete} />
        </SnippetVerticalActions>
    );
};
