import styled from 'styled-components';
export const Report = styled.div`
    padding: 2rem 0.5rem;

    #reportPDF {
    }
`;

export const ReportWrapper = styled.div`
    margin: 0 auto;
    background: ${(p) => p.theme.background};
    border-radius: var(--normalRadius);
    box-shadow: ${(p) => p.theme.out};
    overflow: hidden;
    max-width: 900px;
`;

export const ReportHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${(p) => p.theme.mid};
    padding: 1rem;

    div{ 
        display: flex;
        align-items: center;
    }
`;
export const ReportContainer = styled.div`
    width: 900px;
    background: var(--white);
    padding: 2rem 1rem;
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,  
    h5,
    h6,
    p {
        color: var(--black);
    }
`;








