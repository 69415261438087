import React, { useContext } from 'react';
import { Grey_Link, Main_Plain, Red_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Snippet_Horizontal_Actions } from '../snippets/Snippet_Horizontal_Actions';

const CreatorTitleRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
    gap: 1rem;

    h6 {
        font-family: ${(p) => p.presetStyle?.font.family};
        color: ${(p) => p.presetStyle?.color.textDark};
    }
`;

export default function Creator_Title_Row(props) {
    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    return (
        <CreatorTitleRow presetStyle={reportStyle}>
            <h6>{props.title}</h6>
            <Snippet_Horizontal_Actions viewMode={props.viewMode} onDelete={props.onDelete} onEdit={props.onEdit} />
        </CreatorTitleRow>
    );
}
