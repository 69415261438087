import { CardsSixty, Section_Title, Slider_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const DemographicKpiChartSelection = styled.div``


export default function Add_Meta_Row_Demographics_Charts(props) {
    console.log(props);
    const { t } = useTranslation();

    const [isActive, setIsActive] = useState({
        country: props?.elements?.some((item) => item?.type === "countries" && item?.originalName === props.kpi?.name),
        gender: props?.elements?.some((item) => item?.type === "genders" && item?.originalName === props.kpi?.name),
        age: props?.elements?.some((item) => item?.type === "ages" && item?.originalName === props.kpi?.name),
    })


    useEffect(() => {
        setIsActive({
            country: props?.elements?.some((item) => item?.type === "countries" && item?.originalName === props.kpi?.name),
            gender: props?.elements?.some((item) => item?.type === "genders" && item?.originalName === props.kpi?.name),
            age: props?.elements?.some((item) => item?.type === "ages" && item?.originalName === props.kpi?.name),
        })
    },[props.elements])
    
    return (
        <Slider_ onClick={props.onBack} text={t('Done')} title={t('Demographic Charts')} iconGreyLeft="Back" grey={t('Back')} onGrey={props.onBack}>
            <DemographicKpiChartSelection>
                <Section_Title text={t('Charts')} />
                <CardsSixty>
                    {props.countryDemographicAvailable.some(item => item.name === props.kpi.name) ? 
                        <Toggle_Stat 
                            text={t("Map")} 
                            onClick={() => (!isActive.country ? props.onAddDemographic('countries') : props.onRemoveDemographic('countries'))} 
                            active={isActive.country} 
                        />
                        : null}
                    {props.genderDemographicAvailable.some(item => item.name === props.kpi.name) ?              
                        <Toggle_Stat 
                            text={t("Genders")} 
                            onClick={() => (!isActive.gender ? props.onAddDemographic('genders') : props.onRemoveDemographic('genders'))} 
                            active={isActive.gender} 
                        />
                        : null}
                    {props.ageDemographicAvailable.some(item => item.name === props.kpi.name) ?                    
                        <Toggle_Stat
                            text={t("Ages")} 
                            onClick={() => (!isActive.age ? props.onAddDemographic('ages') : props.onRemoveDemographic('ages'))} 
                            active={isActive.age} 
                        /> : null}
                </CardsSixty>
            </DemographicKpiChartSelection>
        </Slider_>
    );                              
}