import { _Nav } from 'monica-alexandria';
import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Protector } from '../../../services/authentication/Protector';
import store from '../../../redux/store';
import CREDENTIALS from '../pages/credentials/CREDENTIALS';


export default function WOOCOMMERCE_ROUTER() {

    const language = store.getState().selectedLanguage ? store.getState().selectedLanguage : 'en'

    return (
        <Routes>
            <Route exact path="/" element={
                <Navigate to={{pathname: `/${language}/`,}}/>
            }/>

            <Route path="/credentials" element={<Protector role="Doer"><CREDENTIALS /></Protector>}/>
        </Routes>
    )
}