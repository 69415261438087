//         ,''',
//        .' ., .',                                  ../'''',
//        .'. %%, %.',                            .,/' .,%   :
//       .'.% %%%,`%%%'.    .....,,,,,,.....   .,%%% .,%%'. .'
//       : %%% %%%%%%',:%%>>%>' .,>>%>>%>%>>%%>,.   `%%%',% :                         ||```````````````````````````||
//       : %%%%%%%'.,>>>%'   .,%>%>%'.,>%>%' . `%>>>,. `%%%:'                         ||                           ||
//       ` %%%%'.,>>%'  .,%>>%>%' .,%>%>%' .>>%,. `%%>>,. `%                          ||      ΠΡΟΣΟΧΗ: ΝΕΥΡΙΑΖΕΙ   ||
//        `%'.,>>>%'.,%%%%%%%' .,%%>%%>%' >>%%>%>>%.`%% %% `,                         ||     ΑΝ ΑΚΟΥΜΠΗΣΕΤΕ ΤΟΝ    ||
//        ,`%% %%>>>%%%>>%%>%%>>%>>%>%%%  %%>%%>%%>>%>%%%' % %,                       ||     ΚΩΔΙΚΑ Ή ΤΟ ΦΑΙ ΤΟΥ   ||
//      ,%>%'.>>%>%'%>>%%>%%%%>%'                 `%>%>>%%.`%>>%.                     ||                           ||
//    ,%%>' .>%>%'.%>%>>%%%>>%' ,%%>>%%>%>>%>>%>%%,.`%%%>%%. `%>%.                    ===============================
//   ` ,%' .>%%%'.%>%>>%' .,%%%%%%%%'          `%%%%%%.`%%>%% .%%>
//    .%>% .%%>' :%>>%%'.,%%%%%%%%%'.%%%%%' `%%%%.`%%%%%.%%%%> %%>%.
//   ,%>%' >>%%  >%' `%%%%'     `%%%%%%%'.,>,. `%%%%'     `%%%>>%%>%
//  .%%>%' .%%>'  %>>%, %% oO ~ Oo %%%>>'.>>>>>>. `% oO ~ Oo'.%%%'%>%,
//   %>'%> .%>%>%  %%>%%%'  `OoooO'.%%>>'.>>>%>>%>>.`%`OoooO'.%%>% '%>%
//   %',%' %>%>%'  %>%>%>% .%,>,>,   `>'.>>%>%%>>>%>.`%,>,>' %%%%> .>%>,
//   ` %>% `%>>%%. `%%% %' >%%%%%%>,  ' >>%>>%%%>%>>> >>%%' ,%%>%'.%%>>%.
//   .%%'  %%%%>%.   `>%%. %>%%>>>%.>> >>>%>%%%%>%>>.>>>'.>%>%>' %>>%>%%
//   `.%%  `%>>%%>    %%>%  %>>>%%%>>'.>%>>>>%%%>>%>>.>',%>>%'  ,>%'>% '
//    %>'  %%%%%%'    `%%'  %%%%%> >' >>>>%>>%%>>%>>%> %%>%>' .%>%% .%%
//   %>%>, %>%%>>%%,  %>%>% `%%  %>>  >>>%>>>%%>>>>%>>  %%>>,%>%%'.%>%,
//    %>%>%%, `%>%%>%>%, %>%%> ,%>%>>>.>>`.,.  `"   ..'>.%. % %>%>%'.%>%%;
//    %'`%%>%  %%>%%  %>% %'.>%>>%>%%>>%::.  `,   /' ,%>>>%>. >%>%'.%>%'%'
//    ` .%>%'  >%%% %>%%'.>%>%;''.,>>%%>%%::.  ..'.,%>>%>%>,`%  %'.>%%' '
//    %>%>%% `%>  >%%'.%%>%>>%>%>%>>>%>%>>%,,::,%>>%%>%>>%>%% `>>%>'
//     %'`%%>%>>%  %>'.%>>%>%>>;'' ..,,%>%>%%/::%>%%>>%%,,.``% .%>%%
// `    `%>%>>%%' %>%%>>%>>%>%>%>%%>%/'       `%>%%>%>>%%% ' .%'
//       %'  `%>% `%>%%;'' .,>>%>%/',;;;;;,;;;;,`%>%>%,`%'   '
//        `    `  ` `%>%%%>%%>%%;/ @a;;;;;;;;;;;a@  >%>%%'                  //////////////////////////////////////////////
//                   `/////////';, `@a@@a@@a@@aa@',;`//'                    // Περιγραφή: ΡΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ-
//                       `//////.;;,,............,,;;//'                    // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                         `////;;;;;;;;;;;;;;;;;/'                         // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                            `/////////////////'                           // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                                                                          // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΡΡ
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { ThemeContext, LangContext, MenuContext, Page, RegionContext, Theme, ThemeDark, ThemeLight, _Nav, AlertContext } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GuardedRoute } from '../services/authentication/Guard';
import Navbar_ from '../components/navbar/Navbar_';
import { Protector } from '../services/authentication/Protector';
import TRANSLATIONS from '../admin/views/translations/TRANSLATIONS';
import PANEL from '../admin/views/panel/PANEL';
import NO_ACCESS from '../admin/views/noAccess/NO_ACCESS';
import { init, reconstructUrl } from '../helpers/initMenuOptions';
import { useSelector } from 'react-redux';
import LANDING from '../pages/landing/LANDING';
import SUBSCRIBE from '../pages/subscribe/SUBSCRIBE';
import META_ROUTER from '../sources/meta/router/Router';
import i18n from '../localization/i18next';
import GOOGLE_ROUTER from '../sources/google/router/Router';
import COUPONS from '../admin/views/coupons/COUPONS';
import WOOCOMMERCE_ROUTER from '../sources/woocommerce/router/Router';
import ANALYTICS from '../admin/views/analytics/ANALYTICS';
import { ReportStyleContext } from '../sources/meta/context/ReportStyleContext';
import FEATURES from '../pages/features/FEATURES';
import PRICING from '../pages/pricing/PRICING';
import MY_REPORTS from '../pages/myReports/MY_REPORTS';
import FIRST_REPORT from '../pages/firstReport/FIRST_REPORT';
import { FileContext } from '../sources/meta/context/FileContext';
import SUCCESS_PAYMENT from '../pages/SUCCESS_PAYMENT.js/SUCCESS_PAYMENT';
import REPORT from '../pages/report/REPORT';
import REPORT_TEMPLATE from '../sources/meta/pages/reportTemplate/REPORT_TEMPLATE';
import INTEGRATIONS from '../pages/integrations/INTEGRATIONS';
import CREATOR from '../sources/meta/pages/creator/CREATOR';
import CALLBACK from '../sources/google/pages/callback/CALLBACK';
import CONNECT from '../sources/google/pages/connect/CONNECT';
import CONNECTION_ERROR from '../pages/connectionError/CONNECTION_ERROR';
export default function Microservice() {
    const user = useSelector((state) => state.user.user);

    const navigate = useNavigate();

    const [theme, setTheme] = useState();
    const [language, setLanguage] = useState();
    const [region, setRegion] = useState();
    const [activeMenu, setActiveMenu] = useState('Main');
    const [alerts, setAlerts] = useState([]);

    const [reportStyle, setReportStyle] = useState({
        color: {
            background: '#f7f7f7',
            focus: '#c83ab0',
            main: '#5bb6b0',
            textLight: '#f7f7f7',
            textDark: '#3c3c3c',
            grey: '#e0e1e1',
        },
        radius: '1rem',
        font: {
            familly: 'cpm'
        }
    });

    const [file, setFile] = useState();
    
    // To update a value, for example primary color:
    const updatePrimaryColor = (newColor) => {
        setReportStyle(prevState => ({
            ...prevState,
            color: {
                ...prevState.color,
                primary: newColor
            }
        }));
    };

    useEffect(() => {
        if ( i18n.language && reconstructUrl(language)) navigate(reconstructUrl(language));
    }, [language]);

    useEffect(() => {
        init(user, setTheme, setLanguage, setRegion);
    }, [user]);

    return theme && language && region ? (
        <LangContext.Provider value={{ language, setLanguage }}>
            <RegionContext.Provider value={{ region, setRegion }}>
                <ThemeContext.Provider value={{ theme, setTheme }}>
                    <AlertContext.Provider value={{ alerts, setAlerts }}>
                        <ThemeProvider theme={theme === 'dark' ? ThemeDark : ThemeLight}>
                        <ReportStyleContext.Provider value={{reportStyle, setReportStyle}}>{/* Helps with report custom styling */}
                            <FileContext.Provider value={{file, setFile}}>
                                <Theme>
                                    <Page>
                                        <MenuContext.Provider value={{ activeMenu, setActiveMenu }}>
                                            <GuardedRoute>
                                                <Navbar_ />
                                            </GuardedRoute>
                                        </MenuContext.Provider>
                                        <Routes>
                                            { /* AdSnap */ }
                                            <Route path="/" element={
                                                <Navigate to={{pathname: `/${language}/`,}}/>
                                            }/>
                                            <Route path="/" element={<GuardedRoute><LANDING /></GuardedRoute>}/>
                                            <Route path="/:lang" element={<GuardedRoute><LANDING/></GuardedRoute>}/>
                                            <Route path="/:lang/features" element={<GuardedRoute><FEATURES /></GuardedRoute>}/>
                                            <Route path="/:lang/pricing" element={<GuardedRoute><PRICING/></GuardedRoute>}/>

                                            <Route path="/:lang/my-reports" element={<Protector role="Doer"><MY_REPORTS /></Protector>}/>
                                            <Route path="/:lang/success-payment" element={<Protector role="Doer"><SUCCESS_PAYMENT /></Protector>}/>
                                            <Route path="/:lang/report-template" element={<Protector role="Doer"><REPORT_TEMPLATE /></Protector>}/>
                                            <Route path="/:lang/integrations" element={<Protector role="Doer"><INTEGRATIONS /></Protector>}/>

                                            { /* Protected */ }
                                            <Route path="/:lang/welcome" element={<Protector><FIRST_REPORT/></Protector>}/>
                                            { /* Meta Source */ }
                                            <Route path="/:lang/meta/*" element={<META_ROUTER/>}/>
                                            <Route path="/:lang/creator" element={<Protector role="Doer"><CREATOR /></Protector>}/>
                                            <Route path="/:lang/creator/:id/:mode" element={<Protector role="Doer"><CREATOR /></Protector>}/>

                                            { /* Google Source */ }
                                            <Route path="/:lang/google/callback" element={<CALLBACK/>}/>
                                            <Route path="/:lang/google/connect" element={<CONNECT/>}/>
                                            <Route path="/:lang/connection-error" element={<CONNECTION_ERROR/>}/>


                                            { /* Woocommerce Source */ }
                                            <Route path="/:lang/woocommerce/*" element={<WOOCOMMERCE_ROUTER/>}/>
    
                                            {/* Admin */}
                                            <Route path="/:lang/akihiko/lois" element={<Protector role="Knight"><PANEL /></Protector>}/>
                                            <Route path="/:lang/akihiko/lois/translations" element={<Protector role="Knight"><TRANSLATIONS /></Protector>}/>
                                            <Route path="/:lang/akihiko/lois/coupons" element={<Protector role="Knight"><COUPONS /></Protector>}/>
                                            <Route path="/:lang/akihiko/lois/analytics" element={<Protector role="Knight"><ANALYTICS /></Protector>}/>

                                            {/* MISC */}
                                            <Route path="/:lang/access-denied" element={<NO_ACCESS />} />

                                            {/* View Report */}
                                            <Route path="/:lang/report/:id" element={<REPORT />} />

                                        </Routes>
                                    </Page>
                                </Theme>
                                </FileContext.Provider>
                            </ReportStyleContext.Provider>
                        </ThemeProvider>
                    </AlertContext.Provider>
                </ThemeContext.Provider>
            </RegionContext.Provider>
        </LangContext.Provider>
    ) : (
        <></>
    );
}