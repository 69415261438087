import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { Steps_Actions, Google_Search, Builder_, CardsSixty, Section_Title, Toggle_Stat, Partial_Loader, CardsForty , On_Click_Card} from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import Snippet_Toggle_Donot from '../snippets/Snippet_Donot';
import Slider_Expand from '../slider/Slider_Expand';

const WizardGoogleAdAccount = styled.div``;
const BusinessManagerWrapper = styled.div``;
const GoogleAddAccountWrapper = styled.div``;
const GoogleCampaignWrapper = styled.div``;


export default function Wizard_Google_Ad_Account(props) {
    const { t } = useTranslation();


    const fetchBusinessesError = false; 
    const [displayedBusinesses, setDisplayedBusinesses] = useState(props.managerAccounts);
    const [displayedAdAccounts, setDisplayedAdAccounts] = useState(props.adAccounts);
    const [displayedCampaigns, setDisplayedCampaigns] = useState([]);
    const [filteredCampaigns, setFilteredCampaigns] = useState([]);

    const [searchResultsBusiness, setSearchResultsBusiness] = useState();
    const [searchResultsAdAccounts, setSearchResultsAdAccounts] = useState();
    const [searchResultsCampaigns, setSearchResultsCampaigns] = useState();

    const currSection = props.selectedSections[props.currentSectionIndex + 1];
    const [currManager, setCurrBusiness] = useState(currSection?.parentManager);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsBusiness?.input) setDisplayedBusinesses(searchResultsBusiness?.data);
            else setDisplayedBusinesses(props.managerAccounts);
        };
        applySearchResults();
    }, [searchResultsBusiness]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsAdAccounts?.input) setDisplayedAdAccounts(searchResultsAdAccounts?.data);
            else setDisplayedAdAccounts(props.adAccounts);
        };
        applySearchResults();
    }, [searchResultsAdAccounts]);


    useEffect(() => {
        const applySearchResults = () => {
            if (searchResultsCampaigns?.input) setDisplayedCampaigns(searchResultsCampaigns?.data);
            else setDisplayedCampaigns(filteredCampaigns);
        };

        applySearchResults();
    }, [searchResultsCampaigns]);

    useEffect(() => {        
        setDisplayedAdAccounts(props.adAccounts);
    }, [props.adAccounts]);

    useEffect(() => {

        setFilteredCampaigns(props.campaigns?.filter((camp) => camp?.customerId === currSection?.sectionData[0]?.id));
        setDisplayedCampaigns(props.campaigns?.filter((camp) => camp?.customerId === currSection?.sectionData[0]?.id));

    }, [props.campaigns]);



    useEffect(() => {        
        setDisplayedAdAccounts(props.adAccounts);
    }, [props.adAccounts]);


    const [activeTab, setActiveTab] = useState(); // Manage active tab state

    useEffect(() => {
        if(props.managerAccounts.length === 0 && props.accessibleAdAccounts.length !== 0) {
            setActiveTab("withoutManager")
        }
        else if(props.managerAccounts.length !== 0 && props.accessibleAdAccounts.length === 0) {
            setActiveTab("withManager")
        }
        else {
            setActiveTab("")
        }
    }, []);

    return (
        <WizardGoogleAdAccount>
            <h3>{t("Google Ads")}</h3>

            {activeTab === "withManager"  ? 
            <>
                {props.managerAccounts?.length > 15 &&
                    <Builder_ >
                        <Google_Search file={props.managerAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true} placeholder={t('Search Google Manager Account')} setResults={(data) => setSearchResultsBusiness(data)} debounceTime={750} minMatchCharacters={3} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.8} />
                    </Builder_> 
                }
                <Section_Title text={t('Select Google Manager Account')} />         
                <CardsSixty>
                    {!currManager ?
                        <>
                        {displayedBusinesses?.map((item, i) => {
                            const isAdded = item.id === currManager?.id;
                            return (
                                <Toggle_Stat 
                                    key={i} id={i} text={item.descriptiveName} active={isAdded} 
                                    onClick={() => !isAdded ? (props.addSource(item, 'google'), setCurrBusiness(item)) : props.removeSource(item, 'google')} 
                                />
                            )
                        })}
                        </>
                        :
                        <Toggle_Stat
                            key={props.selectedManager?.id} id={0} text={props.selectedManager?.descriptiveName} active={true}
                            onClick={() => {
                                props.removeSource('google');
                                setCurrBusiness(null);
                                if(props.selectedAdAccounts?.length > 0) {
                                    props.removeSection();
                                }
                            }}
                        />
                    }
                </CardsSixty>   
                
                {currManager ? 
                <GoogleAddAccountWrapper>
                    {props.adAccounts?.length > 15 ?
                    <Builder_ >
                        <Google_Search file={props.adAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true} placeholder={t('Search Ad Account')} setResults={(data) => setSearchResultsAdAccounts(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                    </Builder_> 
                    : null }
                    <Section_Title text={t('Select Google Ad Account')} />
                    {props.adAccountLoader ? <Partial_Loader/> :
                    <CardsSixty>
                        {props.selectedSections[props.currentSectionIndex+1]?.sectionTitle === "Google Ad Account"?
                        <>
                        {displayedAdAccounts
                            ?.filter((adAccount) => adAccount?.parentManagerInfo?.id === props.selectedManager?.id)
                            ?.map((item, i) => {
                                const isAdded = props.selectedSections?.some((obj) => obj.type === 'googlePaid' && obj.sectionData?.find((sd) => sd.type === 'googleAdAccount').id === item.id);
                                return (
                                    <Toggle_Stat 
                                        key={i} id={i} text={item.descriptiveName} active={isAdded}     
                                        onClick={() => !isAdded ? props.addSection("google", item)  : props.removeSection("google", item) }
                                    />    
                                )                                                                
                        })}
                        </>
                        : 
                        <Toggle_Stat
                            key={props.selectedSections[props.currentSectionIndex+1]?.id} id={0}
                            text={props.selectedSections[props.currentSectionIndex+1]?.sectionTitle}
                            onClick={() => {props.removeSection("google") }}
                            active={true}
                        />
                        }
                    </CardsSixty>
                    }   
                    {(props.selectedSections[props.currentSectionIndex+1]?.sectionTitle !== "Google Ad Account" && filteredCampaigns?.length >= 0) &&
                    <Slider_Expand
                        filteredCampaigns={filteredCampaigns}
                        setSearchResults={setSearchResultsCampaigns}
                        loadingCampaigns={props.campaignLoader}
                        displayedCampaigns={displayedCampaigns}
                        selectedCampaigns={props.selectedCampaigns}
                        selectedAdAccounts={props.selectedAdAccounts}
                        selectedSections={props.selectedSections}
                        addCampaign={props.addCampaign}
                        removeCampaign={props.removeCampaign}
                        title={t('Specific Campaign (Optional)')}
                        currentSectionIndex={props.currentSectionIndex}
                    />
                    }              
                </GoogleAddAccountWrapper>
                : null }
            </>
            
            : activeTab === "withoutManager"  ? 
        
            <>         
                {props.accessibleAdAccounts?.length > 5 ?
                    <Builder_ >
                        <Google_Search file={props.accessibleAdAccounts} fileKeys={['descriptiveName']} defaultValue={''} sanitize={true} placeholder={t('Search Ad Account')} setResults={(data) => setSearchResultsAdAccounts(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                    </Builder_> 
                    : null }
                <Section_Title text={t('Select Google Ad Accounts')} />
                <CardsSixty>
                    {props.selectedSections[props.currentSectionIndex+1]?.sectionTitle === "Google Ad Account"?
                    <>
                    {props.accessibleAdAccounts?.map((item, i) => {
                        const isAdded = props.selectedAdAccounts?.some((obj) => obj.id === item.id);
                        return (
                            <Toggle_Stat 
                                key={i} id={i} 
                                text={item.descriptiveName}       
                                onClick={() => !isAdded ? props.addSection("google", item)  : props.removeSection("google", item) }
                                active={isAdded} 
                            />    
                        )  
                    })}
                    </>
                    :
                    <Toggle_Stat
                        key={props.selectedSections[props.currentSectionIndex+1]?.id} id={0}
                        text={props.selectedSections[props.currentSectionIndex+1]?.sectionTitle}
                        onClick={() => {props.removeSection("google") }}
                        active={true}
                    />
                    }

                </CardsSixty>
                {(props.selectedSections[props.currentSectionIndex+1]?.sectionTitle !== "Google Ad Account" && filteredCampaigns?.length >= 0) &&
                <Slider_Expand
                    filteredCampaigns={filteredCampaigns}
                    setSearchResults={setSearchResultsCampaigns}
                    loadingCampaigns={props.campaignLoader}
                    displayedCampaigns={displayedCampaigns}
                    selectedCampaigns={props.selectedCampaigns}
                    selectedAdAccounts={props.selectedAdAccounts}
                    selectedSections={props.selectedSections}
                    addCampaign={props.addCampaign}
                    removeCampaign={props.removeCampaign}
                    title={t('Specific Campaign (Optional)')}
                    currentSectionIndex={props.currentSectionIndex}
                />
                }
            </>
           :  <Builder_>
                    <CardsForty>
                        <On_Click_Card subtitle={t('Manager accounts')} onClick={() => setActiveTab('withManager')} />
                        <On_Click_Card subtitle={t('Accessible accounts')} onClick={() => setActiveTab('withoutManager')} />
                    </CardsForty>
                </Builder_> 
            }
            

            <Steps_Actions onClick_Main={props.onClick_Main} 
            onClick_Grey={() => {props.onClick_Grey(); props.removeSource('google'); setCurrBusiness(null); props.removeSection("google"); }} 
            icon_Grey="Back" 
            icon_Main="Next" 
            text_Grey={t("Back")} text_Main={t("Next")}
            />
        </WizardGoogleAdAccount>
    )
}
