import React from 'react'
import styled from 'styled-components'
import { H2, H3, P } from '../../../styles/components/LandingText'
import { Card, Cta_, Focus_, device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const LandingHow = styled.section`

    button{
        margin: 4rem auto 0 auto;
    }
`

const LandingHowGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4rem;

    @media ${device.xl} {
        grid-template-columns: 1fr;
    }
`

const LandingHowCard = styled(Card)`
    display: flex;
    gap: 2rem;
    padding: 2rem 4rem;

    h3{
        color: var(--focus);
    }

    aside{
        h4{
            font-size: 15rem;
            color: var(--focus);
        }
    }

    main{
        margin-top: 3.5rem;
    }
`

const LandingHowCardLast = styled(LandingHowCard)`

    .LandingHowCardSplit{
        display: flex;
        gap: 2rem;
    }
`

const LandingHowAction = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

export default function Landing_How(props) {

    const {t} = useTranslation();

  return (
    <LandingHow>
        <H2>{t("How it works")}</H2>
        <LandingHowGrid>
            <LandingHowCard>
                <aside>
                    <h4>1</h4>
                </aside>
                <main>
                    <H3>{t("Connect")}</H3>
                    <P>{t("Link your Facebook business account to Adsnap, enabling our platform to access the essential ad data for creating your personalized reports")}</P>
                </main>
            </LandingHowCard>
            <LandingHowCard>
                <aside>
                    <h4>2</h4>
                </aside>
                <main>
                    <H3>{t("Create")}</H3>
                    <P>{t("Utilize Adsnap's user-friendly interface and powerful features to create a detailed, informative, and visually appealing report that captures the essence of your Facebook and Instagram ad campaigns, without breaking a sweat.")}</P>
                </main>
            </LandingHowCard>
            <LandingHowCardLast>
                <div className='LandingHowCardSplit'>
                    <aside>
                        <h4>3</h4>
                    </aside>
                    <main>
                        <H3>{t("Export & Share")}</H3>
                        <P>{t("Once your report is ready, exporting it as a PDF is as simple as clicking a button. After exporting your report as a PDF don't forget to share it with colleagues or clients!")}</P>
                    </main>
                </div>
            </LandingHowCardLast>
        </LandingHowGrid>
        <Cta_ size="large" text={t("Try Adsnap today for free")} onClick={props.onBuy}/>
    </LandingHow>
  )
}
