import { Builder_, Dropdown_, Input_, Slider_, Toggle_Stat } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Edit_Report_Title(props) {
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} title={t('Report settings')} text={t('Done')}>
            <Builder_ title={t('Name your report')}>
                <Input_ defaultValue={props.name} placeholder={t('Report name')} onChange={props.onName} />
            </Builder_>
            <Builder_ title={t('Configure the currency of your report')}>
                <Dropdown_>
                    <select onChange={props.onCurreny}>
                        <option value="EUR">€ (EUR)</option>
                        <option value="USD">$ (USD)</option>
                        <option value="GBP">£ (GBP)</option>
                    </select>
                </Dropdown_>
            </Builder_>
        </Slider_>
    );
}
