import axios from "axios"
import { config } from "../../config"


const validate_coupon = (coupon) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/coupons/validateCoupon`,{data: {coupon}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_coupons = () => {
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/coupons/getCoupons`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const create_coupon = (payload) => {
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/coupons/createCoupon`,{data: {payload}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const edit_coupon = (couponId, payload) => {
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/coupons/editCoupon`,{data: {couponId, payload}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const delete_coupon = (couponId) => {
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/coupons/deleteCoupon`,{data: {couponId}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

export {
    validate_coupon,
    get_coupons,
    create_coupon,
    edit_coupon,
    delete_coupon,
}