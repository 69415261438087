import { Toggle_Stat } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'

const SnippetToggleDonot = styled.div`
    .Donot{
        h5{
            color: var(--error) !important;
        }

        div{

            div{
                background: var(--error) !important;
            }
        }
    }
`

export default function Snippet_Toggle_Donot(props) {
  return (
    <SnippetToggleDonot>
        <Toggle_Stat active={props.active} text={props.text} onClick={props.onClick} className="Donot"/>
        
    </SnippetToggleDonot>
  )
}
