import axios from "axios"
import { config } from "../../config"
import store from "../../redux/store"


const get_settings = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.ERISED_BACKEND_URL}/users/${id}/getSettings`)
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_user_images = (userId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getImages?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const save_preferences = (userId) => {
    return new Promise((resolve, reject) => {
        

        const theme = store.getState().theme;
        const language = store.getState().selectedLanguage;
        const region = store.getState().country;

        axios.post(`${config.ERISED_BACKEND_URL}/users/savePreferences`, {
            data: {
                userId: userId,
                theme: theme,
                language: language,
                region: region
            }
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_preferences = (userId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getPreferences?userId=${userId}`)
        .then(res => {
            resolve({
                theme: res?.data?.data?.theme ? res.data.data.theme : false,
                lang: res?.data?.data?.language ? res.data.data.language : false,
                region: res?.data?.data?.region ? res.data.data.region : false
            })
        })
        .catch(() => {
            resolve(false);
        })
    })
}



const validate_Tool_Ownership = (userId, planName) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/validateToolOwnership?userId=${userId}&planName=${planName}`)
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_my_subscriptions = (userId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/getMySubscriptions?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}


export {
    get_settings,
    get_user_images,
    save_preferences,
    get_preferences,
    validate_Tool_Ownership,
    get_my_subscriptions
};
