import { Actions_, Builder_, CardsSixty, Google_Search, Input_, Red_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { fetchCampaignData } from '../../utils/fetchCampaignData';

const SelectCampaign = styled.div``;

const SearchWrapper = styled.div`
    margin-bottom: 2rem;
`;

export default function Select_Campaign(props) {
    const { t } = useTranslation();

    /* Search */ 
    const [searchResults, setSearchResults] = useState([]);
    const [displayedResults, setDisplayedResults] = useState([]);


    const addCampaign = (campaign) => {
        const campaigns = props.report.campaigns;
        campaigns.push({campaign: campaign.campaign, comment: ""});
        props.setReport({...props.report, campaigns: campaigns});
    }


    const removeCampaign = (campaign) => { 
        const campaigns = props.report.campaigns;
        const campaignIndex = campaigns.findIndex(obj=>obj.campaign.id === campaign.campaign.id);
        campaigns.splice(campaignIndex, 1);
        props.setReport({...props.report, campaigns: campaigns});
    }


    useEffect(() => {
        if ( searchResults.input ) setDisplayedResults(searchResults.data);
        else setDisplayedResults(props.googleResponses?.campaigns);
    },[searchResults])


    useEffect(() => {
        props.setSliderText(t('Select campaign'));
    }, []);


    return (
        <SelectCampaign>

           
            {/* Google Search */}
            {true && (
                <SearchWrapper>
                    <Google_Search
                            file={props.googleResponses?.campaigns}
                            fileKeys={['campaign.name', 'campaign.id', 'campaign.name_reversed']}
                            defaultValue={''}
                            sanitize={true}
                            placeholder={t('Campaign')}
                            applyReverseFileProperties={[
                                {
                                    originalKey: 'campaign.name',
                                    reversedKey: 'campaign.name_reversed',
                                },
                            ]}
                            setResults={(data) => setSearchResults(data)}
                            debounceTime={750}
                            minMatchCharacters={1}
                            maxMatchCharacters={50}
                            disabled={false}
                            isCaseSensitive={false}
                            maxAcceptedScore={0.6}
                        />
                    </SearchWrapper>
                )}
            
            
            <CardsSixty>
                {displayedResults?.map((campaign) => {
                    return  <Toggle_Stat 
                        text={campaign?.campaign?.name} 
                        id={"AdSnap_Google_Select_Campaign_1"}
                        onClick={props.report?.campaigns?.some((obj) => obj?.campaign?.id === campaign.campaign.id) 
                            ? () => removeCampaign(campaign) 
                            : () => addCampaign(campaign)
                        } 
                        active={props.report?.campaigns?.some((obj) => obj?.campaign?.id === campaign.campaign.id) 
                            ? true 
                            : false
                        } 
                    />
                })}
            </CardsSixty> 

        </SelectCampaign>
    );
}
