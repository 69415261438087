import { CardsSixty, On_Click_Card, Section_Title } from 'monica-alexandria';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';

const StylePresetPrebuilt = styled.div``;

export default function Style_Preset_Prebuilt(props) {
    const { t } = useTranslation();

    const { reportStyle, setReportStyle } = useContext(ReportStyleContext);

    const apply_style = (style) => {
        setReportStyle(style);
    };
    
    return (
        <StylePresetPrebuilt>
            <Section_Title text={t('Prebuilt Presets')} />
            <CardsSixty>
                { props.preBuilt?.map((style) => {
                    return <On_Click_Card 
                        icon={style.logo}
                        subtitle={style.name}
                        onClick={() => apply_style(style)}
                    />
                })}
            </CardsSixty>
        </StylePresetPrebuilt>
    );
}
