import { CardsSixty, On_Stat, Section_Title, Slider_, Confirmation_} from 'monica-alexandria';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Card_On_Source from '../card/Card_On_Source';
import MetaLogo from '../../../../../../assets/MetaLogo.png';
import FbLogo from '../../../../../../assets/fb.png';
import InstaLogo from '../../../../../../assets/insta.png';
import GoogleLogo from '../../../../../../assets/googleNetwork/pngegg.png';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../../../../localization/i18next';

export default function Add_Section(props) {
    console.log(props);
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Report Sections')}>
            <Section_Title text={t('Sources')} />
            <CardsSixty>
                {props.accessedAccounts.meta.accessToken && 
                <>
                    <Card_On_Source img={MetaLogo} firstColor="var(--metaOne)" secondColor="var(--metaTwo)" title="Meta Ad Account" text={t('Consolidate your advertising efforts by streaming data from Meta Ads. Track the performance of your Facebook, Instagram, Messenger, and Audience Network campaigns in one place.')} 
                        textBtn={t('Add') }   
                        iconBtn="Add"  
                        onClick={() => { props.addSource('meta') }}
                    />

                    <Card_On_Source img={FbLogo} firstColor="var(--facebookOne)" secondColor="var(--facebookTwo)"  title="Facebook Page" text={t('Capture and analyze organic engagement metrics from your Facebook Page. Track audience interactions, post reach, and engagement to refine your content strategy.')}
                        textBtn={t('Add') }   
                        iconBtn="Add"  
                        onClick={() => { props.addSource('page', 'facebook') }}
                    />

                    <Card_On_Source img={InstaLogo} firstColor="var(--instagramOne)" secondColor="var(--instagramTwo)"  title="Instagram Page" text={t('Gather insights from your Instagram account. Monitor post and story performance, follower growth, and engagement trends to enhance your organic Instagram presence.')}
                        textBtn={t('Add') }   
                        iconBtn="Add"  
                        onClick={() => { props.addSource('page', 'instagram') }}
                    />
                </> 
                }

                {props.accessedAccounts.google.accessToken && 
                    <Card_On_Source img={GoogleLogo} firstColor="#fcfcfc" secondColor="#bcbebe" title="Google Ad Account" text={t('Gain comprehensive insights into your Google Ads accounts-campaigns. Track performance, competitive metrics and demographics details for your audiences.')} 
                        textBtn={t('Add') }   
                        iconBtn="Add"   
                        onClick={() => {props.addSource('google')}
                        } 
                    />
                }

                </CardsSixty>
        </Slider_>
    );
}
