import React from 'react'
import styled from 'styled-components'

const SliderEmpty = styled.div`

    h5{
        text-align: center;
    }
`

const SliderEmptyIllus = styled.img`
    display: block;
    margin: 0 auto 2rem auto;
    max-width: 25rem;
    opacity: .6;
`

export default function Slider_Empty(props) {

    return (
        <SliderEmpty>
            <SliderEmptyIllus src='https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/Soon.svg' />
            <h5>{props.text}</h5>
        </SliderEmpty>
    )
}
