import { Cta_, CardsForty, CardsFull, CardsSixty, Confirmation_, Google_Search, IconSvg, Main_, Main_Plain, On_Click_Card, PageAlwaysVisible, PageDesktopPadding, PageFull, PageSixty, PageTitle, Section_Title } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import store from '../../redux/store';
import { AuthContext } from '../../services/authentication/Guard';
import { Helmet } from 'react-helmet';
import { t } from 'i18next';
import { deleteReport, getReports, getDoitReports } from '../../sources/meta/services/api/Reports';
import styled from 'styled-components';
import { checkOwnership } from '../../helpers/verifyToolOwnership';
import { convertUnixTimeToDate } from '../../helpers/StringUnixtimeConverters';
import MetaLogo from '../../assets/MetaLogo.png';
import FbLogo from '../../assets/fb.png';
import InstaLogo from '../../assets/insta.png';
import { dummy_request_to_facebook } from '../../sources/meta/services/api/MetaApi';
import { get_my_subscriptions } from '../../services/api/Users';
import { checkPermissions } from '../../helpers/checkPermissions';
import GoogleLogo from '../../assets/googleNetwork/pngegg.png'
import i18n from '../../localization/i18next';
import { updateReport } from '../../sources/meta/services/api/Reports';

const clients = require('../../config/index').config

const MyReports = styled.div`
    .Children {
        height: calc(100% - 5rem);
    }
`;

const MyReportsCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 12rem;
`;

const MyReportsList = styled.div`
    margin-bottom: 2rem;
`;

const MyReportsItem = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    img {
        width: 2rem;
        height: auto;
    }
`;

const MyReportsFooter = styled.div`
    display: flex;
    justify-content: flex-end;

    h6 {
        color: var(--greyDark);
    }
`;

const MyReportsExtraOptions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 2rem;
`;

export default function MY_REPORTS() {
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';
    const auth = useContext(AuthContext);
    const userId = auth?.user?.sub;

    const [searchResults, setSearchResults] = useState([]);
    const [displayedReports, setDisplayedReports] = useState([]);
    const [reports, setReports] = useState([]);

    const [doitReports, setDoitReport] = useState([]);

    const [isOptions, setIsOption] = useState({ open: false, index: -1 });
    const [reportDeleteId, setReportDeleteId] = useState(false);

    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const permission = checkPermissions('Knight', auth.user.roles);

    const [availReport, setAvailReport] = useState(false);

    const handleNewReport = () => {
        navigate(`/${lang}/report-template`);
    };
    
    const edit_report = (report) => {
        store.dispatch({type: "SAVE_PRESELECTED_TEMPLATE", payload: report.template})
        const url = `/${lang}/creator/${report._id}/editMode`;

        navigate(`/${lang}/integrations?redirectURL=${url}`);
    };
    const view_report = (report) => {
        navigate(`/${lang}/report/${report._id}`);
    };

    const delete_report = () => {
        deleteReport(reportDeleteId, userId)
            .then((res) => {
                const deletedId = reportDeleteId;
                setReportDeleteId(false);
                setReports((prev) => prev.filter((report) => report._id !== deletedId));
                setDisplayedReports((prev) => prev.filter((report) => report._id !== deletedId));
                setIsOption((prev) => ({ ...prev, open: false, index: false }));
            })
            .catch((error) => {
                setReportDeleteId(false);
            });
    };

    const get_reports = () => {
        getReports()
            .then((res) => {
                console.log('res', res);
                const reports = res?.data?.data?.reports;
                const sortedReports = reports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setReports(sortedReports);
                setDisplayedReports(sortedReports);
            })
            .catch((err) => console.error(err));
    };

    const get_doit_reports = () => {
        getDoitReports()
            .then((res) => {
                console.log('res', res);
                const reports = res?.data?.data?.reports;
                const sortedReports = reports.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setDoitReport(sortedReports);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        if (searchResults?.input) setDisplayedReports(searchResults?.data);
        else setDisplayedReports(reports);
    }, [searchResults]);

    useEffect(() => {
        get_reports();
        if(permission) {
            get_doit_reports();
        }
    }, []);

    /* Validate Tool Ownership */
    useEffect(() => {
        get_my_subscriptions(userId)
        .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                console.log('lois subs', lois_subs)
                if(!lois_subs?.tools?.some(tool => tool?.active) && !permission) window.location.assign(payments_URL);
                else setHasCheckOwnership(true);
        }).catch((err) => console.log('error', err));
    }, [userId]);

    const chooseLogo = (section) => {
        if(section.source === "google")
            return GoogleLogo;
        else if (section.source === "meta") {
            if(section.type === "paid")
                return MetaLogo;
            else if(section.type === "facebook")
                return FbLogo;
            else if(section.type === "instagram")
                return InstaLogo;
        }
    }

    const make_available_to_doit = (report) => {
        let newPayload = report;

        newPayload.userIds = [];
        newPayload.admin = true;

        updateReport(report._id, newPayload);
        navigate(0);
    }

    return (
        <PageFull>
            <Helmet>
                <title>Adsnap | My reports</title>
            </Helmet>
            {hasCheckOwnership ? 
                <MyReports>
                    {reportDeleteId && <Confirmation_ message={t('Are you sure you want to delete your report?')} no={t("No")} onNo={() => setReportDeleteId(false)} onYes={delete_report} yes={t("Yes")} />}
                    {availReport && <Confirmation_ message={t('Are you sure you want to make this report available for all Doitforme users (Knights and above) ?')} no={t("No")} onNo={() => setAvailReport(false)} onYes={() => make_available_to_doit(availReport)} yes={t("Yes")} />}
                    <PageAlwaysVisible>
                        <PageTitle cta>
                            <h1>{t('My Reports')}</h1>
                            <Main_ id={'AdSnap_Reports_2'} text={t('New report')} onClick={() => handleNewReport()} iconLeft="AddPlan" />
                        </PageTitle>
                        {/* {reports?.length > 9 ? (
                            <SearchWrapper>
                                <Google_Search
                                    file={reports}
                                    fileKeys={['title']}
                                    defaultValue={''}
                                    sanitize={true}
                                    placeholder={t('Search report title')}
                                    setResults={(data) => setSearchResults(data)}
                                    debounceTime={750}
                                    minMatchCharacters={1}
                                    maxMatchCharacters={50}
                                    disabled={false}
                                    isCaseSensitive={false}
                                    maxAcceptedScore={0.6}
                                />
                            </SearchWrapper>
                        ) : null} */}
                        <CardsFull>
                            {displayedReports?.map((report, i) => {
                            return (
                                <On_Click_Card
                                    key={i}
                                    id={'AdSnap_Reports_1'}
                                    extraOptions={
                                        <MyReportsExtraOptions>
                                            <Main_Plain noPadding text={t('Edit')} iconRight="Edit" onClick={() => edit_report(report)} />
                                           {permission && <Cta_ noPadding text={"DOIT"} onClick={() => setAvailReport(report)} />}
                                        </MyReportsExtraOptions>
                                    }
                                    delete={t("Delete")}
                                    onDelete={() => setReportDeleteId(report._id)}
                                    view={t("View")}
                                    onView={() => view_report(report)}
                                    isOptions={i === isOptions.index && isOptions.open}
                                    options
                                    onClick={() => setIsOption({ ...isOptions, open: isOptions.index === i ? !isOptions.open : true, index: i })}
                                    subtitle={report?.title}
                                >
                                    <MyReportsCard>
                                        <MyReportsList>
                                            {report?.sections
                                                ?.filter((section, index) => index < 5 && section.type !== 'overview') // Take the first 4 sections
                                                .map((section, i) => (
                                                    <MyReportsItem key={i}>
                                                        <img src={chooseLogo(section)} />
                                                        <p>{section.sectionTitle}</p>
                                                    </MyReportsItem>
                                                ))}
                                            {report?.sections?.filter((section, index) => index >= 5 && section.type !== 'overview').length > 0 && ( // Take the sections beyond the first 4 // Check if there are remaining sections
                                                <MyReportsItem>
                                                    <p>+{report?.sections.filter((section) => section.type !== 'overview').slice(4).length} more</p>
                                                </MyReportsItem>
                                            )}
                                        </MyReportsList>
                                        <MyReportsFooter>
                                            <h6>{convertUnixTimeToDate(report?.createdAt)}</h6>
                                        </MyReportsFooter>
                                    </MyReportsCard>
                                </On_Click_Card>
                            )})}
                        </CardsFull>
                        {permission ? 
                        <>
                        <Section_Title text={t("Reports you have access as a member of Doitforme")}/>
                        <CardsFull>
                            {doitReports?.map((report, i) => {
                                let j = i + displayedReports.length;
                            return (
                                <On_Click_Card
                                    key={j}
                                    id={'AdSnap_Reports_2'}
                                    extraOptions={
                                        <MyReportsExtraOptions>
                                            <Main_Plain noPadding text={t('Edit')} iconRight="Edit" onClick={() => edit_report(report)} />
                                            {/* <Main_Plain noPadding text={"Duplicate"} iconRight="Recycle" /> */}
                                        </MyReportsExtraOptions>
                                    }
                                    delete={t("Delete")}
                                    onDelete={() => setReportDeleteId(report._id)}
                                    view={t("View")}
                                    onView={() => view_report(report)}
                                    isOptions={j === isOptions.index && isOptions.open}
                                    options
                                    onClick={() => setIsOption({ ...isOptions, open: isOptions.index === j ? !isOptions.open : true, index: j })}
                                    subtitle={report?.title}
                                >
                                    <MyReportsCard>
                                        <MyReportsList>
                                            {report?.sections
                                                ?.filter((section, index) => index < 5 && section.type !== 'overview') // Take the first 4 sections
                                                .map((section, i) => (
                                                    <MyReportsItem key={i}>
                                                        <img src={chooseLogo(section)} />
                                                        <p>{section.sectionTitle}</p>
                                                    </MyReportsItem>
                                                ))}
                                            {report?.sections?.filter((section, index) => index >= 5 && section.type !== 'overview').length > 0 && ( // Take the sections beyond the first 4 // Check if there are remaining sections
                                                <MyReportsItem>
                                                    <p>+{report?.sections.filter((section) => section.type !== 'overview').slice(4).length} more</p>
                                                </MyReportsItem>
                                            )}
                                        </MyReportsList>
                                        <MyReportsFooter>
                                            <h6>{convertUnixTimeToDate(report?.createdAt)}</h6>
                                        </MyReportsFooter>
                                    </MyReportsCard>
                                </On_Click_Card>
                            )})}
                            
                        </CardsFull>
                        </>
                        : null}
                    </PageAlwaysVisible>
                </MyReports>
                : null }
        </PageFull>
    );
}
