import { Card, Main_Plain, Toggle_ } from 'monica-alexandria'
import React from 'react'
import styled from 'styled-components'

const CardOnTags = styled(Card)`
    background: ${p => p.theme.background};
    padding: 1rem 1.5rem;
    cursor: pointer;

    h5{
        color: ${p => p.added ? 'var(--main)' : p.theme.color};
    }
`

const CardOnTagsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: 1rem 0;
`

const CardOnTagsItem = styled.div`
    background: ${p => p.theme.low};
    padding: .5rem 1rem;
    border-radius: .5rem;

    h6{
        color: var(--greyDark);
    }
`

const CardOnTagsAction = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default function Card_On_Tags(props) {
  return (
    <CardOnTags added={props.added} id={props.id} onClick={props.onClick}>
        <h5>{props.text}</h5>
        {props.addedKpis ?
            <CardOnTagsList>
                {props.addedKpis.map((kpi, index) => (
                    <CardOnTagsItem key={index}>
                        <h6>{kpi}</h6>
                    </CardOnTagsItem>
                ))}
            </CardOnTagsList>
        :
        null
        }
        <CardOnTagsAction>
            <Main_Plain size="small" text={props.added ? props.addedMain : props.textMain} iconRight={props.iconMain ? props.iconMain : 'Next'}/>
        </CardOnTagsAction>
    </CardOnTags>
  )
}
