import styled from "styled-components";

export const H2 = styled.h2` 
    font-size: 4rem;
`

export const H3 = styled.h3` 
    font-size: 2.6rem;
`

export const H5 = styled.h5` 
    font-size: 2rem;
`

export const P = styled.p` 
    font-size: 2rem;
    font-family: 'fontMedium';
    line-height: 2;
    opacity: .7;
`