import { Builder_, CardsSixty, Checkbox_, Google_Search, Input_, On_Stat, Section_Title, Slider_, Toggle_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Card_On_Tags from '../card/Card_On_Tags';

const AddRowDemographicBlocks = styled.div``;

export default function Add_Row_Demographic_Blocks(props) {
    const { t } = useTranslation();
    
    const find_added__kpis = (block) => {
        let list_to_return = []
        props?.reportElements.forEach(element => {
            if(element.originalName === block.name) {
                list_to_return.push(element.type)
            }
        });
        const convertedList = list_to_return.map(item => {
            switch (item.toLowerCase()) {
              case "countries":
                return t("Map");
              case "genders":
                return t("Genders");
              case "ages":
                return t("Ages");
              default:
                return item; // return unchanged if not matched
            }
          });
        return convertedList
    }


    return (
        <AddRowDemographicBlocks>
            <Section_Title text={props.categoryName} />
            <CardsSixty>
            { props.displayedKpis?.map((item, i) => {
                const isAdded = props.reportElements.some((obj) => obj.originalName === item.name);
                
                return <Card_On_Tags 
                    key={i} 
                    id={i} 
                    text={item?.displayedName} 
                    added={isAdded}
                    onClick={() => props.onAddDemographic(item)} 
                    textMain={t("Add KPIs")}
                    addedMain={t("Edit KPIs")}
                    addedKpis={find_added__kpis(item)}
                />;
            })}
            </CardsSixty>
        </AddRowDemographicBlocks>
    );                              
}