import { Builder_, CardsSixty, On_Stat } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';

const ReportTitle = styled.div`

h3{
    margin-top: 2rem;
}
`;


export default function Report_Title(props) {
    const { t } = useTranslation();


    return (
        <ReportTitle>
            <Builder_>
                <On_Stat
                    text={(t("Title")) }
                    title={t("Report" )}
                    variant="main" 
                    btnIcon={!props?.title ? "Add" : "Edit"} 
                    btnText={!props?.title ? (t('Add')) : (t('Edit'))} 
                    onClick={() => props.handleSliderClick('edit_title')}
                />
                <h3>{props?.title}</h3>
            </Builder_>
        </ReportTitle>
    );
}
