import React from 'react';
import styled from 'styled-components';

const SnippetBlockOverlay = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background: ${(p) => p.theme.overlay};
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${(p) => p.stylePreset?.radius};
    pointer-events: none;
    overflow: hidden;
    
    h6 {
        color: var(--white);
        padding: 0.5rem 1.5rem;
        background: var(--black);
        border-top-left-radius: 0.5rem;
    }
`;

export default function Snippet_Block_Overlay(props) {
    return (
        <SnippetBlockOverlay stylePreset={props.stylePreset} className="SnippetBlockOverlay">
            <h6>{props.text}</h6>
        </SnippetBlockOverlay>
    );
}
