import { use } from 'i18next';
import { Focus_Plain, Grey_Link, Main_Plain, Red_ , Confirmation_, device} from 'monica-alexandria';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ReportActions = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    @media ${device.sm} {
        button{
            span{
                display: none !important;
            }
        }
    }
`;

const ReportActionsLeft = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const ReportActionsRight = styled.div`
    display: flex;
    align-items: center;
`;

const ReportActionsView = styled.div`
     @media ${device.sm} {
        button{
            span{
                display: inline-block !important;
            }
        }
    }
`;

export default function Report_Actions(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <ReportActions>
            <ReportActionsLeft>
                {props.viewMode === false && (
                    <>
                        {/* <Main_Plain text={t('Load template')} iconLeft="Template" onClick={props.onLoadTemplate} /> */}
                        {/* <Grey_Link text={t('KPI presets')} iconLeft="Structure" onClick={props.onStructurePreset} /> */}
                        <Grey_Link text={t('Style presets')} iconLeft="Brandkit" onClick={props.onStylePreset} />
                    </>
                )}
            </ReportActionsLeft>
            <ReportActionsRight>
                {props.viewMode === true && props.isSaved === true ? (
                    <ReportActionsView>
                        <Main_Plain text={t('Save and export')} iconLeft="File" onClick={props.onExport} />
                    </ReportActionsView>
                    
                ): null}
                <Focus_Plain text={props.viewMode ? (t('Edit mode')) : (t('View mode'))} iconLeft={props.viewMode ? 'Edit' : 'EyeOpen'} onClick={props.onChangeView} />
            </ReportActionsRight>
        </ReportActions>
    );
}
