import React, { useContext, useEffect, useState } from 'react';
import { loginCallback } from '../../services/api/GoogleApi';
import store from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { AlertContext, Alert_ } from 'monica-alexandria';
import { t } from 'i18next';
import i18n from '../../../../localization/i18next';
import { AuthContext } from '../../../../services/authentication/Guard';
import { checkOwnership } from '../../../../helpers/verifyToolOwnership';


const CALLBACK = () => {

    // Extract the authorization code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const lang = i18n.language ? i18n.language : 'en';
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const userId = auth?.user?.sub;

    /* For tool ownership validation */
    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);

    /* LocalStorage Data */

    const { alerts, setAlerts } = useContext(AlertContext);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };


    useEffect(() => {
        if (code) {
            loginCallback(code)
                .then((response) => {
                    console.log(response);
                    const info = response?.data?.data?.info;

                    if ( info ) {
                        store.dispatch({
                            type: 'SAVE_GOOGLE_USER_PROFILE',
                            payload: {
                                info
                            },
                        });
                        navigate(`/${lang}/integrations`);
                    } 
                    else {
                        navigate(`/${lang}/connection-error`)
                    }

                })
                .catch(error=>{console.error(error); alertMessage((t("Something went wrong")),'Error'); navigate(`/${lang}/integrations`)})
        }
    }, []);

    /* Validate Tool Ownership */
    useEffect(() => {
        if (userId) checkOwnership(userId, navigate, lang, setHasCheckOwnership);
    }, [userId]);

  return (
    <>
        { hasCheckOwnership && <h4>Redirecting...</h4> }
        <Alert_ messages={alerts} />
    </>
  );
};

export default CALLBACK;
