import { Builder_, Dropdown_, Input_, Red_, Slider_, Upload_Media, Confirmation_ } from 'monica-alexandria';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import { deleteMedia, upLoadMedia } from '../../../../../../services/api/Media';
import store from '../../../../../../redux/store';

const EditMediaGrid = styled.div``

const EditMediaList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

const EditMediaItem = styled.div`
    width: 100%;
    position: relative;
    border: 1px solid ${p => p.theme.background};
    background: ${p => p.theme.low};
    
    img{
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

`

export default function Edit_Media(props) {

    const {t} = useTranslation()
    const userId = store.getState()?.user?.user?.sub;
    const [mediaGrid, setMediaGrid] = useState(props.row?.grid);

    const [confirmation, setConfirmation] = useState(false);

    const [selectedImage, setSelectedImage] = useState();

    const upload_pic = async (files) => {
        if (files?.length && files[0]?.preview) {
            console.log(files[0]);
            //here call the uploadmedia with correct arguments
            upLoadMedia(userId, files[0])
            .then(res => {
                if(res.data.code === 200) {
                    props.addMedia(res.data?.data?.filename);
                }
            })
            .catch((err) => {console.log(err);})
        }
    };

    const handleBoomRejected = (type) => {

        console.log('TYPe', type);
        let reason = '';
        switch (type) {

            case 'fileMimetype': {
                reason = (t('File must be png, jpeg, mp4, mpeg, webm'));
                break;
            }
            case 'fileSize': {
                reason = (t('Invalid image size. Image must be less than 6MB'));
                break;
            }
        }
        console.log('Could not upload image. Reason: ',reason);
        // alertMessage(alerts, setAlerts, t('Could not upload image. Reason')+": "+reason,'Error');
    };

    const handleChange = (e) => {
        // console.log(e.target.value);
        props.onGridEdit(e.target.value);
        setMediaGrid(e.target.value);
    };

    const handleRedButton = (imageName) => {
        setSelectedImage(imageName);
        setConfirmation(true);
    }
    
    const deleteImage = (img) => {
        deleteMedia(img)
        .then(res => {
            if(res.data.code === 200) {
                props.removeMedia(img);
            }
        })
        .catch((err) => {console.log(err);})
    }

  return (
    <Slider_ title={t('Edit media')} onClick={props.onClick} text={t("Done")} >
        {confirmation ? 
        <Confirmation_ 
            message={t('If you delete this image it will be removed from all the reports you have used it. Are you sure you want to delete this image from your collection?')} 
            yes={t('Yes')} no={t('No')} 
            onYes={() => {
                deleteImage(selectedImage);
                setConfirmation(false);
            }} 
            onNo={() => {
                setConfirmation(false);

            }} 
        />: null} 
        <Builder_ title={t("Edit title")}>
            <Input_ 
                placeholder={t("Media")}
                value={props.row?.title}
                onChange={(e) => {
                    props.onTitleEdit(e)}
                }
            />
        </Builder_>
        <Builder_ title={t("Select media grid")}>
            <Dropdown_>
                <select value={mediaGrid} onChange={handleChange}>
                    <option value="default">{t("Default")}</option>
                    <option value="split"> {t("Split")}</option>
                    <option value="full"> {t("Full")}</option>
                </select>
            </Dropdown_>
        </Builder_>
        <EditMediaGrid>
            <Builder_ title={t("Select media")}>
                <Upload_Media 
                    // accept="image/jpeg,  image/jpg, "
                    upload={t("Upload")}
                    getFilesFromChild={(files) => upload_pic(files)}
                    delete={t("Delete")}
                    boomRejected={(type) => handleBoomRejected(type)}
                    helpers={[(t('Acceptable files png, jpg, jpeg, mp4, mpeg, webm less than 7mb'))]}
                    maxFiles="1"
                    maxSize="6000000"
                    type ='media'
                />
            </Builder_>
            <EditMediaList>
                {props?.availableMedia?.map((imageName, i) => {
                    return(
                        <EditMediaItem id={i} key={i}>
                            <img  onClick={() => props.onSelectImage(imageName)} src={imageName}/>
                            <Red_ iconLeft="Delete" text="Delete" onClick={() => handleRedButton(imageName)}/>
                        </EditMediaItem>
                    )
                })}
            </EditMediaList>
        </EditMediaGrid>
    </Slider_>
  )
}
