import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Landing_Header from './components/Landing_Header';
import Landing_For from './components/Landing_For';
import Landing_Sum from './components/Landing_Sum';
import Landing_How from './components/Landing_How';
import Landing_Features from './components/Landing_Features';
import Foot_ from '../../components/footer/Foot_';
import Landing_Prices from './components/Landing_Prices';
import Landing_Qa from './components/Landing_Qa';
import { device, PageFull, Main_Loader} from 'monica-alexandria';
import Landing_Ratings from './components/Landing_Ratings';
import store from '../../redux/store';
import { Helmet } from 'react-helmet';
import { get_my_subscriptions} from '../../services/api/Users';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/authentication/Guard';
import { checkPermissions } from '../../helpers/checkPermissions';

const clients = require('../../config/index').config
const authClient = process.env.REACT_APP_AUTH_CLIENT;

const Landing = styled.div`
    section {
        padding: 10rem 4rem;
    }

    h2 {
        margin-bottom: 5rem;
    }

    @media ${device.sm} {
        section{
            padding: 1rem 2rem;
        }
    }
`; 

export default function LANDING() {

    const navigate = useNavigate();

    const lang = store.getState().selectedLanguage ? store.getState().selectedLanguage : 'en';
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const auth = useContext(AuthContext);

    const user = store.getState().user.user;
    const permission = checkPermissions('Knight', auth.user.roles);

    useEffect(() => {
        let lois_subs;
        get_my_subscriptions(user?.sub)
            .then((res) => {
                lois_subs=res?.data.find((sub) => sub?.category==='lois')
                if (auth.authenticated && (permission || lois_subs?.tools?.some(tool => tool?.active))) navigate(`/${lang}/welcome`);
            })
            .catch(err => console.log('err', err))
    }, []);

    const handleRedirect = () => {
        if(auth.authenticated) {
            navigate(`/${lang}/welcome`);
        }
        else {
            auth?.login({clientName: authClient})
        }
    }
    return (
        <Landing>
            <Helmet>
                <title>Adsnap </title>
            </Helmet>   
            <Landing_Header onBuy={() => handleRedirect()}/>
            <Landing_Ratings />
            <Landing_Features onBuy={() => handleRedirect()}/>
            <Landing_Sum onBuy={() => handleRedirect()}/>
            <Landing_Prices onBuy={() => handleRedirect()}/>
            <Landing_For onBuy={() => handleRedirect()} />
            <Landing_Qa onBuy={() => handleRedirect()}/>
            <Landing_How onBuy={() => handleRedirect()}/>
            <Foot_ />
        </Landing>
    );
}
