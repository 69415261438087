import React, {useState} from 'react';
import styled from 'styled-components';
import Block_Graph from '../block/Block_Graph';
import Creator_Title_Row from '../creator/Creator_Title_Row';
import Creator_Plus from '../creator/Creator_Plus';
import { useTranslation } from 'react-i18next';
import { device } from 'monica-alexandria';
const RowGraph = styled.div`
    .AddElement{
        height: 30rem;
    }
`;

const RowGraphGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(33% - 2rem), 1fr));
    grid-gap: 2rem;
    align-items: flex-start;
    @media ${device.md}{grid-template-columns: 1fr}

`;

export default function Row_Graph(props) {
    const { t } = useTranslation();

    const [activeBlock, setActiveBlock] = useState('initial');

    const toggle_actions = (item) => {
        if (item == activeBlock) {
            setActiveBlock(false);
        } else {
            setActiveBlock(item);
        }
    };

    const data2 = [{ uv: 431 }, { uv: 654 }, { uv: 22 }, { uv: 580 }, { uv: 90 }, { uv: 497 }, { uv: 937 }, { uv: 310 }, { uv: 153 }, { uv: 455 }, { uv: 10 }, { uv: 383 }, { uv: 65 }, { uv: 703 }, { uv: 603 }];
    const data = [{ uv: 1431 }, { uv: 3654 }, { uv: 2022 }, { uv: 2580 }, { uv: 690 }, { uv: 2497 }, { uv: 3937 }, { uv: 3310 }, { uv: 2153 }, { uv: 3455 }, { uv: 100 }, { uv: 3683 }, { uv: 165 }, { uv: 2703 }];

    return (
        <RowGraph>
            <Creator_Title_Row title={t("Graphical indicators")} viewMode={props.viewMode} onEdit={props.onEdit} onDelete={props.onDelete} />
            <RowGraphGrid>
                {props.blocks.map((item, i) => {
                    const actions = activeBlock === 'initial' ? 'initial' : activeBlock === item.originalName ;
                    return (
                        <Block_Graph
                            rowLen={props.blocks.length}
                            sliderFlowHelper={props.sliderFlowHelper}
                            actions={actions}
                            onActions={() => toggle_actions(item.originalName)}
                            setActiveBlock={setActiveBlock}
                            key={i}
                            id={i}
                            currency={props.currency}
                            element={item}
                            elementCategory={item.category}
                            sectionName={props.sectionName}
                            sectionType={props.sectionType}
                            sectionId={props.sectionId}
                            rowName={props.staticRowName}
                            rowType={props.rowType}
                            title={item.displayedName}
                            value={item.displayedValue}
                            titleDefaultValue={item.givenName}
                            variant={item.variant}
                            titlePlaceholder={props.staticTitlePlaceholder}
                            viewMode={props.viewMode}
                            setBlock={(originalName, value, property, sectionType, sectionId, rowType) => {
                                props.setBlock(originalName, value, property, sectionType, sectionId, rowType);
                            }}
                            onDelete={() => props.onRemoveBlock(item, 'graphical')}
                            data={item.values}
                            displayedName={item.displayedName}

                        />
                    );
                })}
                <Creator_Plus className="AddElement" viewMode={props.viewMode} inGrid onClick={props.onOpenRowElement} title={t('KPI Element')} />
            </RowGraphGrid>
        </RowGraph>
    );
}
