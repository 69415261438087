import uuid4 from "uuid4";
import { calculateDaysDifference } from "../../../../../helpers/CalculateDaysDiff";
import { deepClone } from "../../../../../helpers/DeepCloneObject";
import { blockToSkipOverviewUpdate } from "../data/special-cases";


export const update_block_element = (creator, setCreator, element, value, property, sectionType, sectionId, rowType, idTypeHelper) => {
    let chartType = element?.type;
    let updatedReportSection = deepClone(creator.report.sections);
    let updatedSectionElements = deepClone(creator.sectionElements);

    let reportBlock = updatedReportSection
        .find((section) => section.type === sectionType && section.sectionData.find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === rowType).elements
        .find((kpi) => kpi.originalName === element.originalName && (chartType === undefined || kpi.type === chartType));

    reportBlock[property] = !isNaN(value) && typeof value === "number" ? parseFloat(value) : value;
    console.log(sectionType, sectionId, rowType);
    let sectionBlock = updatedSectionElements
        .find((section) => section.type === sectionType && 
            section.id === sectionId && 
            section.row === rowType ).kpis
        .find((kpi) => kpi.originalName === element.originalName && (chartType === undefined || kpi.type === chartType));


    if ( sectionBlock ) sectionBlock[property] = !isNaN(value) && typeof value === "number" ? parseFloat(value) : value; 

    /* Logic to update overview section blocks and overview sectionElement kpis will be here */
    const isOverviewSectionSelected = updatedReportSection.some((section) => section.type === "overview");
    if ( isOverviewSectionSelected && !blockToSkipOverviewUpdate.includes(element.originalName) ) {
        const dates = { dateFrom: creator?.report?.dateFrom, dateTo: creator?.report?.dateTo };
        const [newUpdatedReportSection, newUpdatedSectionElements] = update_overview_block_values(updatedReportSection, updatedSectionElements, dates);
        updatedReportSection = newUpdatedReportSection;
        updatedSectionElements = newUpdatedSectionElements;
    }
    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        sectionElements: updatedSectionElements, 
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const find_overview_block_values = (reportSection, sectionElements, dates) => {
   
    let ads_spend_value = 0;
    for ( let section of sectionElements ) {
        if (section.type === "paid" && section.row === "static") {
            ads_spend_value += section?.kpis
                ?.find((kpi) => kpi.originalName === "spend")?.displayedValue
        }
    }

    let agency_cost_value = sectionElements
        ?.find((se) => se.type === "overview" && se.row === "static")?.kpis
        ?.find((kpi) => kpi.originalName === "agency_cost")?.displayedValue;

    ads_spend_value = ads_spend_value && !isNaN(ads_spend_value ) ? parseFloat(ads_spend_value) : 0;
    agency_cost_value = agency_cost_value && !isNaN(agency_cost_value) ? parseFloat(agency_cost_value) : agency_cost_value;

    const total_spend_value = agency_cost_value && !isNaN(agency_cost_value) ? ads_spend_value + agency_cost_value : ads_spend_value;
    const days_reporting_value = calculateDaysDifference(dates?.dateFrom, dates?.dateTo);
    
    const values = {
        ads_spend_value, 
        total_spend_value,
        days_reporting_value,
    }
    return values;
}

export const update_overview_block_values = (reportSection, sectionElements, dates) => {
    const {
        ads_spend_value, 
        total_spend_value,
        days_reporting_value,
    } = find_overview_block_values(reportSection, sectionElements, dates); 

    const blocksToAutomaticallyUpdate = [
        {originalName: "total_spend", value: total_spend_value}, 
        {originalName: "ads_cost", value: ads_spend_value},
        {originalName: "days_reporting", value: days_reporting_value},
    ];
    
    let overviewSection = reportSection.find((section) => section.type === "overview");
    let overviewStaticSectionElement = sectionElements.find((se) => se.type === "overview" && se.row === "static");

    for ( const block of blocksToAutomaticallyUpdate ) {
        let matchedReportSectionBlock = overviewSection.rows
            .find((row) => row.type === "static").elements
            .find((kpi) => kpi.originalName === block.originalName);
        
        let matchedSectionElementBlock = overviewStaticSectionElement.kpis
            .find((kpi) => kpi.originalName === block.originalName);
        
        if ( matchedReportSectionBlock ) {
            matchedReportSectionBlock.displayedValue = block.value
            if (block?.compare_value) {
                matchedReportSectionBlock.displayedCompareValue = block?.compare_value;
                matchedReportSectionBlock.comparedValue = block?.compare_value;
            }
        }    
        if ( matchedSectionElementBlock ) {
            matchedSectionElementBlock.displayedValue = block?.value;
            if (block?.compare_value) {
                matchedSectionElementBlock.displayedCompareValue = block?.compare_value;
                matchedSectionElementBlock.comparedValue = block?.compare_value;
            }
        }
    }
    return [reportSection, sectionElements];
}
  

export const add_block_to_report = (creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper, t, sectionPosition) => {

    let updatedReportSection = deepClone(creator.report.sections);
    const isCustom = block.isCustom;

    if ( isCustom ) {  
        /* Construct the custom KPI */
        const position = updatedReportSection
            .find((section) => section.type === sectionType && section.sectionData
            .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
            .find((row) => row.type === rowType)?.elements?.length
        const original_name =  uuid4();
        // TODO: Maybe we can replace uuid4 with "custom_kpi" for simplicity, then change the logic in the Block_staic for showCurrency
        block = {
            category: "custom",
            displayedName: (t("Custom KPI")),
            displayedValue: 0,
            givenName: (t("Custom KPI")),
            originalName: original_name,
            comparedValue: "+0",
            displayedCompareValue: "",
            position: position,
            recommended: false,
            value: (t("KPI value")),
            variant: "main",
            showCurrency: false,
        }
    }
    if(block.originalName === 'total_spend') {
        const overViewKPIS = creator?.report?.sections.find((section) => section.type === "overview")?.rows.find((row) => row.type === "static")?.elements
        const adsCost = overViewKPIS.find((kpi) => kpi.originalName === "ads_cost")?.displayedValue;
        const agencyCost = (overViewKPIS.find((kpi) => kpi.originalName === "agency_cost")?.displayedValue || 0);

        block.displayedValue = parseFloat(adsCost) + parseFloat(agencyCost);
    }
    updatedReportSection
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === rowType).elements.push(block)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
        sliderFlowHelper: !isCustom ? prev.sliderFlowHelper : {
            ...prev.sliderFlowHelper,
            shouldOpen: true,
            openSlider: "edit-block",
            previousSlider: "",
            metadata: {id: block.originalName},
            closeNested: true,
            openNested: true,
        }
    }))
}


export const remove_block_from_report = (creator, setCreator, block, sectionType, sectionId, rowType, idTypeHelper, sectionPosition) => {
    let updatedReportSection = deepClone(creator.report.sections);
    const indexToRemove = updatedReportSection
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData.find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === rowType).elements.findIndex(obj=>(obj.originalName === block.originalName && obj.type === block.type))

    updatedReportSection 
        .find((section) => section.position === sectionPosition && section.type === sectionType && section.sectionData.find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === rowType).elements.splice(indexToRemove, 1)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const add_demographic_block_to_report = (creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper) => {
    let updatedReportSection = deepClone(creator.report?.sections);
    let demographics = creator.sectionElements?.find((item) => item.type === sectionType && item.id === sectionId && item.row === "demographics")?.kpis;
    let demographicRow = updatedReportSection?.find((section) => section.type === sectionType)?.rows?.find((row) => row.type === "demographics");
    const pos = demographicRow?.elements?.length;

    let kpi = {
        type: chartType,
        originalName: item?.name,
        givenName: item?.name,
        position: pos,
        displayedName: demographics?.find((item) => item?.type === chartType)?.kpis[item?.name]?.displayedName,
        values: demographics?.find((item) => item?.type === chartType)?.kpis[item?.name]?.values,
    };

    updatedReportSection
        .find((section) => section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === "demographics").elements.push(kpi)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}


export const remove_demographic_block_from_report = (creator, setCreator, item, chartType, sectionType, sectionId, idTypeHelper) => {
    let updatedReportSection = deepClone(creator.report.sections);

    const indexToRemove = updatedReportSection
        .find((section) => section.type === sectionType && section.sectionData.find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === "demographics").elements.findIndex(obj=>obj.originalName === item.name && obj.type === chartType)

    updatedReportSection
        .find((section) => section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === "demographics").elements.splice(indexToRemove, 1)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}




// NOT WORKING AT THE MOMENT
export const remove_demographic_from_report = (creator, setCreator, item, sectionType, sectionId, idTypeHelper) => {
    let updatedReportSection = deepClone(creator.report.sections);
    
    updatedReportSection
        .find((section) => section.type === sectionType && section.sectionData
        .find((obj) => obj.type === idTypeHelper)?.id === sectionId)?.rows
        .find((row) => row.type === "demographics").elements.filter((obj) => obj.originalName !== item.name)

    setCreator((prev) => ({
        ...prev, 
        report: {...prev.report, sections: updatedReportSection},
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            isModified: true,
        },
    }))
}