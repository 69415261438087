import { Builder_, Checkbox_, Dropdown_, Input_, Slider_ } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Edit_Block(props) {

    const { t } = useTranslation();
    const [title, setTitle] = useState(props.titleDefaultValue);
    const [value, setValue] = useState(props.displayedValue);

    const notHavingComparedValue = ['agency_cost', 'days_reporting', 'total_spend', 'ads_spend'];

    const applyValidationCategories = {
        custom: {
            defaultTitle: "Custom KPI",
            defaultValue: 0,
        }
    };

    const [messagesTitle, setMessagesTitle] = useState({
        helpers: [],
        errors: [],
        successes: [],
    });

    const [messagesText, setMessagesText] = useState( {
        helpers: [],
        errors: [],
        successes: [],
    });

    //maybe need to change the logic a bit depanding the default values
    //anything can be implemented
    
    const closeSlider = () => {    
        
        if ( !applyValidationCategories[props.elementCategory] ) {
            props.onClick()
        }
        else if (   applyValidationCategories[props.elementCategory]?.defaultTitle !== props.title && 
                    applyValidationCategories[props.elementCategory]?.defaultValue !== props.value &&
                    props.title && 
                    props.value ) {
            props.onClick();
        }   
        else {
            if(title !== "" && value !== "" && /^\d*\.?\d*$/.test(value))
                props.onClick();
            else {
                if(!title)
                    setMessagesTitle({errors: [(t("Fill out this field"))], successes: '', helpers: ''});
                else if(!/^\d*\.?\d*$/.test(value)) 
                    setMessagesText({helpers: [(t("Please enter only numbers"))], successes: '', errors: ''})
                else 
                    setMessagesText({errors: [(t("Fill out this field"))], successes: '', helpers: ''})

            }
        }
    }


    const changeMessages = (text, source) => {
        if(source === 'title') {
            if(!text) {
                setMessagesTitle({helpers: [(t("Fill out this field"))], successes: '', errors: ''})
            }
            else {
                setMessagesTitle({helpers: '', successes: [(t('Fill out this field'))], errors: ''})
            }
        }
        else {
            if(text.length === 0)
                setMessagesText({helpers: [(t('Fill out this field'))], successes: '', errors: ''})
            else if (/^\d*\.?\d*$/.test(text)) {
                setMessagesText({helpers: '', successes: [(t('Please enter only numbers'))], errors: ''})
            } 
            else {
                setMessagesText({helpers: [(t('Please enter only numbers'))], successes: '', errors: ''})
            }
        }
    }    

    return (
    <Slider_ onClick={() => closeSlider()} text={t('Done')} title={t('Edit indicator')} sectionName={props.sectionName} rowName={props.rowName} >
      
        <Builder_ title={t('Edit the title of the indicator')}>
            <Input_ 
                helpers={messagesTitle?.helpers}
                successes={messagesTitle?.successes}
                errors={messagesTitle?.errors}
                required={true} 
                // tips={['Give a name to describe your custom KPI']} 
                placeholder={props.title === props.titleDefaultValue ? props.titlePlaceholder : props.titleDefaultValue} 
                defaultValue={props.displayedName} 
                onChange={(e) => {
                    setTitle(e.target.value);
                    changeMessages(e.target.value, 'title');
                    props.setBlock(props.element, e.target.value, 'displayedName', props.sectionType, props.sectionId, props.rowType)
                }}
            />
        </Builder_>
        {props.rowType === "static" ? 
        <Builder_ title={t('Edit the value of the indicator')}>
            <Input_ 
                // tips={['Give the value of your custom KPI']} 
                helpers={messagesText?.helpers}
                successes={messagesText?.successes}
                errors={messagesText?.errors}
                required={true} 
                placeholder={props.value === props.valueDefaultValue ? props.valuePlaceholder : props.valueDefaultValue} 
                defaultValue={props.displayedValue} 
                onChange={(e) => {
                    setValue(e.target.value);
                    changeMessages(e.target.value, 'value');
                    props.setBlock(props.element, parseFloat(e.target.value), 'displayedValue', props.sectionType, props.sectionId, props.rowType)
                    }
                } 
            />
        </Builder_>
        : null }
        {props.rowType !== "demographics" ?
        <Builder_ title={t('Edit the style of the indicator')}>
            <Dropdown_>
                <select value={props?.element?.variant} onChange={(e) => props.setBlock(props.element, e.target.value, 'variant', props.sectionType, props.sectionId, props.rowType)}>
                    <option value="main">{t('Primary')}</option>
                    <option value="focus">{t('Secondary')}</option>
                    <option value="mainInverted">{t('Primary inverted')}</option>
                    <option value="focusInverted">{t('Secondary inverted')}</option>
                    <option value="simple">{t('Simple')}</option>
                </select>
            </Dropdown_>
        </Builder_>
        : null}
        {!notHavingComparedValue.includes(props.element) && props.rowType === "static" && props.showCompare? (
            <Builder_ title={t('Edit the compared value of the indicator')}>
                <Checkbox_
                    text={t("Display compared values")}
                    checked={props.comparedValue ? true : false}
                    onChange={() => {
                        // console.log(props.comparedValue, props.comparedValueDefaultValue);
                        props.setBlock(props.element, props.comparedValue ? '' : props.comparedValueDefaultValue, 'displayedCompareValue', props.sectionType, props.sectionId, props.rowType);
                    }}
                />
            </Builder_>
        ) : null}
       
        {props.comparedValue && !notHavingComparedValue.includes(props.element) ? (
            <Builder_>
                <Input_ placeholder={props.comparedValue === props.comparedValueDefaultValue ? props.comparedValuePlaceholder : props.comparedValueDefaultValue} defaultValue={props.comparedValue} onChange={(e) => props.setBlock(props.element, e.target.value, 'displayedCompareValue', props.sectionType, props.sectionId, props.rowType)} />
            </Builder_>
        ) : null}
        {props.elementCategory === "custom" ? (
            <Builder_ title={t('Edit static indicator type')}>
                <Checkbox_
                    text={t("Display as a currency")}
                    checked={props.showCurrency ? true : false}
                    onChange={() => {
                        props.setBlock(props.element, !props.showCurrency ,'showCurrency', props.sectionType, props.sectionId, props.rowType);
                    }}
                    />
            </Builder_>
        ) : null}
    </Slider_>
    
    );
}
