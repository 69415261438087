import { Confirmation_, Focus_Plain, Grey_Link, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageFull, PageIllu, PageSixty, PageSubtitle, PageTitle, Main_Loader } from 'monica-alexandria';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select_Templates from '../../components/Select_Templates';
import { deleteTemplate, get_accessed_templates } from '../../services/api/Templates';
import i18n from '../../../../localization/i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import store from '../../../../redux/store';
import styled from 'styled-components';
import { deletePresetStructure } from '../../services/api/PresetStructures';
import { checkPermissions } from '../../../../helpers/checkPermissions';
import { get_my_subscriptions } from '../../../../services/api/Users';
import { AuthContext } from '../../../../services/authentication/Guard';

const clients = require('../../../../config/index').config

const ReportTemplate = styled(PageSixty)`
`

const ReportTemplateTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export default function REPORT_TEMPLATE() {
    
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const navigate = useNavigate();
    const auth = useContext(AuthContext)

    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const permission = checkPermissions('Knight', auth.user.roles);
    const [hasCheckOwnership, setHasCheckOwnership] = useState(false);
    const userId = store.getState()?.user?.user?.sub;

    const [templates, setTemplates] = useState(false);

    const [confirmation, setConfirmation] = useState({show: false, item: false});

    const apply_selection = (template) => {
        store.dispatch({type: "SAVE_PRESELECTED_TEMPLATE", payload: template})
        navigate(`/${lang}/integrations`);
    }

    const delete_template = (template) => {
        console.log(template);
        setConfirmation((prev) => ({...prev, show: false, item: false}))
        deleteTemplate(template._id)
            .then((res) => {
                if ( res?.data?.code === 200 ) setTemplates(templates?.filter((tem) => tem?._id !== template?._id))
            }).catch((err) => {})
    } 

    useEffect(() => {
        get_accessed_templates()
        .then((templateResponse) => {
            const templates = [...templateResponse?.data?.data?.accessedTemplates, ...templateResponse?.data?.data?.preBuiltTemplates]
            if ( templates ) {
                setTemplates(templates);
            }
        })
    },[]);   

    /* Validate Tool Ownership */
    useEffect(() => {
        get_my_subscriptions(userId)
        .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                console.log('lois subs', lois_subs)
                if(!lois_subs?.tools?.some(tool => tool?.active) && !permission) window.location.assign(payments_URL);
                else setHasCheckOwnership(true)
        }).catch((err) => console.log('error', err));
    }, [userId]);

    if(!templates) 
        return (
            <Main_Loader/>
            )

    return ( <ReportTemplate>
        <Helmet>
            <title>Adsnap | Select templates</title>
        </Helmet>
        { hasCheckOwnership ? <PageAlwaysVisible>
            {confirmation.show ? 
                <Confirmation_ 
                    message={t('Are you sure you want to delete this template?')} 
                    yes={t('Yes')} 
                    no={t('No')} 
                    onYes={() => delete_template(confirmation.item)} 
                    onNo={() => setConfirmation((prev) => ({...prev, show: false, item: false}))} 
                    />
            : null }
                <PageTitle cta>
                    <ReportTemplateTitle>
                    <Grey_Link iconLeft="Back" onClick={() => navigate(-1)} noPadding />
                    <h1>{t('Select a template')}</h1>
                    </ReportTemplateTitle>
                    {/* <Focus_Plain text="Pro Mode" iconLeft="Edit" onClick={() =>apply_selection(proTemplate)}/> */}
                </PageTitle>
                <PageSubtitle>
                            <p>{t("Select a prebuilt or saved report template so you don't start always from scratch!")}</p>
                </PageSubtitle>
                <Select_Templates 
                    templates={templates}
                    onApply={(template) => apply_selection(template)}
                    onDelete={(template) => setConfirmation((prev) => ({...prev, show: true, item: template}))}
                    permission={permission}
                    refresh={() => navigate(0)}
                />
        </PageAlwaysVisible> : null }
        <PageIllu>
            <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/templates/SelectTemplate.svg' />
        </PageIllu>
    </ReportTemplate>);
}
