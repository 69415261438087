import React, { useEffect } from 'react';
import styled from 'styled-components';
import { H2, H3, P } from '../../../styles/components/LandingText';
import { Card, Focus_, Focus_Plain, Grey_Link, device } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const LandingFeatures = styled.section``;

const LandingFeaturesBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    grid-gap: 4rem;
    padding: 4rem;
    margin-bottom: 15rem;

    img{
        max-width: 50rem;
        margin: 0 auto;
        display: block;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 4rem;
    }

    main{

        button{
            margin-top: 4rem;
        }
    }

    @media ${device.md} {
        grid-template-columns: 1fr;
    }
`;

const LandingFeaturesBoxInverted = styled(LandingFeaturesBox)`
    grid-template-columns: 2fr 1fr;

    @media ${device.md} {
        grid-template-columns: 1fr;

        img{
            grid-row-start: 1;
        }
    }
`;

export default function Landing_Features(props) {

    const {t} = useTranslation();

    return (
        <LandingFeatures>
            <H2>{t("How can Adsnap help?")}</H2>
                <P>{t("Adsnap helps digital marketers and digital agencies build facebook, instagram and google ads reports in the fastest and easiest way possible.")}</P>
                <P>{t("We make marketing reporting an enjoyful process.")}</P>
            <LandingFeaturesBox>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/WhiteLabel.svg" />
                <main>
                    <H3>{t("White Label Reports")}</H3>
                    <P>{t("Optimize your reporting with our white label solution, designed for clarity and ease of comprehension. Customize your reports by selecting colors that align with your brand identity and uploading your company logo for a professional, personalized touch. This feature not only enhances the aesthetic appeal of your reports but also strengthens brand consistency and recognition in all your analytical communications.")}</P>
                    <Focus_ size="large" text={t("Start for free")} onClick={props.onBuy} />
                </main>
            </LandingFeaturesBox>
            <LandingFeaturesBoxInverted>
                <main>
                    <H3>{t("Dynamic Comparison")}</H3>
                    <P>{t("Our Dynamic Comparison tool enables you to effortlessly compare current period values with those of previous periods.")}</P>
                    <Focus_ size="large" text={t("Start for free")} onClick={props.onBuy} />
                </main>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/Efficient.svg" />
            </LandingFeaturesBoxInverted>
            <LandingFeaturesBox>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/TimeSaving.svg" />
                <main>
                    <H3>{t("Upload Image - Screenshot on your report")}</H3>
                    <P>{t("Have you ever found yourself wishing you could directly incorporate a screenshot into your reports? Imagine the convenience of seamlessly adding an image from Google Ads or Google Search Console to complement your Meta Ads reporting. This feature would not only enrich your reports with visual data but also provide clearer insights and context for your analyses.")}</P>
                    <Focus_ size="large" text={t("Start for free")} onClick={props.onBuy} />
                </main>
            </LandingFeaturesBox>
            <LandingFeaturesBoxInverted>
                <main>
                    <H3>{t("Custom KPIs")}</H3>
                    <P>{t("With our Custom KPIs feature, take control of your performance metrics. Add your own Key Performance Indicators (KPIs) to your reports to focus on what matters most to your business. Tailor your analysis to your specific goals and gain deeper insights into your unique success parameters.")}</P>
                    <Focus_ size="large" text={t("Start for free")} onClick={props.onBuy} />
                </main>
                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/lois/landing/features/WhiteLabel.svg" />
            </LandingFeaturesBoxInverted>
        </LandingFeatures>
    );
}
