import React from 'react';
import styled from 'styled-components';
import { H2, H3, h6, P } from '../../../styles/components/LandingText';
import { Card, device } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';

const LandingQa = styled.section`
    background: ${(p) => p.theme.low};
    h2 {
        text-align: center;
    }
`;

const LandingQaGrid = styled.div`
    padding: 0 10rem;
    max-width: 100rem;
    margin: 0 auto;

    @media ${device.md} {
        padding: 0 2rem;
    }
`;

const LandingQaBox = styled(Card)`
    padding: 2rem;
    position: relative;
    overflow: unset;
    background: ${(p) => p.theme.background};
    margin-top: 2rem;
`;

const LandingQaQ = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--main));
    transform: translate(-4rem, -4rem) rotate(-1deg);

    h3 {
        color: var(--white);
    }
`;

const LandingQaA = styled(Card)`
    padding: 2rem 4rem;
    background: linear-gradient(to bottom right, var(--mainDark), var(--focus));
    transform: translate(4rem, 0) rotate(1deg);

    p {
        color: var(--white);
        margin-top: 2rem;
    }

    h4 {
        color: var(--white);
        margin-bottom: 1rem;
    }

    h6 {
        color: var(--white);
        font-size: 1.8rem;
        font-family: 'fontLight';
    }
`;

export default function Landing_Qa() {

    const {t} = useTranslation()

    return (
        <LandingQa>
            <H2>{t("Q&A")}</H2>
            <LandingQaGrid>
                <LandingQaBox>
                    <LandingQaQ>
                        <H3>{t("How to Get a Facebook Ads Report?")}</H3>
                    </LandingQaQ>
                    <LandingQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Obtaining a Facebook Ads report is straightforward, especially with tools like adsnap.doitforme.eu. If you don’t want to use a facebook ads reporting tool you can do it from facebook ads manager but without the ease and friendly design of adsnap. In Facebook Ads Manager, select the 'Reports' section for a comprehensive view of ad performance. Customize your report by choosing relevant date ranges and metrics. With adsnap.doitforme.eu, this process is made even more effortless, enabling you to easily access insights into ad engagement, reach, and ROI, crucial for informed decision-making.")}</h6>
                    </LandingQaA>
                </LandingQaBox>
                <LandingQaBox>
                    <LandingQaQ>
                        <H3>{t("How to Download Facebook Ad Reports?")}</H3>
                    </LandingQaQ>
                    <LandingQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Downloading a Facebook Ad report is a breeze with the help of adsnap.doitforme.eu. You just Select the Business Manager you want, then the ad account, the time period and you are ready! Data is imported automatically! Just press Download. If you want to download it without the help of external tools just navigate in your Ads Manager, select the desired report and click 'Export' at the top right. Choose your preferred file format, like CSV or Excel, for further analysis. The adsnap.doitforme.eu tool streamlines this process, making it simple to share insights with team members or integrate data into other analytical tools for comprehensive evaluation.")}</h6>
                    </LandingQaA>
                </LandingQaBox>
                <LandingQaBox>
                    <LandingQaQ>
                        <H3>{t("What Are the Best Free Facebook Ads Reporting Tools?")}</H3>
                    </LandingQaQ>
                    <LandingQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t(" The best free Facebook Ads reporting tools offer diverse features to enhance your advertising analytics")}</h6>
                        <P>{t("Facebook Ads Manager")}</P>
                        <h6>{t("The default go-to tool, Facebook's own Ads Manager provides a comprehensive analytics suite. It's ideal for tracking a wide array of performance metrics directly on the platform.")}</h6>
                        <P>{t("adsnap.doitforme.eu")}</P> <h6>{t("This tool stands out for its user-friendly interface and essential analytics capabilities. It simplifies the process of tracking and analyzing your Facebook Ads campaigns, making it a great choice for both beginners and experienced marketers.")}</h6>
                        <P>{t("Buffer/Hootsuite")}</P> <h6>{t("Both offer limited free versions that include Facebook Ads reporting features. They are excellent for integrating social media management with ad performance tracking.")}</h6>
                    </LandingQaA>
                </LandingQaBox>
                <LandingQaBox>
                    <LandingQaQ>
                        <H3>{t("Where Can I Find Facebook Ads Reporting Templates?")}</H3>
                    </LandingQaQ>
                    <LandingQaA>
                        <h4>{t("Answer")}</h4>
                        <h6>{t("Various online resources offer Facebook Ads reporting templates, each catering to different analytical needs")}</h6>
                        <P>{t("adsnap.doitforme.eu")}</P> <h6>{t("Renowned for its full customization and white-label options, this tool allows you to tailor your Facebook Ads reports to your specific needs. It provides a unique advantage for branding, making it ideal for agencies or businesses looking to present data in their own style.")}</h6>
                        <P>{t("HubSpot")}</P>
                        <h6>{t(" Offers a selection of free, downloadable templates that are especially useful for aligning Facebook Ads data with broader inbound marketing strategies.")}</h6> <P>{t("AdEspresso by Hootsuite")}</P>
                        <h6>{t("Known for user-friendly templates, AdEspresso provides clear and concise formats that are great for analyzing and presenting Facebook Ads data effectively.")}</h6>
                        <P>{t("Google Sheets/Excel Templates")}</P>
                        <h6>{t("Numerous free templates are available that can be used in Google Sheets or Excel. These templates are perfect for those who prefer a more manual but highly customizable approach to data analysis.")}</h6>
                    </LandingQaA>
                </LandingQaBox>
            </LandingQaGrid>
        </LandingQa>
    );
}
