export const blockToSkipOverviewUpdate = ["total_spend", "ads_cost", "days_reporting"];

export const currencyBlocks = [
    // fb metrics to show as $
    "total_spend", 
    "ads_cost", 
    "agency_cost", 
    "spend",
    "cpm",
    "cpc",
    "custom_cost_clicks_all",
    "custom_cost_link_clicks",
    "custom_cost_page_engagements",
    "custom_cost_3_second_video_plays",
    "cost_per_thruplay",
    "custom_cost_landing_page_views",
    "custom_cost_website_add_to_cart",
    "custom_cost_website_content_views",
    "custom_cost_initiate_checkout",
    "custom_cost_website_purchases",
    "custom_cost_website_leads",
    "custom_cost_website_complete_registration",
    "custom_cost_contact",
    "custom_cost_schedule_total",
    "custom_cost_start_trial",
    //google metrics to show as $
    "costMicros", "activeViewMeasurableCostMicros", "averageOrderValueMicros",
    "costOfGoodsSoldMicros", "grossProfitMicros", "revenueMicros", "crossSellCostOfGoodsSoldMicros",
    "crossSellGrossProfitMicros", "crossSellRevenueMicros", "leadCostOfGoodsSoldMicros", "leadGrossProfitMicros",
    "leadRevenueMicros", "crossDeviceConversionsValueMicros",    
    "averageCost",     "averageCpc",
    "averageCpe",    "averageCpm",    "averageCpv",    "costPerConversion", "costPerAllConversions",
    "activeViewCpm",
    "custom_cost_leads",
    "custom_cost_click_to_call_call_confirm"
];

export  const rateList = [
    //fb metrics to show as %
    "outbound_clicks_ctr",
    "website_ctr",
    //google metrics to show as %
    "conversionsFromInteractionsRate", "allConversionsFromInteractionsRate", "currentModelAttributedConversionsFromInteractionsRate", 
    "videoViewRate", "engagementRate", "interactionRate", "invalidClickRate", "ctr",
    "topImpressionPercentage","absoluteTopImpressionPercentage","searchBudgetLostImpressionShare","searchExactMatchImpressionShare","searchImpressionShare",
    "searchRankLostImpressionShare",     "contentBudgetLostImpressionShare",    "contentImpressionShare",    "contentRankLostImpressionShare",    
    "activeViewViewability", "activeViewCtr", "activeViewMeasurability",
    "searchAbsoluteTopImpressionShare",
    "searchBudgetLostAbsoluteTopImpressionShare",
    "searchBudgetLostTopImpressionShare",
    "searchRankLostAbsoluteTopImpressionShare",
    "searchRankLostTopImpressionShare",
    "searchClickShare",
    "searchTopImpressionShare",
    "videoQuartileP25Rate",
    "videoQuartileP50Rate",
    "videoQuartileP75Rate",
    "videoQuartileP100Rate",
    "actualRoas"
    ];
