import { PageFull } from 'monica-alexandria';
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
    margin-bottom: 1rem;

    select {
        padding: 1rem;
        background: ${(props) => props.theme.low};
        box-shadow: ${(props) => props.theme.in};
    }
`;

export const AdAccount = styled(PageFull)``;

export const BuilderContent = styled.div`
    padding-bottom: 2rem;
    .DisableCreation {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }
`;

export const LoadingWrapper = styled.div`
    padding-bottom: 1.5rem;
`;

export const TemplateWrapper = styled.div``