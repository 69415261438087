import axios from "axios"
import { config } from "../../../../config"



const get_accessed_preset_structures = () =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.LOIS_BACKEND_URL}/meta/presetstructures/getAccessedPresetStructures`)
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}


const create_preset_structure = (presetStructure, createAction) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstructures/createPresetStructure`,{
            payload: presetStructure,
            createAction: createAction,
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}



const update_preset_structure = (presetStructure, structureId) =>{
    const payload = {presetStructure, structureId};
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstructures/updatePresetStructure`,{
            payload: payload,
        })
            .then(res => {
                console.log(res);
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}



const deletePresetStructure = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.LOIS_BACKEND_URL}/meta/presetstructures/deletePresetStructure`, {
            presetStructureId: id
        })
            .then(res => {
                resolve(res)
            })
            .catch((err) => {
                reject(false);
            })
    })
}




export {
    get_accessed_preset_structures,
    create_preset_structure,
    update_preset_structure,
    deletePresetStructure,
};