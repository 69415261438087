import { AlertContext, Confirmation_, IconSvg, Main_Plain, Partial_Loader, Red_ } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Google_ } from '../../../sources/google/pages/connect/components/Google_Button'
import { useTranslation } from 'react-i18next'
import { removeGoogleConnection } from '../../../sources/google/services/api/GoogleApi'

const ChangeGoogleConnection = styled.div`

`

const Connection = styled.div`
    border-radius: var(--smallRadius);
    box-shadow: ${(p) => p.theme.out};
    padding: var(--largePads);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    .connected{
        color: var(--success);
    }
`

const ConnectionsWrapper = styled.div`
    padding-bottom: 2rem;

`
const AccountActions = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

const ConnectionSuccess = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: pointer;

  svg {
    fill: var(--success);
    width: 1.5rem;
  }

`



export const ValidatorTip = styled.div`
display: grid;
grid-template-columns: var(--smallIcon) 1fr;
align-items: flex-start;
grid-gap: 0.5rem;
padding: 0.5rem 0rem;

h6 {
    color: var(--greyDark) !important;
}

svg {
    width: var(--smallIcon);
    fill: var(--greyDark) !important;
}
`;

export const Change_Google_Connection = ({loadingGoogle, googleConnections, setGoogleConnections, connectToGoogleAccount, chooseGoogleAccount, selectedGoogleAccount, setConfirmation}) => {
  const {t} = useTranslation();
  const [connectionToDlt, setConnectionToDlt] = useState(false);
  const { alerts, setAlerts } = useContext(AlertContext);

  const filteredGoogleConnections = googleConnections.filter(connection => connection.googleId !== selectedGoogleAccount?.userId )

  const alertMessage = (message, status) => {
    setAlerts([
        ...alerts,
        {
            message: {
                text: message,
                status: status,
            },
        },
    ]);
};


  const deleteGoogleConnection = () => {
    removeGoogleConnection(connectionToDlt.googleId)
    .then((res) => {
      setGoogleConnections(googleConnections.filter(connection => connection.googleId !== res.data.data.deletedUser.googleId))
      alertMessage((t("Connection deleted!")),'Success');
      
    })
    .catch((error) => console.error(error))
    setConnectionToDlt(false)

  }

  console.log('filteredGoogleConnections', filteredGoogleConnections);
  console.log('googleConnections', googleConnections, selectedGoogleAccount);
  return (
    <ChangeGoogleConnection>
      {connectionToDlt !== false  ?
       <Confirmation_
          message={t("Are you sure you want to delete this connection?")}
          no={t("No")}
          onNo={() => setConnectionToDlt(false)}
          onYes={deleteGoogleConnection}
          yes={t("Yes")}
      /> : null}
    {loadingGoogle ? 
      (
        <Partial_Loader />
      ) : (
        <ConnectionsWrapper>
          {selectedGoogleAccount?.username  ? 
            <Connection>
              <h5>{selectedGoogleAccount?.username}</h5>
              <ConnectionSuccess onClick={() => {        
                const initObj = {
                  userId: "",
                  accessToken: "",
                  username: "",
                };
                chooseGoogleAccount(initObj)
              }}
              >
                <IconSvg Icon='Check' />
                <h6 className='connected'>{t('Connected')}</h6>
              </ConnectionSuccess>
           
            </Connection> 
          : null }
          { filteredGoogleConnections?.map((connection, i) => (
              <Connection key={i}>
                  <h5>{connection.name}</h5>
                  <AccountActions>
                      <Main_Plain text={t('Connect')} onClick={() => chooseGoogleAccount(connection)} noPadding/>
                      <Red_ noPadding iconLeft='Delete' onClick={ () => setConnectionToDlt(connection)} />
                  </AccountActions>
              </Connection>
          )) }
         
        </ConnectionsWrapper>
        )} 
        <Google_
            icon size="medium" id="Connect_Google_Button" text={t('Connect new account')}
            onClick={() => setConfirmation(true)}
          />
        <ValidatorTip><IconSvg Icon="Tip" /><h6>{t("If you are having issues connecting your Google Account, please contact our Support team.")}</h6></ValidatorTip>

    </ChangeGoogleConnection>
  )
}
