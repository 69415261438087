import { Section_Title, Slider_ } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select_Templates from '../../../../components/Select_Templates';

export default function Edit_Template_Preset(props) {
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} title={t('Apply Template')} text="Done">
            <Select_Templates templates={props.templates} onApply={(template) => props.onApply(template)} onDelete={(template) => props.onDelete(template)} />
        </Slider_>
    );
}
