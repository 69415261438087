import React, { useContext, useEffect, useState } from 'react';
import {
    ThemeContext,
    HamContext,
    LangContext,
    MenuContext,
    Nav_,
    logo,
    Cta_,
    device,
} from 'monica-alexandria';
import { AuthContext } from '../../services/authentication/Guard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { checkPermissions } from '../../helpers/checkPermissions';
import store from '../../redux/store';
import { get_user_images } from '../../services/api/Users';
import { update_options_cookie } from '../../helpers/initMenuOptions';
import Navbar_Menu from './components/Navbar_Menu';
import Navbar_Ham from './components/Navbar_Ham';
import styled from 'styled-components';
import { getNumOfReports } from '../../sources/meta/services/api/Reports';
import { get_my_subscriptions } from '../../services/api/Users';
import { config } from '../../config/index';

const Navbar = styled.div`

    @media ${device.sm} {
        .Cta{display: none;}
    }
`

const clients = require('../../config/index').config;
const authClient = process.env.REACT_APP_AUTH_CLIENT;
const client = process.env.REACT_APP_CLIENT;
const paymentsclient = process.env.REACT_APP_PAYMENTS_CLIENT;

const minioUrl = clients['minio'];

const Navbar_ = () => {
    const { t } = useTranslation();
    const [textCta, setTextCta] = useState('');
    const [hamState, setHamState] = useState(false);
    const { setActiveMenu } = useContext(MenuContext);
    const { setLanguage } = useContext(LangContext);
    const { theme, setTheme } = useContext(ThemeContext);
    const [app, major, minor] = process.env.REACT_APP_VERSION.split('.').map(Number);

    const lang = i18n.language ? i18n.language : 'en';
    const navigate = useNavigate();
    const userId = store.getState()?.user?.user?.sub;
    const payments_URL = `${clients['griphook']}/${lang}/checkout-details-adsnap?category=lois`;
    const [sub, setSub] = useState();

    const changeLanguage = (lang) => {
        let path = window.location.pathname.split('/');
        path[1] = lang;
        let newPath = path.join('/');
        i18n.changeLanguage(lang);
        navigate(newPath);
        setLanguage(lang);
        setActiveMenu('Main');
    };

    const auth = useContext(AuthContext);

    const [image, setImage] = useState(false);

    const permission = checkPermissions('Knight', auth.user.roles);

    const renderImage = () => {
        const userId = store.getState()?.user?.user?.sub;
        if ( !userId ) return;
        
        get_user_images(userId)
            .then((res) => {
                if (res?.data?.data?.images?.avatar != 'set') {
                    setImage(
                        minioUrl +
                            '/doitforme/morning/app/boiler/DefaultAvatar.jpg' +
                            '?v=' +
                            Math.random().toString(36).substring(2, 22)
                    );
                } else {
                    const imageVersion = Math.random()
                        .toString(36)
                        .substring(2, 22);
                    setImage(
                        minioUrl +
                            '/erisedev/' +
                            userId +
                            '/avatar.png' +
                            '?v=' +
                            imageVersion
                    );
                }
            })
            .catch((err) => {});
    };

    const getTextCta = () => {
        if (!auth.authenticated) {
            setTextCta('Get started');
        } else if (!permission) {
            setTextCta('New report');
        } else if (permission) {
            setTextCta('Admin');
        }
    };

    const navigateCta = () => {
        if (!auth.authenticated) {
            navigate(auth?.login());
        } else if (!permission) {
            navigate(`${lang}/meta/connect-to-facebook`);
        } else if (permission) {
            window.location.assign(
                `${clients[client]}/${lang}/akihiko/${client}`
            );
        }
    };
    //admin -> akihiko
    //auth -> erised
    const logoCLick = () => {
        if (!auth.authenticated) {
            window.location.assign(`/${lang}`);
        } else {
            get_number_of_reports()
            .then(reportsAnswer => {
                if (reportsAnswer === 0)
                    navigate(`/${lang}/welcome`);
                else
                    navigate(`/${lang}/my-reports`);
            })
            .catch(err => console.error(err)); // Handle any errors occurred during fetching reports
            // window.location.assign(`/${lang}/welcome`);
        }
    };

    const settingsClick = () => {
        window.location.assign(`${clients[authClient]}/${lang}/settings`);
    };

    const avatarClick = () => {
        window.location.assign(`${clients[authClient]}/${lang}`);
    };

    const multipleLogouts = () => {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                window.FB.logout(function (response) {
                    if (response.status === 'unknown') {
                        auth?.logout();
                    }
                });
            } else auth?.logout();
        });
    };

    useEffect(() => {
        store.dispatch({ type: 'THEME_UPDATE', payload: theme });
        if (theme != store.getState().user?.user?.preferences?.theme)
            update_options_cookie(userId);
    }, [theme]);



    useEffect(() => {
        getTextCta();
        renderImage();
    },[])
    
    const get_number_of_reports = async () => {
        return getNumOfReports()
            .then((res) => {
                const reportsAnswer = res?.data?.data?.numOfReports;
                return reportsAnswer; // Return the reports for further chaining
            }).catch((err) => {
                console.error(err);
                throw err; // Rethrow the error to handle it in the caller
            });
    };
    
    const handleNavigate = () => {
        get_number_of_reports()
            .then(reportsAnswer => {
                if (reportsAnswer === 0)
                    navigate(`/${lang}/welcome`);
                else
                    navigate(`/${lang}/my-reports`);
            })
            .catch(err => console.error(err)); // Handle any errors occurred during fetching reports
    }
    
    useEffect(() => {
        get_my_subscriptions(userId)
            .then((res) => {
                let lois_subs=res?.data.find((sub) => sub?.category==='lois')
                if(lois_subs?.tools?.some(tool => tool?.active)) setSub(true)
                else setSub(false);
            }).catch((err) => console.log('error', err));
    }, [userId]);

    
    return (
        <HamContext.Provider value={{ hamState, setHamState }}>
            <Navbar>
            <Nav_
                home={sub || permission}
                onHome ={() => handleNavigate()} 
                iconHome = {logo?.lois[theme]}
                textHome = {t("My Reports")}
                minorV = {minor}                
                majorV = {major}
                onClick_Logo={logoCLick}
                firstname_Account={auth?.user?.firstname}
                imgAlt_Account=""
                img_Account={image}
                onClick_Logout={() => multipleLogouts()}
                onClick_Account={avatarClick}
                onClick_Login={() => auth?.login({clientName: authClient})}
                onClick_Settings_Main={settingsClick}  
                role={permission ? 'Admin' :auth?.authenticated ? 'Protected' : 'Unprotected'}
                onClick_Admin_Main = {()=> navigate(`/${lang}/akihiko/lois`)}
                text_Admin_Main = {t('Admin')}
                onClick_Subscriptions_Main =  {()=>window.location.assign(`${clients[paymentsclient]}/${lang}`)}
                text_Subscriptions_Main = {t('Subscriptions')}
                text_Login={t('Join')}
                text_Logout={t('Logout')}
                text_Settings_Main={t('Settings')}
                text_Theme_Main={t('Theme')}
                text_Title_Language={t('Language')}
                text_Title_Main={t('Menu')}
                text_Title_Region={t('Region')}
                onClick_English_Language={() => changeLanguage('en')}
                onClick_German_Language={() => changeLanguage('de')}
                onClick_Greek_Language={() => changeLanguage('el')}
                links= {auth?.authenticated ? <div></div> : <Cta_ size='small' text={t("Try it free")} onClick={() => auth?.login({clientName: authClient})}/>}
                menu={auth?.authenticated ? null: <Navbar_Menu onBuy={()=> window.location.assign(payments_URL)}/>}
                hamExtraItems={auth?.authenticated ? null : <Navbar_Ham />}
                text_Apps = {t("Apps")}
                text_All_Title = {t("Doitforme applications")}
                onClick_All_Dailyprofit = {() => window.location.assign(config.dailyprofit)}
                onClick_All_Lois = {() => window.location.assign(config.lois)}
                onClick_All_Whitecastle = {() => window.location.assign(config.whitecastle)}
                onClick_All_Syrax = {() => window.location.assign(config.syrax)}
            />
            </Navbar>
        </HamContext.Provider>
    );
};

export default Navbar_;
