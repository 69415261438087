import { Main_Plain, device } from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';

const ReportTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    h2 {
        width: 100%;
        color: ${(p) => p.presetStyle?.color.textDark};
        border-top: 1px solid ${(p) => p.presetStyle?.color.grey};
        border-bottom: 1px solid ${(p) => p.presetStyle?.color.grey};
        border-left: ${(p) => (!p.presetStyle?.radius ? '0px' : '1px')} solid ${(p) => p.presetStyle?.color.grey};
        border-right: ${(p) => (!p.presetStyle?.radius ? '0px' : '1px')} solid ${(p) => p.presetStyle?.color.grey};
        padding: 1rem 2rem;
        margin: ${(p) => (!p.presetStyle?.radius ? '0rem' : '1rem 2rem 1rem 1rem')};
        border-radius: ${(p) => (!p.presetStyle?.radius ? '0' : p.presetStyle?.radius)};
        font-family: ${(p) => p.presetStyle?.font.family};
    }

    button {
        margin-left: 2rem;
    }
`;

export default function Report_Title(props) {
    const { reportStyle } = useContext(ReportStyleContext);

    return (
        <ReportTitle presetStyle={reportStyle}>
            {!props.viewMode ? <Main_Plain iconLeft="Edit" size="large" noPadding onClick={props.onClick} /> : null}
            <h2>{props.title}</h2>
        </ReportTitle>
    );
}
