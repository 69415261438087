import { CardsSixty, On_Stat, Section_Title, Slider_, Toggle_Stat, Builder_, Google_Search } from 'monica-alexandria';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Error_Message from '../creator/Creator_Error_Message';

const FacebookWrapper = styled.div``;

const InstagramWrapper = styled.div``;

export default function Add_Section_Fbin(props) {
    const { t } = useTranslation();


    // for searching 
    const [displayedFbPages, setDisplayedFbPages] = useState(props.facebookPages);
    const [displayedInstaPages, setDisplayedInstaPages] = useState(props.instagramPages);

    const [searchResults1, setSearchResults1] = useState();
    const [searchResults2, setSearchResults2] = useState();

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults1?.input) setDisplayedFbPages(searchResults1?.data);
            else setDisplayedFbPages(props.facebookPages);
        };

        applySearchResults();
    }, [searchResults1]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults2?.input) setDisplayedInstaPages(searchResults2?.data);
            else setDisplayedInstaPages(props.instagramPages);
        };

        applySearchResults();
    }, [searchResults2]);

    const onDone = () => {
        // props.saveReport();
        props.onClick();
    }



    const error = props.errors?.fetchOrganicPages?.error;

    if (error) {
        return <Creator_Error_Message error={error} errorType={'fetchOrganicPages'} onClick={props.onClick} sliderTitle={t('Select Page')} />;
    }

    return (
        <Slider_ onClick={onDone} text={t('Done')} title={t('Select Page')} iconGreyLeft="Back" grey={t('Back')} onGrey={props.onBack}>
            
            {(props.pageType === 'facebook' || props.pageType?.type === 'facebook')&& props.facebookPages && props.facebookPages?.length > 0 && (
                // {/* Make this component Add_Section_Fbin_Fb */}
                <FacebookWrapper>
                    {props.facebookPages.length > 15 ?
                        <Builder_ >
                            <Google_Search file={props.facebookPages} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Facebook Page')} setResults={(data) => setSearchResults1(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                        </Builder_> 
                    : null }
                    <Section_Title text={t('Facebook Organic')} />
                    <CardsSixty>
                        {displayedFbPages?.map((item, i) => {
                            const isAdded = props.selectedSections.some((obj) => obj.type === 'facebook' && obj.sectionData?.find((sd) => sd.type === 'facebookPage').id === item.id);
                            return <Toggle_Stat key={i} id={i} text={item.name} onClick={() => (!isAdded ? props.addSource(item, 'facebook', 'meta') : props.removeSource(item, 'facebook', 'meta'))} active={isAdded} />;
                        })}
                    </CardsSixty>
                </FacebookWrapper>
            )}
            {(props.pageType === 'instagram' || props?.pageType?.type === 'instagram') && props.instagramPages && props.instagramPages?.length > 0 && (
                // {/* Make this component Add_Section_Fbin_Insta */}
                <InstagramWrapper>
                    {props.instagramPages.length > 15 ?
                        <Builder_ >
                            <Google_Search file={props.instagramPages} fileKeys={['name']} defaultValue={''} sanitize={true}      placeholder={t('Search Instagram Page')} setResults={(data) => setSearchResults2(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                        </Builder_> 
                    : null }
                    <Section_Title text={t('Instagram Organic')} />
                    <CardsSixty>
                        {displayedInstaPages?.map((item, i) => {
                            const isAdded = props.selectedSections.some((obj) => obj.type === 'instagram' && obj.sectionData?.find((sd) => sd.type === 'instagramPage').id === item.instagram_business_account.id);
                            return <Toggle_Stat key={i} id={i} text={item.instagram_business_account.username} onClick={() => (!isAdded ? props.addSource(item, 'instagram', 'meta') : props.removeSource(item, 'instagram', 'meta'))} active={isAdded} />;
                        })}
                    </CardsSixty>
                </InstagramWrapper>
            )}
        </Slider_>
    );
}
