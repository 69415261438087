import React from 'react';
import styled from 'styled-components';
import MetaLogo from '../../../../../../assets/MetaLogo.png';
import Creator_Title_Section from './Creator_Title_Section';
import Card_On_Source from '../card/Card_On_Source';
import { useTranslation } from 'react-i18next';

const CreatorImportSection = styled.div`
    display: ${(p) => (p.viewMode ? 'none' : 'block')};
`;

export default function Creator_Import_Section(props) {
    const { t } = useTranslation();

    return (
        <CreatorImportSection viewMode={props.viewMode}>
            <Creator_Title_Section img={props.img} title={props.title} hideEdit onDelete={props.onDelete} />
            <Card_On_Source img={props.img} firstColor={props.firstColor} secondColor={props.secondColor} onClick={props.onClick} title={props.cardTitle} text={props.text} textBtn={props.textBtn} iconBtn="Add" />
        </CreatorImportSection>
    );
}
