import React, { useContext, useEffect, useState } from 'react';
import q from 'query-string';
import { retrieveApiKeys } from '../../services/api/WoocommerceApi';


const CREDENTIALS = () => {

    const [apiKeys, setApiKeys] = useState({});
    const {domain} = q.parse(window.location.search);

    useEffect(() => {
      const fetchApiKeys = () => {
        try {
          retrieveApiKeys(domain).then(data => {

            let consumer_key = data?.data?.data?.consumerKey;
            let consumer_secret = data?.data?.data?.consumerSecret;

            if (consumer_key && consumer_secret) {
              setApiKeys({consumer_key, consumer_secret});
            }
          });
        } catch (error) {
          console.error('Error fetching API keys:', error);
        }
      }
      fetchApiKeys();
    }, []);
  
    return (
      <div className="container">
        <h1>WooCommerce API Keys</h1>
        <br></br>
        {apiKeys.consumer_key && (
          <div className="key-info">
            <p>
              <strong>Consumer Key:</strong> {apiKeys.consumer_key}
            </p>
            <p>
              <strong>Consumer Secret:</strong> {apiKeys.consumer_secret}
            </p>
          </div>
        )}
      </div>
    );
  }

export default CREDENTIALS;
