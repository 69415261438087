import {Builder_, Dates_, Form } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


const SelectDates = styled.div``;

export default function Select_Dates(props) {

	const maxDate = new Date();
    const [renderedDates, setRenderedDates] = useState([]);

    const onChange = (selections) => {
        setRenderedDates(selections);
            props.setDates({
                ...props.dates,
                sinceDate: selections[0],
                untilDate: selections[1],
            });
    };

    console.log('dates',props.dates);

    useEffect(() => {
      props.setSliderText('Select Dates')
    },[])

  return (
    <SelectDates>
		<Form>
			<Builder_>
				<Dates_ 
					allowPartialRange={true}
					onChange={onChange} 
					value={renderedDates} 
					selectRange={true} 
					dateFullCellRender={(date) => date.date()} 
					maxDate={maxDate} 
				/>
			</Builder_>
		</Form>
    </SelectDates>
  )
}