import { Builder_, CardsSixty, Checkbox_, Google_Search, Input_, On_Stat, Section_Title, Slider_, Toggle_Stat, Partial_Loader } from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Error_Message from '../creator/Creator_Error_Message';
import { Card_On_Element } from '../card/Card_On_Element';
import Edit_Block from '../edit/Edit_Block';
import { property } from 'lodash';

const CheckboxWrapper = styled.div``;

export default function Add_Meta_Row_Graphical(props) {
    const { t } = useTranslation();

    const [displayedKpis, setDisplayedKPIs] = useState(props.allElements);
    const [searchResults, setSearchResults] = useState();
    const recommendedKpis = props.allElements?.filter((obj) => obj?.recommended);

    let matchingError = [];
    switch (props.sectionType) {
        case 'paid': {
            matchingError = 'fetchMetaPaidKpis';
            break;
        }
        case 'facebook': {
            matchingError = 'fetchFacebookKpis';
            break;
        }
        case 'instagram': {
            matchingError = 'fetchInstagramKpis';
            break;
        }
    }
    const error = props.errors[matchingError]?.find((section) => section?.extraInfo?.sectionInfo?.id === props.sectionId)?.error;


    useEffect(() => {
        if(props.allElements && props.allElements?.length > 0){
            setDisplayedKPIs(props.allElements);
        }
    }, [props.allElements]);

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults?.input) setDisplayedKPIs(searchResults?.data);
            else setDisplayedKPIs(props.allElements);
        };

        applySearchResults();
    }, [searchResults]);

    if (error) {
        return <Creator_Error_Message error={error} errorType={matchingError} onClick={props.onClick} sliderTitle={t('Graphical KPIs Elements')} />;
    }

    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Graphical indicators')} >
            {  props.loader ?<Partial_Loader/> :
            <>

            {/* TODO:Remove on Overview section since it has very few indicators */}
            { props.allElements?.length > 15 ? <Builder_ title={t('Search for a key performance indicator')}>
                <Google_Search file={props.allElements} fileKeys={['displayedName', 'givenName']} defaultValue={''} sanitize={true} placeholder={t('Search KPIs')} setResults={(data) => setSearchResults(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
            </Builder_> : null }
            {!searchResults?.input && recommendedKpis?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Recomended')} />
                    <CardsSixty>
                        {recommendedKpis?.map((item, i) => {
                            const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                            return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                        })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'General')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('General')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'General')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Engagement')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Engagement')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Engagement')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Video')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Video')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Video')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Ecommerce')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Ecommerce')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Ecommerce')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Lead Metrics')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Lead Metrics')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Lead Metrics')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            
            {/* google categories */}
            {displayedKpis?.filter((obj) => obj.category === 'Performance')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Performance Metrics')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Performance')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Conversions')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Conversion Metrics')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Conversions')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Competitive')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Competitive Metrics')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Competitive')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => obj.category === 'Other')?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title text={t('Other Metrics')} />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => obj.category === 'Other')
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            {displayedKpis?.filter((obj) => !obj.category)?.length > 0 ? (
                // {/* TODO:Make it individual component and remove <></> */}
                <>
                    <Section_Title />
                    <CardsSixty>
                        {displayedKpis
                            ?.filter((obj) => !obj.category)
                            ?.map((item, i) => {
                                const isAdded = props.elements?.some((obj) => obj.originalName === item.originalName);
                                return <Toggle_Stat key={i} id={i} text={item.displayedName} onClick={() => (!isAdded ? props.addBlock(item) : props.removeBlock(item))} active={isAdded} />;
                            })}
                    </CardsSixty>
                </>
            ) : null}
            </>
            }
        </Slider_>
    );
}
