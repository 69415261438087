import axios from "axios"
import { config } from "../../config"


const create_account = (title, userId, source, subsource) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/accounts/createAccount`, {
            data: {
                title,
                userId,
                source,
                subsource,
            }
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const create_accounts = (accounts) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/accounts/createAccounts`, {
            data: {accounts}
        })
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}


const get_accounts = (userId, source) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/accounts/getAccounts?userId=${userId}&source=${source}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const has_account = (userId) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/accounts/hasAccount?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const delete_account = (id) =>{
    return new Promise((resolve,reject) =>{
        
        axios.post(`${config.LOIS_BACKEND_URL}/accounts/deleteAccount`, {data: {id}})
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}



const save_logo = (files, accountName, userId) => {

    let form = new window.FormData(files[0]);
    form.append('file', files);
    form.append('accountName', accountName);
    form.append('userId', userId);
    form.append('source', "meta");
  
    return new Promise((resolve, reject) => {
  
        axios.post(`${config.LOIS_BACKEND_URL}/accounts/saveLogo`, form)
            .then((res) => {
                resolve(res)
            })
            .catch((err) => {
                resolve(false)
            });
    })
  }

// For now it only checks for reports
const check_user_status_on_landing = (userId) =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/accounts/check_user_status_on_landing?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}

const isUserKnightOrAbove = () =>{
    return new Promise((resolve,reject) =>{
        
        axios.get(`${config.LOIS_BACKEND_URL}/accounts/check_if_user_is_knight_or_above`)
        .then(res => {
            resolve(res)
        })
        .catch((err) => {
            resolve(false);
        })
    })
}




export {
    create_account,
    create_accounts,
    get_accounts,
    has_account,
    delete_account,
    save_logo,
    check_user_status_on_landing,
    isUserKnightOrAbove,
};
