import { structure_section_elements } from "./SectionElements";
import { fetchFacebookKpis, fetchInstagramKpis, fetchMetaPaidKpis, fetchOverviewKpis, fetchMyBusinesses, getMyOrganicPages, fetchMetaPaidDemographics, fetchGraphicalMetaKpis, fetchGraphicalFacebookKpis, fetchGraphicalInstagramKpis, fetchMetaPaidKpisByCampaign, fetchMetaPaidDemographicsByCampaign, fetchGraphicalMetaKpisByCampaign, fetchMetaPaidKpisCompinedCampaigns, fetchMetaPaidDemographicsCompinedCampaigns, fetchMetaPaidGraphicalCompinedCampaigns } from "../../../services/api/MetaApi";
import { get_accessed_preset_structures } from "../../../services/api/PresetStructures";
import { get_accessed_preset_styles } from "../../../services/api/PresetStyles";
import { get_accessed_templates } from "../../../services/api/Templates";
import { update_overview_block_values } from "./UpdateBlocks";
import { getReport } from "../../../services/api/Reports";
import idTypeHelper from '../data/id-type-helper.json';
import apiErrors, { get_error_translation } from '../data/api-errors';
import { deepClone } from "../../../../../helpers/DeepCloneObject";
import { getUserMedia } from "../../../../../services/api/Media";

import {getCustomerResourceNames, getAdAccountKpisStatic, getAdAccountKpisDemographics, getAdAccountKpisGraphical} from "../../../../google/services/api/GoogleApi"

/* 
    This file contain functions that are called in useEffect hooks.  
    If a function is called at a useEffect hook but is used in other parts of the application as well, it may not be located in this file.    
*/



/*
    The first function that is about to run. Called in the first load of the page.
    The same function is called at both scenarios, creating a report or editing an old report.
    Presets/templates are fetched from backend's local database as well as user's facebook businesses and organic pages from the meta api.
    We assign this data to the creator's properties.
*/
export const initalize_page = (creator, setCreator, userId,  accessedAccounts, reportId, reportStyle, setReportStyle, businessCursor, setBusinessCursor, pagesCursor, setPagesCursor) => {
    const metaAccount = accessedAccounts?.meta;
    const metaUserId = metaAccount?.userId;
    const metaAccessToken = metaAccount?.accessToken;

    const googleAccount = accessedAccounts?.google;
    const googleAccessToken = googleAccount?.accessToken;
    const preSelectedTemplate = creator?.preSelectedTemplate;
    let defaultPresetStructure = {};
    let requests = [];
    if(businessCursor === 'initial' && pagesCursor === 'initial') {
        requests = [
            get_accessed_preset_styles(),
            get_accessed_preset_structures(),
            get_accessed_templates(),
            getUserMedia(userId)

        ];
        if ( metaAccount.accessToken) {
            requests.push(
                fetchMyBusinesses(metaUserId, metaAccessToken, businessCursor),
                getMyOrganicPages(metaAccessToken, pagesCursor)
            )
        }
        else {
            requests.push(new Promise((resolve, reject) => resolve(false)));
            requests.push(new Promise((resolve, reject) => resolve(false)));
        }

        if ( googleAccount.accessToken) {
            requests.push(
                getCustomerResourceNames(googleAccessToken, googleAccount.userId)
            )
        }
        else {
            requests.push(new Promise((resolve, reject) => resolve(false)));
        }

        if ( reportId ) {
            requests.push(getReport(reportId))
        }

        Promise.all(requests)
    .then(([presetStyleResponse, presetStructureResponse, templateResponse, mediaResponse, businessesResponse, pagesResponse, googleResponse, getReportResponse]) => {
        
        // const fetchBusinessesError = apiErrors(businessesResponse, creator.t)
        // const fetchOrganicPagesError = apiErrors(pagesResponse, creator.t)

        const prebuiltPresetStyles = presetStyleResponse?.data?.data?.preBuiltPresetStyles;
        const accessedPresetStyles = presetStyleResponse?.data?.data?.accessedPresetStyles;
        const prebuiltPresetStructures = presetStructureResponse?.data?.data?.preBuiltPresetStructures;
        const accessedPresetStructures = presetStructureResponse?.data?.data?.accessedPresetStructures;
        const preBuiltTemplates = templateResponse?.data?.data?.preBuiltTemplates;
        const accessedTemplates = templateResponse?.data?.data?.accessedTemplates;
        setBusinessCursor(businessesResponse?.data?.data?.businesses?.cursor)
        const businesses = businessesResponse?.data?.data?.businesses?.businesses; 
        const facebookPages = pagesResponse?.data?.data?.facebookPages; 
        const instagramPages = pagesResponse?.data?.data?.instagramPages; 
        setPagesCursor(pagesResponse?.data?.data?.cursor);

        const medias = mediaResponse?.data?.data?.medias;
        
        const googleManagerAccounts = (googleResponse?.data?.data?.managerAccounts || []);
        const accessibleGoogleAdAccounts = (googleResponse?.data?.data?.accessibleAdAccounts || []);
        const report = getReportResponse?.data?.data?.report;
        if ( report && report?.template?.presetStyleId) {
            setReportStyle(report?.template?.presetStyleId);
        }
        else {
            setReportStyle(prebuiltPresetStyles[0]);
            defaultPresetStructure = prebuiltPresetStructures[0];
        }
        if(!reportStyle) {
            setReportStyle(prebuiltPresetStyles[0]);
            console.log(reportStyle);
        }
        // if(prebuiltPresetStyles && accessedPresetStyles && prebuiltPresetStructures && accessedPresetStructures && preBuiltTemplates && accessedTemplates && report) {
        //     setCreator((prev) => ({
        //         loaders: {...prev.loaders, initialize: false},
        //     }))
        // }


        console.log(businessCursor);

        const prevBusinesses = creator.metaHelper.businessAccounts;
        const newBusinesses = [...prevBusinesses, ...(businesses || [])];


        const prevFbPages = creator?.metaHelper?.pages?.facebookPages;
        const newFbPages = [...prevFbPages, ...(facebookPages || [])];

        const prevInstaPages = creator?.metaHelper?.pages?.instagramPages;
        const newInstaPages = [...prevInstaPages, ...(instagramPages || [])];

        const templates = [...preBuiltTemplates, ...accessedTemplates];

        const currTemplate = report ? report.template : creator.currentTemplate;
        
        setCreator((prev) => ({
            ...prev, 
            fetchedPresets: {
                ...prev.fetchedPresets, 
                preBuilt: {...prev?.fetchedPresets?.preBuilt, styles: prebuiltPresetStyles, structures: prebuiltPresetStructures, templates: preBuiltTemplates},
                accessed: {...prev?.fetchedPresets?.accessed, styles: accessedPresetStyles, structures: accessedPresetStructures, templates: accessedTemplates}
            }, 
            metaHelper: {
                ...prev.metaHelper, 
                businessAccounts: newBusinesses,
                pages: {
                    ...prev.metaHelper?.pages,
                    facebookPages : newFbPages,
                    instagramPages: newInstaPages,
                }
            },
            loaders: {...prev.loaders, initialize: false},
            report: report ? report : prev.report,
            presetStructure: report ? report?.template?.presetStructureId : defaultPresetStructure,
            keepReportValues: report ? true : false,
            // errors: {
            //     ...prev.errors,
            //     fetchBusinesses: {
            //         ...prev.errors?.fetchBusinesses, 
            //         error: fetchBusinessesError ? fetchBusinessesError : false
            //     },
            //     fetchOrganicPages: {
            //         ...prev.errors?.fetchOrganicPages,
            //         error: fetchOrganicPagesError ? fetchOrganicPagesError : false,
            //     }
            // },
            restructureSectionElements: pagesCursor==="initial" && businessCursor==="initial" ? true : false,

            applyPreSelectedTemplate: preSelectedTemplate && !report ? true : prev.applyPreSelectedTemplate,
            currentTemplate: currTemplate,
            availableMedia: medias,
            googleHelper: {
                ...prev.googleHelper,
                managerAccounts: googleManagerAccounts,
                accessibleAdAccounts: accessibleGoogleAdAccounts,
            }
        }))
    })
    }
    else {
        if(businessCursor) {
            requests.push(fetchMyBusinesses(metaUserId, metaAccessToken, businessCursor));
        }
        else {
            requests.push(new Promise((resolve, reject) => resolve(false)));
        }
        if(pagesCursor) {
            requests.push(getMyOrganicPages(metaAccessToken, pagesCursor));
        }
        else {
            requests.push(new Promise((resolve, reject) => resolve(false)));
        }

        Promise.all(requests)
        .then(([businessesResponse, pagesResponse]) => {

            const prevBusinesses = creator.metaHelper.businessAccounts;
            let newBusinesses;
            const prevFbPages = creator?.metaHelper?.pages?.facebookPages;
            const prevInstaPages = creator?.metaHelper?.pages?.instagramPages;
            let newFbPages;
            let newInstaPages;


            if(businessesResponse !== false) {
                const businesses = businessesResponse?.data?.data?.businesses?.businesses; 
                newBusinesses = [...prevBusinesses, ...(businesses || [])];
            }
            else {
                newBusinesses = prevBusinesses;
            }

            if(pagesResponse !== false) {
                const fbPages = pagesResponse?.data?.data?.facebookPages; 
                const instaPages = pagesResponse?.data?.data?.instagramPages;
                newFbPages = [...prevFbPages, ...(fbPages || [])];
                newInstaPages = [...prevInstaPages, ...(instaPages || [])];
            }
            else {
                newFbPages = prevFbPages;
                newInstaPages = prevInstaPages
            }

            setCreator((prev) => ({
                ...prev, 
                metaHelper: {
                    ...prev.metaHelper, 
                    businessAccounts: newBusinesses,
                    pages: {
                        ...prev.metaHelper.pages,
                        facebookPages: newFbPages,
                        instagramPages: newInstaPages,
                    }
                },
                restructureSectionElements: !pagesResponse?.data?.data?.cursor ? true : false,
            }))
            
            setBusinessCursor(businessesResponse?.data?.data?.businesses?.cursor || false);
            setPagesCursor(pagesResponse?.data?.data?.cursor || false);

        })

    }
}


/*
    This function is triggered whan we modify the dates.
    It modified the kpi property of all creator.sectionElements objects assigning an empty array to them.
    This will trigger the sectionElements useEffect and cause "handle_section_element_change" to run.
*/
export const update_dates_and_section_elements = (creator, setCreator) => {

    if ( 
        creator.report.dateFrom !== creator.datesHelper?.fetchedForDates?.dateFrom ||
        creator.report.dateTo !== creator.datesHelper?.fetchedForDates?.dateTo 
    ) {
        // if(creator?.sectionElements) {
            var updatedSectionElements = deepClone(creator?.sectionElements)?.map((se) => {
                return {...se, kpis: []};
            }) 
        
            setCreator((prev) => ({
                ...prev, 
                sectionElements: updatedSectionElements,
                datesHelper: {
                    ...prev.datesHelper, 
                    fetchedForDates: {
                        ...prev.datesHelper.fetchedForDates,
                        dateFrom: creator.report.dateFrom,
                        dateTo: creator.report.dateTo,
                    }
                },
            }))
        // }
    }
}


/*
    This function is directly assign to creator.report.section the creator.presetStructure sections.
    It also pass the whole presetStructure to the datafilledStructure witch is used as a helper.
*/
export const trigger_structure_init_change_handler = async (creator, setCreator) => {
    if ( 
        creator.presetStructure?._id &&
        creator.presetStructure._id !== creator.presetStructureHelper?.selectedPresetStructureId &&
        !creator.presetStructureHelper?.isInitialized
    ) {
        
        setCreator((prev) => ({
            ...prev,
            report: {...prev.report, sections: creator.presetStructure.sections},
            presetStructureHelper: {
                ...prev.presetStructureHelper, 
                datafilledStructure: creator.presetStructure,
            },
            metaHelper: {
                ...prev.metaHelper,
                selectedBusiness: null,
                selectedAdAccount: null,
                selectedCampaigns: []
            }
        }))

    }
}


/*
    This function is responsible to construct the sectionElement objects with data provided from the presetStructure
*/
export const trigger_structure_change_check_handler = async (creator, setCreator, accessedAccounts) => {
    if ( 
        creator.presetStructure?._id &&
        (creator.presetStructure?._id !== creator.presetStructureHelper?.selectedPresetStructureId || creator.presetStructureHelper?.isModified)
    ) {
        const triggerSource = "preset";

        const {
            updatedSectionElements, 
            updatedMetaHelper,
        } = await structure_section_elements(creator, triggerSource, accessedAccounts);

        let sectionsToKeep;

        if(creator.initialFlowHelper.isCompleted === true) {
            sectionsToKeep = (creator?.presetStructureHelper?.datafilledStructure?.sections)?.filter((s) => 
            s.sectionTitle !== 'Ad Account' // case that the section left empty
            && s.sectionTitle !== 'Google Ad Account' // case that the section left empty
            && s.sectionTitle !== 'Instagram page' && s.sectionTitle !== 'Facebook page'
            && s.sectionTitle !== 'Instagram Page' && s.sectionTitle !== 'Facebook Page');
        }
        else {
            sectionsToKeep = creator?.presetStructureHelper?.datafilledStructure?.sections;
        }

        setCreator((prev) => ({
            ...prev,
            presetStructureHelper: {...prev.presetStructureHelper, selectedPresetStructureId: creator.presetStructure._id},
            sectionElements: updatedSectionElements,
            report: {...prev.report, sections: sectionsToKeep},
            metaHelper: updatedMetaHelper,
        }))
    }
}


/*
    This function is responsible to construct the sectionElement objects with data provided from the report
*/
export const trigger_restructure_section_elements_handler = async (creator, setCreator, accessedAccounts) => {
    if (creator.restructureSectionElements) {
        const triggerSource = "report";
        const {updatedSectionElements}= await structure_section_elements(creator, triggerSource, accessedAccounts);
        console.log();
        setCreator((prev) => ({
            ...prev,
            sectionElements: updatedSectionElements,
            presetStructureHelper: {
                ...prev.presetStructureHelper, 
                isModified: true,
            },
            restructureSectionElements: false,
        }))
    }
}


/*
    This function is responsible to assign kpi values to every element of every row of every section in the report.
    In order to do so, for each element we find the matching one in the sectionElements.
    We use the block values from sectionElements to put/update report's block values.
*/

export const assign_properties_to_report_blocks = (creator, setCreator) => {
    
    const sectionElements = deepClone(creator?.sectionElements);
    const reportSections = deepClone(creator?.report?.sections);

    const acceptableSectinoElementRowTypes = ['static', 'demographics', 'graphical'];
    //  removed overview section because every time the date is changed the overview section was set to zero.
    const matchingSectionDataTypePerSectionType = {
        paid: "adAccount",
        facebook: "facebookPage",
        instagram: "instagramPage",
        googlePaid: "googleAdAccount"
    }
    /* Apply modification to the report section in order to be assigned with values from the fetched kpis (from sectionElements) */
    reportSections?.forEach((section) => {
        const sectionRows = section?.rows;
    
        if (sectionRows?.length > 0 && section.sectionData && section.type !== 'overview') {
            sectionRows.forEach((row) => {

                if (row.elements?.length > 0 && acceptableSectinoElementRowTypes.includes(row.type)) {

                    const matchingSectionElement = sectionElements?.find((obj) =>
                        obj.type === section.type &&
                        obj.id === section.sectionData.find((obj) => obj.type === matchingSectionDataTypePerSectionType[section.type]).id &&
                        obj.row === row.type
                    );
                   
                    const matchingSectionElementKpis = matchingSectionElement?.kpis;

                    row.elements = row.elements.map((element) => {
                        let matchingElement;
                        if(row.type === 'demographics') {
                            const matching = matchingSectionElementKpis?.find((obj) => obj.type === element.type);
                            const kpis = matching?.kpis
                            let target;
                            for (const key in kpis) {
                              // Check if the displayedName of the current property is 'Reach'
                              if (kpis[key].originalName === element.originalName) {
                                // If found, assign the values to reachValues
                                target = kpis[key].values;
                                break; // Stop iterating since we found the desired property
                              }
                            }
                            matchingElement = {
                                type: element.type,
                                values: target
                            };

                            if(element?.values !== matchingElement?.values) {

                                return {
                                    ...element,
                                    values:matchingElement ? matchingElement?.values : element?.values,
                                    displayedValue: matchingElement ? matchingElement?.displayedValue : element?.displayedValue,
                                    displayedName: section.type === "overview" && matchingElement?.displayedName 
                                        ? matchingElement?.displayedName 
                                        : element?.displayedName,
                                    comparedValue: matchingElement ? matchingElement?.comparedValue : element?.comparedValue,
                                    displayedCompareValue: matchingElement ? matchingElement?.displayedCompareValue : element?.displayedCompareValue,
                                };
                            }
                            else {
                                return {
                                    ...element,
                                }
                            }
                        }
                        else if(row.type === "graphical") {
                            const matchingElement = matchingSectionElementKpis?.find((obj) => obj.originalName === element.originalName);

                            if(element?.values !== matchingElement?.values) {
                                return {
                                    ...element,
                                    values: matchingElement?.values ? matchingElement?.values : element?.values,
                                    
                                }
                            }
                            else {
                                return {
                                    ...element,
                                }
                            }
                        }
                        else {
                            matchingElement = matchingSectionElementKpis?.find((obj) => obj.originalName === element.originalName);

                            if(element?.value !== matchingElement?.value) {
                            
                                return {
                                    ...element,
                                    value: matchingElement ? matchingElement?.value : element?.value, // Use the matchingElement value if found, otherwise use the original value
                                    displayedValue: matchingElement ? matchingElement?.displayedValue : element?.displayedValue,
                                    displayedName: section.type === "overview" && matchingElement?.displayedName 
                                        ? matchingElement?.displayedName 
                                        : element?.displayedName,
                                    comparedValue: matchingElement ? matchingElement?.comparedValue : element?.comparedValue,
                                    displayedCompareValue: row.isCompared ? (matchingElement ? matchingElement.displayedCompareValue : element?.displayedCompareValue) : "",
                                    previousValue: matchingElement ? matchingElement?.previousValue : element?.previousValue,
                                };
                            }
                            else {
                                return {
                                    ...element,
                                    displayedCompareValue: row.isCompared ? element?.displayedCompareValue : "",
                                }
                            }
                        }
                    });
                }
            });
        }
    });

    // now update seperatly the overview section
    let overviewSection = sectionElements.find((section) => section.type === 'overview');

    // filter returns a list but we only have ONE overview section thats why we use index 0
    var sumOfAdsCost = 0;

    overviewSection?.kpis.forEach((kpi) => {
        if(kpi?.originalName === 'ads_cost') {
            // go and find for all meta ad accounts the SPEND KPI, add them and assign this value to ads_cost of overview
            // always filter them and by row because when you add demographic there will be a conflict
            let adAccountSectionsMeta = sectionElements?.filter((section) => section?.type === 'paid' && section?.row === 'static');
            let adAccountSectionsGoogle = sectionElements?.filter((section) => section?.type === 'googlePaid' && section?.row === 'static');

            adAccountSectionsMeta?.forEach((section) => {
                const spend = section?.kpis.find((kpi) =>  kpi?.originalName === 'spend')?.displayedValue
                sumOfAdsCost = sumOfAdsCost + spend;
            })


            adAccountSectionsGoogle?.forEach((section) => {
                const cost = section?.kpis.find((kpi) =>  kpi?.originalName === 'costMicros')?.displayedValue
                sumOfAdsCost = sumOfAdsCost + cost;
            })
            if(sumOfAdsCost != 0)
                kpi.displayedValue = sumOfAdsCost;
        }    
        if(kpi?.originalName === 'total_spend') {
            // find agency  cost and ads cost
            const agencyCost = reportSections?.find((section) => section.type === "overview")?.rows?.find((row) => row.type === 'static')?.elements?.find((element) => element.originalName === 'agency_cost')?.displayedValue;
            if(agencyCost)
                kpi.displayedValue = sumOfAdsCost + agencyCost;
            else
                kpi.displayedValue = sumOfAdsCost; 

        }
        
        //logic for all kpis of overview       
    })
  

    reportSections?.forEach((section) => {
        if(section.type === 'overview') {
            const sectionRows = section?.rows;
            if (sectionRows?.length > 0 && section.sectionData ) {
                sectionRows.forEach((row) => {
                    if (row.elements?.length > 0 && acceptableSectinoElementRowTypes.includes(row.type)) {
                        const matchingSectionElementKpis = overviewSection?.kpis;
                        row.elements = row.elements.map((element) => {
                            if(element?.originalName ==='ads_cost' || element?.originalName === 'total_spend') {
                                const matchingElement = matchingSectionElementKpis?.find((obj) => obj.originalName === element?.originalName);
                                return {
                                    ...element,
                                    value: matchingElement ? matchingElement?.value : element?.value, // Use the matchingElement value if found, otherwise use the original value
                                    displayedValue: matchingElement ? matchingElement?.displayedValue : element?.displayedValue,
                                    displayedName: section.type === "overview" && matchingElement?.displayedName 
                                        ? matchingElement?.displayedName 
                                        : element?.displayedName,
                                    comparedValue: matchingElement ? matchingElement?.comparedValue : element?.comparedValue,
                                    displayedCompareValue: matchingElement ? matchingElement?.displayedCompareValue : element?.displayedCompareValue,
                                };
                            }
                            else {
                                return {
                                    ...element
                                }
                            }
                        });
                    }
                })
            }
        }
    })  

    // Update the state with the modified reportSections
    setCreator((prev) => ({
        ...prev,
        report: {
            ...prev.report,
            sections: reportSections,
        },
        shouldAssign: false,
    }));
};


/* 
    This function is going to fetch and assign kpis to all sectionElements with an empty kpi array.
    shouldFetch is switched between true/false in order to avoid re-renders causing errors 
    shouldAssign is set to true to trigger a useEffect in CREATOR.js 
*/
export const handle_section_element_change = (creator, setCreator, accessedAccounts) => {

    let sectionElements = deepClone(creator.sectionElements);
    const reportSections = deepClone(creator?.report?.sections);
    const keepReportValues = deepClone(creator.keepReportValues);
    const dateFrom = creator.report.dateFrom;
    const dateTo = creator.report.dateTo;
    if ( sectionElements.length === 0 ) return;
    if ( !creator.shouldFetch ) {
        setCreator((prev) => ({
            ...prev, 
            shouldFetch: true,
        }))
        return;
    }

    let requests = [];
    let indexesToUpdate = [];
    var request;
    for (const [index, section] of sectionElements.entries()) {
        if ( sectionElements[index]?.kpis?.length > 0 ) continue;
        // Add the correct request per sectionElement based on the section type and row
        switch ( section.source) {
            case("google"): {
                switch ( section.type ) {
                    case ("googlePaid"): {

                        switch ( section.row ) {
                            case ("static"): {

                                let campaignsIds;
                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                request = getAdAccountKpisStatic(section?.id, section?.parentManager?.id, campaignsIds, section.accessToken, dateFrom, dateTo, accessedAccounts.google.userId);
                                break;
                            }
                            case ("demographics"): {
                                let campaignsIds;

                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                request = getAdAccountKpisDemographics(section?.id, section?.parentManager?.id, campaignsIds, section.accessToken, dateFrom, dateTo, accessedAccounts.google.userId);
                                break;
                            }
                            case ("graphical"): {
                                let campaignsIds;

                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                    request = getAdAccountKpisGraphical(section?.id, section?.parentManager?.id, campaignsIds, section.accessToken, dateFrom, dateTo, accessedAccounts.google.userId);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case("meta"): {
                switch ( section.type ) {
                    case ("paid"): {
                        switch ( section.row ) {
                            case ("static"): {
        
                                let campaignsIds;
                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                request = fetchMetaPaidKpis(section?.id, campaignsIds, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                            case ("demographics"): {
                                let campaignsIds;
        
                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                request = fetchMetaPaidDemographics(section?.id, campaignsIds, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                            case ("graphical"): {
                                let campaignsIds;
        
                                if(section.campaigns) {
                                    campaignsIds = section.campaigns.map(campaign => campaign.id);
                                }
                                    request = fetchGraphicalMetaKpis(section?.id, campaignsIds, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                        }
                        break;
                    }
                    case ("facebook"): {
                        switch ( section.row ) {
                            case ("static"): {
                                request = fetchFacebookKpis(section?.id, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                            case ("graphical"): {
                                request = fetchGraphicalFacebookKpis(section?.id, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                        }
                        break;
                    }
                    case ("instagram"): {
                        switch ( section.row ) {
                            case ("static"): {
                                request = fetchInstagramKpis(section?.id, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                            case ("graphical"): {
                                request = fetchGraphicalInstagramKpis(section?.id, section.accessToken, dateFrom, dateTo);
                                break;
                            }
                        }
                        break;
                    }
                }
            }
            default: {
                if(section.type === "overview" && section.row === "static") {
                    request = fetchOverviewKpis(dateFrom, dateTo)
                    break;
                }
            }
        }
        
        requests.push(request);
        indexesToUpdate.push({numOfReq: requests?.length - 1, sectionPosition: index});
    }

    if ( requests.length === 0 ) {
        setCreator((prev) => ({
            ...prev, 
            shouldAssign: true,
        }))
        return;
    }
    
    if(requests.length > 0) {
        setCreator((prev) => ({
            ...prev,
            loaders: {
                ...prev.loaders,
                sections: true,
            }
        }))
    }
    // Performe all requests in parallel and match the response to a specific sectionElement using the indexToUpdate helper
    Promise.all(requests)
        .then(async(responses) => {

            let fetchOverviewError = false;
            let fetchMetaPaidKpisError = false; 
            let fetchFacebookKpisError = false;
            let fetchInstagramKpisError = false; 
            let paidSectionInfo = false;
            let facebookSectionInfo = false;
            let instagramSectionInfo = false;

            for (const pair of indexesToUpdate) {
                const reqIndex = pair.numOfReq;
                const sectionIndex = pair.sectionPosition;
                const sectionSource = sectionElements[sectionIndex]?.source
                const sectionType = sectionElements[sectionIndex]?.type
                const sectionRow = sectionElements[sectionIndex]?.row

                let response = responses[reqIndex];
                console.log('response',response);
        
            
                /* For errors */
                if (sectionType === "overview") fetchOverviewError = apiErrors(response, creator.t)
                if (sectionSource === "meta" && sectionType === "paid") {
                    paidSectionInfo = sectionElements[sectionIndex];
                    fetchMetaPaidKpisError = apiErrors(response, creator.t)
                }
                if (sectionType === "facebook") {
                    facebookSectionInfo = sectionElements[sectionIndex];
                    fetchFacebookKpisError = apiErrors(response, creator.t)
                }
                if (sectionType === "instagram"){
                    instagramSectionInfo = sectionElements[sectionIndex];
                    fetchInstagramKpisError = apiErrors(response, creator.t) 
                }


                /* error handling end */

                const kpis = response?.data?.data?.kpis;
                let sectionElement = sectionElements[sectionIndex];
                sectionElement.kpis = kpis ? kpis : [];
                
            }
            const hasError = ( fetchOverviewError || fetchMetaPaidKpisError || fetchFacebookKpisError || fetchInstagramKpisError ) ? true : false;

            setCreator((prev) => ({
                ...prev, 
                sectionElements,
                shouldAssign: true,
                shouldFetch: false,
                keepReportValues: false,
                errors: !hasError ? // if no errors we must remove old errors for fetching kpis
                {
                    fetchOverview: { extraInfo: false, error: false },
                    fetchMetaPaidKpis: [
                        /*{ extraInfo: false, error: false }*/
                    ],
                    fetchFacebookKpis: [
                        /*{ extraInfo: false, error: false }*/
                    ],
                    fetchInstagramKpis: [
                        /*{ extraInfo: false, error: false }*/
                    ],
                }
                : {
                    ...prev.errors,
                    fetchOverview: {
                        ...prev.errors.fetchOverview, 
                        error: fetchOverviewError ? fetchOverviewError : false
                    },
                    fetchMetaPaidKpis: !fetchMetaPaidKpisError ? prev.errors.fetchMetaPaidKpis : 
                    [...prev.errors.fetchMetaPaidKpis ,{
                        ...prev.errors.fetchMetaPaidKpis, 
                        extraInfo: {
                            ...prev.errors.fetchMetaPaidKpis.extraInfo,
                            sectionInfo: fetchMetaPaidKpisError ? paidSectionInfo : false,
                        },
                        error: fetchMetaPaidKpisError ? fetchMetaPaidKpisError : false
                    }],
                    fetchFacebookKpis: !fetchFacebookKpisError ? prev.errors.fetchFacebookKpis : 
                    [...prev.errors.fetchFacebookKpis ,{
                        ...prev.errors.fetchFacebookKpis, 
                        extraInfo: {
                            ...prev.errors.fetchFacebookKpis.extraInfo,
                            sectionInfo: fetchFacebookKpisError ? facebookSectionInfo : false,
                        },
                        error: fetchFacebookKpisError ? fetchFacebookKpisError : false
                    }],
                    fetchInstagramKpis: !fetchInstagramKpisError ? prev.errors.fetchInstagramKpis : 
                    [...prev.errors.fetchInstagramKpis ,{
                        ...prev.errors.fetchInstagramKpis, 
                        extraInfo: {
                            ...prev.errors.fetchInstagramKpis.extraInfo,
                            sectionInfo: fetchInstagramKpisError ? instagramSectionInfo : false,
                        },
                        error: fetchInstagramKpisError ? fetchInstagramKpisError : false
                    }],
                },
                loaders: {
                    ...prev.loaders,
                    sections: false,
                }
            }))
        })
}


/* 
    This function is responsible to assign to sectionElements[all].kpi[all] values directly from the matching report block value.
    It applys only to kpis that exists in the report. The rest kpis keep their values.
*/
const assign_report_kpi_value_to_section_elements = (reportSections, sectionElement) => {

    let updatedSectionElementKpis = deepClone(sectionElement.kpis);

    const matchingReportSectionElements = reportSections
        .find((section) => 
            section.type === sectionElement.type &&
            section.source === sectionElement.source &&
            section.sectionData?.find((sd) => sd.type === idTypeHelper[sectionElement.type])?.id === sectionElement?.id
        )
        ?.rows
        ?.find((row) => row.type === sectionElement.row )?.elements;

    updatedSectionElementKpis = updatedSectionElementKpis?.map((element) => {
        const matchingKpi = matchingReportSectionElements?.find((kpi) => kpi.originalName === element.originalName);
        if ( matchingKpi ) {
            return {
                ...element,
                value: matchingKpi.value,
                displayedValue: matchingKpi.displayedValue,
                comparedValue: matchingKpi.comparedValue,
                displayedCompareValue: matchingKpi.displayedCompareValue,
                givenName: matchingKpi.givenName,
                displayedName: matchingKpi.displayedName,
            }
        } else return element
    })
    return updatedSectionElementKpis
}


/* 
    Initiliaze the overview values like spend, total spend and days reporting 
*/
export const initialize_overview_automatic_values = (creator, setCreator) => {

    const automaticFilledBlocks = ["total_spend"];
    const overviewBlocks = creator?.sectionElements
        ?.find((se) => se.type === "overview" && se.row === "static")?.kpis
    let shouldAssign = false;

    if ( !overviewBlocks || overviewBlocks?.length === 0 ) return;

    for ( const block of overviewBlocks ) {
        if ( automaticFilledBlocks.includes(block.originalName) && block.value === 0 ) {
            shouldAssign = true;
        }
    }
    if ( shouldAssign ) {
        const dates = { dateFrom: creator?.report?.dateFrom, dateTo: creator?.report?.dateTo };
        const [newReportSection, newSectionElements] = update_overview_block_values(creator.report.sections, creator.sectionElements, dates);
        setCreator((prev) => ({...prev, sectionElements: newSectionElements, report: {...prev.report, sections: newReportSection}}))
    }
}


/* 
    Update the error translations in order to dynamically change on lang change even
*/
export const update_error_translations = (creator, setCreator) => {
    let newErrors = deepClone(creator.errors);
    Object.keys(newErrors).forEach((key) => {
        if (newErrors[key] && newErrors[key].error) {
            newErrors[key].error = get_error_translation(newErrors[key].error, creator.t);
        }
    });
    setCreator((prev) => ({...prev, errors: newErrors}))
}


/* 
    This function is responsible apply the pre-selected template (preset style and structure) to the report.
*/
export const apply_pre_selected_template = (creator, setCreator, setReportStyle) => {

    const template = deepClone(creator.preSelectedTemplate);
    let flow_list = creator.currentTemplate?.presetStructureId.sections.map(item => item.type);
    flow_list = flow_list.filter((sections) => sections !== "overview")

    // if(!template?.presetStyleId)
    //     setReportStyle(creator.fetchedPresets?.preBuilt?.styles[0])
    setCreator((prev) => ({
        ...prev, 
        presetStructure: template?.presetStructureId,
        report: {
            ...prev.report,
            currency: template?.presetStructureId?.currency,
            // title: template?.presetStructureId?.title,
            templateId: template?._id,
            template: template,
        },
        presetStructureHelper: {
            ...prev.presetStructureHelper, 
            triggerPresetStructureInitChange: !prev.presetStructureHelper.triggerPresetStructureInitChange
        },
        initialFlowHelper: {
            ...prev.initialFlowHelper,
            flow: flow_list,
        },
    }))
}