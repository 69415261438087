import { Builder_, Google_Search, Slider_ , Partial_Loader} from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Creator_Error_Message from '../creator/Creator_Error_Message';
import Add_Row_Demographic_Blocks from './Add_Row_Demographic_Blocks';

const DemographicKpiSelection = styled.div``;

export default function Add_Meta_Row_Demographics(props) {
    const { t } = useTranslation();

    const [displayedKpis, setDisplayedKPIs] = useState(props.allDemographicAvailable);
    const [searchResults, setSearchResults] = useState();

    let matchingError = [];
    switch (props.sectionType) {
        case 'paid': {
            matchingError = 'fetchMetaPaidKpis';
            break;
        }
        case 'facebook': {
            matchingError = 'fetchFacebookKpis';
            break;
        }
        case 'instagram': {
            matchingError = 'fetchInstagramKpis';
            break;
        }
    }
    const error = props.errors[matchingError]?.find((section) => section?.extraInfo?.sectionInfo?.id === props.sectionId)?.error;

    useEffect(() => {
        const applySearchResults = () => {
            if (searchResults?.input) setDisplayedKPIs(searchResults?.data);
            else setDisplayedKPIs(props.allDemographicAvailable);
        };

        applySearchResults();
    }, [searchResults]);

    useEffect(() => {
        if(props.allDemographicAvailable) {
            setDisplayedKPIs(props.allDemographicAvailable)
        }
    }, [props.allDemographicAvailable])

    if (error) {
        return <Creator_Error_Message error={error} errorType={matchingError} onClick={props.onClick} sliderTitle={t('Demographic KPIs Elements')} />;
    }

    return (
        <Slider_ onClick={props.onClick} text={t('Done')} title={t('Demographic indicators')} >
            {props.loader ? 
            <Partial_Loader/> 
            :
            <>
                { props.allDemographicAvailable?.length > 15 ?
                 <Builder_ title={t('Search for a key performance indicator')}>
                    <Google_Search file={props.allDemographicAvailable} fileKeys={['displayedName', 'name']} defaultValue={''} sanitize={true}      placeholder={t('Search KPIs')} setResults={(data) => setSearchResults(data)} debounceTime={750} minMatchCharacters={1} maxMatchCharacters={50} disabled={false} isCaseSensitive={false} maxAcceptedScore={0.6} />
                </Builder_> : null }
                {displayedKpis?.length > 0 ? (
                    <DemographicKpiSelection>
                        {displayedKpis?.filter((obj) => obj.category === 'General')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'General')}
                                reportElements={props.reportElements}
                                categoryName={t("General")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}

                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Engagement')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Engagement')}
                                reportElements={props.reportElements}
                                categoryName={t("Engagement")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Video')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Video')}
                                reportElements={props.reportElements}
                                categoryName={t("Video")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Ecommerce')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Ecommerce')}
                                reportElements={props.reportElements}
                                categoryName={t("Ecommerce")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Lead Metrics')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Lead Metrics')}
                                reportElements={props.reportElements}
                                categoryName={t("Lead Metrics")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {/* google demographics categories */}
                        {displayedKpis?.filter((obj) => obj.category === 'Performance')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Performance')}
                                reportElements={props.reportElements}
                                categoryName={t("Performance Metrics")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Conversions')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Conversions')}
                                reportElements={props.reportElements}
                                categoryName={t("Conversions Metrics")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Competitive')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Competitive')}
                                reportElements={props.reportElements}
                                categoryName={t("Competitive Metrics")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                        {displayedKpis?.filter((obj) => obj.category === 'Other')?.length > 0 ? 
                            <Add_Row_Demographic_Blocks
                                displayedKpis={displayedKpis?.filter((obj) => obj.category === 'Other')}
                                reportElements={props.reportElements}
                                categoryName={t("Other Metrics")}
                                onAddDemographic={(item) => props.onAddDemographic(item)}
                            />    
                        : null}
                    </DemographicKpiSelection>
                ) : null}
            </>
        }
        </Slider_>
    );                              
}