import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import { Grey_Link, Main_Plain, Red_ } from 'monica-alexandria';
import { Snippet_Horizontal_Actions } from '../snippets/Snippet_Horizontal_Actions';

const SplitterSectionText = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`;

const SplitterSectionTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 4rem 2rem;
    width: 100%;

    h4 {
        white-space: nowrap;
        color: ${(p) => p.presetStyle.color.main};
        font-family: ${(p) => p.presetStyle.font.family};
    }
`;

const SplitterSectionTextLines = styled.div`
    height: 2px;
    width: 100%;
    background: ${(p) => p.presetStyle.color.main};
`;

export default function Splitter_Section_Text(props) {
    const { t } = useTranslation();
    const { reportStyle } = useContext(ReportStyleContext);
    return (
        <SplitterSectionText>
            <Snippet_Horizontal_Actions />
            <SplitterSectionTextWrapper presetStyle={reportStyle}>
                <SplitterSectionTextLines presetStyle={reportStyle}></SplitterSectionTextLines>
                <h4>{props.text}</h4>
                <SplitterSectionTextLines presetStyle={reportStyle}></SplitterSectionTextLines>
            </SplitterSectionTextWrapper>
        </SplitterSectionText>
    );
}
