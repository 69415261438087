import { Slider_ } from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Structure_Preset_Prebuilt from '../structure/Structure_Preset_Prebuilt';
import Structure_Preset_Saved from '../structure/Structure_Preset_Saved';

export default function Edit_Structure_Preset(props) {
    const { t } = useTranslation();

    return (
        <Slider_ onClick={props.onClick} title={t('Apply KPI Presets')} text="Done">
            <Structure_Preset_Prebuilt preBuilt={props.preBuilt} onApply={props.onApply} />
            <Structure_Preset_Saved accessed={props.accessed} onApply={props.onApply} onDelete={(structure) => props.onDelete(structure)} />
        </Slider_>
    );
}
