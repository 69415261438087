import { Builder_, CardsSixty, Google_Search, On_Click_Card, Section_Title } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportStyleContext } from '../../../../context/ReportStyleContext';
import store from '../../../../../../redux/store';
const StylePresetSaved = styled.div``;

export default function Style_Preset_Saved(props) {
    const { t } = useTranslation();
    const userId = store.getState()?.user?.user.sub;
    const doitformeLogo = "https://genius1071.friktoriaservers.net/doitforme/logo/logo_light.svg";

    const [displayedPresets, setDisplayedPresets] = useState(props.accessed);
    const [searchResults, setSearchResults] = useState([]);
    
    const [isOptions, setIsOption] = useState({open: false, index: -1});

    const { reportStyle, setReportStyle } = useContext(ReportStyleContext);

    const apply_style = (style, index) => {
        setReportStyle(style);
        setIsOption({...isOptions, open: !isOptions.open, index})
    };

    useEffect(() => {
        if ( searchResults.input ) setDisplayedPresets(searchResults.data);
        else setDisplayedPresets(props.accessed)
    },[searchResults, props.accessed])
    
    return (
        <StylePresetSaved>
            <Section_Title text={t('Saved Presets')} />
            { props.accessed?.length > 9 ? <Builder_ title={t('Search for a style')}>
                <Google_Search
                    file={props.accessed}
                    fileKeys={['brand']}
                    defaultValue={''}
                    sanitize={true}
                    placeholder={t('Search Preset')}
                    setResults={(data) => setSearchResults(data)}
                    debounceTime={750}
                    minMatchCharacters={1}
                    maxMatchCharacters={50}
                    disabled={false}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                />
            </Builder_> : null }
            <CardsSixty>
                {displayedPresets?.map((style, index) => {
                    return <On_Click_Card 
                        onClick={() => setIsOption({...isOptions, open: !isOptions.open, index})} 
                        view={t('Use')} 
                        onView={() => apply_style(style, index)}
                        delete={t('Delete')} 
                        onDelete={() => {props.onDelete(style); setIsOption({...isOptions, open: !isOptions.open, index})}}
                        options 
                        isOptions={index === isOptions.index && isOptions.open} 
                        imgSmall={
                            style?.logo === "Moon" || style?.logo === "Sun" ?
                            doitformeLogo
                            :  style?.logo
                        }              
                        title={style?.brand} 
                    />
                })}
            </CardsSixty>
        </StylePresetSaved>
    );
}
